import {
  ComponentRef,
  Directive,
  DoCheck,
  EmbeddedViewRef,
  EnvironmentInjector,
  Injector,
  Input,
  NgModuleRef,
  OnInit,
  Renderer2,
  TemplateRef,
  ViewContainerRef
} from '@angular/core'
import {NGXLogger} from 'ngx-logger'
import {ImageLoadingAnimationComponent} from './image-loading-animation.component'

/**
 * A structural directive usable on img which renders a loading animation until the image is loaded.
 *
 * @usageNotes
 *
 * Use this directive on the img tag and pass the image source as argument:
 *
 * @example
 * ```
 * <img *loadingAnimation="imageSourceAsVariable">
 * <img *loadingAnimation="'https://picsum.photos/200.jpg'">
 * ```
 *
 * Optionally, the placement and sizing as well as a background can be defined.
 *
 * ### Sizing
 *
 * For sizing the loading animation, there are 2 options:
 * - `fill`: tries to fit the height of its parent and use the available width
 * - `css-variable`: uses the css variables `--loading-animation-height` and `--loading-animation-width`
 * The default does not apply any sizing related rules.
 *
 * Known issues: The css variable properties need to be set on an ancestor
 *
 * @example
 * ```
 * <img *loadingAnimation="source, sizing: 'fill'">
 * <img *loadingAnimation="source, sizing: 'css-variable'" style="--loading-animation-height: 50px;">
 * ```
 *
 * ### Placement
 *
 * For placing the loading animation, there are 2 options:
 * - `here` (default): places the loading animation where the image element will be rendered inside the placeholder
 *    element (by applying `position: relative`). Using this option will likely require setting a size for desired
 *    results
 * - `container`: places the loading animation outside the placeholder element and inside its closest positioned
 *    ancestor. Using this option will likely require `position: relative` on an ancestor for desired results
 *
 * @example
 * ```
 * <img *loadingAnimation="source"><!-- Uses default `here` placement strategy -->
 * <img *loadingAnimation="source, placement: 'here'">
 * <img *loadingAnimation="source, placement: 'container'">
 * ```
 * ```
 *
 * ### Background
 *
 * For a background on the loading animation, there are 3 options:
 * - `false` (default): no background
 * - `true`: uses `rgba(0, 0, 0, .1) as background
 * - color: uses the given color as background
 *
 * @example
 * ```
 * <img *loadingAnimation="source"><!-- Uses no background (default) -->
 * <img *loadingAnimation="source, background: false">
 * <img *loadingAnimation="source, background: true">
 * <img *loadingAnimation="source, background: '#ff0000'">
 * <img *loadingAnimation="source, background: 'rgba(255, 0, 0, .5)'">
 * ```
 *
 */
@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: 'ng-template[loadingAnimation]',
})
export class ImageLoadingAnimationDirective implements OnInit, DoCheck {
  private animationBackground: CSSColorDefinition | boolean
  private animationPlacementStrategy: 'here' | 'container'
  private animationSizing: 'fill' | 'css-variable' | undefined
  private componentRef: ComponentRef<ImageLoadingAnimationComponent>
  private dirty: boolean
  private image: HTMLImageElement
  private readonly imageLoadListener: () => void
  private imageSource: string
  private viewRef: EmbeddedViewRef<unknown>

  constructor(
    private templateRef: TemplateRef<unknown>,
    private viewContainer: ViewContainerRef,
    private renderer: Renderer2,
    private environmentInjector: EnvironmentInjector,
    private elementInjector: Injector,
    private logger: NGXLogger,
    private moduleRef: NgModuleRef<unknown>
  ) {
    this.dirty = true
    this.imageLoadListener = (): void => {
      this.componentRef?.destroy()
      if (this.viewRef) {
        this.viewContainer.insert(this.viewRef)
      }
    }
  }

  ngDoCheck(): void {
    if (this.dirty) {
      this.recreate()
      this.dirty = false
    }
  }

  ngOnInit(): void {
    this.recreate()
  }

  private recreate(): void {
    this.image?.removeEventListener('load', this.imageLoadListener)
    this.image = undefined
    this.componentRef?.destroy()
    this.viewRef?.destroy()
    this.viewContainer.clear()

    this.viewRef = this.viewContainer.createEmbeddedView(this.templateRef)
    const rootNode: unknown = this.viewRef.rootNodes?.[0]
    if (
      this.viewRef.rootNodes?.length !== 1
      || !(rootNode instanceof HTMLImageElement)
    ) {
      this.logger.error('Incorrect usage! This directive needs to be applied to exactly one HTMLImageElement!')
      return
    }
    this.image = rootNode
    if (typeof this.imageSource === 'string' && this.imageSource !== '') {
      this.image.addEventListener('load', this.imageLoadListener)
      this.image.src = this.imageSource
    }

    const viewIndex = this.viewContainer.indexOf(this.viewRef)
    if (viewIndex !== -1) {
      this.viewContainer.detach(viewIndex)
    }

    this.componentRef = this.viewContainer.createComponent(ImageLoadingAnimationComponent, {
      injector: this.elementInjector,
      ngModuleRef: this.moduleRef
    })
    this.componentRef.instance.loadingAnimationBackground = this.animationBackground
    this.componentRef.instance.placementStrategy = this.animationPlacementStrategy
    this.componentRef.instance.sizing = this.animationSizing
  }

  @Input({required: true}) set loadingAnimation(source: string) {
    this.imageSource = source
    this.dirty = true
  }

  @Input() set loadingAnimationBackground(background: CSSColorDefinition | boolean) {
    this.animationBackground = background
    this.dirty = true
  }

  @Input() set loadingAnimationPlacement(placement: 'here' | 'container') {
    this.animationPlacementStrategy = placement
    this.dirty = true
  }

  @Input() set loadingAnimationSizing(sizing: 'fill' | 'css-variable' | undefined) {
    this.animationSizing = sizing
    this.dirty = true
  }
}

type CSSColorDefinition = string
