import {Component, Inject} from '@angular/core'
import {MAT_DIALOG_DATA} from '@angular/material/dialog'

const TRANSLATION_KEY = {
  LOADING_MODAL_LOADING_CONFIGURATOR: 'LoadingModal.Loading.Configurator',
  LOADING_MODAL_PLEASE_WAIT: 'LoadingModal.PleaseWait',
} as const

@Component({
  templateUrl: './loading-modal.component.html',
  styleUrls: ['./loading-modal.component.scss']
})
export class LoadingModalComponent {
  protected readonly TRANSLATION_KEY = TRANSLATION_KEY
  message: string

  constructor(@Inject(MAT_DIALOG_DATA) loadingMessage: string | null) {
    this.message = loadingMessage ?? TRANSLATION_KEY.LOADING_MODAL_PLEASE_WAIT
  }

  setMessage(message: string): void {
    this.message = message
  }


}
