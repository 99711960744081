/**
 * Use to download an image file in the browser.
 *
 * @param url url to the image
 * @param filename filename (without extension) for the downloaded image
 * @param type type for the downloaded image. Will fall back to png if the browser does not support it. Defaults to png.
 *
 * Note: Currently, browsers support png, jpeg and webp.
 */
export const crossOriginImageDownload =
  (url: string, filename: string = 'image', type: keyof typeof supportedMimeTypes = 'png'): Promise<void> =>
    new Promise((resolve, reject): void => {
      const image = new Image()
      image.setAttribute('crossOrigin', 'anonymous')
      image.addEventListener('load', (): void => {
        const canvas = document.createElement('canvas')
        canvas.setAttribute('height', String(image.height))
        canvas.setAttribute('width', String(image.width))
        const context = canvas.getContext('2d')
        context.drawImage(image, 0, 0)
        const dataUrl = canvas.toDataURL(supportedMimeTypes[type])
        const dataUrlTypeMatch = dataUrl.match(/^data:([^;,]+)(?:;base64)?,/)
        if (dataUrlTypeMatch === null || dataUrlTypeMatch.length < 2) {
          reject(new Error('Unable to parse data url'))
          return
        }
        const dataUrlType = dataUrlTypeMatch[1]
        const extensionMatch = supportedMimeTypes[type] === dataUrlType
        const extension = extensionMatch
          ? type
          : Object.entries(supportedMimeTypes)
            .find((keyValuePair): boolean => keyValuePair[1] === dataUrlType)?.[0]
        if (typeof extension === 'undefined') {
          reject(new Error(`Unknown data type '${dataUrlType}'`))
          return
        }
        const fullFilename = typeof filename !== 'undefined' ? `${filename}.${extension}` : ''
        const downloadLinkHelper = document.createElement('a')
        downloadLinkHelper.setAttribute('target', '_blank')
        downloadLinkHelper.setAttribute('download', fullFilename)
        downloadLinkHelper.setAttribute('href', dataUrl.replace(`data:${dataUrlType}`, 'data:image/octet-stream'))
        downloadLinkHelper.click()
        resolve()
      })
      image.setAttribute('src', url)
    })

const supportedMimeTypes = {
  gif: 'image/gif',
  jpg: 'image/jpeg',
  jpeg: 'image/jpeg',
  png: 'image/png',
  svg: 'image/svg+xml',
  webp: 'image/webp',
}
