import {Beschichtung} from '../../color/beschichtung'
import {OneSidedColorizableComponent} from '../../color/colorizableComponent'
import {ModelConfigurationCoating} from '../../../api/model/configuration/coating/model-configuration-coating.interface'

export class Sprossen extends OneSidedColorizableComponent {
  AnzahlFelder: number
  Art: string
  Beschreibung: string
  Bezeichnung: string
  Breite: number
  DisabilityReasons: string[]
  Hoehe: number
  Id: number
  ImageUrl: string
  IsWienerSprosse: boolean
  ObjectType: string
  PreviewImageUrl: string
  Staerke: number
  Typ: string
  loaded: boolean

  constructor(data?: Partial<Sprossen> & { Beschichtung?: ModelConfigurationCoating }) {
    const coating = data?.Beschichtung ? new Beschichtung(data.Beschichtung) : undefined
    super(data)
    this.AnzahlFelder = data && data.AnzahlFelder
    this.Art = data && data.Art
    this.Beschichtung = coating
    this.Beschreibung = data && data.Beschreibung
    this.Bezeichnung = data && data.Bezeichnung
    this.Breite = data && data.Breite
    this.DisabilityReasons = []
    this.Hoehe = data && data.Hoehe
    this.Id = data && data.Id
    this.ImageUrl = data && data.ImageUrl
    this.IsWienerSprosse = data && data.IsWienerSprosse
    this.loaded = false
    this.ObjectType = 'sprossen'
    this.PreviewImageUrl = data && data.PreviewImageUrl
    this.Staerke = data && data.Staerke
    this.Typ = data && data.Typ

  }

  protected doColorMaterialSupportCheck(): boolean {
    return false
  }
}
