import {ColorBase} from './colorBase'
import {ColorType} from '../../../types'

export class DesignOberflaeche extends ColorBase {
  ImageUrl: string
  IsAussenMoeglich: boolean
  IsDeckschichtMoeglich: boolean
  IsInnenMoeglich: boolean
  IsStruktur: boolean
  PreviewImageUrl: string
  objectType = 'Designoberflaeche'

  constructor(data?: Partial<DesignOberflaeche>) {
    super(data)
    this.ImageUrl = data?.ImageUrl
    this.IsAussenMoeglich = data?.IsAussenMoeglich
    this.IsDeckschichtMoeglich = data?.IsDeckschichtMoeglich
    this.IsInnenMoeglich = data?.IsInnenMoeglich
    this.IsStruktur = data?.IsStruktur
    this.PreviewImageUrl = data?.PreviewImageUrl
  }

  getType(): ColorType {
    return 'designoberflaeche'
  }
}
