import {Beschichtung} from '../../color/beschichtung'
import {OneSidedColorizableComponent} from '../../color/colorizableComponent'
import {ModelFrame} from '../../../api/model/frame/model-frame.interface'


export class Rahmen extends OneSidedColorizableComponent implements ModelFrame {
  Beschreibung: string
  Bezeichnung: string
  Id: number
  ImageUrl: string
  IsAussen: boolean
  IsInnen: boolean
  PreviewImageUrl: string
  Typ: string

  constructor(data?: RahmenConstructorParameter) {
    super(data)
    this.Beschichtung = data && data.Beschichtung && new Beschichtung(data.Beschichtung)
    this.Beschreibung = data && data.Beschreibung
    this.Bezeichnung = data && data.Bezeichnung
    this.Id = data && data.Id
    this.ImageUrl = data && data.ImageUrl
    this.IsAussen = data && data.IsAussen
    this.IsInnen = data && data.IsInnen
    this.PreviewImageUrl = data && data.PreviewImageUrl
    this.Typ = data && data.Typ
  }

  protected doColorMaterialSupportCheck(): boolean {
    return false
  }
}

export type RahmenConstructorParameter<RahmenTyp extends Rahmen = Rahmen> =
  Partial<Omit<RahmenTyp, 'Beschichtung'>> & { Beschichtung?: ConstructorParameters<typeof Beschichtung>[0] }
