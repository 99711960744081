import {Component, DoCheck, ElementRef, ViewChild} from '@angular/core'
import {ParameterService} from '../../../classes/service/parameter/parameter.service'
import {MatInput} from '@angular/material/input'
import {ToastrService} from 'ngx-toastr'
import {TranslateService} from '../../../translate'
import {NavigatorService} from '../../../classes/service/navigation/navigator.service'
import {NavigationMenuEntryKey} from '../../../classes/service/navigation/navigation-menu-entry'
import {EventBusSupport} from '../../../classes/service/event/event-bus-support'
import {EventBusService} from '../../../classes/service/event/event-bus.service'
import {loadRequestDoorId} from '../../../classes/service/event/events'
import {ModelSearchService} from '../../../classes/service/model-search/model-search.service'

const TRANSLATION_KEY = {
  LOADNSEARCH_HEAD_TITLE: 'LoadNSearch.Head.Title',
  LOADNSEARCH_FIND_MODEL_BOX_TITLE: 'LoadNSearch.FindModel.BoxTitle',
  LOADNSEARCH_FIND_MODEL_INPUT_LABEL: 'LoadNSearch.FindModel.Input.Label',
  LOADNSEARCH_FIND_MODEL_INPUT_PLACEHOLDER: 'LoadNSearch.FindModel.Input.Placeholder',
  LOADNSEARCH_FIND_MODEL_INPUT_HINT: 'LoadNSearch.FindModel.Input.Hint',
  LOADNSEARCH_FIND_MODEL_BUTTON_TITLE: 'LoadNSearch.FindModel.Button.Title',
  LOADNSEARCH_NO_ID_HINT: 'LoadNSearch.NoIdHint',
  LOADNSEARCH_LOAD_ID_BOX_TITLE: 'LoadNSearch.LoadId.BoxTitle',
  LOADNSEARCH_LOAD_ID_INPUT_LABEL: 'LoadNSearch.LoadId.Input.Label',
  LOADNSEARCH_LOAD_ID_INPUT_PLACEHOLDER: 'LoadNSearch.LoadId.Input.Placeholder',
  LOADNSEARCH_LOAD_ID_INPUT_HINT: 'LoadNSearch.LoadId.Input.Hint',
  LOADNSEARCH_LOAD_ID_BUTTON_TITLE: 'LoadNSearch.LoadId.Button.Title',
  LOADNSEARCH_CURRENT_ID_BOX_TITLE: 'LoadNSearch.CurrentId.BoxTitle',
  LOADNSEARCH_CURRENT_ID_INPUT_LABEL: 'LoadNSearch.CurrentId.Input.Label',
  LOADNSEARCH_CURRENT_ID_BUTTON_HINT: 'LoadNSearch.CurrentId.Button.Hint',
  LOADNSEARCH_COPY_DOOR_ID_SUCCESS_TITLE: 'LoadNSearch.CopyDoorId.Success.Title',
  LOADNSEARCH_COPY_DOOR_ID_SUCCESS_MESSAGE: 'LoadNSearch.CopyDoorId.Success.Message',
  LOADNSEARCH_COPY_DOOR_ID_ERROR_TITLE: 'LoadNSearch.CopyDoorId.Error.Title',
  LOADNSEARCH_COPY_DOOR_ID_ERROR_MESSAGE: 'LoadNSearch.CopyDoorId.Error.Message'
}

@Component({
  selector: 'configurator-load-n-search-menu',
  templateUrl: './load-n-search-menu.component.html',
  styleUrls: ['./load-n-search-menu.component.scss']
})
export class LoadNSearchMenuComponent extends EventBusSupport implements DoCheck {
  protected readonly NavigationMenuEntryKey = NavigationMenuEntryKey
  protected readonly TRANSLATION_KEY = TRANSLATION_KEY
  @ViewChild('currentDoorIdInput') currentDoorIdInput: ElementRef<HTMLInputElement>
  @ViewChild('currentDoorIdInput', {read: MatInput}) currentDoorIdMatInput: MatInput
  @ViewChild('loadDoorIdInput') loadDoorIdInput: ElementRef<HTMLInputElement>
  private loadingDoorId: string | null

  constructor(
    private readonly modelSearchService: ModelSearchService,
    private readonly navigatorService: NavigatorService,
    private parameterService: ParameterService,
    private toastrService: ToastrService,
    private translateService: TranslateService,
    eventBus: EventBusService
  ) {
    super(eventBus)
    this.loadingDoorId = null
  }

  protected closeMenu(): void {
    this.navigatorService.closeMenu()
  }

  copyDone(successful: boolean): void {
    if (successful) {
      this.toastrService.success(
        this.translateService.translate(TRANSLATION_KEY.LOADNSEARCH_COPY_DOOR_ID_SUCCESS_MESSAGE),
        this.translateService.translate(TRANSLATION_KEY.LOADNSEARCH_COPY_DOOR_ID_SUCCESS_TITLE)
      )
    } else {
      this.toastrService.error(
        this.translateService.translate(TRANSLATION_KEY.LOADNSEARCH_COPY_DOOR_ID_ERROR_MESSAGE),
        this.translateService.translate(TRANSLATION_KEY.LOADNSEARCH_COPY_DOOR_ID_ERROR_TITLE)
      )
    }
  }

  copyIdClicked(event: MouseEvent): boolean {
    this.currentDoorIdInput.nativeElement.blur()
    this.currentDoorIdMatInput._focusChanged(false)
    event.preventDefault()
    event.stopImmediatePropagation()
    return false
  }

  loadDoor(doorId: unknown): void {
    if (typeof doorId === 'string' && doorId.trim() !== '') {
      this.publish(loadRequestDoorId(doorId.trim()))
      this.loadingDoorId = doorId.trim()
    }
  }


  ngDoCheck(): void {
    if (this.loadingDoorId && this.loadingDoorId === this.parameterService.parameter.tuerId) {
      this.loadingDoorId = null
      this.loadDoorIdInput.nativeElement.value = ''
    }
  }

  searchModel(searchTerm: unknown): void {
    if (typeof searchTerm === 'string' && searchTerm.trim() !== '') {
      this.modelSearchService.modelSearchTerm = searchTerm.trim()
      this.modelSearchService.selectedCatalogueId = null
      this.navigatorService.activateMenu(NavigationMenuEntryKey.Model, true)
    }
  }


  get doorId(): string {
    return this.parameterService.parameter.tuerId
  }
}
