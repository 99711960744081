import {ModelConfiguration} from './modelConfiguration'
import {isMaterial, Material} from '../material'
import {
  ModelLoadColorConfigurationRequestData
} from '../../api/model/model-load-color-configuration-request-data.interface'

export class ParameterModelConfiguration implements ModelConfiguration, ModelLoadColorConfigurationRequestData {
  DekorFuellung: string
  DekorFuellungInnen: string
  DekorRahmen: string
  DekorRahmenInnen: string
  FarbeFuellung: string
  FarbeFuellungInnen: string
  FarbeRahmen: string
  FarbeRahmenInnen: string
  bxa: number
  bxb: number
  bya: number
  byb: number
  din: string
  fingerprintFraesung: {
    aktiv: boolean
    b: number
    h: number
    r: number
    t: number
    v: number
  }
  griffPosition: {
    aktiv: boolean
    s: number
    t: number
  }
  griffVerstaerkung: {
    aktiv: boolean
    ga: number
    gb: number
    gd: number
  }
  konstruktion: number
  material: Material
  maxsheeti: number
  maxsheeto: number
  maxstaerke: number
  minsheeti: number
  minsheeto: number
  minstaerke: number
  modellid: number
  pos: string
  pzPosition: {
    aktiv: boolean
    d: number
    typ: string
    v: number
    w: number
  }
  seitenteilHoehenverteilung: {
    a: number
    aktiv: boolean
    b: number
  }
  sixa: number
  sixb: number
  siya: number
  siyb: number
  soxa: number
  soxb: number
  soya: number
  soyb: number
  private staerke: number[] | null = null
  tuertyp: string
  vixa: number
  vixb: number
  viya: number
  viyb: number
  voxa: number
  voxb: number
  voya: number
  voyb: number

  constructor(data?: URLParameters) {
    if (typeof data?.bxa === 'number') {
      this.bxa = data.bxa
    }
    if (typeof data?.bxb === 'number') {
      this.bxb = data.bxb
    }
    if (typeof data?.bya === 'number') {
      this.bya = data.bya
    }
    if (typeof data?.byb === 'number') {
      this.byb = data.byb
    }
    if (typeof data?.dekorfuellung === 'string') {
      this.DekorFuellung = data.dekorfuellung
    }
    if (typeof data?.dekorrahmen === 'string') {
      this.DekorRahmen = data.dekorrahmen
    }
    if (typeof data?.dekorfuellung_innen === 'string') {
      this.DekorFuellungInnen = data.dekorfuellung_innen
    }
    if (typeof data?.dekorrahmen_innen === 'string') {
      this.DekorRahmenInnen = data.dekorrahmen_innen
    }
    if (typeof data?.din === 'string') {
      this.din = data.din
    }
    if (typeof data?.farbefuellung === 'string') {
      this.FarbeFuellung = data.farbefuellung
    }
    if (typeof data?.farberahmen === 'string') {
      this.FarbeRahmen = data.farberahmen
    }
    if (typeof data?.farbefuellung_innen === 'string') {
      this.FarbeFuellungInnen = data.farbefuellung_innen
    }
    if (typeof data?.farberahmen_innen === 'string') {
      this.FarbeRahmenInnen = data.farberahmen_innen
    }
    if (typeof data?.fingerprint === 'object') {
      this.fingerprintFraesung = {
        aktiv: !!data.fingerprint.aktiv,
        b: typeof data.fingerprint.b === 'number' ? data.fingerprint.b : 0,
        h: typeof data.fingerprint.h === 'number' ? data.fingerprint.h : 0,
        r: typeof data.fingerprint.r === 'number' ? data.fingerprint.r : 0,
        t: typeof data.fingerprint.t === 'number' ? data.fingerprint.t : 0,
        v: typeof data.fingerprint.v === 'number' ? data.fingerprint.v : 0,
      }
    }
    if (typeof data?.griff_position === 'object') {
      this.griffPosition = {
        aktiv: !!data.griff_position.aktiv,
        s: typeof data.griff_position.s === 'number' ? data.griff_position.s : 0,
        t: typeof data.griff_position.t === 'number' ? data.griff_position.t : 0,
      }
    }
    if (typeof data?.griff_verstaerkung === 'object') {
      this.griffVerstaerkung = {
        aktiv: !!data.griff_verstaerkung.aktiv,
        ga: typeof data.griff_verstaerkung.ga === 'number' ? data.griff_verstaerkung.ga : 0,
        gb: typeof data.griff_verstaerkung.gb === 'number' ? data.griff_verstaerkung.gb : 0,
        gd: typeof data.griff_verstaerkung.gd === 'number' ? data.griff_verstaerkung.gd : 0,
      }
    }
    if (typeof data?.konstruktion === 'number') {
      this.konstruktion = data.konstruktion
    }
    const material = data?.material?.toUpperCase()
    if (isMaterial(material)) {
      this.material = material
    }
    if (typeof data?.maxsheeti === 'number') {
      this.maxsheeti = data.maxsheeti
    }
    if (typeof data?.maxsheeto === 'number') {
      this.maxsheeto = data.maxsheeto
    }
    if (typeof data?.maxstaerke === 'number') {
      this.maxstaerke = data.maxstaerke
    }
    if (typeof data?.minsheeti === 'number') {
      this.minsheeti = data.minsheeti
    }
    if (typeof data?.minsheeto === 'number') {
      this.minsheeto = data.minsheeto
    }
    if (typeof data?.minstaerke === 'number') {
      this.minstaerke = data.minstaerke
    }
    if (typeof data?.modellid === 'number') {
      this.modellid = data.modellid
    }
    if (typeof data?.pos === 'string') {
      this.pos = data.pos
    }
    if (typeof data?.pz_position === 'object') {
      this.pzPosition = {
        aktiv: !!data.pz_position.aktiv,
        d: typeof data.pz_position.d === 'number' ? data.pz_position.d : 0,
        typ: typeof data.pz_position.typ === 'string' ? data.pz_position.typ : '',
        v: typeof data.pz_position.v === 'number' ? data.pz_position.v : 0,
        w: typeof data.pz_position.w === 'number' ? data.pz_position.w : 0,
      }
    }
    if (typeof data?.st_hoehen_verteilung === 'object') {
      this.seitenteilHoehenverteilung = {
        aktiv: !!data.st_hoehen_verteilung.aktiv,
        a: typeof data.st_hoehen_verteilung.a === 'number' ? data.st_hoehen_verteilung.a : 0,
        b: typeof data.st_hoehen_verteilung.b === 'number' ? data.st_hoehen_verteilung.b : 0,
      }
    }
    if (typeof data?.sixa === 'number') {
      this.sixa = data.sixa
    }
    if (typeof data?.sixb === 'number') {
      this.sixb = data.sixb
    }
    if (typeof data?.siya === 'number') {
      this.siya = data.siya
    }
    if (typeof data?.siyb === 'number') {
      this.siyb = data.siyb
    }
    if (typeof data?.soxa === 'number') {
      this.soxa = data.soxa
    }
    if (typeof data?.soxb === 'number') {
      this.soxb = data.soxb
    }
    if (typeof data?.soya === 'number') {
      this.soya = data.soya
    }
    if (typeof data?.soyb === 'number') {
      this.soyb = data.soyb
    }
    if (typeof data?.tuertyp === 'string') {
      this.tuertyp = data.tuertyp
    }
    if (typeof data?.vixa === 'number') {
      this.vixa = data.vixa
    }
    if (typeof data?.vixb === 'number') {
      this.vixb = data.vixb
    }
    if (typeof data?.viya === 'number') {
      this.viya = data.viya
    }
    if (typeof data?.viyb === 'number') {
      this.viyb = data.viyb
    }
    if (typeof data?.voxa === 'number') {
      this.voxa = data.voxa
    }
    if (typeof data?.voxb === 'number') {
      this.voxb = data.voxb
    }
    if (typeof data?.voya === 'number') {
      this.voya = data.voya
    }
    if (typeof data?.voyb === 'number') {
      this.voyb = data.voyb
    }
    if (this.minstaerke === null || this.minstaerke <= 24) {
      this.minstaerke = 24
    }
    if (this.maxstaerke === null || this.maxstaerke <= 24) {
      this.maxstaerke = 24
    }
    if (this.minstaerke > this.maxstaerke) {
      [this.minstaerke, this.maxstaerke] = [this.maxstaerke, this.minstaerke]
    }
  }

  get staerken(): number[] {
    return this.staerke ??= Array(1 - this.minstaerke + this.maxstaerke)
      .map((_, k): number => k + this.minstaerke)
  }
}

type URLParameters =
  Partial<Omit<
  ParameterModelConfiguration
  , keyof ModelLoadColorConfigurationRequestData | 'fingerprintFraesung' | 'griffPosition' | 'griffVerstaerkung'
  | 'material' | 'pzPosition' | 'seitenteilHoehenverteilung'
  >> & Partial<ModifiedParameter>

type ModifiedParameter =
  ModiefiedParameterBase
  & KeyStringAppendSuffix<ModiefiedParameterBase, '_innen'>
  & { 'fingerprint': ParameterModelConfiguration['fingerprintFraesung'] }
  & { 'griff_position': ParameterModelConfiguration['griffPosition'] }
  & { 'griff_verstaerkung': ParameterModelConfiguration['griffVerstaerkung'] }
  & { 'material': string }
  & { 'pz_position': ParameterModelConfiguration['pzPosition'] }
  & { 'st_hoehen_verteilung': ParameterModelConfiguration['seitenteilHoehenverteilung'] }

type ModiefiedParameterBase =
  KeyLowercase<Pick<
  ModelLoadColorConfigurationRequestData, 'FarbeRahmen' | 'FarbeFuellung' | 'DekorRahmen' | 'DekorFuellung'>
  >

type KeyLowercase<T> = {
  [K in keyof T as K extends string ? Lowercase<K> : K]: T[K];
}

type KeyStringAppendSuffix<T, Suffix extends string = ''> = {
  [K in keyof T as K extends string | number | bigint | boolean ? `${K}${Suffix}` : K]: T[K];
}
