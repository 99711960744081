import {Beschichtung} from '../../color/beschichtung'
import {OneSidedColorizableComponent} from '../../color/colorizableComponent'
import {ColorBase} from '../../color/colorBase'
import {Folie} from '../../color/folie'
import {OptionType, SideType} from '../../../../types'
import {Material} from '../../material'
import {ModelOption} from '../../../api/model/addons/model-option.interface'

const OPTION_CATEGORY_IDENTIFIER_NAME = {
  griffschale: 'Griffschalen',
  zubehoer_allgemein: 'Allgemein',
  applikationen: 'Applikationen',
  fingerprint: 'Fingerprint',
  stangengriff: 'Stangengriffe',
  sockelleiste: 'Sockelleisten',
  rosette: 'Rosetten',
  drueckergarnitur: 'Drückergarnituren',
  tierklappen: 'Tierklappen',
  indoor_design: 'Indoor Designs',
  spion: 'Türspione',
  briefdurchwurf: 'Briefdurchwürfe',
  tuerklopfer: 'Türklopfer',
  rahmen: 'Rahmen',
}

export class Option extends OneSidedColorizableComponent {
  AbhaengigeOptionen: number[]
  Beschreibung: string
  Bezeichnung: string
  Gruppe: string
  Id: number
  ImageUrl: string
  IsAluMoeglich: boolean
  IsAussen: boolean
  IsInnen: boolean
  IsKunststoffMoeglich: boolean
  IsNurEinzelnFaerbbar: boolean
  IsOptional: boolean
  IsOrnamentrahmen: boolean
  IsStandard: boolean
  IsZierrahmen: boolean
  ObjectType: string
  PreviewImageUrl: string
  Typ: OptionType
  disabilityReasons: string[] = []
  loaded: boolean
  side: SideType

  constructor(data?: Partial<ModelOption> | Option) {
    super(data)

    this.Beschreibung = data?.Beschreibung
    this.Bezeichnung = data?.Bezeichnung
    this.Gruppe = data?.Gruppe
    this.Id = data?.Id
    this.ImageUrl = data?.ImageUrl
    this.IsAluMoeglich = data?.IsAluMoeglich
    this.IsAussen = data?.IsAussen
    this.IsInnen = data?.IsInnen
    this.IsKunststoffMoeglich = data?.IsKunststoffMoeglich
    this.IsNurEinzelnFaerbbar = data?.IsNurEinzelnFaerbbar
    this.IsOptional = data?.IsOptional
    this.IsOrnamentrahmen = data?.IsOrnamentrahmen
    this.IsStandard = data?.IsStandard
    this.IsZierrahmen = data?.IsZierrahmen
    this.PreviewImageUrl = data?.PreviewImageUrl
    this.Typ = data?.Typ
    this.loaded = data?.loaded ?? false

    this.ObjectType = 'option'

    this.Beschichtung = data?.Beschichtung ? new Beschichtung(data.Beschichtung) : undefined
    this.side = this.IsAussen ? SideType.Outside : SideType.Inside

    this.AbhaengigeOptionen = []
    if (typeof data?.AbhaengigeOptionen !== 'undefined' && Array.isArray(data?.AbhaengigeOptionen)) {
      this.AbhaengigeOptionen.push(...data.AbhaengigeOptionen)
    }
  }

  protected doColorMaterialSupportCheck(): boolean {
    return true
  }

  isColorSupported(color: ColorBase, side: SideType, material: Material): boolean {
    return super.isColorSupported(color, side, material)
      && !(color instanceof Folie && this.IsOrnamentrahmen && !color.IsOrnamentrahmenMoeglich)
      && !(color instanceof Folie && this.IsZierrahmen && !color.IsOrnamentrahmenMoeglich)
  }

  public static optionCategoryMappings(name: string): string {
    return name in OPTION_CATEGORY_IDENTIFIER_NAME
      ? OPTION_CATEGORY_IDENTIFIER_NAME[name as keyof typeof OPTION_CATEGORY_IDENTIFIER_NAME]
      : name
  }

  suitableForMaterial(material: Material): boolean {
    return (this.IsKunststoffMoeglich && material === Material.Kunststoff)
      || (this.IsAluMoeglich && material === Material.Alu)
  }

  updateVisibility(material: Material): void {
    const reasons: string[] = []
    if (this.IsStandard && !this.IsOptional) {
      reasons.push('Diese Option ist nicht abwählbar.')
    }
    if (!this.suitableForMaterial(material)) {
      switch (material) {
        case Material.Alu:
          reasons.push('Diese Option ist für ALU Profile nicht möglich.')
          break
        case Material.Kunststoff:
          reasons.push('Diese Option ist für Kunststoff Profile nicht möglich.')
          break
      }
    }
    this.disabilityReasons = reasons
  }
}
