import {Component, ElementRef, Input, OnChanges, SimpleChange} from '@angular/core'
import {D3, NgxD3Service, Selection} from '@d-m-p/ngx-d3' // <-- import the D3 Service, the type alias for the d3 variable and the Selection interface

@Component({
  selector: 'door-view',
  template: '<svg id="door-view" class="door-view"></svg>',
  styleUrls: ['door.view.css'],
})
export class DoorViewComponent implements OnChanges {
  private changed = false
  private d3: D3 // <-- Define the private member which will hold the d3 reference
  private doorImageV2: Selection<SVGImageElement, any, null, undefined>
  @Input() heightBackground = 0
  @Input() heightDoor = 0
  private parentNativeElement: any
  private sourcePoints: [number, number][]
  private svgDoor: Selection<SVGSVGElement, any, null, undefined>
  private targetPoints: [number, number][]
  @Input() urlDoor = ''
  @Input() widthBackground = 0
  @Input() widthDoor = 0
  @Input() x0 = 0
  @Input() x1 = 0
  @Input() x2 = 0
  @Input() x3 = 0
  @Input() y0 = 0
  @Input() y1 = 0
  @Input() y2 = 0
  @Input() y3 = 0

  constructor(element: ElementRef, d3Service: NgxD3Service) { // <-- pass the D3 Service into the constructor
    this.d3 = d3Service.getD3() // <-- obtain the d3 object from the D3 Service
    this.parentNativeElement = element.nativeElement
  }

  private LU(A, fast) {
    fast = fast || false

    let i
    let j
    let k
    let absAjk
    let Akk
    let Ak
    let Pk
    let Ai
    let max
    const n = A.length
    const n1 = n - 1
    const P = new Array(n)

    if (!fast) {
      A = this.clone(A)
    }

    for (k = 0; k < n; ++k) {
      Pk = k
      Ak = A[k]
      max = Math.abs(Ak[k])
      for (j = k + 1; j < n; ++j) {
        absAjk = Math.abs(A[j][k])
        if (max < absAjk) {
          max = absAjk
          Pk = j
        }
      }
      P[k] = Pk

      if (Pk != k) {
        A[k] = A[Pk]
        A[Pk] = Ak
        Ak = A[k]
      }

      Akk = Ak[k]

      for (i = k + 1; i < n; ++i) {
        A[i][k] /= Akk
      }

      for (i = k + 1; i < n; ++i) {
        Ai = A[i]
        for (j = k + 1; j < n1; ++j) {
          Ai[j] -= Ai[k] * Ak[j]
          ++j
          Ai[j] -= Ai[k] * Ak[j]
        }
        if (j === n1) {
          Ai[j] -= Ai[k] * Ak[j]
        }
      }
    }

    return {
      LU: A,
      P
    }
  }

  private LUsolve(LUP, b) {
    let i
    let j
    const LU = LUP.LU
    const n = LU.length
    const x = this.clone(b)
    const P = LUP.P
    let Pi
    let LUi
    let tmp

    for (i = n - 1; i !== -1; --i) {
      x[i] = b[i]
    }
    for (i = 0; i < n; ++i) {
      Pi = P[i]
      if (P[i] !== i) {
        tmp = x[i], x[i] = x[Pi], x[Pi] = tmp
      }
      LUi = LU[i]
      for (j = 0; j < i; ++j) {
        x[i] -= x[j] * LUi[j]
      }
    }

    for (i = n - 1; i >= 0; --i) {
      LUi = LU[i]
      for (j = i + 1; j < n; ++j) {
        x[i] -= x[j] * LUi[j]
      }
      x[i] /= LUi[i]
    }

    return x
  }

  private _dim(x) {
    const ret = []
    while (typeof x === 'object') {
      ret.push(x.length), x = x[0]
    }
    return ret
  }

  private _foreach2(x, s, k, f) {
    if (k === s.length - 1) {
      return f(x)
    }
    let i
    const n = s[k]
    const ret = Array(n)
    for (i = n - 1; i >= 0; --i) {
      ret[i] = this._foreach2(x[i], s, k + 1, f)
    }
    return ret
  }

  private changeLayout() {

    const d3 = this.d3 // <-- for convenience use a block scope variable
    let d3ParentElement: Selection<any, any, any, any> // <-- Use the Selection interface (very basic here for illustration only)
    let doorG: Selection<SVGGElement, any, null, undefined>

    if (this.svgDoor != null) {
      this.svgDoor.selectAll('*').remove()
    }

    if (this.parentNativeElement !== null) {

      d3ParentElement = d3.select(this.parentNativeElement)

      this.svgDoor = d3ParentElement.select<SVGSVGElement>('#door-view')

      this.sourcePoints = [[0, 0], [this.widthDoor, 0], [this.widthDoor, this.heightDoor], [0, this.heightDoor]]
      this.targetPoints = [[this.x0, this.y0], [this.x1, this.y1], [this.x2, this.y2], [this.x3, this.y3]]

      this.changedBackgroundDim()

      doorG = this.svgDoor.append<SVGGElement>('g')
      doorG.attr('class', 'na')
      doorG.attr('transform', 'translate(' + 0 + ',' + 0 + ')')

      this.svgDoor.style('transform-origin', 0 + 'px ' + 0 + 'px 0')

      const doorImage = doorG.append('image').attr('id', 'door_panel_image').attr('xlink:href', this.urlDoor).attr('width', this.widthDoor).attr('height', this.heightDoor)

      this.transformed()
    }

  }

  private changedBackgroundDim() {
    this.svgDoor.attr('width', this.widthDoor).attr('height', this.heightDoor)
  }

  private clone(x) {
    return typeof x !== 'object' ? x : this._foreach2(x, this.dim(x), 0, this.cloneV)
  }

  private cloneV(x) {
    const _n = x.length
    let i
    const ret = Array(_n)
    for (i = _n - 1; i !== -1; --i) {
      ret[i] = x[i]
    }
    return ret
  }

  private dim(x) {
    let y
    let z
    if (typeof x === 'object') {
      y = x[0]
      if (typeof y === 'object') {
        z = y[0]
        if (typeof z === 'object') {
          return this._dim(x)
        }
        return [x.length, y.length]
      }
      return [x.length]
    }
    return []
  }

  ngOnChanges(changes: { [propKey: string]: SimpleChange }) {

    if (changes.urlDoor && !changes.urlDoor.isFirstChange()) {
      if (this.svgDoor != null) {
        this.svgDoor.select('image').attr('xlink:href', this.urlDoor)
      }

    }

    if ((changes.widthDoor && !changes.widthDoor.isFirstChange()) ||
      (changes.heightDoor && !changes.heightDoor.isFirstChange())) {
      this.changeLayout()
    }

    if (
      (changes.x0 && !changes.x0.isFirstChange()) ||
      (changes.x1 && !changes.x1.isFirstChange()) ||
      (changes.x2 && !changes.x2.isFirstChange()) ||
      (changes.x3 && !changes.x3.isFirstChange()) ||
      (changes.y0 && !changes.y0.isFirstChange()) ||
      (changes.y1 && !changes.y1.isFirstChange()) ||
      (changes.y2 && !changes.y2.isFirstChange()) ||
      (changes.y3 && !changes.y3.isFirstChange())
    ) {
      this.transformed()
    }


    if (changes.widthBackground && !changes.widthBackground.isFirstChange()) {
      this.changedBackgroundDim()
    }

  }

  ngOnInit() {
    this.changeLayout()
  }

  private solve(A, b, fast) {
    return this.LUsolve(this.LU(A, fast), b)
  };

  private transformed() {
    this.sourcePoints = [[0, 0], [this.widthDoor, 0], [this.widthDoor, this.heightDoor], [0, this.heightDoor]]
    this.targetPoints = [[this.x0, this.y0], [this.x1, this.y1], [this.x2, this.y2], [this.x3, this.y3]]

    for (var a = [], b = [], i = 0, n = this.sourcePoints.length; i < n; ++i) {
      const s = this.sourcePoints[i]
      const t = this.targetPoints[i]
      a.push([s[0], s[1], 1, 0, 0, 0, -s[0] * t[0], -s[1] * t[0]]), b.push(t[0])
      a.push([0, 0, 0, s[0], s[1], 1, -s[0] * t[1], -s[1] * t[1]]), b.push(t[1])
    }

    const X = this.solve(a, b, true)
    const matrix = [
      X[0], X[3], 0, X[6],
      X[1], X[4], 0, X[7],
      0, 0, 1, 0,
      X[2], X[5], 0, 1
    ].map(function(x) {
      return x
    })

    // d3ParentElement.select<SVGSVGElement>('#door-view').style(transform, "matrix3d(" + matrix + ")");
    this.svgDoor.style('transform', 'matrix3d(' + matrix + ')')
  }


}
