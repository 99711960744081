import {Injectable} from '@angular/core'
import {SideType} from '../../types'
import {EventBusService} from './event/event-bus.service'
import {viewChanged} from './event/events'

@Injectable()
export class ViewService {
  private _view: SideType

  constructor(
    private readonly eventBusService: EventBusService
  ) {
    this._view = SideType.Outside
  }

  get currentSide(): SideType {
    return this._view
  }

  set view(view: SideType) {
    if (view !== this._view) {
      const old = this._view
      this._view = view
      this.eventBusService.publish(viewChanged({old, new: this._view}))
    }
  }
}
