export interface InsideOutsideObject<Type> {
  Inside: Type
  Outside: Type
}

export type InsideOutsideArray<Type> = InsideOutsideObject<Type[]>

export interface SimpleIdentifier {
  Id: number
  Typ: ColorType
}

export type OptionType = string

export const enum Din {
  Left = 0,
  Right = 1
}

export enum SideType {
  Inside = 'Inside',
  Outside = 'Outside'
}

export type ColorFilter = 'farben' | 'pulver' | 'folien' | 'designoberflaechen'
export type ColorType = 'farbe' | 'folie' | 'pulver' | 'designoberflaeche'
export type GlasPositionLong = 'GlasscheibeMitte1' | 'GlasscheibeMitte2' | 'GlasscheibeAussen' | 'GlasscheibeInnen'
export type MotivPositionLong = 'MotivInnen' | 'MotivMitte1' | 'MotivMitte2' | 'MotivAussen'
export type GlasaufbauPositionShort = 'innen' | 'aussen' | 'mitte1' | 'mitte2'

export enum GlasMode {
  Glas = 'glas',
  Motiv = 'motiv',
  Sprosse = 'sprosse'
}


export type Oeffnungsart = 'innen' | 'aussen'

export interface errorEntry {
  [key: string]: string
}

export interface minMax {
  maxHeight: number
  maxWidth: number
  minHeight: number
  minWidth: number
}

export enum SizeType {
  Height = 'height',
  Width = 'width'
}

export enum ConfiguratorMode {
  FBS = 'FBS',
  TTK = 'TTK'
}

export type OverloadedParameters<T> =
  T extends {
    (...args: infer P1): unknown
    (...args: infer P2): unknown
    (...args: infer P3): unknown
    (...args: infer P4): unknown
    (...args: infer P5): unknown
    (...args: infer P6): unknown
    (...args: infer P7): unknown
    (...args: infer P8): unknown
    (...args: infer P9): unknown
    (...args: infer P10): unknown
  } ? P1 | P2 | P3 | P4 | P5 | P6 | P7 | P8 | P9 | P10 :
    T extends {
      (...args: infer P1): unknown
      (...args: infer P2): unknown
      (...args: infer P3): unknown
      (...args: infer P4): unknown
      (...args: infer P5): unknown
      (...args: infer P6): unknown
      (...args: infer P7): unknown
      (...args: infer P8): unknown
      (...args: infer P9): unknown
    } ? P1 | P2 | P3 | P4 | P5 | P6 | P7 | P8 | P9 :
      T extends {
        (...args: infer P1): unknown
        (...args: infer P2): unknown
        (...args: infer P3): unknown
        (...args: infer P4): unknown
        (...args: infer P5): unknown
        (...args: infer P6): unknown
        (...args: infer P7): unknown
        (...args: infer P8): unknown
      } ? P1 | P2 | P3 | P4 | P5 | P6 | P7 | P8 :
        T extends {
          (...args: infer P1): unknown
          (...args: infer P2): unknown
          (...args: infer P3): unknown
          (...args: infer P4): unknown
          (...args: infer P5): unknown
          (...args: infer P6): unknown
          (...args: infer P7): unknown
        } ? P1 | P2 | P3 | P4 | P5 | P6 | P7 :
          T extends {
            (...args: infer P1): unknown
            (...args: infer P2): unknown
            (...args: infer P3): unknown
            (...args: infer P4): unknown
            (...args: infer P5): unknown
            (...args: infer P6): unknown
          } ? P1 | P2 | P3 | P4 | P5 | P6 :
            T extends {
              (...args: infer P1): unknown
              (...args: infer P2): unknown
              (...args: infer P3): unknown
              (...args: infer P4): unknown
              (...args: infer P5): unknown
            } ? P1 | P2 | P3 | P4 | P5 :
              T extends {
                (...args: infer P1): unknown
                (...args: infer P2): unknown
                (...args: infer P3): unknown
                (...args: infer P4): unknown
              } ? P1 | P2 | P3 | P4 :
                T extends {
                  (...args: infer P1): unknown
                  (...args: infer P2): unknown
                  (...args: infer P3): unknown
                } ? P1 | P2 | P3 :
                  T extends {
                    (...args: infer P1): unknown
                    (...args: infer P2): unknown
                  } ? P1 | P2 :
                    T extends (...args: infer P) => unknown ? P : never
