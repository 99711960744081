import {Component, Input} from '@angular/core'
import {Catalog} from '../../../class'
import {CatalogueService} from '../../../classes/service/catalogueService'
import {NavigatorService} from '../../../classes/service/navigation/navigator.service'
import {NavigationMenuEntryKey} from '../../../classes/service/navigation/navigation-menu-entry'
import {ModelSearchService} from '../../../classes/service/model-search/model-search.service'

const TRANSLATION_KEY = {
  TITLE: 'CatalogMenuComponent.Title'
}

@Component({
  selector: 'configurator-catalogue-menu',
  styleUrls: ['./catalogue-menu.component.scss'],
  templateUrl: './catalogue-menu.component.html'
})
export class CatalogueMenuComponent {
  protected readonly NavigationMenuEntryKey = NavigationMenuEntryKey
  protected readonly TRANSLATION_KEY = TRANSLATION_KEY
  @Input() lazyImageLoaded: (object: unknown) => void

  constructor(
    private _catalogueService: CatalogueService,
    protected readonly navigatorService: NavigatorService,
    private modelSearchService: ModelSearchService
  ) {
  }

  onSearchInCatalogs(): void {
    this.navigatorService.activateMenu(NavigationMenuEntryKey.Model, true)
  }

  onSelectCatalog(catalog: Catalog): void {
    if (!catalog) {
      return
    }
    this.modelSearchService.selectedCatalogueId = catalog.KatalogId
    this.modelSearchService.modelSearchTerm = ''
    this.navigatorService.activateMenu(NavigationMenuEntryKey.Model)
  }

  get catalogueService(): CatalogueService {
    return this._catalogueService
  }

  get selectedCatalogue(): number | null {
    return this.modelSearchService.selectedCatalogueId
  }
}
