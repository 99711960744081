<modal-frame [title]="TRANSLATION_KEY.HEADING | translate" modalCloseButton>
  <h2>{{ TRANSLATION_KEY.TABLE_HEADING_PARAMETER | translate }}</h2>
  <ng-container *ngTemplateOutlet="dataTable; context: {$implicit: generalParameterData}"></ng-container>

  <br><br>
  <h2>{{ TRANSLATION_KEY.TABLE_HEADING_MODELLE | translate }}</h2>
  <ng-container *ngTemplateOutlet="dataTable; context: {$implicit: modelParameterData}"></ng-container>
</modal-frame>

<ng-template #dataTable let-dataSource>
  <button
    (cdkCopyToClipboardCopied)="this.onClipboardCopy($event)"
    [cdkCopyToClipboard]="this.fullUrl"
    color="primary"
    mat-flat-button
  >{{ TRANSLATION_KEY.COPY_FULL_URL | translate }}
  </button>
  <mat-table [dataSource]="dataSource" matSort>
    <ng-container matColumnDef="parameter">
      <mat-header-cell
        *matHeaderCellDef [sortActionDescription]="TRANSLATION_KEY.TABLE_SORT_ACTION_DESCRIPTION_PARAMETER | translate"
        mat-sort-header
      >
        <h3>{{ TRANSLATION_KEY.TABLE_HEAD_PARAMETER | translate }}</h3>
      </mat-header-cell>
      <mat-cell *matCellDef="let row"> {{ row.parameter }}</mat-cell>
    </ng-container>
    <ng-container matColumnDef="value">
      <mat-header-cell
        *matHeaderCellDef [sortActionDescription]="TRANSLATION_KEY.TABLE_SORT_ACTION_DESCRIPTION_VALUE | translate"
        mat-sort-header
      >
        <h3>{{ TRANSLATION_KEY.TABLE_HEAD_VALUE | translate }}</h3>
      </mat-header-cell>
      <mat-cell *matCellDef="let row"> {{ row.value }}</mat-cell>
    </ng-container>
    <mat-header-row *matHeaderRowDef="['parameter', 'value']"></mat-header-row>
    <mat-row *matRowDef="let row; columns: ['parameter', 'value']"></mat-row>
  </mat-table>
</ng-template>
