import {Component, Input} from '@angular/core'
import {ParameterModelConfiguration} from '../../../classes/model/modelConfiguration/parameterModelConfiguration'
import {GrundformService} from '../../../classes/service/grundform.service'
import {Grundform} from '../../../classes/model/component/other/grundform'
import {ConfiguratorConfigurationModel} from '../../../classes/model/configuratorConfigurationModel'
import {NavigatorService} from '../../../classes/service/navigation/navigator.service'
import {NavigationMenuEntryKey} from '../../../classes/service/navigation/navigation-menu-entry'

const TRANSLATION_KEY = {
  TITLE: 'GrundformMenuComponent.Title'
}

@Component({
  selector: 'configurator-grundform-menu',
  styleUrls: ['./grundform-menu.component.scss'],
  templateUrl: './grundform-menu.component.html'
})
export class GrundformMenuComponent {
  protected readonly NavigationMenuEntryKey = NavigationMenuEntryKey
  protected readonly TRANSLATION_KEY = TRANSLATION_KEY
  @Input() lazyImageLoaded: (object: unknown) => void
  @Input() parameterModel: ParameterModelConfiguration


  constructor(
    private navigatorService: NavigatorService,
    private _grundformService: GrundformService,
    private configuratorConfigurationModel: ConfiguratorConfigurationModel,
  ) {
  }

  protected closeMenu(): void {
    this.navigatorService.closeMenu()
  }

  setGrundform(grundform: Grundform): void {
    this.configuratorConfigurationModel.grundform = grundform
  }

  get currentGrundformKey(): string {
    return this.configuratorConfigurationModel.configuratedDoor?.Grundform?.Key
  }

  get grundformService(): GrundformService {
    return this._grundformService
  }
}
