<configurator-base-menu
  [menuHead]='head'
  [menuNavigatorIdentifier]='NavigationMenuEntryKey.Basis'
  menuClass='basis'
>
  <div class='container'>
    <div class='container-inner max'>
      <!--<div *ngIf="this.activateNav('Basis')" class="container-child">-->
      <div class='container-child'>

        <div class='basisWrapper'>

          <div
            *ngIf='this.selectedComponent?.model?.MenueEinstellungen?.Basis?.MaterialTuersystem?.Sichtbar'
            class='basisrow materialChoice'
          >
            <div class='title'>{{ TRANSLATION_KEY.BOX_TITLE_SYSTEM_MATERIAL|translate }}</div>
            <div class='content'>
              <div (change)="onSystemMaterialChangeRequest($event)" appBetterMatRadioGroup aria-labelledby='mat-radio-group-label'>
                <mat-radio-button
                  [checked]='this.configuratedDoor.profile.Material === Material.Kunststoff'
                  [disabled]='
                    !!modelConfiguration?.material
                    || (
                        !modelConfiguration?.material
                        && !this.selectedComponent?.model?.MenueEinstellungen?.Basis
                                ?.MaterialTuersystem?.Aenderbar
                    )
                  '
                  [value]='Material.Kunststoff'
                >{{ TRANSLATION_KEY.BOX_CONTENT_BUTTON_MATERIAL_KU | translate }}
                </mat-radio-button>
                <mat-radio-button
                  [checked]='this.configuratedDoor.profile.Material === Material.Alu'
                  [disabled]='
                    !!modelConfiguration?.material
                    || (
                        !modelConfiguration?.material
                        && !this.selectedComponent?.model?.MenueEinstellungen?.Basis
                                ?.MaterialTuersystem?.Aenderbar
                    )
                  '
                  [value]='Material.Alu'
                >{{ TRANSLATION_KEY.BOX_CONTENT_BUTTON_MATERIAL_ALU | translate }}
                </mat-radio-button>
              </div>
            </div>
          </div>

          <div
            *ngIf='this.selectedComponent?.model?.MenueEinstellungen?.Basis?.MaterialTuerfuellung?.Sichtbar'
            class='basisrow materialChoice'
          >
            <div class='title'>{{ TRANSLATION_KEY.BOX_TITLE_FILLING_MATERIAL|translate }}</div>
            <div class='content'>
              <div
                #elementMaterialRadio [attr.data-previous]='this.selectedComponent.material'
                [ngModel]='this.selectedComponent.material'
                appBetterMatRadioGroup
                aria-labelledby='mat-radio-group-label'
              >
                <mat-radio-button
                  [disabled]='
                    !this.selectedComponent.model?.IsKunststoffMoeglich
                    || (
                        this.selectedComponent.model?.IsKunststoffMoeglich
                        && !this.selectedComponent?.model?.MenueEinstellungen?.Basis
                                ?.MaterialTuerfuellung?.Aenderbar
                    )
                  '
                  [value]='Material.Kunststoff'
                >{{ TRANSLATION_KEY.BOX_CONTENT_BUTTON_MATERIAL_KU | translate }}
                </mat-radio-button>
                <mat-radio-button
                  [disabled]='
                    !this.selectedComponent.model?.IsAluMoeglich
                    || (
                        this.selectedComponent.model?.IsAluMoeglich
                        && !this.selectedComponent?.model?.MenueEinstellungen?.Basis
                                ?.MaterialTuerfuellung?.Aenderbar
                    )
                  '
                  [value]='Material.Alu'
                >{{ TRANSLATION_KEY.BOX_CONTENT_BUTTON_MATERIAL_ALU | translate }}
                </mat-radio-button>
                <mat-radio-button
                  [disabled]='
                    !this.selectedComponent.model?.IsGlasMoeglich
                    || (
                        this.selectedComponent.model?.IsGlasMoeglich
                        && !this.selectedComponent?.model?.MenueEinstellungen?.Basis
                                ?.MaterialTuerfuellung?.Aenderbar
                    )
                  '
                  [value]='Material.Glas'
                >{{ TRANSLATION_KEY.BOX_CONTENT_BUTTON_MATERIAL_GLASS | translate }}
                </mat-radio-button>
              </div>
            </div>
          </div>

          <div *ngIf='this.selectedComponent?.model?.MenueEinstellungen?.Basis?.Kommission?.Sichtbar'
               class='basisrow kommission'>
            <div class='title'>{{ TRANSLATION_KEY.BOX_TITLE_KOMMISSION | translate }}</div>
            <div class='content'>
              <mat-form-field appearance='fill'>
                <mat-label>{{ TRANSLATION_KEY.BOX_CONTENT_INPUT_KOMMISSION_LABEL | translate }}</mat-label>
                <input
                  [(ngModel)]='this.configuratedDoor.Kommission'
                  [disabled]='!this.selectedComponent?.model?.MenueEinstellungen?.Basis?.Kommission?.Aenderbar'
                  [value]="parameterService.parameter.kommission ?? ''"
                  matInput
                >
              </mat-form-field>
              <mat-form-field *ngIf="this.parameterService.parameter.ext_id" appearance='fill'>
                <mat-label>{{ TRANSLATION_KEY.BOX_CONTENT_INPUT_EXTERN_ID_LABEL | translate }}</mat-label>
                <input
                  [disabled]='true'
                  [value]="this.parameterService.parameter.ext_id"
                  matInput
                >
              </mat-form-field>
            </div>
          </div>


          <div
            *ngIf='this.selectedComponent?.model?.MenueEinstellungen?.Basis?.Oeffnungsart?.Sichtbar'
            class='basisrow oeffnungsart'
          >
            <div class='title'
                 id='oeffnungsart-radio-group-label'>{{ TRANSLATION_KEY.BOX_TITLE_OPENINGTYPE | translate }}
            </div>
            <div class='content'>
              <ng-container *ngIf='this.selectedComponent'>
                <mat-radio-group
                  (ngModelChange)="updateOeffnungsart()"
                  [(ngModel)]='this.selectedComponent.oeffnungsart'
                  aria-labelledby='oeffnungsart-radio-group-label'
                >
                  <mat-radio-button
                    [disabled]='
                      !!modelConfiguration.tuertyp
                      || (
                          !modelConfiguration.tuertyp
                          && !this.selectedComponent?.model?.MenueEinstellungen?.Basis
                                  ?.Oeffnungsart?.Aenderbar
                      )
                    '
                    [value]="'innen'"
                  >{{ TRANSLATION_KEY.BOX_CONTENT_BUTTON_OPENINGTYPE_INSIDE | translate }}
                  </mat-radio-button>
                  <mat-radio-button
                    [disabled]='
                      !!modelConfiguration.tuertyp
                      || (
                          !modelConfiguration.tuertyp
                          && !this.selectedComponent?.model?.MenueEinstellungen?.Basis
                                  ?.Oeffnungsart?.Aenderbar
                      )
                    '
                    [value]="'aussen'"
                  >{{ TRANSLATION_KEY.BOX_CONTENT_BUTTON_OPENINGTYPE_OUTSIDE | translate }}
                  </mat-radio-button>
                </mat-radio-group>
              </ng-container>
            </div>

          </div>

          <div
            *ngIf='this.selectedComponent?.model?.MenueEinstellungen?.Basis?.DinRichtungTuersystem?.Sichtbar'
            class='basisrow  din'
          >
            <div class='title'
                 id='din-radio-group-label'>{{ TRANSLATION_KEY.BOX_TITLE_DIN_SYSTEM | translate }}
            </div>
            <div class='content'>
              <ng-container *ngIf='this.selectedComponent'>
                <mat-radio-group
                  (ngModelChange)="updateOeffnungsart()"
                  [(ngModel)]='this.selectedComponent.din'
                  aria-labelledby='din-radio-group-label'
                >
                  <mat-radio-button
                    [disabled]='
                      !!modelConfiguration.din
                      || (
                          !modelConfiguration.din
                          && !this.selectedComponent?.model?.MenueEinstellungen?.Basis
                                  ?.DinRichtungTuersystem?.Aenderbar
                      )
                    '
                    [value]='0'
                  >{{ TRANSLATION_KEY.BOX_CONTENT_BUTTON_DIN_LEFT | translate }}
                  </mat-radio-button>
                  <mat-radio-button
                    [disabled]='
                      !!modelConfiguration.din
                      || (
                          !modelConfiguration.din
                          && !this.selectedComponent?.model?.MenueEinstellungen?.Basis
                                  ?.DinRichtungTuersystem?.Aenderbar
                      )
                    '
                    [value]='1'
                  >{{ TRANSLATION_KEY.BOX_CONTENT_BUTTON_DIN_RIGHT | translate }}
                  </mat-radio-button>
                </mat-radio-group>
              </ng-container>
            </div>
          </div>


          <div
            *ngIf='this.selectedComponent?.model?.MenueEinstellungen?.Basis?.DinRichtungTuerfuellung?.Sichtbar'
            class='basisrow dinfuellung'
          >
            <div
              class='title'
              id='dinfuellung-radio-group-label'
            >{{ TRANSLATION_KEY.BOX_TITLE_DIN_FILLING | translate }}
            </div>
            <div class='content'>
              <ng-container>
                <mat-radio-group
                  (ngModelChange)="requestRender()"
                  [(ngModel)]='this.selectedComponent.dinfuellung'
                  aria-labelledby='dinfuellung-radio-group-label'
                >
                  <mat-radio-button
                    [disabled]='!this.selectedComponent?.model?.MenueEinstellungen?.Basis
                                                    ?.DinRichtungTuerfuellung?.Aenderbar'
                    [value]='0'
                  >{{ TRANSLATION_KEY.BOX_CONTENT_BUTTON_DIN_LEFT | translate }}
                  </mat-radio-button>
                  <mat-radio-button
                    [disabled]='!this.selectedComponent?.model?.MenueEinstellungen?.Basis
                                                    ?.DinRichtungTuerfuellung?.Aenderbar'
                    [value]='1'
                  >{{ TRANSLATION_KEY.BOX_CONTENT_BUTTON_DIN_RIGHT | translate }}
                  </mat-radio-button>
                </mat-radio-group>
              </ng-container>
            </div>
          </div>


          <div
            *ngIf='this.selectedComponent?.model?.MenueEinstellungen?.Basis?.StaerkeTuerfuellung?.Sichtbar'
            class='basisrow masse'
          >
            <div class='title'>
              {{ TRANSLATION_KEY.BOX_TITLE_WIDTH_FILLING | translate }}
              <i
                #zMassTooltip='mtxTooltip'
                (click)='zMassTooltip._isTooltipVisible() ? zMassTooltip.hide(0) : zMassTooltip.show(0)'
                [mtxTooltipHideDelay]='9999999999999'
                [mtxTooltipShowDelay]='9999999999999'
                [mtxTooltip]='zmassTooltip'
                class='fa fa-info-circle'
              ></i>
            </div>
            <div class='content'>
              <mat-form-field appearance='fill'>
                <mat-label>{{ TRANSLATION_KEY.BOX_CONTENT_INPUT_WIDTH_LABEL | translate }}</mat-label>
                <input [disabled]='true' [value]='this.selectedComponent.staerke.toString()' matInput>
                <span matTextSuffix>{{
                    TRANSLATION_KEY.BOX_CONTENT_INPUT_WIDTH_SUFFIX | translate
                  }}</span>
              </mat-form-field>

              <mat-form-field appearance='fill'>
                <mat-label>{{ TRANSLATION_KEY.BOX_CONTENT_INPUT_ZVALUE_LABEL | translate }}
                  ({{ TRANSLATION_KEY.BOX_CONTENT_INPUT_ZVALUE_MIN_PREFIX | translate }}{{
                    this.configuratedDoor.MinZMass
                  }}{{
                    TRANSLATION_KEY.BOX_CONTENT_INPUT_ZVALUE_MIN_SUFFIX | translate
                  }}{{
                    TRANSLATION_KEY.BOX_CONTENT_INPUT_ZVALUE_MAX_PREFIX | translate
                  }}{{ this.configuratedDoor.MaxZMass }}{{
                    TRANSLATION_KEY.BOX_CONTENT_INPUT_ZVALUE_MAX_SUFFIX | translate
                  }})
                </mat-label>
                <input
                  (ngModelChange)="zMassChange($event)"
                  [disabled]='!this.selectedComponent?.model?.MenueEinstellungen?.Basis
                                            ?.StaerkeTuerfuellung?.Aenderbar'
                  [ngModel]='this.selectedComponent.Zmass'
                  [value]='this.selectedComponent.Zmass.toString()'
                  matInput
                  step='0.1'
                  type='number'
                >
                <span matTextSuffix>{{ TRANSLATION_KEY.BOX_CONTENT_INPUT_ZVALUE_SUFFIX | translate }}</span>
              </mat-form-field>
            </div>
          </div>

          <div
            *ngIf='this.selectedComponent?.model?.MenueEinstellungen?.Basis?.MasseTuerfuellung?.Sichtbar'
            class='basisrow masse'
          >
            <div class='title'
                 id='masse-radio-group-label'>{{ TRANSLATION_KEY.BOX_TITLE_MEASURES | translate }}
            </div>
            <div class='content'>

              <button (click)="showMasseModal()" class='fullWidth' mat-flat-button>
                {{ TRANSLATION_KEY.BOX_CONTENT_BUTTON_SHOW_MEASURES | translate }}
              </button>

            </div>
          </div>

          <div
            *ngIf='this.selectedComponent?.konstruktion !== SimpleConstruction.Einsatz
                                && this.selectedComponent?.model?.MenueEinstellungen?.Basis?.Klebesystem?.Sichtbar'
            class='basisrow klebesystem'
          >
            <div class='title'>{{ TRANSLATION_KEY.BOX_TITLE_ADHESIVE_SYSTEM | translate }}</div>
            <div class='content'>
              <mat-form-field appearance='fill'>
                <mat-label>{{ TRANSLATION_KEY.BOX_CONTENT_DROPDOWN_ADHESIVE_SYSTEM_LABEL | translate }}</mat-label>
                <mat-select
                  (ngModelChange)="updateZMass()"
                  [(ngModel)]='this.selectedComponent.Klebesystem'
                  [compareWith]='ObjectUtil.matchIds'
                  [disabled]='!this.selectedComponent?.model?.MenueEinstellungen?.Basis?.Klebesystem?.Aenderbar'
                >
                  <ng-container *ngIf='this.selectedComponent.model'>
                    <ng-container *ngFor='let k of this.selectedComponent.model.Klebesysteme'>
                      <mat-option [value]='k'>
                        {{ k.Bezeichnung | translate }}
                      </mat-option>
                    </ng-container>
                  </ng-container>
                </mat-select>
              </mat-form-field>
            </div>
          </div>

          <div
            *ngIf='this.selectedComponent?.model?.MenueEinstellungen?.Basis?.Konstruktion?.Sichtbar'
            class='basisrow konstruktion'
          >
            <div class='title'>{{ TRANSLATION_KEY.BOX_TITLE_CONSTRUCTION | translate }}</div>
            <div class='content'>

              <mat-radio-group
                (change)="onConstructionChangeRequest($event)"
                [value]="this.selectedComponent.konstruktion"
                aria-labelledby='mat-radio-group-label'
              >
                <mat-radio-button
                  [disabled]='
                    this.constructionDisabled(modelConfiguration.konstruktion)
                    || (
                        !this.constructionDisabled(modelConfiguration.konstruktion)
                        && !this.selectedComponent?.model?.MenueEinstellungen?.Basis?.Konstruktion?.Aenderbar
                    )
                  '
                  [value]='SimpleConstruction.Einsatz'
                >{{ TRANSLATION_KEY.BOX_CONTENT_CONSTRUCTION_INSERTED | translate }}
                </mat-radio-button>
                <mat-radio-button
                  [disabled]='
                    this.constructionDisabled(modelConfiguration.konstruktion)
                    || (
                        !this.constructionDisabled(modelConfiguration.konstruktion)
                        && !this.selectedComponent?.model?.MenueEinstellungen?.Basis?.Konstruktion?.Aenderbar
                    )
                  '
                  [value]='SimpleConstruction.AufsatzEinseitig'
                >{{ TRANSLATION_KEY.BOX_CONTENT_CONSTRUCTION_CONCEALING_SINGLE | translate }}
                </mat-radio-button>
                <mat-radio-button
                  [disabled]='
                    this.constructionDisabled(modelConfiguration.konstruktion)
                    || (
                        !this.constructionDisabled(modelConfiguration.konstruktion)
                        && !this.selectedComponent?.model?.MenueEinstellungen?.Basis?.Konstruktion?.Aenderbar
                    )
                  '
                  [value]='SimpleConstruction.AufsatzBeidseitig'
                >{{ TRANSLATION_KEY.BOX_CONTENT_CONSTRUCTION_CONSEALING_BOTH | translate }}
                </mat-radio-button>
              </mat-radio-group>
            </div>
          </div>

          <div
            *ngIf='this.selectedComponent?.konstruktion !== SimpleConstruction.Einsatz
                                && (this.selectedComponent?.model?.MenueEinstellungen?.Basis?.KonstruktionsVariante?.Sichtbar
                                || this.selectedComponent?.model?.MenueEinstellungen?.Basis?.Klebeset?.Sichtbar)'
            class='basisrow konstruktion'
          >
            <div class='title'>{{ TRANSLATION_KEY.BOX_TITLE_CONSTRUCTION_VARIANT | translate }}</div>
            <div class='content'>
              <ng-container *ngIf='this.selectedComponent.model?.Konstruktionen?.length > 0'>

                <mat-form-field *ngIf='this.selectedComponent.fbsKonstruktion?.Id' appearance='fill'>
                  <mat-label>{{ TRANSLATION_KEY.BOX_CONTENT_INPUT_CONSTRUCTION_VARIANT_CHOOSE_LABEL | translate }}</mat-label>
                  <input
                    (click)="showKonstruktionsauswahlModal()"
                    [disabled]='!this.selectedComponent?.model?.MenueEinstellungen?.Basis?.KonstruktionsVariante?.Aenderbar'
                    [value]='this.selectedComponent.fbsKonstruktion?.Bezeichnung | translate'
                    class='text-left'
                    matInput
                    placeholder='{{TRANSLATION_KEY.BOX_CONTENT_INPUT_CONSTRUCTION_VARIANT_CHOOSE_LABEL | translate}}'
                    readonly
                  />
                </mat-form-field>

                <ng-container *ngIf='!this.selectedComponent.fbsKonstruktion?.Id'>
                  <button
                    (click)="showKonstruktionsauswahlModal()"
                    [disabled]='!this.selectedComponent?.model?.MenueEinstellungen?.Basis?.KonstruktionsVariante?.Aenderbar'
                    class='fullWidth' mat-flat-button
                  >{{ TRANSLATION_KEY.BOX_CONTENT_INPUT_CONSTRUCTION_VARIANT_CHOOSE_BUTTON | translate }}
                  </button>
                </ng-container>
              </ng-container>

              <mat-checkbox
                *ngIf='this.selectedComponent?.model?.MenueEinstellungen?.Basis?.Klebeset?.Sichtbar
                            && this.selectedComponent?.Klebesystem?.Id === 2'
                [(ngModel)]='this.selectedComponent.Klebeset'
                [checked]='this.selectedComponent?.Klebeset'
                [disabled]='!this.selectedComponent?.model?.MenueEinstellungen?.Basis?.Klebeset?.Aenderbar'
              >{{ TRANSLATION_KEY.BOX_CONTENT_INPUT_CONSTRUCTION_VARIANT_ADHESIONSET | translate }}
              </mat-checkbox>

            </div>

          </div>

          <div *ngIf='this.selectedComponent?.model?.MenueEinstellungen?.Basis?.Werksmontage?.Sichtbar'
               class='basisrow montage'>
            <div class='title'>{{ TRANSLATION_KEY.BOX_TITLE_SHOPASSEMBLY | translate }}</div>
            <div class='content'>
              <mat-checkbox
                [(ngModel)]='this.configuratedDoor.Werksmontage'
                [checked]='this.configuratedDoor.Werksmontage'
                [disabled]='!this.selectedComponent?.model?.MenueEinstellungen?.Basis?.Werksmontage?.Aenderbar'
              >{{ TRANSLATION_KEY.BOX_CONTENT_CHECKBOX_SHOPASSEMBLY_LABEL | translate }}
              </mat-checkbox>
            </div>
          </div>

          <div *ngIf='this.selectedComponent?.model?.MenueEinstellungen?.Basis?.Seeklimatauglich?.Sichtbar'
               class='basisrow'>
            <div class='title'>{{ TRANSLATION_KEY.BOX_TITLE_MARITIMECLIMATE | translate }}</div>
            <div class='content'>
              <mat-checkbox
                [(ngModel)]='this.configuratedDoor.Seeklimatauglich'
                [checked]='this.configuratedDoor.Seeklimatauglich'
                [disabled]='!this.selectedComponent?.model?.MenueEinstellungen?.Basis?.Seeklimatauglich?.Aenderbar'
              >{{ TRANSLATION_KEY.BOX_CONTENT_CHECKBOX_MARITIMECLIMATE_LABEL | translate }}
              </mat-checkbox>
            </div>
          </div>

          <div *ngIf='this.selectedComponent?.model?.MenueEinstellungen?.Basis?.Pakete?.Sichtbar'
               class='basisrow pakete'>
            <div class='title'>{{ TRANSLATION_KEY.BOX_TITLE_PACKAGES | translate }}</div>
            <div class='content'>
              <ng-template [ngTemplateOutlet]='waermeschutzDropdown'></ng-template>

              <mat-form-field appearance='fill'>
                <mat-label>{{ TRANSLATION_KEY.BOX_CONTENT_DROPDOWN_PACKAGES_SECURITY_LABEL | translate }}</mat-label>
                <mat-select
                  [(ngModel)]='securityPackage'
                  [compareWith]='ObjectUtil.matchIds'
                  [disabled]='!this.selectedComponent?.model?.MenueEinstellungen?.Basis?.Pakete?.Aenderbar'
                >
                  <mat-option [value]='null'>
                    {{ TRANSLATION_KEY.BOX_CONTENT_DROPDOWN_PACKAGES_SECURITY_EMPTY_OPTION | translate }}
                  </mat-option>
                  <ng-container *ngFor='let s of getSicherheitspakete()'>
                    <mat-option [value]='s'>
                      {{ s.Bezeichnung | translate }}
                    </mat-option>
                  </ng-container>
                </mat-select>
              </mat-form-field>
            </div>
          </div>

          <div *ngIf='this.selectedComponent?.model?.MenueEinstellungen?.Basis?.Hoehenverteilung?.Sichtbar'
               class='basisrow'>
            <div class='title'>{{ TRANSLATION_KEY.BOX_TITLE_HEIGHTDISTRIBUTION|translate }}</div>
            <div class='content'>
              <mat-form-field appearance='fill'>
                <mat-label>{{ TRANSLATION_KEY.BOX_CONTENT_INPUT_HEIGHTDISTRIBUTION_LABEL | translate }}</mat-label>
                <input
                  [(ngModel)]='this.selectedComponent.Hoehenverteilung'
                  [disabled]='!this.selectedComponent?.model?.MenueEinstellungen?.Basis?.Hoehenverteilung?.Aenderbar'
                  matInput
                  type='number'
                >
                <span
                  matTextSuffix>{{ TRANSLATION_KEY.BOX_CONTENT_INPUT_HEIGHTDISTRIBUTION_LABEL_SUFFIX | translate }}</span>
              </mat-form-field>
            </div>
          </div>

          <div
            *ngIf='this.selectedComponent.Querfries
                                && this.selectedComponent?.model?.MenueEinstellungen?.Basis?.Querfries?.Sichtbar'
            class='basisrow'
          >
            <div class='title'>{{ TRANSLATION_KEY.BOX_TITLE_RAIL|translate }}</div>
            <div class='content'>
              <mat-form-field appearance='fill'>
                <mat-label>{{ TRANSLATION_KEY.BOX_CONTENT_INPUT_RAIL_LABEL | translate }}</mat-label>
                <input
                  [(ngModel)]='this.selectedComponent.Querfries'
                  [disabled]='!this.selectedComponent?.model?.MenueEinstellungen?.Basis?.Querfries?.Aenderbar'
                  matInput
                  type='number'
                >
                <span matTextSuffix>{{ TRANSLATION_KEY.BOX_CONTENT_INPUT_RAIL_LABEL_SUFFIX | translate }}</span>
              </mat-form-field>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
</configurator-base-menu>


<ng-template #waermeschutzDropdown>
  <mat-form-field *ngIf='this.selectedComponent.glasaufbau' appearance='fill'>
    <mat-label>{{ TRANSLATION_KEY.BOX_CONTENT_DROPDOWN_PACKAGES_INSULATION_LABEL | translate }}</mat-label>
    <mat-select
      [(ngModel)]='this.thermalInsulationPackage'
      [compareWith]='ObjectUtil.matchIds'
      [disabled]='!this.selectedComponent?.model?.MenueEinstellungen?.Basis?.Pakete?.Aenderbar'
    >
      <mat-option [value]='null'>
        {{ TRANSLATION_KEY.BOX_CONTENT_DROPDOWN_PACKAGES_INSULATION_EMPTY_OPTION | translate }}
      </mat-option>
      <ng-container *ngFor='let ws of getWaermeschutzpakete()'>
        <mat-option [value]='ws'>
          {{ ws.Bezeichnung | translate }}
        </mat-option>
      </ng-container>
    </mat-select>
  </mat-form-field>
</ng-template>

<ng-template #zmassTooltip>
  <div class='tooltipWrapper'>
    <img [src]="'https://images.rodenberg.ag/konstruktionen/zmass' + this.selectedComponent.konstruktion + '.png'"
         alt='ZMassImage'>
    <div>
      <h3>{{ TRANSLATION_KEY.ZVALUEMODAL_TITLE | translate }}</h3>
      <p>
        {{ TRANSLATION_KEY.ZVALUEMODAL_MESSAGE | translate }}
      </p>
    </div>
  </div>
</ng-template>

<ng-template #head>
  <div class='name'>
    <span>{{ TRANSLATION_KEY.TITLE | translate }}</span>
    <span (click)="closeMenu()" class='close'></span>
  </div>
</ng-template>
