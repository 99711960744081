<configurator-base-menu
  [menuHead]='head'
  [menuNavigatorIdentifier]='NavigationMenuEntryKey.Glass'
  has-component-selector
  menuClass='glaeser'
>

  <ng-container *ngIf='this.configuratorMode === ConfiguratorMode.FBS'>
    <div class='container'>
      <div #scrollGlasContainer class='container-inner max'>
        <div *ngIf='this.selectedComponent?.glasaufbau' class='fbsGlaeserDiv'>
          <ng-container *ngIf='this.selectedComponent.glasaufbau.errors.length'>
            <p
              *ngFor='let item of this.selectedComponent.glasaufbau.errors'
              [switchOnTypeOf]='item'
              class='fbsGlasaufbauImpossible'
            >
              <i class='fa fa-exclamation-triangle'></i>
              <ng-container *caseForTyp='SpacerThicknessUndercutError as err'>
                {{
                  TRANSLATION_KEY.ERROR_SPACER_UNDERCUT_MESSAGE_PREFIX | translate
                }}{{
                  item.requiredSpacerThickness
                }}{{
                  TRANSLATION_KEY.ERROR_SPACER_UNDERCUT_MESSAGE_SUFFIX | translate
                }}
              </ng-container>
              <ng-container *caseForTyp='SpacerThicknessExceededError as err'>
                {{
                  TRANSLATION_KEY.ERROR_SPACER_EXCEEDED_MESSAGE_PREFIX | translate
                }}{{
                  item.requiredSpacerThickness
                }}{{
                  TRANSLATION_KEY.ERROR_SPACER_EXCEEDED_MESSAGE_SUFFIX | translate
                }}
              </ng-container>
              <ng-container *caseForTypUnknown>
                {{ TRANSLATION_KEY.ERROR_SPACER_UNKNOWN_MESSAGE | translate }}
              </ng-container>
            </p>
          </ng-container>


          <div *ngIf='this.configuratorMode === ConfiguratorMode.FBS' class='basisrow pakete'>
            <div class='title'>{{ TRANSLATION_KEY.GLASSES_MENU_DROPDOWN_PACKAGE_TITLE|translate }}</div>
            <div class='content'>
              <ng-template [ngTemplateOutlet]='waermeschutzDropdown'></ng-template>
            </div>
          </div>

          <ng-container
            *ngIf='this.selectedComponent.model?.Sprossen && this.selectedComponent.model.Sprossen.length > 0'
          >
            <div class='basisrow sprossen'>
              <div
                [class.active]='this.selectedComponent.glasaufbau.Sprossen'
                class='title'
              >{{ TRANSLATION_KEY.GLASSES_MENU_DROPDOWN_SPROSSEN_TITLE | translate }}
              </div>
              <div class='content'>
                <mat-form-field
                  *ngIf='this.selectedComponent.glasaufbau.Sprossen'
                  appearance='fill'
                >
                  <mat-label>{{ TRANSLATION_KEY.GLASSES_MENU_DROPDOWN_SPROSSEN_CHOICE_LABEL | translate }}</mat-label>
                  <input
                    (click)='showSprossenModal(this.selectedComponent)'
                    [value]='this.selectedComponent.glasaufbau.Sprossen.Bezeichnung'
                    matInput
                    readonly
                  >
                  <button
                    (click)="this.unset('sprosse')" aria-label='{{ TRANSLATION_KEY.CLEAR | translate }}'
                    mat-icon-button
                    matSuffix
                  >
                    <i class='fa fa-times'></i>
                  </button>
                </mat-form-field>

                <ng-container *ngIf='!this.selectedComponent.glasaufbau.Sprossen'>
                  <button
                    (click)='showSprossenModal(this.selectedComponent)' class='fullWidth'
                    mat-flat-button
                  >
                    {{ TRANSLATION_KEY.GLASSES_MENU_BUTTON_CHOOSE_SPROSSEN_LABEL | translate }}
                  </button>
                </ng-container>
              </div>
            </div>
          </ng-container>

          <div *ngIf='this.configuratorMode === ConfiguratorMode.FBS' class='basisrow'>
            <div class='title'>
              {{ TRANSLATION_KEY.GLASSES_MENU_GLASSES_NUM_TITLE | translate }}
            </div>
            <div class='content anzahlglaeser'>
              <mat-radio-group
                (change)='this.onAnzahlGlaeserChanged($event)'
                aria-labelledby='mat-radio-group-label'
              >
                <mat-radio-button
                  [checked]='this.selectedComponent?.glasaufbau?.numGlasses === 2'
                  [disabled]='this.selectedComponent?.glasaufbau.MinAnzahlGlasscheiben > 2'
                  [value]="'2'"
                >{{ TRANSLATION_KEY.GLASSES_MENU_GLASSES_NUM_TWO | translate }}
                </mat-radio-button>
                <mat-radio-button
                  [checked]='this.selectedComponent?.glasaufbau?.numGlasses === 3'
                  [disabled]='this.selectedComponent?.glasaufbau.MinAnzahlGlasscheiben > 3'
                  [value]="'3'"
                >{{ TRANSLATION_KEY.GLASSES_MENU_GLASSES_NUM_THREE | translate }}
                </mat-radio-button>
                <mat-radio-button
                  [checked]='this.selectedComponent?.glasaufbau?.numGlasses === 4'
                  [disabled]='this.selectedComponent?.glasaufbau.MinAnzahlGlasscheiben > 4'
                  [value]="'4'"
                >{{ TRANSLATION_KEY.GLASSES_MENU_GLASSES_NUM_FOUR | translate }}
                </mat-radio-button>

              </mat-radio-group>

              <img
                [src]="'https://images.rodenberg.ag/konstruktionen/'
                                        + this.selectedComponent?.glasaufbau?.numGlasses
                                        + 'Glas.png'"
                class='glassesImage'
              >

              <div class='alert alert-primary'>
                <p *ngIf='this.selectedComponent?.glasaufbau?.numGlasses === 2'><span
                  class='tipp-title'
                >{{ TRANSLATION_KEY.HINT_TITLE  | translate }}
                  :</span> {{ TRANSLATION_KEY.HINT_2_GLASSES_MOTIVE_POSITION | translate }}
                </p>
                <p *ngIf='this.selectedComponent?.glasaufbau?.numGlasses === 3'><span
                  class='tipp-title'
                >{{ TRANSLATION_KEY.HINT_TITLE  | translate }}
                  :</span> {{ TRANSLATION_KEY.HINT_3_GLASSES_MOTIVE_POSITION | translate }}
                </p>
                <p *ngIf='this.selectedComponent?.glasaufbau?.numGlasses === 4'><span
                  class='tipp-title'
                >{{ TRANSLATION_KEY.HINT_TITLE  | translate }}
                  :</span> {{ TRANSLATION_KEY.HINT_4_GLASSES_MOTIVE_POSITION | translate }}
                </p>
              </div>

            </div>
          </div>
          <div
            *ngIf='this.configuratorMode === ConfiguratorMode.FBS'
            [class.filled]='this.selectedComponent.glasaufbau.GlasscheibeAussen?.Id
            && this.selectedComponent.glasaufbau.GlasscheibeAussen?.Id > 0'
            class='fbsGlasContainer'
          >
            <p class='topic'>{{ TRANSLATION_KEY.GLASPOSITION_OUTSIDE|translate }}</p>
            <div class='content'>
              <p>{{ TRANSLATION_KEY.GLASS|translate }}</p>
              <div class='row'>
                <mat-form-field
                  *ngIf='this.selectedComponent.glasaufbau.GlasscheibeAussen?.Id
                                            && this.selectedComponent.glasaufbau.GlasscheibeAussen?.Id > 0'
                  appearance='fill'
                >
                  <mat-label>{{ TRANSLATION_KEY.INPUT_CHOICE_LABEL | translate }}</mat-label>
                  <input
                    (click)="showGlassModal('aussen', 'glass')"
                    [value]='this.selectedComponent.glasaufbau.GlasscheibeAussen?.Beschreibung | translate' matInput
                    placeholder='{{TRANSLATION_KEY.INPUT_CHOICE_PLACEHOLDER | translate}}'
                    readonly
                  >
                  <button aria-label='{{TRANSLATION_KEY.CLEAR}}' disabled mat-icon-button matSuffix>
                    <i class='fa fa-times'></i>
                  </button>
                </mat-form-field>

                <ng-container *ngIf='!this.selectedComponent.glasaufbau.GlasscheibeAussen?.Id'>
                  <button
                    (click)="showGlassModal('aussen','glass')" class='fullWidth'
                    mat-flat-button
                  >
                    {{ TRANSLATION_KEY.CHOOSE_GLASS | translate }}
                  </button>
                </ng-container>
              </div>

              <ng-container *ngIf='this.selectedComponent.model?.Motive.length > 0'>
                <p>{{ TRANSLATION_KEY.MOTIVE |translate }}</p>
                <div
                  [mtxTooltipDisabled]="this.selectedComponent.glasaufbau.motivPossible('aussen')"
                  [mtxTooltip]='TRANSLATION_KEY.ERROR_MOTIVE_GLASS_INCOMPATIBLE | translate'
                  class='row'
                >

                  <mat-form-field
                    *ngIf='this.selectedComponent.glasaufbau.MotivAussen?.Id && this.selectedComponent.glasaufbau.MotivAussen?.Id > 0'
                    appearance='fill'
                  >
                    <mat-label>{{ TRANSLATION_KEY.INPUT_CHOICE_LABEL | translate }}</mat-label>
                    <input
                      (click)="showGlassModal('aussen', 'motiv')"
                      [value]='this.selectedComponent.glasaufbau.MotivAussen?.Beschreibung  | translate' matInput
                      placeholder='{{ TRANSLATION_KEY.INPUT_CHOICE_PLACEHOLDER | translate}}'
                      readonly
                    >
                    <button
                      (click)="this.unset('motiv', 'aussen')" aria-label='{{TRANSLATION_KEY.CLEAR | translate}}'
                      mat-icon-button
                      matSuffix
                    >
                      <i class='fa fa-times'></i>
                    </button>
                  </mat-form-field>

                  <ng-container *ngIf='!this.selectedComponent.glasaufbau.MotivAussen?.Id'>
                    <button
                      (click)="showGlassModal('aussen', 'motiv')"
                      [disabled]="!this.selectedComponent.glasaufbau.motivPossible('aussen')"
                      class='fullWidth' mat-flat-button
                    >
                      {{ TRANSLATION_KEY.CHOOSE_MOTIVE | translate }}
                    </button>
                  </ng-container>
                </div>
              </ng-container>
            </div>
          </div>


          <div
            *ngIf='this.configuratorMode === ConfiguratorMode.FBS && this.selectedComponent?.glasaufbau?.numGlasses > 2'
            [class.filled]='this.selectedComponent.glasaufbau.GlasscheibeMitte1?.Id
                                && this.selectedComponent.glasaufbau.GlasscheibeMitte1?.Id > 0'
            class='fbsGlasContainer'
          >
            <p class='topic'>{{ TRANSLATION_KEY.GLASPOSITION_MID1|translate }}</p>
            <div class='content'>
              <p>{{ TRANSLATION_KEY.GLASS| translate }}</p>
              <div class='row'>
                <mat-form-field
                  *ngIf='this.selectedComponent.glasaufbau.GlasscheibeMitte1?.Id
                                            && this.selectedComponent.glasaufbau.GlasscheibeMitte1?.Id > 0'
                  appearance='fill'
                >
                  <mat-label>{{ TRANSLATION_KEY.INPUT_CHOICE_LABEL | translate }}</mat-label>
                  <input
                    (click)="this.showGlassModal('mitte1', 'glass')"
                    [value]='this.selectedComponent.glasaufbau.GlasscheibeMitte1?.Beschreibung | translate' matInput
                    placeholder='{{ TRANSLATION_KEY.INPUT_CHOICE_PLACEHOLDER | translate}}'
                    readonly
                  >
                  <button
                    (click)="this.unset('glass', 'mitte1')" aria-label='{{TRANSLATION_KEY.CLEAR | translate}}'
                    mat-icon-button
                    matSuffix
                  >
                    <i class='fa fa-times'></i>
                  </button>
                </mat-form-field>

                <ng-container *ngIf='!this.selectedComponent.glasaufbau.GlasscheibeMitte1?.Id'>
                  <button
                    (click)="this.showGlassModal('mitte1','glass')" class='fullWidth'
                    mat-flat-button
                  >
                    {{ TRANSLATION_KEY.CHOOSE_GLASS | translate }}
                  </button>
                </ng-container>
              </div>

              <ng-container *ngIf='this.selectedComponent.model?.Motive.length > 0'>
                <p>{{ TRANSLATION_KEY.MOTIVE|translate }}</p>
                <div
                  [mtxTooltipDisabled]="this.selectedComponent.glasaufbau.motivPossible('mitte1')"
                  [mtxTooltip]='TRANSLATION_KEY.ERROR_MOTIVE_GLASS_INCOMPATIBLE | translate'
                  class='row'
                >

                  <mat-form-field
                    *ngIf='this.selectedComponent.glasaufbau.MotivMitte1?.Id
                                                && this.selectedComponent.glasaufbau.MotivMitte1?.Id > 0'
                    appearance='fill'
                  >
                    <mat-label>{{ TRANSLATION_KEY.INPUT_CHOICE_LABEL | translate }}</mat-label>
                    <input
                      (click)="this.showGlassModal('mitte1', 'motiv')"
                      [value]='this.selectedComponent.glasaufbau.MotivMitte1?.Beschreibung | translate' matInput
                      placeholder='{{TRANSLATION_KEY.INPUT_CHOICE_PLACEHOLDER | translate}}'
                      readonly
                    >
                    <button
                      (click)="this.unset('motiv', 'mitte1')" aria-label='{{TRANSLATION_KEY.CLEAR | translate}}'
                      mat-icon-button
                      matSuffix
                    >
                      <i class='fa fa-times'></i>
                    </button>
                  </mat-form-field>

                  <ng-container *ngIf='!this.selectedComponent.glasaufbau.MotivMitte1?.Id'>
                    <button
                      (click)="this.showGlassModal('mitte1', 'motiv')"
                      [disabled]="!this.selectedComponent.glasaufbau.motivPossible('mitte1')"
                      class='fullWidth' mat-flat-button
                    >
                      {{ TRANSLATION_KEY.CHOOSE_MOTIVE | translate }}
                    </button>
                  </ng-container>
                </div>
              </ng-container>
            </div>
          </div>

          <div
            *ngIf='this.configuratorMode === ConfiguratorMode.FBS && this.selectedComponent?.glasaufbau?.numGlasses > 3'
            [class.filled]='this.selectedComponent.glasaufbau.GlasscheibeMitte2?.Id
                                && this.selectedComponent.glasaufbau.GlasscheibeMitte2?.Id > 0'
            class='fbsGlasContainer'
          >
            <p class='topic'>{{ TRANSLATION_KEY.GLASPOSITION_MID2 | translate }}</p>

            <div class='content'>
              <p>{{ TRANSLATION_KEY.GLASS|translate }}</p>
              <div class='row'>
                <mat-form-field
                  *ngIf='this.selectedComponent.glasaufbau.GlasscheibeMitte2?.Id
                                            && this.selectedComponent.glasaufbau.GlasscheibeMitte2?.Id > 0'
                  appearance='fill'
                >
                  <mat-label>{{ TRANSLATION_KEY.INPUT_CHOICE_LABEL | translate }}</mat-label>
                  <input
                    (click)="this.showGlassModal('mitte2', 'glass')"
                    [value]='this.selectedComponent.glasaufbau.GlasscheibeMitte2?.Beschreibung | translate' matInput
                    placeholder='{{ TRANSLATION_KEY.INPUT_CHOICE_PLACEHOLDER | translate}}'
                    readonly
                  >
                  <button
                    (click)="this.unset('glass', 'mitte2')" aria-label='{{ TRANSLATION_KEY.CLEAR | translate }}'
                    mat-icon-button
                    matSuffix
                  >
                    <i class='fa fa-times'></i>
                  </button>
                </mat-form-field>

                <ng-container *ngIf='!this.selectedComponent.glasaufbau.GlasscheibeMitte2?.Id'>
                  <button
                    (click)="this.showGlassModal('mitte2','glass')" class='fullWidth'
                    mat-flat-button
                  >
                    {{ TRANSLATION_KEY.CHOOSE_GLASS | translate }}
                  </button>
                </ng-container>
              </div>

              <ng-container *ngIf='this.selectedComponent.model?.Motive.length > 0'>
                <p>{{ TRANSLATION_KEY.MOTIVE|translate }}</p>
                <div
                  [mtxTooltipDisabled]="this.selectedComponent.glasaufbau.motivPossible('mitte2')"
                  [mtxTooltip]='TRANSLATION_KEY.ERROR_MOTIVE_GLASS_INCOMPATIBLE | translate'
                  class='row'
                >

                  <mat-form-field
                    *ngIf='this.selectedComponent.glasaufbau.MotivMitte2?.Id
                                                && this.selectedComponent.glasaufbau.MotivMitte2?.Id > 0'
                    appearance='fill'
                  >
                    <mat-label>{{ TRANSLATION_KEY.INPUT_CHOICE_LABEL | translate }}</mat-label>
                    <input
                      (click)="this.showGlassModal('mitte2', 'motiv')"
                      [value]='this.selectedComponent.glasaufbau.MotivMitte2?.Beschreibung | translate' matInput
                      placeholder='{{TRANSLATION_KEY.INPUT_CHOICE_PLACEHOLDER | translate}}'
                      readonly
                    >
                    <button
                      (click)="this.unset('motiv', 'mitte2')" aria-label='{{ TRANSLATION_KEY.CLEAR | translate }}'
                      mat-icon-button
                      matSuffix
                    >
                      <i class='fa fa-times'></i>
                    </button>
                  </mat-form-field>

                  <ng-container *ngIf='!this.selectedComponent.glasaufbau.MotivMitte2?.Id'>
                    <button
                      (click)="this.showGlassModal('mitte2', 'motiv')"
                      [disabled]="!this.selectedComponent.glasaufbau.motivPossible('mitte2')"
                      class='fullWidth' mat-flat-button
                    >
                      {{ TRANSLATION_KEY.CHOOSE_MOTIVE | translate }}
                    </button>
                  </ng-container>
                </div>
              </ng-container>

            </div>

          </div>


          <div
            *ngIf='this.configuratorMode === ConfiguratorMode.FBS'
            [class.filled]='this.selectedComponent.glasaufbau.GlasscheibeInnen?.Id
                                && this.selectedComponent.glasaufbau.GlasscheibeInnen?.Id > 0'
            class='fbsGlasContainer'
          >
            <p class='topic'>{{ TRANSLATION_KEY.GLASPOSITION_INSIDE |translate }}</p>
            <div class='content'>
              <p>{{ TRANSLATION_KEY.GLASS|translate }}</p>
              <div class='row'>
                <mat-form-field
                  *ngIf='this.selectedComponent.glasaufbau.GlasscheibeInnen?.Id
                                            && this.selectedComponent.glasaufbau.GlasscheibeInnen?.Id > 0'
                  appearance='fill'
                >
                  <mat-label>{{ TRANSLATION_KEY.INPUT_CHOICE_LABEL | translate }}</mat-label>
                  <input
                    (click)="this.showGlassModal('innen', 'glass')"
                    [value]='this.selectedComponent.glasaufbau.GlasscheibeInnen?.Beschreibung | translate' matInput
                    placeholder='{{TRANSLATION_KEY.INPUT_CHOICE_PLACEHOLDER | translate}}'
                    readonly
                  >
                  <button aria-label='{{ TRANSLATION_KEY.CLEAR | translate }}' disabled mat-icon-button matSuffix>
                    <i class='fa fa-times'></i>
                  </button>
                </mat-form-field>

                <ng-container *ngIf='!this.selectedComponent.glasaufbau.GlasscheibeInnen?.Id'>
                  <button
                    (click)="this.showGlassModal('innen','glass')" class='fullWidth'
                    mat-flat-button
                  >
                    {{ TRANSLATION_KEY.CHOOSE_GLASS | translate }}
                  </button>
                </ng-container>
              </div>

              <ng-container *ngIf='this.selectedComponent.model?.Motive.length > 0'>
                <p>{{ TRANSLATION_KEY.MOTIVE|translate }}</p>
                <div
                  [mtxTooltipDisabled]="this.selectedComponent.glasaufbau.motivPossible('innen')"
                  [mtxTooltip]='TRANSLATION_KEY.ERROR_MOTIVE_GLASS_INCOMPATIBLE | translate'
                  class='row'
                >

                  <mat-form-field
                    *ngIf='this.selectedComponent.glasaufbau.MotivInnen?.Id
                                                && this.selectedComponent.glasaufbau.MotivInnen?.Id > 0'
                    appearance='fill'
                  >
                    <mat-label>{{ TRANSLATION_KEY.INPUT_CHOICE_LABEL | translate }}</mat-label>
                    <input
                      (click)="this.showGlassModal('innen', 'motiv')"
                      [value]='this.selectedComponent.glasaufbau.MotivInnen?.Beschreibung | translate' matInput
                      placeholder='{{TRANSLATION_KEY.INPUT_CHOICE_PLACEHOLDER | translate}}'
                      readonly
                    >
                    <button
                      (click)="this.unset('motiv', 'innen')" aria-label='{{ TRANSLATION_KEY.CLEAR | translate }}'
                      mat-icon-button
                      matSuffix
                    >
                      <i class='fa fa-times'></i>
                    </button>
                  </mat-form-field>

                  <ng-container *ngIf='!this.selectedComponent.glasaufbau.MotivInnen?.Id'>
                    <button
                      (click)="this.showGlassModal('innen', 'motiv')"
                      [disabled]="!this.selectedComponent.glasaufbau.motivPossible('innen')"
                      class='fullWidth' mat-flat-button
                    >
                      {{ TRANSLATION_KEY.CHOOSE_MOTIVE | translate }}
                    </button>
                  </ng-container>
                </div>
              </ng-container>
            </div>

          </div>

          <div *ngIf='this.configuratorMode !== ConfiguratorMode.FBS' class='fbsGlasContainer'>
            <p class='topic'>{{ TRANSLATION_KEY.GLASSES_TITLE_TTK |translate }}</p>
            <div class='content'>
              <p>{{ TRANSLATION_KEY.GLASS|translate }}</p>
              <div class='row'>
                <mat-form-field
                  *ngIf='this.selectedComponent.glasaufbau[this.selectedComponent.glasaufbau.simpleGlassScheibe]?.Id
                                            && this.selectedComponent.glasaufbau[this.selectedComponent.glasaufbau.simpleGlassScheibe]
                                                    ?.Id > 0'
                  appearance='fill'
                >
                  <mat-label>{{ TRANSLATION_KEY.INPUT_CHOICE_LABEL | translate }}</mat-label>
                  <input
                    (click)="this.showGlassModal(this.selectedComponent.glasaufbau.simplePosition, 'glass')"
                    [value]='this.selectedComponent.glasaufbau[this.selectedComponent.glasaufbau.simpleGlassScheibe]
                             ?.Beschreibung | translate' matInput
                    placeholder='{{TRANSLATION_KEY.INPUT_CHOICE_PLACEHOLDER | translate}}'
                    readonly
                  >
                  <button aria-label='{{ TRANSLATION_KEY.CLEAR | translate }}' disabled mat-icon-button matSuffix>
                    <i class='fa fa-times'></i>
                  </button>
                </mat-form-field>

                <ng-container
                  *ngIf='!this.selectedComponent.glasaufbau[this.selectedComponent.glasaufbau.simpleGlassScheibe]?.Id'
                >
                  <button
                    (click)="this.showGlassModal(this.selectedComponent.glasaufbau.simplePosition,'glass')"
                    class='fullWidth'
                    mat-flat-button
                  >
                    {{ TRANSLATION_KEY.CHOOSE_GLASS | translate }}
                  </button>
                </ng-container>
              </div>

              <ng-container *ngIf='this.selectedComponent.model?.Motive.length > 0'>
                <p>{{ TRANSLATION_KEY.MOTIVE|translate }}</p>
                <div class='row'>

                  <mat-form-field
                    *ngIf='this.selectedComponent.glasaufbau[this.selectedComponent.glasaufbau.simpleMotiv]?.Id
                                                && this.selectedComponent.glasaufbau[this.selectedComponent.glasaufbau.simpleMotiv]
                                                        ?.Id > 0'
                    appearance='fill'
                  >
                    <mat-label>{{ TRANSLATION_KEY.INPUT_CHOICE_LABEL | translate }}</mat-label>
                    <input
                      (click)="this.showGlassModal(this.selectedComponent.glasaufbau.simplePosition, 'motiv')"
                      [value]='this.selectedComponent.glasaufbau[this.selectedComponent.glasaufbau.simpleMotiv]
                                                    ?.Beschreibung | translate' matInput
                      placeholder='{{TRANSLATION_KEY.INPUT_CHOICE_PLACEHOLDER | translate}}'
                      readonly
                    >
                    <button
                      (click)="this.unset('motiv', this.selectedComponent.glasaufbau.simplePosition)"
                      aria-label='{{ TRANSLATION_KEY.CLEAR | translate }}' mat-icon-button
                      matSuffix
                    >
                      <i class='fa fa-times'></i>
                    </button>
                  </mat-form-field>

                  <ng-container
                    *ngIf='!this.selectedComponent.glasaufbau[this.selectedComponent.glasaufbau.simpleMotiv]?.Id'
                  >
                    <button
                      (click)="this.showGlassModal(this.selectedComponent.glasaufbau.simplePosition, 'motiv')"
                      class='fullWidth'
                      mat-flat-button
                    >
                      {{ TRANSLATION_KEY.CHOOSE_MOTIVE | translate }}
                    </button>
                  </ng-container>
                </div>
              </ng-container>
            </div>
          </div>
        </div>

      </div>
    </div>

  </ng-container>

  <ng-container *ngIf='this.configuratorMode === ConfiguratorMode.TTK'>
    <div class='container'>
      <div class='container-inner glassesMenu '>
        <div class='inputArea'>
          <mat-form-field appearance='fill'>
            <mat-label>{{ this.glassModeLabel() }}</mat-label>
            <input
              #glassSearchInput (keyup)='this.collect(this.glassMode)' [(ngModel)]='this.glassUpdate.filterTerm'
              matInput
              type='text'
            />
            <button (click)='this.resetGlasTerm()' aria-label='{{ TRANSLATION_KEY.CLEAR | translate }}' mat-icon-button
                    matTextSuffix>
              <i class='fa fa-2x fa-times'></i>
            </button>
          </mat-form-field>
          <div class='glassModeWrapper'>
            <button
              (click)="this.glassMode=GlasMode.Glas"
              [class.active]="this.glassMode === GlasMode.Glas"
            >
              {{ TRANSLATION_KEY.GLASSES_MENU_BUTTON_GLASSES | translate }}
            </button>
            <button
              (click)="this.glassMode=GlasMode.Motiv"
              [class.active]="this.glassMode === GlasMode.Motiv"
            >
              {{ TRANSLATION_KEY.GLASSES_MENU_BUTTON_MOTIVES | translate }}
            </button>
            <button
              (click)="this.glassMode=GlasMode.Sprosse"
              *ngIf="this.currentSprossen.length > 0"
              [class.active]="this.glassMode === GlasMode.Sprosse"
            >
              {{ TRANSLATION_KEY.GLASSES_MENU_BUTTON_SPROSSEN | translate }}
            </button>
          </div>
        </div>
        <div class='contentArea'>


          <div *ngIf="this.glassMode === GlasMode.Glas" class='virtualscroll'>

            <ng-container *ngIf='this.currentGlasses.length === 0'>
              <p class='noResults'>
                                <span>
                                {{ TRANSLATION_KEY.GLASSES_MENU_HINT_NO_GLASSES | translate }}
                                </span>
              </p>
            </ng-container>


            <cdk-virtual-scroll-viewport
              #glasViewPort [itemSize]='this.virtualScrollItemHeight' class='viewport'
              style='width: 100%'
            >
              <ng-container *cdkVirtualFor='let row of this.currentGlasses; templateCacheSize: 1500'>
                <div class='glassRow'>
                  <ng-container *ngFor='let item of row; let index = index'>

                    <article
                      (click)="item.disabilityReasons.length === 0 ? this.setGlas('aussen', item) : ''"
                      [attr.data.index]='index'
                      [class.active]='!this.selectedComponent?.glasaufbau?.MotivAussen
                                                && this.selectedComponent?.glasaufbau?.GlasscheibeAussen?.Id === item.Id'
                      class='item glaeser'
                    >
                      <div
                        [class.disabled]='item.disabilityReasons.length > 0'
                        [mtxTooltipDisabled]='item.disabilityReasons.length === 0'
                        [mtxTooltip]='StringUtil.concatStringArray(item.disabilityReasons)' class='image-wrapper'
                      >
                        <div
                          (click)='this.showImageModal(item, $event)'
                          class='zoomglassicon'
                        >
                          <i class='fa fa-search-plus fa-3x fa-fw'></i>
                        </div>
                        <img
                          *loadingAnimation="item.PreviewImageUrl, sizing: 'fill', background: true"
                          [attr.data.id]='item.Id'
                        >

                      </div>
                      <p><span class='option-text'>{{ item.Beschreibung | translate }}</span></p>
                    </article>

                  </ng-container>
                </div>
              </ng-container>
            </cdk-virtual-scroll-viewport>
          </div>
          <div *ngIf="this.glassMode === GlasMode.Motiv" class='virtualscroll'>

            <ng-container *ngIf='this.currentMotive.length === 0'>
              <p class='noResults'>
                <span>{{ TRANSLATION_KEY.GLASSES_MENU_HINT_NO_MOTIVES | translate }}</span>
              </p>
            </ng-container>
            <cdk-virtual-scroll-viewport
              #motivViewPort [itemSize]='this.virtualScrollItemHeight' class='viewport' style='width: 100%'
            >
              <ng-container *cdkVirtualFor='let row of this.currentMotive; templateCacheSize: 1500'>
                <ng-container *ngFor='let item of row; let index = index'>

                  <article
                    (click)='this.handleMotivClick(item)' [attr.data.index]='index'
                    [class.active]='this.isSameMotivAussen(item) || this.isSameMotivMitte1(item)'
                    class='item glaeser'
                  >
                    <div
                      [class.disabled]='item.disabilityReasons.length > 0'
                      [mtxTooltipDisabled]='item.disabilityReasons.length === 0'
                      [mtxTooltip]='StringUtil.concatStringArray(item.disabilityReasons)' class='image-wrapper'
                    >
                      <div
                        (click)='this.showImageModal(item, $event)'
                        class='zoomglassicon'
                      >
                        <i class='fa fa-search-plus fa-3x fa-fw'></i>
                      </div>
                      <img
                        *loadingAnimation="item.PreviewImageUrl, sizing: 'fill', background: true"
                        [attr.data.id]='item.Id'
                      >
                    </div>
                    <p><span class='option-text'>{{ item.Beschreibung | translate }}</span></p>
                  </article>
                </ng-container>
              </ng-container>
            </cdk-virtual-scroll-viewport>
          </div>
          <div *ngIf="this.glassMode === GlasMode.Sprosse" class='virtualscroll'>

            <ng-container *ngIf='this.currentSprossen.length === 0'>
              <p class='noResults'>
                <span>{{ TRANSLATION_KEY.GLASSES_MENU_HINT_NO_SPROSSEN | translate }}</span>
              </p>
            </ng-container>
            <cdk-virtual-scroll-viewport
              #motivViewPort [itemSize]='this.virtualScrollItemHeight' class='viewport' style='width: 100%'
            >
              <ng-container *cdkVirtualFor='let row of this.currentSprossen; templateCacheSize: 1500'>
                <ng-container *ngFor='let item of row; let index = index'>

                  <article
                    (click)='this.handleBarsClick(item)' [attr.data.index]='index'
                    [class.active]='this.selectedComponent?.glasaufbau?.Sprossen?.Id === item.Id'
                    class='item glaeser'
                  >
                    <div class='image-wrapper'>
                      <div
                        (click)='this.showImageModal(item, $event)'
                        class='zoomglassicon'
                      >
                        <i class='fa fa-search-plus fa-3x fa-fw'></i>
                      </div>
                      <img
                        *loadingAnimation="item.PreviewImageUrl, sizing: 'fill', background: true"
                        [attr.data.id]='item.Id'
                      >
                    </div>
                    <p><span class='option-text'>{{ item.Beschreibung | translate }}</span></p>
                  </article>
                </ng-container>
              </ng-container>
            </cdk-virtual-scroll-viewport>
          </div>
        </div>

      </div>
    </div>
  </ng-container>
</configurator-base-menu>

<ng-template #head>
  <div class='name'>
    <span>{{ TRANSLATION_KEY.GLASSES_MENU_TITLE | translate }}</span>
    <span (click)="closeMenu()" class='close'></span>
  </div>
</ng-template>

<ng-template #waermeschutzDropdown>
  <mat-form-field *ngIf='this.selectedComponent.glasaufbau' appearance='fill'>
    <mat-label>{{ TRANSLATION_KEY.GLASSES_MENU_DROPDOWN_INSULATION_TITLE | translate }}</mat-label>
    <mat-select [(ngModel)]='thermalInsulationPackage' [compareWith]='ObjectUtil.matchIds'>
      <!-- (selectionChange)="this.selectedComponent.glasaufbau.setWaermeschutzGlaeser()" -->
      <mat-option [value]='null'>
        {{ TRANSLATION_KEY.GLASSES_MENU_DROPDOWN_INSULATION_BLANK_OPTION | translate }}
      </mat-option>
      <ng-container *ngFor='let ws of thermalInsulations'>
        <mat-option [value]='ws'>
          {{ ws.Bezeichnung | translate }}
        </mat-option>
      </ng-container>
    </mat-select>
  </mat-form-field>
</ng-template>
