import {Component, QueryList, ViewChildren} from '@angular/core'
import {MatTableDataSource} from '@angular/material/table'
import {MatSort} from '@angular/material/sort'
import {MatDialogRef} from '@angular/material/dialog'
import {ConfiguratorModeService} from '../../../classes/service/configuratorMode.service'
import {ParameterService} from '../../../classes/service/parameter/parameter.service'
import {ParameterModes} from '../../../classes/service/parameter/parameter-mode'
import {TranslateService, TranslationKeys} from '../../../translate'
import {ConfiguratorMode} from '../../../types'
import {ToastrService} from 'ngx-toastr'

const TRANSLATION_KEY = {
  COPY_ERROR_TEXT: 'UrlParameterModel.UrlCopyErrorText',
  COPY_ERROR_TITLE: 'UrlParameterModel.UrlCopyErrorTitle',
  COPY_FULL_URL: 'UrlParameterModel.CopyFullUrl',
  COPY_SUCCESSFUL_TEXT: 'UrlParameterModel.UrlCopySuccessfulText',
  COPY_SUCCESSFUL_TITLE: 'UrlParameterModel.UrlCopySuccessfulTitle',
  HEADING: 'UrlParameterModel.Heading',
  PARAMETER_MODE_EDIT: 'UrlParameterModal.ParameterModes.Edit',
  PARAMETER_MODE_NEW: 'UrlParameterModal.ParameterModes.New',
  PARAMETER_MODE_OPEN: 'UrlParameterModal.ParameterModes.Open',
  PARAMETER_MODE_UNKNOWN: 'UrlParameterModal.ParameterModes.Unknown',
  PARAMETER_NAME_BRUTTO: 'UrlParameterModel.ParameterName.Brutto',
  PARAMETER_NAME_CLERK: 'UrlParameterModel.ParameterName.Clerk',
  PARAMETER_NAME_CLOSE: 'UrlParameterModel.ParameterName.Close',
  PARAMETER_NAME_COLOR: 'UrlParameterModel.ParameterName.Color',
  PARAMETER_NAME_KOMMISSION: 'UrlParameterModel.ParameterName.Kommission',
  PARAMETER_NAME_LANG: 'UrlParameterModel.ParameterName.Lang',
  PARAMETER_NAME_LOGO: 'UrlParameterModel.ParameterName.Logo',
  PARAMETER_NAME_MODE: 'UrlParameterModel.ParameterName.Mode',
  PARAMETER_NAME_MODELLE: 'UrlParameterModel.ParameterName.Modelle',
  PARAMETER_NAME_MODELLE_BXA: 'UrlParameterModel.ParameterName.Modelle.Bxa',
  PARAMETER_NAME_MODELLE_BXB: 'UrlParameterModel.ParameterName.Modelle.Bxb',
  PARAMETER_NAME_MODELLE_BYA: 'UrlParameterModel.ParameterName.Modelle.Bya',
  PARAMETER_NAME_MODELLE_BYB: 'UrlParameterModel.ParameterName.Modelle.Byb',
  PARAMETER_NAME_MODELLE_DEKORFUELLUNG: 'UrlParameterModel.ParameterName.Modelle.DekorFuellung',
  PARAMETER_NAME_MODELLE_DEKORFUELLUNGINNEN: 'UrlParameterModel.ParameterName.Modelle.DekorFuellungInnen',
  PARAMETER_NAME_MODELLE_DEKORRAHMEN: 'UrlParameterModel.ParameterName.Modelle.DekorRahmen',
  PARAMETER_NAME_MODELLE_DEKORRAHMENINNEN: 'UrlParameterModel.ParameterName.Modelle.DekorRahmenInnen',
  PARAMETER_NAME_MODELLE_DIN: 'UrlParameterModel.ParameterName.Modelle.Din',
  PARAMETER_NAME_MODELLE_FARBEFUELLUNG: 'UrlParameterModel.ParameterName.Modelle.FarbeFuellung',
  PARAMETER_NAME_MODELLE_FARBEFUELLUNGINNEN: 'UrlParameterModel.ParameterName.Modelle.FarbeFuellungInnen',
  PARAMETER_NAME_MODELLE_FARBERAHMEN: 'UrlParameterModel.ParameterName.Modelle.FarbeRahmen',
  PARAMETER_NAME_MODELLE_FARBERAHMENINNEN: 'UrlParameterModel.ParameterName.Modelle.FarbeRahmenInnen',
  PARAMETER_NAME_MODELLE_FINGERPRINTFRAESUNG_B: 'UrlParameterModel.ParameterName.Modelle.FingerprintFraesung.B',
  PARAMETER_NAME_MODELLE_FINGERPRINTFRAESUNG_H: 'UrlParameterModel.ParameterName.Modelle.FingerprintFraesung.H',
  PARAMETER_NAME_MODELLE_FINGERPRINTFRAESUNG_R: 'UrlParameterModel.ParameterName.Modelle.FingerprintFraesung.R',
  PARAMETER_NAME_MODELLE_FINGERPRINTFRAESUNG_T: 'UrlParameterModel.ParameterName.Modelle.FingerprintFraesung.T',
  PARAMETER_NAME_MODELLE_FINGERPRINTFRAESUNG_V: 'UrlParameterModel.ParameterName.Modelle.FingerprintFraesung.V',
  PARAMETER_NAME_MODELLE_GRIFFPOSITION_AKTIV: 'UrlParameterModel.ParameterName.Modelle.GriffPosition.Aktiv',
  PARAMETER_NAME_MODELLE_GRIFFPOSITION_S: 'UrlParameterModel.ParameterName.Modelle.GriffPosition.S',
  PARAMETER_NAME_MODELLE_GRIFFPOSITION_T: 'UrlParameterModel.ParameterName.Modelle.GriffPosition.T',
  PARAMETER_NAME_MODELLE_GRIFFVERSTAERKUNG_AKTIV: 'UrlParameterModel.ParameterName.Modelle.GriffVerstaerkung.Aktiv',
  PARAMETER_NAME_MODELLE_GRIFFVERSTAERKUNG_GA: 'UrlParameterModel.ParameterName.Modelle.GriffVerstaerkung.Ga',
  PARAMETER_NAME_MODELLE_GRIFFVERSTAERKUNG_GB: 'UrlParameterModel.ParameterName.Modelle.GriffVerstaerkung.Gb',
  PARAMETER_NAME_MODELLE_GRIFFVERSTAERKUNG_GD: 'UrlParameterModel.ParameterName.Modelle.GriffVerstaerkung.Gd',
  PARAMETER_NAME_MODELLE_KONSTRUKTION: 'UrlParameterModel.ParameterName.Modelle.Konstruktion',
  PARAMETER_NAME_MODELLE_MATERIAL: 'UrlParameterModel.ParameterName.Modelle.Material',
  PARAMETER_NAME_MODELLE_MAXSHEETI: 'UrlParameterModel.ParameterName.Modelle.MaxSheeti',
  PARAMETER_NAME_MODELLE_MAXSHEETO: 'UrlParameterModel.ParameterName.Modelle.MaxSheeto',
  PARAMETER_NAME_MODELLE_MAXSTAERKE: 'UrlParameterModel.ParameterName.Modelle.MaxStaerke',
  PARAMETER_NAME_MODELLE_MINSHEETI: 'UrlParameterModel.ParameterName.Modelle.MinSheeti',
  PARAMETER_NAME_MODELLE_MINSHEETO: 'UrlParameterModel.ParameterName.Modelle.MinSheeto',
  PARAMETER_NAME_MODELLE_MINSTAERKE: 'UrlParameterModel.ParameterName.Modelle.MinStaerke',
  PARAMETER_NAME_MODELLE_MODELLID: 'UrlParameterModel.ParameterName.Modelle.Modellid',
  PARAMETER_NAME_MODELLE_POS: 'UrlParameterModel.ParameterName.Modelle.Pos',
  PARAMETER_NAME_MODELLE_PZPOSITION_AKTIV: 'UrlParameterModel.ParameterName.Modelle.PzPosition.Aktiv',
  PARAMETER_NAME_MODELLE_PZPOSITION_D: 'UrlParameterModel.ParameterName.Modelle.PzPosition.D',
  PARAMETER_NAME_MODELLE_PZPOSITION_TYP: 'UrlParameterModel.ParameterName.Modelle.PzPosition.Typ',
  PARAMETER_NAME_MODELLE_PZPOSITION_V: 'UrlParameterModel.ParameterName.Modelle.PzPosition.V',
  PARAMETER_NAME_MODELLE_PZPOSITION_W: 'UrlParameterModel.ParameterName.Modelle.PzPosition.W',
  PARAMETER_NAME_MODELLE_SEITENTEILHOEHENVERTEILUNG_A: 'UrlParameterModel.ParameterName.Modelle.SeitenteilHoehenverteilung.A',
  PARAMETER_NAME_MODELLE_SEITENTEILHOEHENVERTEILUNG_B: 'UrlParameterModel.ParameterName.Modelle.SeitenteilHoehenverteilung.B',
  PARAMETER_NAME_MODELLE_SIXA: 'UrlParameterModel.ParameterName.Modelle.Sixa',
  PARAMETER_NAME_MODELLE_SIXB: 'UrlParameterModel.ParameterName.Modelle.Sixb',
  PARAMETER_NAME_MODELLE_SIYA: 'UrlParameterModel.ParameterName.Modelle.Siya',
  PARAMETER_NAME_MODELLE_SIYB: 'UrlParameterModel.ParameterName.Modelle.Siyb',
  PARAMETER_NAME_MODELLE_SOXA: 'UrlParameterModel.ParameterName.Modelle.Soxa',
  PARAMETER_NAME_MODELLE_SOXB: 'UrlParameterModel.ParameterName.Modelle.Soxb',
  PARAMETER_NAME_MODELLE_SOYA: 'UrlParameterModel.ParameterName.Modelle.Soya',
  PARAMETER_NAME_MODELLE_SOYB: 'UrlParameterModel.ParameterName.Modelle.Soyb',
  PARAMETER_NAME_MODELLE_TUERTYP: 'UrlParameterModel.ParameterName.Modelle.TuerTyp',
  PARAMETER_NAME_MODELLE_VIXA: 'UrlParameterModel.ParameterName.Modelle.Vixa',
  PARAMETER_NAME_MODELLE_VIXB: 'UrlParameterModel.ParameterName.Modelle.Vixb',
  PARAMETER_NAME_MODELLE_VIYA: 'UrlParameterModel.ParameterName.Modelle.Viya',
  PARAMETER_NAME_MODELLE_VIYB: 'UrlParameterModel.ParameterName.Modelle.Viyb',
  PARAMETER_NAME_MODELLE_VOXA: 'UrlParameterModel.ParameterName.Modelle.Voxa',
  PARAMETER_NAME_MODELLE_VOXB: 'UrlParameterModel.ParameterName.Modelle.Voxb',
  PARAMETER_NAME_MODELLE_VOYA: 'UrlParameterModel.ParameterName.Modelle.Voya',
  PARAMETER_NAME_MODELLE_VOYB: 'UrlParameterModel.ParameterName.Modelle.Voyb',
  PARAMETER_NAME_PROFIL: 'UrlParameterModel.ParameterName.Profil',
  PARAMETER_NAME_REQUEST: 'UrlParameterModel.ParameterName.Request',
  PARAMETER_NAME_REQUESTCATALOG: 'UrlParameterModel.ParameterName.RequestCatalog',
  PARAMETER_NAME_REQUESTDEALER: 'UrlParameterModel.ParameterName.RequestDealer',
  PARAMETER_NAME_SESSIONTOKEN: 'UrlParameterModel.ParameterName.SessionToken',
  PARAMETER_NAME_SHOWRAHMEN: 'UrlParameterModel.ParameterName.Showrahmen',
  PARAMETER_NAME_SOFTWARE: 'UrlParameterModel.ParameterName.Software',
  PARAMETER_NAME_TO: 'UrlParameterModel.ParameterName.To',
  PARAMETER_NAME_TOKEN: 'UrlParameterModel.ParameterName.Token',
  PARAMETER_NAME_TRANSKEY: 'UrlParameterModel.ParameterName.Transkey',
  TABLE_HEADING_MODELLE: 'UrlParameterModel.Table.Heading.Modelle',
  TABLE_HEADING_PARAMETER: 'UrlParameterModel.Table.Heading.Parameter',
  TABLE_HEAD_PARAMETER: 'UrlParameterModel.Table.Head.Parameter',
  TABLE_HEAD_VALUE: 'UrlParameterModel.Table.Head.Value',
  TABLE_SORT_ACTION_DESCRIPTION_PARAMETER: 'UrlParameterModel.Table.SortActionDescription.Parameter',
  TABLE_SORT_ACTION_DESCRIPTION_VALUE: 'UrlParameterModel.Table.SortActionDescription.Value',
  UNDEFINED_PARAMETER: 'UrlParameterModal.UndefinedParameter',
} as const

@Component({
  templateUrl: './url-parameter-modal.component.html',
  styleUrls: ['url-parameter-modal.component.scss'],
})
export class UrlParameterModalComponent {
  protected readonly TRANSLATION_KEY = TRANSLATION_KEY
  protected readonly generalParameterData: MatTableDataSource<{ readonly parameter: string; readonly value: string }>
  protected readonly modelParameterData: MatTableDataSource<{ readonly parameter: string; readonly value: string }>

  constructor(
    protected readonly parameterService: ParameterService,
    protected readonly translateService: TranslateService,
    private toastrService: ToastrService,
    readonly configuratorModeService: ConfiguratorModeService,
    readonly matDialogRef: MatDialogRef<false>,
  ) {
    if (configuratorModeService.mode !== ConfiguratorMode.FBS) {
      matDialogRef.close()
    }
    this.generalParameterData = new MatTableDataSource([
      {
        parameter: this.translateService.translate(TRANSLATION_KEY.PARAMETER_NAME_BRUTTO),
        value: this.booleanToDisplayString(parameterService.parameter.brutto),
      }, {
        parameter: this.translateService.translate(TRANSLATION_KEY.PARAMETER_NAME_CLERK),
        value: this.stringToDisplayString(parameterService.parameter.clerk),
      },
      {
        parameter: this.translateService.translate(TRANSLATION_KEY.PARAMETER_NAME_CLOSE),
        value: this.stringToDisplayString(parameterService.parameter.close),
      },
      {
        parameter: this.translateService.translate(TRANSLATION_KEY.PARAMETER_NAME_COLOR),
        value: this.stringToDisplayString(parameterService.parameter.color),
      },
      {
        parameter: this.translateService.translate(TRANSLATION_KEY.PARAMETER_NAME_KOMMISSION),
        value: this.stringToDisplayString(parameterService.parameter.kommission),
      },
      {
        parameter: this.translateService.translate(TRANSLATION_KEY.PARAMETER_NAME_LANG),
        value: this.stringToDisplayString(parameterService.parameter.lang),
      },
      {
        parameter: this.translateService.translate(TRANSLATION_KEY.PARAMETER_NAME_LOGO),
        value: this.stringToDisplayString(parameterService.parameter.logo),
      },
      {
        parameter: this.translateService.translate(TRANSLATION_KEY.PARAMETER_NAME_MODE),
        value: this.parameterModeToDisplayString(parameterService.parameter.mode),
      },
      {
        parameter: this.translateService.translate(TRANSLATION_KEY.PARAMETER_NAME_MODELLE),
        value: this.stringToDisplayString(parameterService.parameter.modelle),
      },
      {
        parameter: this.translateService.translate(TRANSLATION_KEY.PARAMETER_NAME_PROFIL),
        value: this.stringToDisplayString(parameterService.parameter.profil),
      },
      {
        parameter: this.translateService.translate(TRANSLATION_KEY.PARAMETER_NAME_REQUEST),
        value: this.stringToDisplayString(parameterService.parameter.request),
      },
      {
        parameter: this.translateService.translate(TRANSLATION_KEY.PARAMETER_NAME_REQUESTCATALOG),
        value: this.stringToDisplayString(parameterService.parameter.request_catalog),
      },
      {
        parameter: this.translateService.translate(TRANSLATION_KEY.PARAMETER_NAME_REQUESTDEALER),
        value: this.stringToDisplayString(parameterService.parameter.request_dealer),
      },
      {
        parameter: this.translateService.translate(TRANSLATION_KEY.PARAMETER_NAME_SESSIONTOKEN),
        value: this.stringToDisplayString(parameterService.parameter.session_token),
      },
      {
        parameter: this.translateService.translate(TRANSLATION_KEY.PARAMETER_NAME_SHOWRAHMEN),
        value: this.booleanToDisplayString(parameterService.parameter.showrahmen),
      },
      {
        parameter: this.translateService.translate(TRANSLATION_KEY.PARAMETER_NAME_SOFTWARE),
        value: this.stringToDisplayString(parameterService.parameter.software),
      },
      {
        parameter: this.translateService.translate(TRANSLATION_KEY.PARAMETER_NAME_TO),
        value: this.stringToDisplayString(parameterService.parameter.to),
      },
      {
        parameter: this.translateService.translate(TRANSLATION_KEY.PARAMETER_NAME_TOKEN),
        value: this.stringToDisplayString(parameterService.parameter.token),
      },
      {
        parameter: this.translateService.translate(TRANSLATION_KEY.PARAMETER_NAME_TRANSKEY),
        value: this.stringToDisplayString(parameterService.parameter.transkey),
      },
    ])

    this.modelParameterData = new MatTableDataSource([
      {
        parameter: this.translateService.translate(TRANSLATION_KEY.PARAMETER_NAME_MODELLE_BXA),
        value: this.numberToDisplayString(parameterService.model?.bxa),
      },
      {
        parameter: this.translateService.translate(TRANSLATION_KEY.PARAMETER_NAME_MODELLE_BXB),
        value: this.numberToDisplayString(parameterService.model?.bxb),
      },
      {
        parameter: this.translateService.translate(TRANSLATION_KEY.PARAMETER_NAME_MODELLE_BYA),
        value: this.numberToDisplayString(parameterService.model?.bya),
      },
      {
        parameter: this.translateService.translate(TRANSLATION_KEY.PARAMETER_NAME_MODELLE_BYB),
        value: this.numberToDisplayString(parameterService.model?.byb),
      },
      {
        parameter: this.translateService.translate(TRANSLATION_KEY.PARAMETER_NAME_MODELLE_DEKORFUELLUNG),
        value: this.stringToDisplayString(parameterService.model?.DekorFuellung),
      },
      {
        parameter: this.translateService.translate(TRANSLATION_KEY.PARAMETER_NAME_MODELLE_DEKORFUELLUNGINNEN),
        value: this.stringToDisplayString(parameterService.model?.DekorFuellungInnen),
      },
      {
        parameter: this.translateService.translate(TRANSLATION_KEY.PARAMETER_NAME_MODELLE_DEKORRAHMEN),
        value: this.stringToDisplayString(parameterService.model?.DekorRahmen),
      },
      {
        parameter: this.translateService.translate(TRANSLATION_KEY.PARAMETER_NAME_MODELLE_DEKORRAHMENINNEN),
        value: this.stringToDisplayString(parameterService.model?.DekorRahmenInnen),
      },
      {
        parameter: this.translateService.translate(TRANSLATION_KEY.PARAMETER_NAME_MODELLE_DIN),
        value: this.stringToDisplayString(parameterService.model?.din),
      },
      {
        parameter: this.translateService.translate(TRANSLATION_KEY.PARAMETER_NAME_MODELLE_FARBEFUELLUNG),
        value: this.stringToDisplayString(parameterService.model?.FarbeFuellung),
      },
      {
        parameter: this.translateService.translate(TRANSLATION_KEY.PARAMETER_NAME_MODELLE_FARBEFUELLUNGINNEN),
        value: this.stringToDisplayString(parameterService.model?.FarbeFuellungInnen),
      },
      {
        parameter: this.translateService.translate(TRANSLATION_KEY.PARAMETER_NAME_MODELLE_FARBERAHMEN),
        value: this.stringToDisplayString(parameterService.model?.FarbeRahmen),
      },
      {
        parameter: this.translateService.translate(TRANSLATION_KEY.PARAMETER_NAME_MODELLE_FARBERAHMENINNEN),
        value: this.stringToDisplayString(parameterService.model?.FarbeRahmenInnen),
      },
      {
        parameter: this.translateService.translate(TRANSLATION_KEY.PARAMETER_NAME_MODELLE_FINGERPRINTFRAESUNG_B),
        value: this.numberToDisplayString(parameterService.model?.fingerprintFraesung?.b),
      },
      {
        parameter: this.translateService.translate(TRANSLATION_KEY.PARAMETER_NAME_MODELLE_FINGERPRINTFRAESUNG_H),
        value: this.numberToDisplayString(parameterService.model?.fingerprintFraesung?.h),
      },
      {
        parameter: this.translateService.translate(TRANSLATION_KEY.PARAMETER_NAME_MODELLE_FINGERPRINTFRAESUNG_R),
        value: this.numberToDisplayString(parameterService.model?.fingerprintFraesung?.r),
      },
      {
        parameter: this.translateService.translate(TRANSLATION_KEY.PARAMETER_NAME_MODELLE_FINGERPRINTFRAESUNG_T),
        value: this.numberToDisplayString(parameterService.model?.fingerprintFraesung?.t),
      },
      {
        parameter: this.translateService.translate(TRANSLATION_KEY.PARAMETER_NAME_MODELLE_FINGERPRINTFRAESUNG_V),
        value: this.numberToDisplayString(parameterService.model?.fingerprintFraesung?.v),
      },
      {
        parameter: this.translateService.translate(TRANSLATION_KEY.PARAMETER_NAME_MODELLE_GRIFFPOSITION_AKTIV),
        value: this.booleanToDisplayString(parameterService.model?.griffPosition?.aktiv),
      },
      {
        parameter: this.translateService.translate(TRANSLATION_KEY.PARAMETER_NAME_MODELLE_GRIFFPOSITION_S),
        value: this.numberToDisplayString(parameterService.model?.griffPosition?.s),
      },
      {
        parameter: this.translateService.translate(TRANSLATION_KEY.PARAMETER_NAME_MODELLE_GRIFFPOSITION_T),
        value: this.numberToDisplayString(parameterService.model?.griffPosition?.t),
      },
      {
        parameter: this.translateService.translate(TRANSLATION_KEY.PARAMETER_NAME_MODELLE_GRIFFVERSTAERKUNG_AKTIV),
        value: this.booleanToDisplayString(parameterService.model?.griffVerstaerkung?.aktiv),
      },
      {
        parameter: this.translateService.translate(TRANSLATION_KEY.PARAMETER_NAME_MODELLE_GRIFFVERSTAERKUNG_GA),
        value: this.numberToDisplayString(parameterService.model?.griffVerstaerkung?.ga),
      },
      {
        parameter: this.translateService.translate(TRANSLATION_KEY.PARAMETER_NAME_MODELLE_GRIFFVERSTAERKUNG_GB),
        value: this.numberToDisplayString(parameterService.model?.griffVerstaerkung?.gb),
      },
      {
        parameter: this.translateService.translate(TRANSLATION_KEY.PARAMETER_NAME_MODELLE_GRIFFVERSTAERKUNG_GD),
        value: this.numberToDisplayString(parameterService.model?.griffVerstaerkung?.gd),
      },
      {
        parameter: this.translateService.translate(TRANSLATION_KEY.PARAMETER_NAME_MODELLE_KONSTRUKTION),
        value: this.numberToDisplayString(parameterService.model?.konstruktion),
      },
      {
        parameter: this.translateService.translate(TRANSLATION_KEY.PARAMETER_NAME_MODELLE_MATERIAL),
        value: this.stringToDisplayString(parameterService.model?.material as string),
      },
      {
        parameter: this.translateService.translate(TRANSLATION_KEY.PARAMETER_NAME_MODELLE_MAXSHEETI),
        value: this.numberToDisplayString(parameterService.model?.maxsheeti),
      },
      {
        parameter: this.translateService.translate(TRANSLATION_KEY.PARAMETER_NAME_MODELLE_MAXSHEETO),
        value: this.numberToDisplayString(parameterService.model?.maxsheeto),
      },
      {
        parameter: this.translateService.translate(TRANSLATION_KEY.PARAMETER_NAME_MODELLE_MAXSTAERKE),
        value: this.numberToDisplayString(parameterService.model?.maxstaerke),
      },
      {
        parameter: this.translateService.translate(TRANSLATION_KEY.PARAMETER_NAME_MODELLE_MINSHEETI),
        value: this.numberToDisplayString(parameterService.model?.minsheeti),
      },
      {
        parameter: this.translateService.translate(TRANSLATION_KEY.PARAMETER_NAME_MODELLE_MINSHEETO),
        value: this.numberToDisplayString(parameterService.model?.minsheeto),
      },
      {
        parameter: this.translateService.translate(TRANSLATION_KEY.PARAMETER_NAME_MODELLE_MINSTAERKE),
        value: this.numberToDisplayString(parameterService.model?.minstaerke),
      },
      {
        parameter: this.translateService.translate(TRANSLATION_KEY.PARAMETER_NAME_MODELLE_MODELLID),
        value: this.numberToDisplayString(parameterService.model?.modellid),
      },
      {
        parameter: this.translateService.translate(TRANSLATION_KEY.PARAMETER_NAME_MODELLE_POS),
        value: this.stringToDisplayString(parameterService.model?.pos),
      },
      {
        parameter: this.translateService.translate(TRANSLATION_KEY.PARAMETER_NAME_MODELLE_PZPOSITION_AKTIV),
        value: this.booleanToDisplayString(parameterService.model?.pzPosition?.aktiv),
      },
      {
        parameter: this.translateService.translate(TRANSLATION_KEY.PARAMETER_NAME_MODELLE_PZPOSITION_D),
        value: this.numberToDisplayString(parameterService.model?.pzPosition?.d),
      },
      {
        parameter: this.translateService.translate(TRANSLATION_KEY.PARAMETER_NAME_MODELLE_PZPOSITION_TYP),
        value: this.stringToDisplayString(parameterService.model?.pzPosition?.typ),
      },
      {
        parameter: this.translateService.translate(TRANSLATION_KEY.PARAMETER_NAME_MODELLE_PZPOSITION_V),
        value: this.numberToDisplayString(parameterService.model?.pzPosition?.v),
      },
      {
        parameter: this.translateService.translate(TRANSLATION_KEY.PARAMETER_NAME_MODELLE_PZPOSITION_W),
        value: this.numberToDisplayString(parameterService.model?.pzPosition?.w),
      },
      {
        parameter: this.translateService.translate(TRANSLATION_KEY.PARAMETER_NAME_MODELLE_SEITENTEILHOEHENVERTEILUNG_A),
        value: this.numberToDisplayString(parameterService.model?.seitenteilHoehenverteilung?.a),
      },
      {
        parameter: this.translateService.translate(TRANSLATION_KEY.PARAMETER_NAME_MODELLE_SEITENTEILHOEHENVERTEILUNG_B),
        value: this.numberToDisplayString(parameterService.model?.seitenteilHoehenverteilung?.b),
      },
      {
        parameter: this.translateService.translate(TRANSLATION_KEY.PARAMETER_NAME_MODELLE_SIXA),
        value: this.numberToDisplayString(parameterService.model?.sixa),
      },
      {
        parameter: this.translateService.translate(TRANSLATION_KEY.PARAMETER_NAME_MODELLE_SIXB),
        value: this.numberToDisplayString(parameterService.model?.sixb),
      },
      {
        parameter: this.translateService.translate(TRANSLATION_KEY.PARAMETER_NAME_MODELLE_SIYA),
        value: this.numberToDisplayString(parameterService.model?.siya),
      },
      {
        parameter: this.translateService.translate(TRANSLATION_KEY.PARAMETER_NAME_MODELLE_SIYB),
        value: this.numberToDisplayString(parameterService.model?.siyb),
      },
      {
        parameter: this.translateService.translate(TRANSLATION_KEY.PARAMETER_NAME_MODELLE_SOXA),
        value: this.numberToDisplayString(parameterService.model?.soxa),
      },
      {
        parameter: this.translateService.translate(TRANSLATION_KEY.PARAMETER_NAME_MODELLE_SOXB),
        value: this.numberToDisplayString(parameterService.model?.soxb),
      },
      {
        parameter: this.translateService.translate(TRANSLATION_KEY.PARAMETER_NAME_MODELLE_SOYA),
        value: this.numberToDisplayString(parameterService.model?.soya),
      },
      {
        parameter: this.translateService.translate(TRANSLATION_KEY.PARAMETER_NAME_MODELLE_SOYB),
        value: this.numberToDisplayString(parameterService.model?.soyb),
      },
      {
        parameter: this.translateService.translate(TRANSLATION_KEY.PARAMETER_NAME_MODELLE_TUERTYP),
        value: this.stringToDisplayString(parameterService.model?.tuertyp),
      },
      {
        parameter: this.translateService.translate(TRANSLATION_KEY.PARAMETER_NAME_MODELLE_VIXA),
        value: this.numberToDisplayString(parameterService.model?.vixa),
      },
      {
        parameter: this.translateService.translate(TRANSLATION_KEY.PARAMETER_NAME_MODELLE_VIXB),
        value: this.numberToDisplayString(parameterService.model?.vixb),
      },
      {
        parameter: this.translateService.translate(TRANSLATION_KEY.PARAMETER_NAME_MODELLE_VIYA),
        value: this.numberToDisplayString(parameterService.model?.viya),
      },
      {
        parameter: this.translateService.translate(TRANSLATION_KEY.PARAMETER_NAME_MODELLE_VIYB),
        value: this.numberToDisplayString(parameterService.model?.viyb),
      },
      {
        parameter: this.translateService.translate(TRANSLATION_KEY.PARAMETER_NAME_MODELLE_VOXA),
        value: this.numberToDisplayString(parameterService.model?.voxa),
      },
      {
        parameter: this.translateService.translate(TRANSLATION_KEY.PARAMETER_NAME_MODELLE_VOXB),
        value: this.numberToDisplayString(parameterService.model?.voxb),
      },
      {
        parameter: this.translateService.translate(TRANSLATION_KEY.PARAMETER_NAME_MODELLE_VOYA),
        value: this.numberToDisplayString(parameterService.model?.voya),
      },
      {
        parameter: this.translateService.translate(TRANSLATION_KEY.PARAMETER_NAME_MODELLE_VOYB),
        value: this.numberToDisplayString(parameterService.model?.voyb),
      },
    ])
  }

  private booleanToDisplayString(value?: boolean): string {
    return this.translateService.translate(
      typeof value === 'undefined' ? TRANSLATION_KEY.UNDEFINED_PARAMETER
        : value ? TranslationKeys.BOOLEAN_TRUE
          : TranslationKeys.BOOLEAN_FALSE
    )
  }

  private numberToDisplayString(value?: number): string {
    return typeof value === 'undefined' ? this.translateService.translate(TRANSLATION_KEY.UNDEFINED_PARAMETER)
      : typeof value === 'number' ? JSON.stringify(value)
        : JSON.stringify(String(value))
  }

  onClipboardCopy(successful: boolean): void {
    if (successful) {
      this.toastrService.success(
        this.translateService.translate(TRANSLATION_KEY.COPY_SUCCESSFUL_TEXT),
        this.translateService.translate(TRANSLATION_KEY.COPY_SUCCESSFUL_TITLE),
      )
    } else {
      this.toastrService.error(
        this.translateService.translate(TRANSLATION_KEY.COPY_ERROR_TEXT),
        this.translateService.translate(TRANSLATION_KEY.COPY_ERROR_TITLE),
      )
    }
  }

  private parameterModeToDisplayString(value?: unknown): string {
    return typeof value === 'undefined' ? this.translateService.translate(TRANSLATION_KEY.UNDEFINED_PARAMETER)
      : value === ParameterModes.New ? this.translateService.translate(TRANSLATION_KEY.PARAMETER_MODE_NEW)
        : value === ParameterModes.Edit ? this.translateService.translate(TRANSLATION_KEY.PARAMETER_MODE_EDIT)
          : value === ParameterModes.Open ? this.translateService.translate(TRANSLATION_KEY.PARAMETER_MODE_OPEN)
            : this.translateService.translate(TRANSLATION_KEY.PARAMETER_MODE_UNKNOWN)
  }

  private stringToDisplayString(value?: string): string {
    return typeof value === 'undefined' ? this.translateService.translate(TRANSLATION_KEY.UNDEFINED_PARAMETER)
      : JSON.stringify(String(value))
  }

  get fullUrl(): string {
    return window.location.href
  }

  @ViewChildren(MatSort)
  set matSorts(sorts: QueryList<MatSort>) {
    if (sorts.length === 2) {
      this.generalParameterData.sort = sorts.get(0)
      this.modelParameterData.sort = sorts.get(1)
    }
  }
}
