import {ChangeEvent, ChangeType} from './events.types'
import {ZubehoerAddonEntry} from '../component/extras/zubehoer/zubehoerAddonEntry'
import {MehrpreisEntry} from '../component/extras/zubehoer/mehrpreisEntry'

export interface AddonChangeEventPayload extends ChangeEvent<Pick<ZubehoerAddonEntry, 'Id' | 'Typ'>> {
  readonly mehrpreis: MehrpreisEntry
}

export default class AddonChangeEvent implements AddonChangeEventPayload {
  constructor(
    readonly identifier: Pick<ZubehoerAddonEntry, 'Id' | 'Typ'>,
    readonly type: ChangeType,
    readonly mehrpreis: MehrpreisEntry
  ) {
  }

  static added(addon: ZubehoerAddonEntry, mehrpreis: MehrpreisEntry): AddonChangeEvent {
    return new AddonChangeEvent(addon, ChangeType.Added, mehrpreis)
  }

  static removed(addon: ZubehoerAddonEntry, mehrpreis: MehrpreisEntry): AddonChangeEvent {
    return new AddonChangeEvent(addon, ChangeType.Removed, mehrpreis)
  }
}
