import {Injectable} from '@angular/core'
import {HttpClient, HttpHeaders} from '@angular/common/http'
import {Params} from '@angular/router'
import {environment} from '../environments/environment'
import {Observable, throwError} from 'rxjs'
import {catchError} from 'rxjs/operators'
import {ConfiguratedElement, shareParameter} from './class'
import TrackingPostData from './classes/api/models/trackingPostData'
import {ConfiguratedDoorDto} from './classes/api/configuratedDoor.dto'
import {ModelLoadRequestData} from './classes/api/model/model-load-request-data.dto'
import {Settings} from './classes/service/settings/settings'
import {SavedFavouriteDto} from './classes/api/models/savedFavourite.dto'
import {CompactComponentModel} from './classes/api/model/compact-component-model.interface'
import {ModelLoadResponse} from './classes/api/model/model-load-response.interface'
import {ResponsePrice} from './classes/api/price/response-price.interface'
import {ResponseImage} from './classes/api/image/response-render.interface'
import {ResponseValidation} from './classes/api/validation/response-validation.interface'
import {ResponseWorkflow} from './classes/api/workflow/response-workflow.interface'
import {ResponseDeckschichtStaerken} from './classes/api/deckschicht/response-deckschicht.interface'
import {ResponseInsulation} from './classes/api/insulations/response-insulation.interface'
import {ResponseText} from './classes/api/texts/response-text.interface'
import {ResponseWeight} from './classes/api/weights/response-weight.interface'
import {ResponseKatalog} from './classes/api/kataloge/response-katalog.interface'
import {ResponseGrundform} from './classes/api/grundformen/response-grundform.interface'
import {ResponseGlass} from './classes/api/glasses/response-glass.interface'
import {ResponseProfil} from './classes/api/profile/response-profil.interface'
import {ResponseZubehoer} from './classes/api/zubehoer/response-zubehoer.interface'
import {ResponseMassblaetter} from './classes/api/massblaetter/response-massblaetter.interface'
import {RequestContact} from './classes/api/contact/request-contact.interface'
import {ResponseStatus} from './classes/api/contact/response-status.interface'
import {Hausfront} from './classes/model/component/hausfront/hausfront'
import {CookieConsentData} from './cookie-consent/cookie-consent-data.type'
import {SideType} from './types'
import {HausfrontRequest} from './classes/model/component/hausfront/hausfront-request'
import {ParameterService} from './classes/service/parameter/parameter.service'
import {SaveConfigurationResponse} from './classes/api/saveConfigurationResponse'
import {Tag} from './classes/model/component/other/tag'

@Injectable()
export class HttpService {
  private readonly apiUrl = environment.API_ENDPOINT_V2
  private readonly calculateUrl = environment.API_CALCULATE_ENDPOINT_V2
  private headers = new HttpHeaders()
  private headersFile = new HttpHeaders()
  private readonly rendererUrl = environment.API_RENDERER_ENDPOINT_V2

  constructor(
    private http: HttpClient,
    parameterService: ParameterService
  ) {
    const params = parameterService.params
    this.createAuthorizationHeader(params)
    this.createAuthorizationHeaderFileUpload(params)
    if (typeof params.API_CALCULATE_ENDPOINT_V2 === 'string') {
      this.calculateUrl = params.API_CALCULATE_ENDPOINT_V2
    }
    if (typeof params.API_ENDPOINT_V2 === 'string') {
      this.apiUrl = params.API_ENDPOINT_V2
    }
    if (typeof params.API_RENDERER_ENDPOINT_V2 === 'string') {
      this.rendererUrl = params.API_RENDERER_ENDPOINT_V2
    }
  }

  /* Header generieren */

  /* JSON */
  createAuthorizationHeader(params: Params): void {
    // let token = params['token'] || '85e5b585-d313-4391-828a-a9b3b89cdf68';
    const token = typeof params.token === 'string' ? params.token : environment.token
    this.headers = new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Basic ' + btoa('rodenberg:' + token),
      'Accept-Language': typeof params.lang === 'string' ? params.lang : 'de',
    })
  }

  /* File upload */
  createAuthorizationHeaderFileUpload(params: Params): void {
    const token = typeof params.token === 'string' ? params.token : environment.token
    this.headersFile = new HttpHeaders({
      Authorization: 'Basic ' + btoa('rodenberg:' + token),
      'Accept-Language': typeof params.lang === 'string' ? params.lang : 'de',
    })
  }

  fbsGetDeckschichtStaerke(data: ConfiguratedDoorDto): Observable<ResponseDeckschichtStaerken> {
    return this.http.post<ResponseDeckschichtStaerken>(this.apiUrl + '/deckschicht', JSON.stringify(data), {headers: this.headers})
  }

  fbsGetInsulations(data: ConfiguratedDoorDto): Observable<ResponseInsulation> {
    return this.http.post<ResponseInsulation>(
      this.apiUrl + '/insulations',
      JSON.stringify(data),
      {headers: this.headers}
    )
  }

  fbsGetTexts(data: ConfiguratedDoorDto): Observable<ResponseText> {
    return this.http.post<ResponseText>(this.apiUrl + '/texts', JSON.stringify(data), {headers: this.headers})
  }

  fbsGetValidations(data: ConfiguratedDoorDto): Observable<ResponseValidation> {
    return this.http.post<ResponseValidation>(this.apiUrl + '/validations', JSON.stringify(data), {headers: this.headers})
  }

  fbsGetWeights(data: ConfiguratedDoorDto): Observable<ResponseWeight> {
    return this.http.post<ResponseWeight>(this.apiUrl + '/weights', JSON.stringify(data), {headers: this.headers})
  }

  /* Catalogs */
  getAllCatalogs(): Observable<ResponseKatalog[]> {
    return this.http.get<ResponseKatalog[]>(this.apiUrl + '/kataloge/', {headers: this.headers}).pipe(
      catchError(this.handleError)
    )
  }

  /* Models */
  getAllDoorModelsV2(): Observable<{ Modelle?: CompactComponentModel[] }> {
    return this.http.get<{
      Modelle?: CompactComponentModel[]
    }>(this.apiUrl + '/common/search', {headers: this.headers})
  }

  getAllGrundformen(): Observable<ResponseGrundform[]> {
    return this.http.get<ResponseGrundform[]>(this.apiUrl + '/grundformen', {headers: this.headers})
  }

  getCalculationFBS(configuration: ConfiguratedDoorDto): Observable<ResponsePrice> {
    return this.http.post<ResponsePrice>(
      this.calculateUrl + '/calculation',
      JSON.stringify(configuration),
      {headers: this.headers}
    )
  }

  getConfig(tuerIdOrTranskey: string, pos?: string): Observable<Partial<ConfiguratedElement>> {
    const url = `${this.apiUrl}/konfiguration/load${
      typeof pos !== 'undefined'
        ? `?id=${tuerIdOrTranskey}&positionsId=${pos}`
        : `?id=${tuerIdOrTranskey}`
    }`
    return this.http.get<Partial<ConfiguratedElement>>(
      url,
      {headers: this.headers}
    )
  }

  getCustomHausfront(id: number): Observable<Hausfront> {
    return this.http.get<Hausfront>(this.apiUrl + '/hausfronten/' + id, {headers: this.headersFile})
  }

  getDoorImageThumbnails(tuerId: string): Observable<ResponseImage> {
    return this.http.get<ResponseImage>(
      this.apiUrl + '/images/thumbnail/' + tuerId,
      {headers: this.headers}
    )
  }

  getDoorImages(tuerId: string): Observable<ResponseImage> {
    return this.http.get<ResponseImage>(
      this.apiUrl + '/images/' + tuerId,
      {headers: this.headers}
    )
  }

  getDoorReportUrl(tuerId: string): Observable<{ Url: string }> {
    return this.http.get<{ Url: string }>(
      this.apiUrl + '/reports/ttk/' + tuerId,
      {headers: this.headers}
    )
  }

  /* Get Favourites */
  getFavorite(hash: string): Observable<unknown> {
    return this.http.get(this.apiUrl + '/store' + '/' + hash, {headers: this.headers})
  }

  getGlasses(): Observable<ResponseGlass[]> {
    return this.http.get<ResponseGlass[]>(this.apiUrl + '/glasses', {headers: this.headers})
  }

  getHeaders(): HttpHeaders {
    return this.headers
  }

  /* Images */
  getLogo(type: string, logoId: string): Observable<string> {
    return this.http.get<string>(this.apiUrl + '/images' + '/' + 'logo' + '/' + type + '/' + logoId, {headers: this.headers})
  }

  /* Profiles */
  getProfiles(): Observable<ResponseProfil[]> {
    return this.http.get<ResponseProfil[]>(this.apiUrl + '/profile', {headers: this.headers})
  }

  getSettingsFBS(token: string): Observable<Partial<Settings>> {
    return this.http.get(this.apiUrl + '/common/' + token + '/settings', {headers: this.headers})
  }

  getSettingsTTK(subdomain: string): Observable<Partial<Settings>> {
    return this.http.get(this.apiUrl + '/common/' + subdomain, {headers: this.headers})
  }

  getTags(): Observable<Tag[]> {
    return this.http.get<Tag[]>(this.apiUrl + '/tags', {headers: this.headers})
  }

  getTranslations(lang: string): Observable<unknown> {
    return this.http.get(this.apiUrl + '/common/translations/' + lang, {headers: this.headers})
  }

  getWorkflows(): Observable<ResponseWorkflow[]> {
    return this.http.get<ResponseWorkflow[]>(
      this.apiUrl + '/automatisierungen',
      {headers: this.headers}
    )
  }

  getZubehoer(): Observable<ResponseZubehoer[]> {
    return this.http.get<ResponseZubehoer[]>(this.apiUrl + '/zubehoer')
  }

  private handleError(this: void, error: unknown): Observable<never> {
    let tmp: unknown
    const errorString = (
      typeof error === 'object'
      && 'json' in error
      && typeof error.json === 'function'
      && (tmp = error.json() as unknown)
      && typeof tmp === 'string'
    ) ? tmp : 'Server error'
    // return Observable.throw(error.json() || 'Server error');
    return throwError((): Error => new Error(errorString))
  }

  postContactRequest(data: RequestContact): Observable<ResponseStatus> {
    return this.http.post<ResponseStatus>(this.apiUrl + '/contacts', data, {headers: this.headers})
  }

  postDoorModelV2(data: ModelLoadRequestData): Observable<ModelLoadResponse> {
    return this.http.post<ModelLoadResponse>(this.apiUrl + '/models', data, {headers: this.headers})
  }

  /* Save Favourites */
  postFavorite(data: ConfiguratedDoorDto): Observable<SavedFavouriteDto> {
    return this.http.post(this.apiUrl + '/favorite/new', JSON.stringify(data), {headers: this.headers})
  }

  postFbsConfig(data: ConfiguratedDoorDto): Observable<SaveConfigurationResponse> {
    return this.http.post<SaveConfigurationResponse>(
      this.apiUrl + '/konfiguration/save?transaktionsId=' + data.TransKey + '&positionsId=' + data.Pos,
      JSON.stringify(data),
      {headers: this.headers}
    )
  }

  postHausfrontUpdate(customHausfrontRequest: HausfrontRequest): Observable<Hausfront> {
    return this.http.post<Hausfront>(this.apiUrl + '/hausfronten', customHausfrontRequest, {headers: this.headersFile})
  }

  /* Image download */
  postImagesV2(data: ConfiguratedDoorDto): Observable<ResponseImage> {
    return this.http.post(this.rendererUrl + '/images/render', JSON.stringify(data), {headers: this.headers})
  }

  postMultivision(tuerId: string, side: SideType): Observable<void> {
    return this.http.post<void>(
      this.apiUrl + '/images/multivision',
      JSON.stringify({TuerId: tuerId, Ansicht: side}),
      {headers: this.headers}
    )
  }

  /* Share Configuration */
  postShareConfiguration(data: shareParameter): Observable<void> {
    return this.http.post<void>(this.apiUrl + '/contacts/share', JSON.stringify(data), {headers: this.headers})
  }

  postUploadedHausfront(data: File, customHausfrontRequest: HausfrontRequest): Observable<Hausfront> {
    const formData = new FormData()
    if (data) {
      formData.append('file', data)
    }
    formData.append('position', JSON.stringify(customHausfrontRequest))
    return this.http.post<Hausfront>(this.apiUrl + '/upload/hausfronten', formData, {headers: this.headersFile})
  }

  saveCookieConsent(consentData: CookieConsentData): Observable<void> {
    return this.http.post<void>(this.apiUrl + '/privacy-consent/', JSON.stringify(consentData), {headers: this.headers})
  }

  setLang(lang: string): void {
    this.headers = this.headers.set('Accept-Language', lang)
  }

  setToken(token: string): void {
    this.headers = this.headers.set('Authorization', 'Basic ' + btoa('rodenberg:' + token))
  }

  track(data: TrackingPostData): Observable<unknown> {
    return this.http.post(this.apiUrl + '/logging', JSON.stringify(data), {headers: this.headers})
  }

  validateMassblaetter(data: ConfiguratedDoorDto, scriptId?: number): Observable<ResponseMassblaetter> {
    const dataObj: ConfiguratedDoorDto & { SkriptId?: number } = data
    if (scriptId) {
      dataObj.SkriptId = scriptId
    }

    return this.http.post<ResponseMassblaetter>(
      this.apiUrl + '/massblaetter',
      JSON.stringify(dataObj),
      {headers: this.headers}
    )
  }
}
