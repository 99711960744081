import {Component, ElementRef, EventEmitter, HostListener, isDevMode, OnInit, Output, ViewChild} from '@angular/core'
import {HausfrontDoorPosition} from '../class'
import {HttpService} from '../http.service'
import {TranslateService} from '../translate'
import {environment} from '../../environments/environment'
import {ComponentModel} from '../classes/model/component/model/component-model'
import {ConstructionComponent} from '../classes/model/component/construction/constructionComponent'
import * as constEnumModule from '../types'
import {ConfiguratorMode, Din, InsideOutsideObject, SideType} from '../types'
import {NGXLogger} from 'ngx-logger'
import {ConfiguratedDoor} from '../classes/model/configuratedDoor'
import {CatalogueService} from '../classes/service/catalogueService'
import {StringUtil} from '../classes/util/stringUtil'
import {Settings} from '../classes/service/settings/settings'
import {ParameterService} from '../classes/service/parameter/parameter.service'
import {ConfiguratorDataModel} from '../classes/model/configuratorDataModel'
import {FBS_MODEL_PROVIDER_TOKEN} from '../classes/model/dataProvider/fbsModel/fbsModel.type'
import {ParameterModelConfiguration} from '../classes/model/modelConfiguration/parameterModelConfiguration'
import {ConfiguratorConfigurationModel} from '../classes/model/configuratorConfigurationModel'
import {ModalService} from './modal/modal.service'
import TrackingService from '../classes/service/tracking.service'
import {BetterMatRadioChange} from '../directives/better-mat-radio-group.directive'
import {DinUtil} from '../classes/util/dinUtil'
import {ToastrService} from 'ngx-toastr'
import {GrundformService} from '../classes/service/grundform.service'
import {ProfileService} from '../classes/service/profile.service'
import {HausfrontMenuComponent} from './menu/hausfront-menu/hausfront-menu.component'
import {ConfiguratorModeService} from '../classes/service/configuratorMode.service'
import {ConfiguratedDoorDto} from '../classes/api/configuratedDoor.dto'
import {isMaterial, Material} from '../classes/model/material'
import {ShareAPIPolyfillOptions} from 'share-api-polyfill'
import {ComponentSelectionService} from '../classes/service/componentSelectionService'
import {WorkflowService} from '../workflow/workflow.service'
import {WorkflowSubscriber} from '../workflow/workflow.types'
import {ViewService} from '../classes/service/view.service'
import {Parameter} from '../classes/service/parameter/parameter'
import * as ConstructionComponentTypeEnumModule
  from '../classes/model/component/construction/construction-component-type.enum'
import {
  ConstructionComponentType,
  isConstructionComponentType
} from '../classes/model/component/construction/construction-component-type.enum'
import {FbsModelTyp} from '../classes/model/component/model/component-model-type.enum'
import {SettingsService} from '../classes/service/settings/settings.service'
import MemoListService from '../classes/service/memo-list/memo-list.service'
import {LoadRequestIdentifier} from './components/load-configuration-button/load-configuration-button.component'
import {CookieConsentService} from '../cookie-consent'
import {ConfiguratorInitializerService, LoadService} from './load'
import {NavigatorService} from '../classes/service/navigation/navigator.service'
import {NavigationMenuEntryKey} from '../classes/service/navigation/navigation-menu-entry'
import {EventBusService} from '../classes/service/event/event-bus.service'
import {EventBusSupport} from '../classes/service/event/event-bus-support'
import {
  closeCharacteristicsMenuRequest,
  fillingMaterialChanged,
  grundformChange,
  houseFrontChanged,
  loadRequestDoorId,
  modelSelectRequest,
  optionChanged,
  renderRequest,
  securityPackageChanged,
  zMassChanged
} from '../classes/service/event/events'


const TRANSLATION_KEY = {
  CONFIGURATOR_FRONTOPTIONS_ADDMEMO: 'ConfiguratorComponent.FrontOptions.AddMemo',
  CONFIGURATOR_FRONTOPTIONS_CHARACTERISTICS: 'ConfiguratorComponent.FrontOptions.Charasteristics',
  CONFIGURATOR_FRONTOPTIONS_MULTIVISION: 'ConfiguratorComponent.FrontOptions.Multivision',
  CONFIGURATOR_FRONTOPTIONS_PRICE: 'ConfiguratorComponent.FrontOptions.Price',
  TOAST_ERROR_CONFIGURATION_NOT_FOUND_TITLE: 'ConfiguratorComponent.Toast.Error.ConfigurationNotFound.Title',
  TOAST_ERROR_CONFIGURATION_NOT_FOUND_MESSAGE: 'ConfiguratorComponent.Toast.Error.ConfigurationNotFound.Message',
  TOAST_ERROR_CONFIGURATION_LOAD_UNKNOWN_TITLE: 'ConfiguratorComponent.Toast.Error.ConfigurationLoadUnknown.Title',
  TOAST_ERROR_CONFIGURATION_LOAD_UNKNOWN_MESSAGE: 'ConfiguratorComponent.Toast.Error.ConfigurationLoadUnknown.Message',
  TOAST_ERROR_GLASS_SPACER_UNDERCUT_TITLE: 'ConfiguratorComponent.Toast.Error.SpacerThicknessUndercut.Title',
  TOAST_ERROR_GLASS_SPACER_UNDERCUT_MESSAGE_PREFIX: 'ConfiguratorComponent.Toast.Error.SpacerThicknessUndercut.Message.Prefix',
  TOAST_ERROR_GLASS_SPACER_UNDERCUT_MESSAGE_SUFFIX: 'ConfiguratorComponent.Toast.Error.SpacerThicknessUndercut.Message.Suffix',
  TOAST_ERROR_GLASS_SPACER_EXCEEDED_TITLE: 'ConfiguratorComponent.Toast.Error.SpacerThicknessExceeded.Title',
  TOAST_ERROR_GLASS_SPACER_EXCEEDED_MESSAGE_PREFIX: 'ConfiguratorComponent.Toast.Error.SpacerThicknessExceeded.Message.Prefix',
  TOAST_ERROR_GLASS_SPACER_EXCEEDED_MESSAGE_SUFFIX: 'ConfiguratorComponent.Toast.Error.SpacerThicknessExceeded.Message.Suffix',
  TOAST_ERROR_GLASS_SPACER_UNKNOWN_TITLE: 'ConfiguratorComponent.Toast.Error.SpacerThicknessUnknown.Title',
  TOAST_ERROR_GLASS_SPACER_UNKNOWN_MESSAGE: 'ConfiguratorComponent.Toast.Error.SpacerThicknessUnknown.Message',
  TOAST_ERROR_LOADING_MODEL_MESSAGE: 'ConfiguratorComponent.Toast.Error.LoadingModel.Message',
  TOAST_ERROR_LOADING_MODEL_TITLE: 'ConfiguratorComponent.Toast.Error.LoadingModel.Title',
  TOAST_ERROR_RENDER_TITLE: 'ConfiguratorComponent.Toast.Error.Render.Title',
  TOAST_ERROR_RENDER_MESSAGE: 'ConfiguratorComponent.Toast.Error.Render.Message',
  TOAST_WARNING_ZVALUE_TOO_LARGE_TITLE: 'ConfiguratorComponent.Toast.Warning.ZValueTooLarge.Title',
  TOAST_WARNING_ZVALUE_TOO_LARGE_MESSAGE_PREFIX: 'ConfiguratorComponent.Toast.Warning.ZValueTooLarge.Message.Prefix',
  TOAST_WARNING_ZVALUE_TOO_LARGE_MESSAGE_SUFFIX: 'ConfiguratorComponent.Toast.Warning.ZValueTooLarge.Message.Suffix',
  TOAST_WARNING_ZVALUE_TOO_SMALL_TITLE: 'ConfiguratorComponent.Toast.Warning.ZValueTooSmall.Title',
  TOAST_WARNING_ZVALUE_TOO_SMALL_MESSAGE_PREFIX: 'ConfiguratorComponent.Toast.Warning.ZValueTooSmall.Message.Prefix',
  TOAST_WARNING_ZVALUE_TOO_SMALL_MESSAGE_SUFFIX: 'ConfiguratorComponent.Toast.Warning.ZValueTooSmall.Message.Suffix',
  LOADING_PLEASE_WAIT: 'ConfiguratorComponent.Loading.PleaseWait',

  VIEW_OUTSIDE: 'ConfiguratorComponent.View.Outside',
  VIEW_INSIDE: 'ConfiguratorComponent.View.Inside',
  DOWNLOAD_DOOR_IMAGE: 'ConfiguratorComponent.Door.DownloadImage',
  HINT_MEASURES_NOT_OBLIGING: 'ConfiguratorComponent.Hint.MeasuresNotObliging',
  FOOTER_ITEMS_VERSION: 'ConfiguratorComponent.Footer.Version',

  BUTTON_VIEWSWITCH_OUTSIDE: 'ConfiguratorComponent.Button.ViewSwitch.Outside',
  BUTTON_VIEWSWITCH_INSIDE: 'ConfiguratorComponent.Button.ViewSwitch.Inside',

  HEADER_TTK_SETTINGS: 'ConfiguratorComponent.Header.TTK.Settings',
} as const

@Component({
  selector: 'app-configurator',
  templateUrl: './configurator.component.html',
  styleUrls: ['./configurator.component.scss'],
  providers: [
    TrackingService,
    {provide: FBS_MODEL_PROVIDER_TOKEN, useExisting: ConfiguratorDataModel},
  ]
})
export class ConfiguratorComponent extends EventBusSupport implements OnInit {
  protected ConfiguratorMode = ConfiguratorMode
  protected readonly ConstructionComponentType: typeof ConstructionComponentType =
    (ConstructionComponentTypeEnumModule as {
      ConstructionComponentType: Required<typeof ConstructionComponentType>
    })?.ConstructionComponentType
  protected readonly NavigationMenuEntryKey = NavigationMenuEntryKey
  protected readonly SideType: typeof SideType = (constEnumModule as {
    SideType: Required<typeof SideType>
  })?.SideType
  protected readonly StringUtil = StringUtil
  protected readonly TRANSLATION_KEY = TRANSLATION_KEY
  @ViewChild('contentWrapper') protected contentWrapper: ElementRef
  protected doorDisplay: InsideOutsideObject<{
    doorImageUrl: string | undefined
    houseFrontImageUrl: string | undefined
  }>
  protected doorPosition: HausfrontDoorPosition | null = null
  @ViewChild(HausfrontMenuComponent) protected hausfrontMenuComponent: HausfrontMenuComponent
  @ViewChild('houseFrontImage') protected houseFrontImage: ElementRef
  protected readonly isDevMode = isDevMode
  protected isMobile = false
  // if the current device is mobile
  protected loading = true
  @Output() readonly ngInit: EventEmitter<void>
  private screenHeight = 0
  private screenWidth = 0
  protected showCharacteristics: boolean = false
  public showNextToggle = ''
  protected version: string = environment.version

  constructor(
    private httpService: HttpService,
    private _translate: TranslateService,
    private toastrService: ToastrService,
    private logger: NGXLogger,
    protected modalService: ModalService,
    private catalogueService: CatalogueService,
    private grundformService: GrundformService,
    private viewService: ViewService,
    private _configuratorModeService: ConfiguratorModeService,
    protected _componentSelection: ComponentSelectionService,
    private profileService: ProfileService,
    protected navigatorService: NavigatorService,
    private parameterService: ParameterService,
    private trackingService: TrackingService,
    private _configuratorDataModel: ConfiguratorDataModel,
    private configuratorConfigurationModel: ConfiguratorConfigurationModel,
    private workflowService: WorkflowService,
    private settingsService: SettingsService,
    protected readonly memoListService: MemoListService,
    protected cookieConsentService: CookieConsentService,
    private readonly configuratorInitializeHelper: ConfiguratorInitializerService,
    private readonly loadService: LoadService,
    eventBus: EventBusService
  ) {
    super(eventBus)
    this.logger.trace('ConfiguratorComponent::constructor')
    this.isMobile = window.innerWidth <= 767
    this.screenWidth = window.innerWidth
    this.screenHeight = window.innerHeight
    this.ngInit = new EventEmitter<void>()
    this.doorDisplay = {
      [SideType.Inside]: {
        doorImageUrl: undefined,
        houseFrontImageUrl: undefined
      },
      [SideType.Outside]: {
        doorImageUrl: undefined,
        houseFrontImageUrl: undefined
      }
    }
    if (this.configuratorMode === ConfiguratorMode.FBS) {
      this.cookieConsentService.showConsentBanner = false
      // this.cookieConsentService.addConsent('necessary')
      // this.cookieConsentService.addConsent('tracking')
    }
  }

  // checks if all configuratedDoor elements' models have been loaded
  configuratedModelsLoaded(): ComponentModel {
    return this._componentSelection.selectedComponent && this._componentSelection.selectedComponent.model
  }

  generateElement(): ConfiguratedDoorDto {
    return this.configuratorConfigurationModel.configuratedDoorDto
  }

  getDeckschichtStaerken(): Promise<void> {
    return this.configuratorConfigurationModel.updateDeckschichtStaerke()
  }

  getDin(): Din | undefined {
    return this._componentSelection.selectedComponent.dinfuellung as Din || DinUtil.fromString(this.configuratedDoor?.Grundform?.DinElement)
  }

  getModel(
    modelId: string,
    componentType: ConstructionComponentType,
    material?: Material | null,
    loadRequestIdentifier?: LoadRequestIdentifier
  ): Promise<ComponentModel> {
    this.logger.trace('getModel')
    this.loading = true
    return this.configuratorDataModel
      .loadModel(
        modelId,
        componentType,
        material ?? this._componentSelection.selectedComponent.material ?? this.modell.material ?? Material.Kunststoff,
        this.parameter.profil ?? this.configuratedDoor.profile.Beschreibung,
        this._componentSelection.selectedComponent.konstruktion,
        this.modell,
        this._componentSelection.selectedComponent.oeffnungsart,
        this.getDin()
        ?? (this._componentSelection.selectedComponent.oeffnungsart === 'innen'
          ? DinUtil.fromString(this.modell.din)
          : DinUtil.fromString(this.modell.din) as number === 0 ? 1 : 0),
        loadRequestIdentifier
      )
  }

  hideAndReloadConsent(): void {
    this.cookieConsentService.showConsentBanner = false
    window.location.reload()
  }

  lazyImageLoaded(object: {
    loaded?: boolean
  }): void {
    object.loaded = true
  }

  async loadComponentByIndex(loadRequestIdentifier: LoadRequestIdentifier): Promise<void> {
    return this.loadService.loadComponentOfDoorFromLoadedConfig(loadRequestIdentifier)
  }

  async loadDoorById(doorId: string): Promise<void> {
    this.loading = true
    try {
      await this.loadService.loadDoorById(doorId)
      await this.render()
    } finally {
      this.loading = false
    }
  }

  ngOnInit(): void {
    this.logger.trace('ngOnInit')
    this.loading = true
    this.ngInit.emit()
    window.ttc = this
    this.subscribeToEvents()
    const loadingModal = this._configuratorModeService.mode === ConfiguratorMode.TTK
      ? this.modalService.showInitialLoadingModal()
      : undefined
    loadingModal?.componentInstance.setMessage('Lade Einstellungen...')
    const workflowSubscriber: WorkflowSubscriber[] = []
    loadingModal?.componentInstance.setMessage('Lade Kataloge und Modelle...')
    const preloading = [
      this._configuratorDataModel.preload(),
      this.catalogueService.load(),
      this.grundformService.load(),
      this.profileService.load(),
      this.workflowService.load().then((subs: WorkflowSubscriber[]): void => {
        workflowSubscriber.push(...subs)
      }),
      this.configuratorInitializeHelper.loadConfig(),
      this.configuratorInitializeHelper.construction()
    ]

    void Promise.all(preloading)
      .then((): Promise<void> => this.configuratorInitializeHelper.resetConfiguratedDoor())
      .then((): void => workflowSubscriber.forEach((sub): void =>
        void sub.subscribe(this.configuratorConfigurationModel, this._configuratorDataModel, this.parameterService, this.toastrService))
      ).then((): Promise<void> | void =>
        this.configuratorInitializeHelper.initialLoadDoor()
          .then((): Promise<void> => this.render())
          .catch(async (error): Promise<void> => {
            this.logger.error(error)
            this.toastrService.error(
              this._translate.translate(
                TRANSLATION_KEY.TOAST_ERROR_LOADING_MODEL_MESSAGE
              ),
              this._translate.translate(TRANSLATION_KEY.TOAST_ERROR_LOADING_MODEL_TITLE)
            )
            await this.resetConfiguratedDoor()
          })
      ).catch((error): void => {
        this.logger.error(error)
      })
      .then((): void => {
        this.loading = false
      })
      .then(() => void this.trackingService.track())
      .then(() => void loadingModal?.close())
      .then(() => void this.ngInit.complete())
  }

  onMaterialChange(materialChange: BetterMatRadioChange): void {
    // event.preventDefault()
    const oldValue = materialChange.previous
    const newValue = materialChange.value as unknown
    if (newValue === oldValue || !isMaterial(newValue)) {
      return
    }
    const component = this.selectedComponent
    this.modalService.showFuellungMaterialChangeModal()
      .afterClosed()
      .subscribe((result): void => {
        if (result) {
          component.material = newValue
          component.optionen = {Inside: [], Outside: []}
          void this.onSelectModel(
            component.model.Id,
            component.objectType,
            newValue,
          ).then((): Promise<void> =>
            this.publishAsync(fillingMaterialChanged({
              component,
              old: isMaterial(oldValue) ? oldValue : undefined,
              new: newValue
            })).then((): void => {
            })
          )
        } else {
          materialChange.cancel()
        }
      })
  }

  onOptionChange(): void {
    void this.getDeckschichtStaerken()
      .then((): void => {
        void this.render()
      })
  }

  @HostListener('window:resize')
  onResize(): void {
    this.logger.trace('onResize')
    this.screenWidth = window.innerWidth
    this.screenHeight = window.innerHeight
    this.isMobile = this.screenWidth <= 767
    if (this.configuratedDoor?.Hausfronten) {
      this.updateDoorPosition()
    }
  }

  async onSelectModel(
    model_id: string | number,
    compTypeParam: ConstructionComponentType | FbsModelTyp,
    material?: Material,
  ): Promise<void> {
    try {
      let componentType: ConstructionComponentType
      if (!compTypeParam) {
        componentType = undefined
      } else if (!isConstructionComponentType(compTypeParam)) {
        switch (compTypeParam) {
          case FbsModelTyp.tuer:
            componentType = ConstructionComponentType.Door
            break
        }
      } else {
        componentType = compTypeParam
      }
      material ??= this.configuratorConfigurationModel.material
      this.loading = true
      await this.loadService.setComponentModelById(String(model_id), componentType, material, this.selectedComponent)
      await this.render()
      if (this.configuratorMode === ConfiguratorMode.FBS) {
        this.showModelHints()
      }
    } catch (err) {
      this.logger.error(err)
    }
  }

  onZmassChange(): void {
    if (this.selectedComponent.Zmass < this.configuratedDoor.MinZMass) {
      this.selectedComponent.Zmass = this.configuratedDoor.MinZMass
      if (this.configuratorMode === ConfiguratorMode.FBS) {
        this.toastrService.warning(
          this._translate.translate(TRANSLATION_KEY.TOAST_WARNING_ZVALUE_TOO_SMALL_MESSAGE_PREFIX)
          + this.configuratedDoor.MinZMass
          + this._translate.translate(TRANSLATION_KEY.TOAST_WARNING_ZVALUE_TOO_SMALL_MESSAGE_SUFFIX),
          this._translate.translate(TRANSLATION_KEY.TOAST_WARNING_ZVALUE_TOO_SMALL_TITLE)
        )
      }
    }
    if (this.selectedComponent.Zmass > this.configuratedDoor.MaxZMass) {
      this.selectedComponent.Zmass = this.configuratedDoor.MaxZMass
      if (this.configuratorMode === ConfiguratorMode.FBS) {
        this.toastrService.warning(
          this._translate.translate(TRANSLATION_KEY.TOAST_WARNING_ZVALUE_TOO_LARGE_MESSAGE_PREFIX)
          + this.configuratedDoor.MaxZMass
          + this._translate.translate(TRANSLATION_KEY.TOAST_WARNING_ZVALUE_TOO_LARGE_MESSAGE_SUFFIX),
          this._translate.translate(TRANSLATION_KEY.TOAST_WARNING_ZVALUE_TOO_LARGE_TITLE)
        )
      }
    }
    this.updateStaerkeValues()
    // TODO: Do not set glasaufbau at all, if it is empty
    if (
      this.selectedComponent.glasaufbau
      && this.selectedComponent.glasaufbau.Id !== 0
    ) {
      this.selectedComponent.glasaufbau.calcGlasStaerke()
    }
  }

  postMultivision(): void {
    this.loading = true
    this.httpService.postMultivision(this.parameterService.parameter.tuerId, this.viewService.currentSide).subscribe({
      next: (): void => {
        this.loading = false
      },
      error: (error): void => {
        this.logger.error(error)
        this.loading = false
      }
    })
  }

  recalcMobileHeight(): string {
    const contentWrapper = document.getElementById('image_background')
    const sn = document.querySelector('.subnav')
    if (contentWrapper && sn) {
      const snh = this.screenHeight - contentWrapper.offsetHeight - 57 + 'px'
      if (sn instanceof HTMLElement) {
        sn.style.height = snh
      }
      return snh
    }
    return null
  }

  render(): Promise<void> {
    let elem: ConfiguratedDoorDto
    try {
      elem = this.generateElement()
    } catch (err) {
      this.logger.error(err)
      return Promise.reject(err)
    }
    this.loading = true
    const display: ConfiguratorComponent['doorDisplay'] = {
      Outside: {
        houseFrontImageUrl: undefined,
        doorImageUrl: undefined
      },
      Inside: {
        houseFrontImageUrl: undefined,
        doorImageUrl: undefined
      }
    }
    const loadDoorImages: Promise<void[]> = new Promise<void>((resolve, reject): void => {
      this.httpService.postImagesV2(elem).subscribe({
        next: (result): void => {
          display.Inside.doorImageUrl = result.Images?.Innen ?? ''
          display.Outside.doorImageUrl = result.Images?.Aussen ?? ''
          if (result.TuerId !== '') {
            this.parameterService.setTuerId(result.TuerId)
          }
          resolve()
        }, error: (error): void => {
          reject(error)
        }, complete: (): void => {
          resolve()
        }
      })
    }).then((): Promise<void[]> =>
      Promise.all([SideType.Outside, SideType.Inside]
        .map((side): string => display[side]?.doorImageUrl)
        .filter((url): boolean => !!url)
        .map((url): Promise<void> => new Promise<void>((resolve, reject): void => {
          const image = new Image()
          image.onload = (): void => resolve()
          image.onerror = (): void => reject()
          image.src = url
        }))
      )
    )
    display[SideType.Outside].houseFrontImageUrl = this.configuratedDoor.Hausfronten[SideType.Outside]?.Url
    display[SideType.Inside].houseFrontImageUrl = this.configuratedDoor.Hausfronten[SideType.Inside]?.Url
    const loadHouseFrontImages = Promise.all([SideType.Outside, SideType.Inside]
      .map((side): string => display[side]?.houseFrontImageUrl)
      .filter((url): boolean => !!url)
      .map((url): Promise<void> => new Promise<void>((resolve, reject): void => {
        const image = new Image()
        image.onload = (): void => resolve()
        image.onerror = (): void => reject()
        image.src = url
      }))
    )
    return Promise.all([loadDoorImages, loadHouseFrontImages]).then((): Promise<void> => new Promise((resolve): void => {
      this.doorDisplay = {
        Outside: display.Outside,
        Inside: display.Inside
      }
      this.configuratedDoor.Images = {
        Inside: display.Inside.doorImageUrl ?? '',
        Outside: display.Outside.doorImageUrl ?? ''
      }
      void setTimeout((): void => {
        this.updateDoorPosition()
        resolve()
      })
    })).then((): void => {
      this.loading = false
    }).catch((error): Promise<never> => {
      this.toastrService.error(
        this._translate.translate(TRANSLATION_KEY.TOAST_ERROR_RENDER_MESSAGE),
        this._translate.translate(TRANSLATION_KEY.TOAST_ERROR_RENDER_TITLE),
      )
      this.logger.error(error)
      return Promise.reject(error)
    })
  }

  async resetConfiguratedDoor(material?: Material): Promise<void> {
    this.doorPosition = null
    this.doorDisplay = {
      Outside: {
        houseFrontImageUrl: undefined,
        doorImageUrl: undefined
      },
      Inside: {
        houseFrontImageUrl: undefined,
        doorImageUrl: undefined
      }
    }
    await this.loadService.resetConfiguratedDoor(material)
    this.loading = false
  }

  selectFirstDoor(): void {
    if (this._componentSelection.selectedComponent._objectType !== ConstructionComponentType.Door) {
      this._componentSelection.selectedComponent = this.configuratedDoor.Components
        .filter((c: ConstructionComponent): boolean => c._objectType === ConstructionComponentType.Door)?.[0]?.Index
    }
  }

  setShowNextToggle(showNext: string): void {
    this.logger.trace('setShowNextToggle')
    if (this.showNextToggle === showNext) {
      this.showNextToggle = ''
    } else {
      this.showNextToggle = showNext
    }
    if (showNext === 'calculateFbs') {
      // this.showNextToggle = showNext
      this.modalService.showKalkulationModal()
    }
  }

  shareDialog(): void {
    const myNavigator: Navigator = window.navigator
    const data = {
      url: window.location.href,
      text:
        'Traumtür erstellt mit dem Rodenberg Traumtürkonfigurator (Referenzcode: ' +
        this.parameter.tuerId +
        ') ', // + (imageURL ? imageURL : ''),
      title:
        'Rodenberg Traumtürkonfigurator (Referenzcode: ' +
        this.parameter.tuerId +
        ')'
    }
    const options: ShareAPIPolyfillOptions = {
      // change configurations to hide specific unnecessary icons
      copy: true,
      email: true,
      print: true,
      sms: true,
      messenger: true,
      facebook: true,
      whatsapp: true,
      twitter: true,
      telegram: true,
      skype: false,
      linkedin: false,
      language: 'de' // specify the default language
    }
    if (myNavigator && myNavigator.share) {
      myNavigator
        .share(data, options)
        .catch((err): void => {
          this.logger.error(err)
        })
    }
  }

  showModelHints(): void {
    this.configuratedDoor.Components.forEach((c: ConstructionComponent): void => {
      if (c.model?.Hinweise?.length > 0) {
        const hinweise = c.model?.Hinweise.join('<br>')
        this.toastrService.info(hinweise, 'Hinweis', {enableHtml: true})
      }
    })
  }

  subscribeToEvents(): void {
    this.subscribe(closeCharacteristicsMenuRequest, (): void => {
      this.showCharacteristics = false
    })
    this.subscribe(grundformChange, (): void => {
      this.loading = true
      void this.loadService.loadModelsAfterGrundformChange().then((): Promise<void> => this.render())
      this.selectFirstDoor()
    })
    this.subscribe(houseFrontChanged, (event): Promise<void> => event.payload.callRender ? void this.render() : null)
    this.subscribe(loadRequestDoorId, (event): Promise<void> => this.loadDoorById(event.payload))
    this.subscribe(modelSelectRequest, event => void this.onSelectModel(event.payload.Id, event.payload.Typ))
    this.subscribe(optionChanged, () => void this.onOptionChange())
    this.subscribe(renderRequest, (): Promise<void> => this.render())
    this.subscribe(securityPackageChanged, () => void this.render())
    this.subscribe(zMassChanged, () => void this.onZmassChange())
  }

  updateDoorPosition(): void {
    const doorPosition = new HausfrontDoorPosition()
    const hausfront = this.configuratorConfigurationModel?.configuratedDoor?.Hausfronten?.[this.view]
    const houseFrontImage: unknown = this.houseFrontImage?.nativeElement
    const contentWrapper: unknown = this.contentWrapper.nativeElement
    if (
      !(houseFrontImage instanceof HTMLImageElement)
      || !hausfront
      || !(contentWrapper instanceof HTMLElement)
      || houseFrontImage.naturalHeight === 0
      || houseFrontImage.naturalWidth === 0
    ) {
      return
    }
    const elementBreite = hausfront.ElementBreite
    const elementHoehe = hausfront.ElementHoehe
    const scaleFactor = houseFrontImage.offsetHeight / houseFrontImage.naturalHeight
    const minWidth = scaleFactor * houseFrontImage.naturalWidth
    const shiftLeft = (minWidth - houseFrontImage.offsetWidth) * 0.5
    doorPosition.x0 = hausfront.X1
    doorPosition.y0 = hausfront.Y1
    doorPosition.x1 = hausfront.X2
    doorPosition.y1 = hausfront.Y2
    doorPosition.x2 = hausfront.X3
    doorPosition.y2 = hausfront.Y3
    doorPosition.x3 = hausfront.X4
    doorPosition.y3 = hausfront.Y4
    // const faktorBreite = tuerBreite / bildBreite
    doorPosition.backgroundWidth = houseFrontImage.offsetWidth
    doorPosition.backgroundHeight = houseFrontImage.offsetHeight
    doorPosition.doorHeight = scaleFactor * elementHoehe
    doorPosition.doorWidth = scaleFactor * elementBreite
    doorPosition.y0 = doorPosition.y0 * scaleFactor
    doorPosition.y1 = doorPosition.y1 * scaleFactor
    doorPosition.y2 = doorPosition.y2 * scaleFactor
    doorPosition.y3 = doorPosition.y3 * scaleFactor
    doorPosition.x0 = doorPosition.x0 * scaleFactor - shiftLeft
    doorPosition.x1 = doorPosition.x1 * scaleFactor - shiftLeft
    doorPosition.x2 = doorPosition.x2 * scaleFactor - shiftLeft
    doorPosition.x3 = doorPosition.x3 * scaleFactor - shiftLeft
    doorPosition.doorOffset = ((contentWrapper.offsetWidth || 0) - (houseFrontImage.offsetWidth || 0)) / 2
    this.doorPosition = doorPosition
  }

  updateStaerkeValues(): void {
    this._componentSelection.selectedComponent.staerke =
      this._componentSelection.selectedComponent.Zmass
      + this.configuratedDoor.DeckschichtStaerken.StaerkeAussen
      + this.configuratedDoor.DeckschichtStaerken.StaerkeInnen
  }

  get configuratedDoor(): ConfiguratedDoor {
    return this.configuratorConfigurationModel.configuratedDoor
  }

  get configuratorDataModel(): ConfiguratorDataModel {
    return this._configuratorDataModel
  }

  get configuratorMode(): ConfiguratorMode {
    return this._configuratorModeService.mode
  }

  get modell(): ParameterModelConfiguration {
    return this.parameterService.model || {} as ParameterModelConfiguration
  }

  get parameter(): Parameter {
    return this.parameterService.parameter
  }

  get selectedComponent(): ConstructionComponent {
    return this._componentSelection.selectedComponent
  }

  get settings(): Settings {
    return this.settingsService.settings
  }

  get view(): SideType {
    return this.viewService.currentSide
  }

  set view(side: SideType) {
    this.viewService.view = side
  }

}

declare global {
  interface Window {
    ttc: ConfiguratorComponent
  }
}
