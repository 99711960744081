export class Settings {
  AnfrageHaendler: boolean
  AnfrageHaendlerPflicht: boolean
  AnfrageKatalog: boolean
  BruttoPreise = true
  DatenschutzUrl: string
  DownloadBilder: boolean
  EinfacheDekorbezeichnung: boolean
  Fachpartner: boolean
  FachpartnerPflicht: boolean
  // Hex encoded RGB color value without leading hash
  Farbe: string
  Grundformen: string
  Id: number
  ImpressumUrl: string
  IsAnfragenMoeglich: boolean
  IsTelefonPflicht: boolean
  Login: boolean
  Logo: string
  MehrpreiseAnzeigen: boolean
  Menue: Array<(
    'BASIS' | 'FARBEN' | 'FERTIG' | 'GLAESER' | 'GRUNDFORM' | 'HAUSFRONT' | 'KATALOG'
    | 'LADEN' | 'MASSE' | 'MEHRPREISE' | 'MODELL' | 'OPTIONEN'
  )>
  Multivision: boolean
  Name: string
  NettoPreise = true
  Oberlichter: boolean
  OeffnePdfDatei: boolean
  Preise: boolean
  Sprache: string
  StandardModellId: number
  Subdomain: string
  SucheMoeglich: boolean
  Token: string
  TuerIdOeffnen: boolean
  UebergeordneteSucheDekore: boolean
  UebergeordneteSucheFarben: boolean
  UebergeordneteSucheGlaeser: boolean
  UebergeordneteSucheModelle: boolean
  UebergeordneteSucheZubehoer: boolean

  constructor(data?: Partial<Settings>) {
    this.update(data)
  }

  update(data?: Partial<Settings>): void {
    this.AnfrageHaendler = data?.AnfrageHaendler
    this.AnfrageHaendlerPflicht = data?.AnfrageHaendlerPflicht
    this.AnfrageKatalog = data?.AnfrageKatalog
    this.BruttoPreise = data?.BruttoPreise
    this.DatenschutzUrl = data?.DatenschutzUrl
    this.DownloadBilder = data?.DownloadBilder
    this.EinfacheDekorbezeichnung = data?.EinfacheDekorbezeichnung
    this.Fachpartner = data?.Fachpartner
    this.FachpartnerPflicht = data?.FachpartnerPflicht
    this.Farbe = data?.Farbe ?? this.Farbe
    this.Grundformen = data?.Grundformen
    this.Id = data?.Id
    this.ImpressumUrl = data?.ImpressumUrl
    this.IsAnfragenMoeglich = data?.IsAnfragenMoeglich
    this.IsTelefonPflicht = data?.IsTelefonPflicht
    this.Login = data?.Login
    this.Logo = data?.Logo ?? this.Logo ?? 'rodenberg'
    this.MehrpreiseAnzeigen = data?.MehrpreiseAnzeigen
    this.Menue = Array.isArray(data?.Menue) ? data.Menue : this.Menue ?? []
    this.Multivision = data?.Multivision
    this.Name = data?.Name
    this.NettoPreise = data?.NettoPreise
    this.Oberlichter = data?.Oberlichter
    this.OeffnePdfDatei = data?.OeffnePdfDatei
    this.Preise = data?.Preise
    this.Sprache = data?.Sprache ? data.Sprache : this.Sprache
    this.StandardModellId = data?.StandardModellId || 0
    this.Subdomain = data?.Subdomain
    this.SucheMoeglich = data?.SucheMoeglich
    this.Token = data?.Token
    this.TuerIdOeffnen = data?.TuerIdOeffnen
    this.UebergeordneteSucheDekore = data?.UebergeordneteSucheDekore
    this.UebergeordneteSucheFarben = data?.UebergeordneteSucheFarben
    this.UebergeordneteSucheGlaeser = data?.UebergeordneteSucheGlaeser
    this.UebergeordneteSucheModelle = data?.UebergeordneteSucheModelle
    this.UebergeordneteSucheZubehoer = data?.UebergeordneteSucheZubehoer
  }
}
