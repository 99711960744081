import {NgModule} from '@angular/core'
import {LocalStorageServiceFactory} from './local-storage.service'

@NgModule({
  providers: [
    LocalStorageServiceFactory
  ]
})
export class StorageModule {
}
