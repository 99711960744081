import {ZUBEHOER_CATEGORY, ZubehoerCategoryIdentifier, ZubehoerCategoryName} from './zubehoer-category'

export class Zubehoer {
  AnzahlBohrungen: number
  Beschreibung: string
  Bezeichnung: string
  Bohrabstand1: number
  Bohrabstand2: number
  Bohrabstand3: number
  Breite: number
  Hoehe: number
  Id: number
  ImageUrl: string
  IsAluMoeglich: boolean
  IsAussenMoeglich: boolean
  IsDigital: boolean
  IsDinLinksMoeglich: boolean
  IsDinRechtsMoeglich: boolean
  IsGlasMoeglich: boolean
  IsInnenMoeglich: boolean
  IsKunststoffMoeglich: boolean
  IsMontageMoeglich: boolean
  IsMontageStandard: boolean
  IsOptional: boolean
  IsStandard: boolean
  // Massblatt: any
  PreviewImageUrl: string
  Sort: number
  StaerkeBis: number
  StaerkeVon: number
  Tags: string[]
  Typ: string
  Value: string
  disabilityReasons: string[] = []
  loaded: boolean

  constructor(data?: Partial<Zubehoer>) {
    this.AnzahlBohrungen = data && data.AnzahlBohrungen
    this.Beschreibung = data && data.Beschreibung
    this.Bezeichnung = data && data.Bezeichnung
    this.Bohrabstand1 = data && data.Bohrabstand1
    this.Bohrabstand2 = data && data.Bohrabstand2
    this.Bohrabstand3 = data && data.Bohrabstand3
    this.Breite = data && data.Breite
    this.Hoehe = data && data.Hoehe
    this.Id = data && data.Id
    this.Sort = data && data.Sort
    this.ImageUrl = data && data.ImageUrl
    this.IsDigital = data && data.IsDigital
    this.PreviewImageUrl = data && data.PreviewImageUrl
    this.StaerkeBis = data && data.StaerkeBis
    this.StaerkeVon = data && data.StaerkeVon
    this.Typ = data && data.Typ
    // this.Massblatt = data && data.Massblatt
    this.loaded = false
    this.IsAluMoeglich = data && data.IsAluMoeglich
    this.IsAussenMoeglich = data && data.IsAussenMoeglich
    this.IsDinLinksMoeglich = data && data.IsDinLinksMoeglich
    this.IsDinRechtsMoeglich = data && data.IsDinRechtsMoeglich
    this.IsGlasMoeglich = data && data.IsGlasMoeglich
    this.IsInnenMoeglich = data && data.IsInnenMoeglich
    this.IsKunststoffMoeglich = data && data.IsKunststoffMoeglich
    this.IsMontageMoeglich = data && data.IsMontageMoeglich
    this.IsMontageStandard = data && data.IsMontageStandard
    this.IsStandard = data && data.IsStandard
    this.IsOptional = data && data.IsOptional
    this.Value = data && data.Value
    this.Tags = data && data.Tags ? data.Tags : []
  }

  public static getCategoryName(typ: ZubehoerCategoryIdentifier): ZubehoerCategoryName {
    return {
      [ZUBEHOER_CATEGORY.MAIL_SLOT]: 'Briefdurchwürfe',
      [ZUBEHOER_CATEGORY.LEVER_HANDLE_SET]: 'Drückergarnituren',
      [ZUBEHOER_CATEGORY.FINGERPRINT]: 'Fingerprint',
      [ZUBEHOER_CATEGORY.HANDLE_SHELL]: 'Griffschalen',
      [ZUBEHOER_CATEGORY.INDOOR_DESIGN]: 'Indoor Designs',
      [ZUBEHOER_CATEGORY.ROSETTE]: 'Rosetten',
      [ZUBEHOER_CATEGORY.SKIRTING_BOARD]: 'Sockelleisten',
      [ZUBEHOER_CATEGORY.SPYHOLE]: 'Türspione',
      [ZUBEHOER_CATEGORY.BAR_HANDLE]: 'Stangengriffe',
      [ZUBEHOER_CATEGORY.ANIMAL_FLAP]: 'Tierklappen',
      [ZUBEHOER_CATEGORY.DOOR_KNOCKER]: 'Türklopfer',
      [ZUBEHOER_CATEGORY.GENERAL]: 'Allgemein',
    }[typ] ?? typ
  }
}
