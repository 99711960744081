<modal-frame
  [footer]='footer'
  [modalCloseButton]='false'
  hostClass='uploadModal'
  title='{{TRANSLATION_KEY.TITLE}}'
>
  <div class='content'>
    <p *ngIf='!uploadImagePath' class='center'>{{ TRANSLATION_KEY.INFO_DRAG_FILE_HERE | translate }}
      <br/><em>{{
          (TRANSLATION_KEY.FILESIZE_PREFIX | translate)
          + (options.maxFileSize / 1024 / 1024).toFixed(1)
          + (TRANSLATION_KEY.FILESIZE_SUFFIX | translate)
        }}</em>
      <br/>{{ TRANSLATION_KEY.INFO_OR | translate }}
    </p>
    <p *ngIf='uploadImagePath' class='center'>
      {{ TRANSLATION_KEY.INFO_DRAG_CORNERS | translate }}
    </p>
    <div class='maskWrapper'>
      <div class='mask'>
        <div class='doorMaskWrapper'>
          <div
            (uploadOutput)='onUploadOutput($event)'
            [ngClass]="{ 'file-over': dragOver, 'image-selected': uploadImagePath }"
            [options]='options'
            class='well my-drop-zone'
            ngFileDrop
          >

            <door-mask
              (changeBackground)='maskPositionOnResize($event)' (pointChanged)='onChangePoints($event)'
              *ngIf='uploadedHTMLImage'
              [heightBackground]='uploadPosition.backgroundHeight'
              [heightDoor]='doorHeight'
              [urlBackground]='uploadImagePath'
              [widthBackground]='uploadPosition.backgroundWidth'
              [widthDoor]='doorWidth'
              [x0]='uploadPosition.x0'
              [x1]='uploadPosition.x1'
              [x2]='uploadPosition.x2' [x3]='uploadPosition.x3'
              [y0]='uploadPosition.y0'
              [y1]='uploadPosition.y1'
              [y2]='uploadPosition.y2'
              [y3]='uploadPosition.y3'
              class='upload'
            >
            </door-mask>

            <img
              #uploadedImageRef (change)='onChangeUploadedImage($event)' *ngIf='uploadImagePath'
              [src]='uploadImagePath' class='media-object img-preview'
              id='uploadedImage'
            />

            <div class='zoomWindow'>
              <img *ngIf='uploadImagePath' [src]='uploadImagePath' class='media-object zoomImage'
                   id='uploadedZoomImage'/>
              <span id='circle'></span>
            </div>

            <input
              #selectOwnImage (uploadOutput)='onUploadOutput($event)' [options]='options'
              ngFileSelect
              style='display: none;'
              type='file'
            />

            <div *ngIf='!uploadImagePath' class='uploader-box'>
              <div [ngClass]="{'hidden': uploaderError === null}" class='error-box'>
                <p
                  *ngIf="uploaderError === 'landscape'"
                  class='error-message'
                >{{ TRANSLATION_KEY.ERROR_USE_LANDSCAPE | translate }}</p>
              </div>
              <div class='upload-cover'>
                <i class='icon-facade'></i>
                <button (click)='selectOwnImage.click();' tabindex='-1'>
                  {{ TRANSLATION_KEY.BUTTON_CHOOSE_IMAGE_LABEL | translate }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</modal-frame>
<ng-template #footer>
  <button
    (click)='saveHausfrontChanges(customHausfront, this.doorWidth, this.doorHeight)' *ngIf='uploadImagePath'
    color='primary'
    mat-flat-button
  >
    <i *ngIf="uploadStatus === 'loading'" class='fa fa-spinner fa-pulse fa-fw'></i>
    {{ TRANSLATION_KEY.BUTTON_SAVE_LABEL | translate }}
  </button>
</ng-template>
