import {Inject, Injectable, INJECTOR, Injector} from '@angular/core'
import {FbsGlas, Profile} from '../../class'
import {Subscribable} from 'rxjs'


import {GlassProvider} from './dataProvider/glass/glass.type'
import {GlassService} from './dataProvider/glass/glass.service'
import {FbsModelService} from './dataProvider/fbsModel/fbsModel.service'
import {FbsModelProvider} from './dataProvider/fbsModel/fbsModel.type'
import {Oberlicht} from './component/construction/oberlicht'
import {Seitenteil} from './component/construction/seitenteil'
import {Tuer} from './component/construction/tuer'
import {ComponentModel} from './component/model/component-model'
import {ParameterModelConfiguration} from './modelConfiguration/parameterModelConfiguration'
import {ProfileService} from '../service/profile.service'
import {Material} from './material'
import {minMax, Oeffnungsart} from '../../types'
import {ConstructionComponentType} from './component/construction/construction-component-type.enum'
import {CompactComponentModel} from '../api/model/compact-component-model.interface'
import {ComponentSelectionService} from '../service/componentSelectionService'
import {
  LoadRequestIdentifier
} from '../../configurator/components/load-configuration-button/load-configuration-button.component'
import {TagService} from '../service/tag.service'

@Injectable()
export class ConfiguratorDataModel implements FbsModelProvider, GlassProvider {
  private fbsModelService: FbsModelService
  private initialized = false

  constructor(
    private componentSelectionService: ComponentSelectionService,
    private glassService: GlassService,
    private profileService: ProfileService,
    private tagService: TagService,
    @Inject(INJECTOR) private injector: Injector,
  ) {
    setTimeout(() => void this.initialize())
  }

  calcMinMaxValuesFBS(element: Tuer | Seitenteil | Oberlicht, model: CompactComponentModel, construction: number): minMax {
    this.initialize()
    return this.fbsModelService.calcMinMaxValuesFBS(element, model, construction)
  }

  filterModels(
    elementToFit: Tuer | Seitenteil | Oberlicht,
    construction: number | number[],
    modelSearchTerm?: string,
    selectedCatalogueId?: number,
    models?: (CompactComponentModel & { disabilityReasons?: string[] })[]
  ): (CompactComponentModel & { disabilityReasons?: string[] })[] {
    this.initialize()
    return this.fbsModelService.filterModels(elementToFit, construction, modelSearchTerm, selectedCatalogueId, models)
  }

  findGlass(id: number): FbsGlas | undefined {
    return this.glassService.findGlass(id)
  }

  getAllGlasses(): readonly FbsGlas[] {
    return this.glassService.getAllGlasses()
  }

  getDefaultGlass(): FbsGlas {
    return this.glassService.getDefaultGlass()
  }

  getDefaultMiddleGlass(): FbsGlas {
    return this.glassService.getDefaultMiddleGlass()
  }

  getGlassesForModel(model: ComponentModel): FbsGlas[] {
    return this.glassService.getGlassesForModel(model)
  }

  getObservableGlasses(): Subscribable<readonly FbsGlas[]> {
    return this.glassService.getObservableGlasses()
  }

  getProfiles(): readonly Profile[] {
    return this.profileService.profiles
  }

  private initialize(): void {
    if (!this.initialized) {
      this.fbsModelService = this.injector.get<FbsModelService>(FbsModelService)
      this.initialized = true
    }
  }

  loadModel(
    modelId: string,
    componentType: ConstructionComponentType,
    modelMaterial: Material,
    profil: string,
    konstruktion: number,
    parameterModel: ParameterModelConfiguration,
    oeffnungsart: Oeffnungsart,
    dinFuellung: number,
    loadRequestIdentifier?: LoadRequestIdentifier
  ): Promise<ComponentModel> {
    this.initialize()
    return this.fbsModelService.loadModel(
      modelId,
      componentType,
      modelMaterial,
      profil,
      konstruktion,
      parameterModel,
      oeffnungsart,
      dinFuellung,
      loadRequestIdentifier
    )
  }


  public preload(): Promise<void> {
    this.initialize()
    return Promise.all([
      this.fbsModelService.loadFBSModels(),
      this.glassService.loadGlasses(),
      this.tagService.loadTags()
    ]).then((): void => void 0)
      .catch((error: Error): void => console.error(error))
  }
}
