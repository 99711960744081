import {Injectable} from '@angular/core'
import {EventBus} from '@d-m-p/ts-bus'
import {BusEvent} from '@d-m-p/ts-bus/dist/types'
import {EventCreatorFn, SubscriptionDef} from '@d-m-p/ts-bus/src/types'
import {PredicateFn} from '@d-m-p/ts-bus/src/event-bus'
import {OverloadedParameters} from '../../../types'

@Injectable()
export class EventBusService extends EventBus {
  waitFor<T extends BusEvent>(
    subscription: EventCreatorFn<T> | PredicateFn<T>,
  ): Promise<T>

  waitFor<T extends { type: string }>(
    subscription: T['type'],
  ): Promise<T>

  waitFor<T extends BusEvent>(
    subscription: SubscriptionDef<T>,
  ): Promise<T> {
    let teardown: () => void = null
    let executed = false
    let resolve: (value: T) => void
    const awaitable = new Promise<T>((r): void => {
      resolve = r
    })
    teardown = this.subscribe<T>(
      subscription satisfies OverloadedParameters<typeof this.subscribe<T>>[0] as Parameters<typeof this.subscribe>[0],
      (event): void => {
        teardown?.call(undefined)
        executed = true
        resolve(event)
      }
    )
    if (executed) {
      teardown()
    }
    return awaitable
  }
}
