import {MassblattScript} from './massblatt-script'
import {ModelDienstleistungMassblatt} from '../../../api/model/addons/model-dienstleistung-massblatt.interface'

export class LetterMapping {
  [key: string]: number

  constructor(data?: LetterMapping) {
    for (const letter in data) {
      if (data.hasOwnProperty(letter)) {
        this[letter] = Number(parseFloat(data[letter].toString().replace(',', '.')))
      }
    }
  }
}

export class Massblatt {
  Beschreibung: string
  Bezeichnung: string
  Filter: string
  Id: number
  ImageUrl: string
  ImageUrl2: string
  ImageUrl3: string
  Parameter: string
  PreviewImageUrl: string
  Render: boolean
  ScriptId: number
  readonly Scripts: MassblattScript[]
  Staerke: number
  Typ: string
  Values: LetterMapping

  constructor(data?: Partial<Massblatt | ModelDienstleistungMassblatt>) {
    this.Beschreibung = data && data.Beschreibung
    this.Bezeichnung = data && data.Bezeichnung
    this.Filter = data && data.Filter
    this.Id = data && data.Id
    this.ImageUrl = data && data.ImageUrl
    this.ImageUrl2 = data && data.ImageUrl2
    this.ImageUrl3 = data && data.ImageUrl3
    this.Parameter = data && data.Parameter
    this.PreviewImageUrl = data && data.PreviewImageUrl
    this.Render = data && data.Render
    this.Staerke = data && 'Staerke' in data ? data.Staerke : undefined
    this.Typ = data && data.Typ
    this.Values = data && 'Values' in data && data.Values ? new LetterMapping(data.Values) : new LetterMapping()
    this.ScriptId = data && 'ScriptId' in data ? data.ScriptId : -1
    this.Scripts = data && 'Scripts' in data ? data.Scripts : []
    if (this.Scripts.length > 0 && this.ScriptId < 0) {
      this.ScriptId = this.Scripts[0].Id
    } else if (!this.Scripts) {
      this.ScriptId = -1
    }
    this.sanitizeValues()
  }

  isActiveValue(value: string): boolean {
    return this.parameters.includes(value) && !this.filters.includes(value)
  }

  private sanitizeValues(): void {
    this.Values ??= {}
    this.parameters.filter((parameter): boolean => !this.isActiveValue(parameter) || !(parameter in this.Values))
      .forEach(parameter => void (this.Values[parameter] = 0))
  }

  /**
   * Updates the values of this dimension sheet
   * @param values the values to change
   * @returns whether any value did change
   */
  updateValues(values: this['Values']): boolean {
    let changed = false
    for (const key in this.Values) {
      if (key in values) {
        const newValue = Number(values[key])
        changed ||= this.Values[key] !== newValue
        this.Values[key] = Number(values[key])
      }
    }
    return changed
  }

  get filters(): string[] {
    return this?.Filter?.split(';') || []
  }

  get parameters(): string[] {
    return this?.Parameter?.split(';') || []
  }
}
