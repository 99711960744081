import {Catalog} from '../../class'
import {HttpService} from '../../http.service'
import {Injectable} from '@angular/core'
import {NGXLogger} from 'ngx-logger'
import {ResponseKatalog} from '../api/kataloge/response-katalog.interface'

@Injectable()
export class CatalogueService {
  #catalogues: Catalog[] = []

  constructor(
    private httpService: HttpService,
    private _logger: NGXLogger,
  ) {
  }

  load(): Promise<void> {
    return new Promise<void>((resolve, reject): void => {
      this.httpService.getAllCatalogs().subscribe({
        next: (data): void => {
          data.forEach((item: ResponseKatalog): void => void this.#catalogues.push(new Catalog(item)))
          resolve()
        },
        error: (error): void => {
          this._logger.error(error)
          reject()
        }
      }
      )
    })
  }

  get catalogues(): Catalog[] {
    return this.#catalogues
  }
}
