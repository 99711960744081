import {NgModule} from '@angular/core'
import {DeviceService} from './device.service'


@NgModule({
  providers: [
    DeviceService
  ]
})
export class DeviceModule {

}
