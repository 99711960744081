import {Injectable} from '@angular/core'
import {EventBusService} from '../event/event-bus.service'
import {EventBusSupport} from '../event/event-bus-support'
import {Catalog} from '../../../class'
import {CatalogueService} from '../catalogueService'
import {configuratedDoorInitialized, selectedCatalogueChange} from '../event/events'
import {ComponentSelectionService} from '../componentSelectionService'

@Injectable()
export class ModelSearchService extends EventBusSupport {
  private _modelSearchTerm: string
  private _selectedCatalogueId: number | null

  constructor(
    catalogueService: CatalogueService,
    eventBusService: EventBusService,
    componentSelectionService: ComponentSelectionService
  ) {
    super(eventBusService)
    this._modelSearchTerm = ''
    this._selectedCatalogueId = null

    this.subscribe(configuratedDoorInitialized, (): void => {
      const model = componentSelectionService.selectedComponent.model
      const catalogue = catalogueService.catalogues.find((c: Catalog): boolean => c.KatalogId === model.KatalogId)
      if (catalogue) {
        this.selectedCatalogueId = catalogue.KatalogId
      } else if (this._selectedCatalogueId !== null) {
        this.selectedCatalogueId = null
      }
    })
  }

  get modelSearchTerm(): string {
    return this._modelSearchTerm
  }

  set modelSearchTerm(value: string) {
    this._modelSearchTerm = value
  }

  get selectedCatalogueId(): number | null {
    return this._selectedCatalogueId
  }

  set selectedCatalogueId(value: number | null) {
    const old = this._selectedCatalogueId
    this._selectedCatalogueId = value
    if (old !== this._selectedCatalogueId) {
      this.publish(selectedCatalogueChange({old, new: this._selectedCatalogueId}))
    }
  }
}
