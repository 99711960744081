<configurator-base-menu
  [menuHead]="head"
  [menuNavigatorIdentifier]='NavigationMenuEntryKey.Model'
  has-component-selector
>
  <div class="container">
    <div class="container-inner modelMenu ">
      <div class="inputArea">
        <ng-template [ngTemplateOutlet]="modelSearchForm"></ng-template>
      </div>
      <div class="contentArea virtualscroll">
        <ng-template [ngTemplateOutletContext]="{
                                 selectedModelId: this.selectedComponent?.model?.Id || null,
                                 selectedElement: this.selectedComponent
                             }"
                     [ngTemplateOutlet]="modelList"
        >
        </ng-template>
      </div>


    </div>
  </div>
</configurator-base-menu>
<ng-template #modelSearchForm>
  <mat-form-field>
    <mat-label>{{ TRANSLATION_KEY.DROPDOWN_KATALOG_PLACEHOLDER | translate }}</mat-label>
    <mat-select [(ngModel)]="this.selectedCatalogue">
      <mat-option [value]="-1">
        {{ TRANSLATION_KEY.DROPDOWN_KATALOG_ALL_OPTION | translate }}
      </mat-option>
      <mat-option *ngFor="let catalog of this.catalogs" [value]="catalog.KatalogId">
        {{ catalog.Bezeichnung | translate }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field appearance="fill">
    <mat-label>{{ TRANSLATION_KEY.INPUT_MODEL_SEARCH_PLACEHOLDER | translate }}</mat-label>
    <input #modelSearchInput [(ngModel)]="modelTerm" matInput type="text">
    <button
      (click)="modelTerm = ''"
      [attr.aria-label]="TRANSLATION_KEY.INPUT_MODEL_SEARCH_CLEAR | translate"
      [title]="TRANSLATION_KEY.INPUT_MODEL_SEARCH_CLEAR | translate"
      mat-icon-button
      matSuffix
    >
      <i (click)="modelTerm = ''" class="fa fa-times"></i>
    </button>
  </mat-form-field>
</ng-template>
<ng-template #modelList let-selectedElement="selectedElement" let-selectedModelId="selectedModelId">
  <cdk-virtual-scroll-viewport #modelViewPort [itemSize]="isMobile ? 300 : 360" class="viewport">
    <ng-container *cdkVirtualFor="let row of filteredModels; templateCacheSize: 3000">
      <ng-container *ngFor="let model of row; let index = index">

        <article (click)="model.disabilityReasons.length === 0 ? modelSelected(model) : ''" [attr.data.index]="index"
                 [class.active]="model.Id === selectedModelId"
                 class="modell">
          <div [class.disabled]="model.disabilityReasons.length > 0"
               [mtxTooltipDisabled]="model.disabilityReasons.length === 0"
               [mtxTooltip]="StringUtil.concatStringArray(model.disabilityReasons)" class="image-wrapper">
            <img *loadingAnimation="model.PreviewImageUrl, sizing: 'fill', background: true"
                 [attr.data.id]="model.Id" [class.flipped]="selectedElement.dinfuellung === 1">
          </div>
          <p>{{ model.Bezeichnung | translate }} {{ model.Katalog  | translate }}</p>
        </article>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="filteredModels.length === 0">
      <article [class.active]="false"
               class="modell noResults">
        <div [class.disabled]="false"
             [mtxTooltipDisabled]="true"
             [mtxTooltip]="">
        </div>
        <span>{{ this.noModelsFoundText() }}</span>
        <button (click)="this.selectedCatalogue = -1" *ngIf="this.selectedCatalogue > -1" mat-flat-button>
          {{ TRANSLATION_KEY.BUTTON_KATALOG_SEARCH_ALL | translate }}
        </button>
      </article>
    </ng-container>
  </cdk-virtual-scroll-viewport>
</ng-template>

<ng-template #head>
  <div class="name">
    <span>{{ TRANSLATION_KEY.MODEL | translate }}</span>
    <span (click)="closeMenu()" class="close"></span>
  </div>
</ng-template>
