import {Directive, DoCheck, Host, Input, TemplateRef, ViewContainerRef,} from '@angular/core'

/* eslint "@angular-eslint/directive-selector": ["error",{"type": "attribute","style": "camelCase"}]
  --- Do not prefix selectors for utility directives with 'app' */

@Directive({
  selector: '[switchOnTypeOf]',
})
export class SwitchOnTypeOfDirective implements DoCheck {
  private readonly cases: [CaseForTypeDirective<unknown>, SwitchViewController][]
  private readonly defaultViews: SwitchViewController[]
  private dirty: boolean
  private instanceToCheck: unknown

  constructor() {
    this.defaultViews = []
    this.cases = []
    this.dirty = false
  }

  /** @internal */
  public _addCase<T>(caseDirective: CaseForTypeDirective<T>, viewController: SwitchViewController<T>): void {
    this._markDirty()
    this.cases.push([caseDirective, viewController])
  }

  /** @internal */
  public _addDefault(view: SwitchViewController): void {
    this._markDirty()
    this.defaultViews.push(view)
  }

  /** @internal */
  public _markDirty(): void {
    this.dirty = true
  }

  public ngDoCheck(): void {
    if (this.dirty) {
      let matched = false
      for (const [type, viewController] of this.cases) {
        if (typeof type.caseForTyp !== 'undefined' && this.instanceToCheck instanceof type.caseForTyp) {
          matched = true
          viewController.setState(this.instanceToCheck)
        }
      }
      this.defaultViews?.forEach(view => void view.setState(!matched))
      this.dirty = false
    }
  }

  @Input()
  public set switchOnTypeOf(newValue: unknown) {
    if (this.instanceToCheck !== newValue) {
      this._markDirty()
      this.instanceToCheck = newValue
    }
  }
}

@Directive({
  selector: '[caseForTyp]',
})
export class CaseForTypeDirective<T> {
  private type: Class<T>
  private readonly viewController: SwitchViewController<T>

  public constructor(
    viewContainer: ViewContainerRef,
    templateRef: TemplateRef<TemplateCtx<T>>,
    @Host() private switchDirective: SwitchOnTypeOfDirective
  ) {
    this.viewController = new SwitchViewController<T>(viewContainer, templateRef)
    switchDirective._addCase(this, this.viewController)
  }

  public static ngTemplateContextGuard<T>(
    dir: CaseForTypeDirective<T>,
    ctx: unknown
  ): ctx is TemplateCtx<Exclude<T, false | 0 | '' | null | undefined>> {
    return true
  }

  @Input()
  get caseForTyp(): Class<T> | undefined {
    return this.type
  }

  set caseForTyp(type: Class<T>) {
    if (this.type !== type) {
      this.type = type
      this.switchDirective._markDirty()
    }
  }
}

@Directive({
  selector: '[caseForTypUnknown]',
})
export class CaseForTypeUnknownDirective {
  public constructor(
    viewContainer: ViewContainerRef,
    templateRef: TemplateRef<TemplateCtx>,
    @Host() switchDirective: SwitchOnTypeOfDirective
  ) {
    switchDirective._addDefault(new SwitchViewController(viewContainer, templateRef))
  }
}

class SwitchViewController<T = unknown> {
  private active: boolean

  public constructor(
    private viewContainerRef: ViewContainerRef,
    private templateRef: TemplateRef<TemplateCtx<T>>
  ) {
    this.active = false
  }

  private createView(result: T): void {
    this.active = true
    this.viewContainerRef.createEmbeddedView(this.templateRef, {$implicit: result})
  }

  private destroyView(): void {
    this.active = false
    this.viewContainerRef.clear()
  }

  public setState(result: T | undefined): void {
    if (result && !this.active) {
      this.createView(result)
    } else if (!result && this.active) {
      this.destroyView()
    }
  }
}

type Class<T = unknown> = new (...args: unknown[]) => T

type TemplateCtx<T = unknown> = {
  $implicit: T
}
