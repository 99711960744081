import {AfterViewChecked, Component, ElementRef, Input, ViewChild} from '@angular/core'
import {StringUtil} from '../../../classes/util/stringUtil'
import {CdkVirtualScrollViewport} from '@angular/cdk/scrolling'
import {ConfiguratorModeService} from '../../../classes/service/configuratorMode.service'
import {ConfiguratorMode, minMax} from '../../../types'
import {ComponentSelectionService} from '../../../classes/service/componentSelectionService'
import {ConstructionComponent} from '../../../classes/model/component/construction/constructionComponent'
import ArrayUtil from '../../../classes/util/arrayUtil'
import {ConfiguratorDataModel} from '../../../classes/model/configuratorDataModel'
import {ConfiguratorConfigurationModel} from '../../../classes/model/configuratorConfigurationModel'
import {CatalogueService} from '../../../classes/service/catalogueService'
import {Catalog} from '../../../class'
import {CompactComponentModel} from '../../../classes/api/model/compact-component-model.interface'
import {TranslateService} from '../../../translate'
import {ConstructionDimensionService} from '../../../classes/service/construction-dimension.service'
import {SimpleConstruction} from '../../../classes/model/component/other/construction'
import {ConstructionComponentType} from '../../../classes/model/component/construction/construction-component-type.enum'
import {NavigationMenuEntryKey} from '../../../classes/service/navigation/navigation-menu-entry'
import {NavigatorService} from '../../../classes/service/navigation/navigator.service'
import {EventBusSupport} from '../../../classes/service/event/event-bus-support'
import {EventBusService} from '../../../classes/service/event/event-bus.service'
import {activeMenuChange, modelSelectRequest, selectedComponentChanged} from '../../../classes/service/event/events'
import {ModelSearchService} from '../../../classes/service/model-search/model-search.service'

const TRANSLATION_KEY = {
  DROPDOWN_KATALOG_PLACEHOLDER: 'ModelMenu.Dropdown.Katalog.Placeholder',
  DROPDOWN_KATALOG_ALL_OPTION: 'ModelMenu.Dropdown.Katalog.Option.All',
  INPUT_MODEL_SEARCH_PLACEHOLDER: 'ModelMenu.Input.Model.Search.Placeholder',
  INPUT_MODEL_SEARCH_CLEAR: 'ModelMenu.Input.Model.Search.Clear',
  BUTTON_KATALOG_SEARCH_ALL: 'ModelMenu.Button.Katalog.SearchAll',
  MODEL: 'ModelMenu.Model',
  ERROR_NO_MODEL_FOR_PREFIX_A: 'ModelMenu.NoModel.PrefixA',
  ERROR_NO_MODEL_FOR_PREFIX_B: 'ModelMenu.NoModel.PrefixB',
  ERROR_NO_MODEL_FOR_DOOR: 'ModelMenu.NoModel.For.Door',
  ERROR_NO_MODEL_FOR_SIDEPANEL: 'ModelMenu.NoModel.For.Sidepanel',
  ERROR_NO_MODEL_FOR_FANLIGHT: 'ModelMenu.NoModel.For.Fanlight',
  HINT_CHANGE_YOUR_PARAMS: 'ModelMenu.NoModel.Hint'
} as const

@Component({
  selector: 'configurator-model-menu',
  templateUrl: './model-menu.component.html',
  styleUrls: ['./model-menu.component.scss']
})
export class ModelMenuComponent extends EventBusSupport implements AfterViewChecked {
  protected readonly ConfiguratorMode = ConfiguratorMode
  protected readonly NavigationMenuEntryKey = NavigationMenuEntryKey
  protected readonly StringUtil = StringUtil
  protected readonly TRANSLATION_KEY = TRANSLATION_KEY
  private _filteredModels: (CompactComponentModel & { disabilityReasons?: string[] })[][] = []
  @Input() isMobile: boolean
  @ViewChild('modelSearchInput') modelSearchInput: ElementRef<HTMLInputElement>
  @ViewChild('modelViewPort', {static: false}) modelViewPort: CdkVirtualScrollViewport
  @ViewChild(CdkVirtualScrollViewport) scrollViewport: CdkVirtualScrollViewport

  constructor(
    private readonly navigatorService: NavigatorService,
    private readonly modelSearchService: ModelSearchService,
    private _configuratorModeService: ConfiguratorModeService,
    private componentSelection: ComponentSelectionService,
    private configuratorDataModel: ConfiguratorDataModel,
    private configuratorConfigurationModel: ConfiguratorConfigurationModel,
    private _catalogueService: CatalogueService,
    private _translate: TranslateService,
    private constructionDimensionService: ConstructionDimensionService,
    eventBus: EventBusService
  ) {
    super(eventBus)

    this.subscribe(selectedComponentChanged, (): void => {
      this.filterModels()
      this.scrollToCurrentModel()
    })


    this.subscribe(activeMenuChange, (event): void => {
      if (event.payload === NavigationMenuEntryKey.Model) {
        this.filterModels()
        this.scrollToCurrentModel()
        if (event.meta?.focusSearchInputRequest) {
          this.modelSearchInput.nativeElement.focus()
        }
      }
    })
  }

  protected closeMenu(): void {
    this.navigatorService.closeMenu()
  }

  filterModels(): void {
    this._filteredModels =
      ArrayUtil.groupedArray(
        this.configuratorDataModel.filterModels(
          this.componentSelection.selectedComponent,
          this.findPossibleConstructions(this.selectedComponent),
          this.modelTerm,
          this.selectedCatalogue
        ),
        2
      )
  }

  findPossibleConstructions(
    selectedComponent: ConstructionComponent
  ): SimpleConstruction[] {
    return this.configuratorMode === ConfiguratorMode.TTK
      ? [SimpleConstruction.Einsatz, SimpleConstruction.AufsatzEinseitig, SimpleConstruction.AufsatzBeidseitig]
      : [selectedComponent.konstruktion]
  }

  modelSelected(model: CompactComponentModel): void {
    const component = this.selectedComponent
    if (component.model && component.model.Id === model.Id) {
      return
    }
    if (this.configuratorMode === ConfiguratorMode.FBS) {
      this.publish(modelSelectRequest(model))
      return
    }
    const constructions = this.findPossibleConstructions(component)
    const modelAllowedConstructions = constructions.filter((construct): boolean =>
      (construct === SimpleConstruction.Einsatz && model.IsEinsatzMoeglich)
      || (construct === SimpleConstruction.AufsatzEinseitig && model.IsAufsatzEinseitigMoeglich)
      || (construct === SimpleConstruction.AufsatzBeidseitig && model.IsAufsatzBeidseitigMoeglich)
    )
    const elementDimensionRequirements = constructions.reduce<{ [K: number]: minMax }>((accumulator, construct): {
      [K: number]: minMax
    } => {
      const constructionDimensions = this.constructionDimensionService.calculateConstructionDimensions(component, construct)
      accumulator[construct] =
        {
          minWidth: constructionDimensions.minFuellungsBreite,
          maxWidth: constructionDimensions.maxFuellungsBreite,
          minHeight: constructionDimensions.minFuellungsHoehe,
          maxHeight: constructionDimensions.maxFuellungsHoehe
        }
      return accumulator
    }, {})
    const dimensionAllowedConstructions = modelAllowedConstructions.filter((construct): boolean => {
      const dimensionBounds = this.configuratorDataModel.calcMinMaxValuesFBS(component, model, construct)
      const dimensionRequirements = elementDimensionRequirements[construct]
      return dimensionRequirements.minWidth >= dimensionBounds.minWidth
        && dimensionRequirements.maxWidth <= dimensionBounds.maxWidth
        && dimensionRequirements.minHeight >= dimensionBounds.minHeight
        && dimensionRequirements.maxHeight <= dimensionBounds.maxHeight
    })
    if (dimensionAllowedConstructions.length === 0) {
      return
    }
    if (dimensionAllowedConstructions.includes(component.konstruktion)) {
      this.publish(modelSelectRequest(model))
      return
    }
    component.konstruktion = dimensionAllowedConstructions[0]
    void this.configuratorConfigurationModel.updateConstruction().then((): void => {
      this.publish(modelSelectRequest(model))
    })
  }

  ngAfterViewChecked(): void {
    if (this.scrollViewport.getViewportSize() === 0) {
      this.scrollViewport.checkViewportSize()
    }
  }

  noModelsFoundText(): string {
    let noModelsText: string = ''
    const errorMessageStart = this.selectedCatalogue > -1
      ? this._translate.translate(TRANSLATION_KEY.ERROR_NO_MODEL_FOR_PREFIX_A)
      : this._translate.translate(TRANSLATION_KEY.ERROR_NO_MODEL_FOR_PREFIX_B)
    switch (this.configuratorConfigurationModel.configuratedDoor.selectedComponent.objectType) {
      case ConstructionComponentType.Door:
        // NO_MODELS_FOUND
        noModelsText = errorMessageStart + this._translate.translate(TRANSLATION_KEY.ERROR_NO_MODEL_FOR_DOOR)
        break
      case ConstructionComponentType.Fanlight:
        noModelsText = errorMessageStart + this._translate.translate(TRANSLATION_KEY.ERROR_NO_MODEL_FOR_FANLIGHT)
        break
      case ConstructionComponentType.SidePanel:
        noModelsText = errorMessageStart + this._translate.translate(TRANSLATION_KEY.ERROR_NO_MODEL_FOR_SIDEPANEL)
        break
    }
    if (this.modelTerm !== '') {
      noModelsText += '\n' + this._translate.translate(TRANSLATION_KEY.HINT_CHANGE_YOUR_PARAMS)
    }
    return this._translate.translate(noModelsText)
  }

  scrollToCurrentModel(): void {
    setTimeout((): void => {
      const element = this.componentSelection.selectedComponent
      if (element?.model?.Id) {
        const groupIndex = this.filteredModels.findIndex((group): boolean =>
          group.some((item): boolean =>
            item.Id === element.model.Id || item.Bezeichnung === element.model.Bezeichnung)
        )
        if (groupIndex !== -1) {
          const SCROLL_DISTANCE = 5
          if (groupIndex >= SCROLL_DISTANCE) {
            this.modelViewPort?.scrollToIndex(groupIndex - SCROLL_DISTANCE, 'instant')
          }
          this.modelViewPort?.scrollToIndex(groupIndex, 'smooth')
        }
      }
    }, 1000)
  }


  get catalogs(): Catalog[] {
    return this._catalogueService.catalogues
  }

  get configuratorMode(): ConfiguratorMode {
    return this._configuratorModeService.mode
  }

  get filteredModels(): typeof this._filteredModels {
    return this._filteredModels
  }

  get modelTerm(): string {
    return this.modelSearchService.modelSearchTerm
  }

  set modelTerm(value: string) {
    this.modelSearchService.modelSearchTerm = value
    this.filterModels()
  }

  get selectedCatalogue(): number {
    return this.modelSearchService.selectedCatalogueId
  }

  set selectedCatalogue(selectedCatalogue: number) {
    this.modelSearchService.selectedCatalogueId = selectedCatalogue
    this.filterModels()
  }

  get selectedComponent(): ConstructionComponent {
    return this.componentSelection.selectedComponent
  }
}
