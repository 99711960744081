import {MatDialogRef} from '@angular/material/dialog'
import {
  ConstructionChooserModalComponent
} from '../modal/construction-chooser-modal/construction-chooser-modal.component'
import {isSimpleConstruction, SimpleConstruction} from '../../classes/model/component/other/construction'
import {ConfiguratorMode} from '../../types'
import {ConfiguratorModeService} from '../../classes/service/configuratorMode.service'
import {ParameterModes} from '../../classes/service/parameter/parameter-mode'
import {Parameter} from '../../classes/service/parameter/parameter'
import {ParameterService} from '../../classes/service/parameter/parameter.service'
import {NGXLogger} from 'ngx-logger'
import {ModalService} from '../modal/modal.service'
import {Router} from '@angular/router'
import {Material} from '../../classes/model/material'
import {ConfigurationLoaderService} from './configuration-loader.service'
import {ConfiguratorConfigurationModel} from '../../classes/model/configuratorConfigurationModel'
import {ToastrService} from 'ngx-toastr'
import {TranslateService} from '../../translate'
import {Injectable} from '@angular/core'
import {environment} from '../../../environments/environment'
import {LoadingResult} from './loading-result.enum'
import {SettingsService} from '../../classes/service/settings/settings.service'
import {LoadService} from './load.service'
import {EventBusService} from '../../classes/service/event/event-bus.service'
import {configuratedDoorOpened} from '../../classes/service/event/events'

const TRANSLATION_KEY = {
  TOAST_WARNING_PARTIAL_LOAD_TITLE: 'ConfiguratorInitializer.LoadedPartially.Title',
  TOAST_WARNING_PARTIAL_LOAD_MESSAGE: 'ConfiguratorInitializer.LoadedPartially.Message',
  TOAST_ERROR_LOAD_ERROR_TITLE: 'ConfiguratorInitializer.LoadError.Title',
  TOAST_ERROR_LOAD_ERROR_MESSAGE: 'ConfiguratorInitializer.LoadError.Message'
}

@Injectable()
export class ConfiguratorInitializerService {
  constructor(
    private readonly configurationLoaderService: ConfigurationLoaderService,
    private readonly configuratorConfigurationModel: ConfiguratorConfigurationModel,
    private readonly configuratorModeService: ConfiguratorModeService,
    private readonly eventBusService: EventBusService,
    private readonly loadService: LoadService,
    private readonly logger: NGXLogger,
    private readonly modalService: ModalService,
    private readonly parameterService: ParameterService,
    private readonly router: Router,
    private readonly settingsService: SettingsService,
    private readonly toastrService: ToastrService,
    private readonly translateService: TranslateService
  ) {
  }

  private chooseConstruction(): Promise<boolean> {
    return new Promise<boolean>((resolve): void => {
      if (this.configuratorModeService.mode !== ConfiguratorMode.FBS) {
        resolve(false)
        return
      }
      if (this.isEditMode) {
        resolve(false)
        return
      }
      const model = this.parameterService.model
      if (!model || typeof model.konstruktion !== 'undefined') {
        resolve(false)
        return
      }
      let constructionChooserModal: MatDialogRef<ConstructionChooserModalComponent, SimpleConstruction>
      const openConstructionChooser = (): void => {
        constructionChooserModal = this.modalService.showConstructionChooserModal()
        constructionChooserModal.afterClosed().subscribe({
          next: (construction): void => {
            if (isSimpleConstruction(construction)) {
              this.parameterService.model.konstruktion = construction
              resolve(true)
            } else {
              openConstructionChooser()
            }
          },
          error: (error): void => {
            this.logger.error(error)
            openConstructionChooser()
          }
        })
      }
      openConstructionChooser()
    })
  }

  private chooseConstructionDimensions(): Promise<boolean> {
    return new Promise<boolean>((resolve): void => {
      if (this.configuratorModeService.mode !== ConfiguratorMode.FBS) {
        resolve(false)
        return
      }
      const model = this.parameterService.model
      // TODO: Check all coordinates for existence
      if (!model || typeof model.bxa !== 'undefined') {
        resolve(false)
        return
      }
      const openConstructionDimensionInput = (): void => {
        this.modalService.showMasseModal('input', this.parameterService.model.konstruktion).afterClosed().subscribe({
          next: (dimensions): void => {
            if (dimensions) {
              const newModel = dimensions.toParameterModel()
              this.parameterService.model.bxa = newModel.bxa
              this.parameterService.model.bya = newModel.bya
              this.parameterService.model.bxb = newModel.bxb
              this.parameterService.model.byb = newModel.byb
              this.parameterService.model.soxa = newModel.soxa
              this.parameterService.model.soya = newModel.soya
              this.parameterService.model.soxb = newModel.soxb
              this.parameterService.model.soyb = newModel.soyb
              this.parameterService.model.sixa = newModel.sixa
              this.parameterService.model.siya = newModel.siya
              this.parameterService.model.sixb = newModel.sixb
              this.parameterService.model.siyb = newModel.siyb
              resolve(true)
            } else {
              openConstructionDimensionInput()
            }
          },
          error: (error): void => {
            this.logger.error(error)
            openConstructionDimensionInput()
          }
        })
      }
      return openConstructionDimensionInput()
    })
  }

  public async construction(): Promise<void> {
    const result = await this.chooseConstruction()
    if (result) {
      await this.router.navigate(['/'], {
        queryParams: this.parameterService.queryParameter,
        onSameUrlNavigation: 'reload'
      })
    }
    const result2 = await this.chooseConstructionDimensions()
    if (result2) {
      await this.router.navigate(['/'], {
        queryParams: this.parameterService.queryParameter,
        onSameUrlNavigation: 'reload'
      })
    }
  }

  public async initialLoadDoor(): Promise<void> {
    await new Promise<void>((resolve): void => {
      if (this.isEditMode || this.configuratorConfigurationModel.material) {
        resolve()
        return
      }
      this.modalService.showMaterialChoiceModal()
        .afterClosed()
        .subscribe((result): void => {
          this.configuratorConfigurationModel.material = result ? Material.Kunststoff : Material.Alu
          resolve(this.configuratorConfigurationModel.createComponents())
        })
    })
    await this.resolveLoadedMaterialConflict()
    let modelId = this.configurationLoaderService.getLoadedDoorModelId()
    if (typeof modelId !== 'number' || modelId === 0 || Number.isNaN(modelId)) {
      if (this.configuratorModeService.mode === ConfiguratorMode.FBS) {
        modelId = this.parameterService.model?.modellid
      } else if (this.configuratorModeService.mode === ConfiguratorMode.TTK && this.settingsService.settings.StandardModellId !== 0) {
        modelId = this.settingsService.settings.StandardModellId
      } else if (this.configuratorModeService.mode === ConfiguratorMode.TTK) {
        modelId = environment.DEFAULT_DOOR_MODEL_ID
      }
    }
    if (typeof modelId !== 'number' || modelId === 0 || Number.isNaN(modelId)) {
      return
    }
    let material: Material | null = null
    if (this.configurationLoaderService.hasLoadedConfig()) {
      material = this.configurationLoaderService.getLoadedDoorFillingMaterial()
    }
    await this.loadService.setInitialDoorModel(String(modelId), material)
    if (this.configurationLoaderService.hasLoadedConfig()) {
      const result = await this.configurationLoaderService.applyLoadedConfig()
      if (result === LoadingResult.PartialLoad) {
        this.toastrService.warning(
          this.translateService.translate(TRANSLATION_KEY.TOAST_WARNING_PARTIAL_LOAD_MESSAGE
          ),
          this.translateService.translate(TRANSLATION_KEY.TOAST_WARNING_PARTIAL_LOAD_TITLE)
        )
      }
      await this.eventBusService.publishAsync(configuratedDoorOpened())
    }
  }

  public loadConfig(): Promise<void> {
    return this.configurationLoaderService.loadConfig().catch((): void => {
      this.toastrService.error(
        this.translateService.translate(TRANSLATION_KEY.TOAST_ERROR_LOAD_ERROR_MESSAGE),
        this.translateService.translate(TRANSLATION_KEY.TOAST_ERROR_LOAD_ERROR_TITLE)
      )
    })
  }

  public async resetConfiguratedDoor(material?: Material): Promise<void> {
    return this.loadService.resetConfiguratedDoor(material)
  }

  public resolveLoadedMaterialConflict(): Promise<void> {
    return this.loadService.resolveLoadedMaterialConflict()
  }

  get isEditMode(): boolean {
    const mode = this.parameterService.parameter.mode
    return mode === (ParameterModes.Open as string) || mode === (ParameterModes.Edit as string)
  }

  get parameter(): Parameter {
    return this.parameterService.parameter
  }
}
