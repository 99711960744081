export class Tag {
  IsFavorit: boolean
  Kategorie: string
  Tag: string
  Typ: string

  constructor(data?: Tag) {
    this.Kategorie = data?.Kategorie
    this.Typ = data?.Typ
    this.Tag = data?.Tag
    this.IsFavorit = data?.IsFavorit
  }
}

