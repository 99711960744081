import {Component} from '@angular/core'
import {ConstructionComponent} from '../../classes/model/component/construction/constructionComponent'
import {ComponentSelectionService} from '../../classes/service/componentSelectionService'
import * as ConstructionComponentTypeEnumModule
  from '../../classes/model/component/construction/construction-component-type.enum'
import {ConstructionComponentType} from '../../classes/model/component/construction/construction-component-type.enum'
import {ConfiguratorConfigurationModel} from '../../classes/model/configuratorConfigurationModel'
import {Oberlicht} from '../../classes/model/component/construction/oberlicht'
import {Seitenteil} from '../../classes/model/component/construction/seitenteil'
import {Tuer} from '../../classes/model/component/construction/tuer'
import {SideType} from '../../types'
import {ViewService} from '../../classes/service/view.service'

const TRANSLATION_KEY = {
  DOOR: 'ComponentSelector.Door',
  SIDEPANEL: 'ComponentSelector.Sidepanel',
  FANLIGHT: 'ComponentSelector.Fanlight'
} as const

@Component({
  selector: 'configurator-component-selector',
  templateUrl: './component-selector.component.html',
  styleUrls: ['./component-selector.component.scss']
})
export class ComponentSelectorComponent {
  protected readonly ConstructionComponentType: typeof ConstructionComponentType =
    (ConstructionComponentTypeEnumModule as {
      ConstructionComponentType: Required<typeof ConstructionComponentType>
    })?.ConstructionComponentType
  protected readonly TRANSLATION_KEY = TRANSLATION_KEY

  constructor(
    private componentSelection: ComponentSelectionService,
    private configuratorConfigurationModel: ConfiguratorConfigurationModel,
    private viewService: ViewService
  ) {
  }

  inversedOrder(): ConstructionComponent[] {
    const newOrderArrayFanlights: Oberlicht[] = []
    const newOrderArrayOther: (Tuer | Seitenteil)[] = []
    this.configuratorConfigurationModel.configuratedDoor?.Components.forEach((c: ConstructionComponent): void => {
      switch (c.objectType) {
        case ConstructionComponentType.Door:
          newOrderArrayOther.push(c)
          break
        case ConstructionComponentType.SidePanel:
          newOrderArrayOther.push(c)
          break
        case ConstructionComponentType.Fanlight:
          newOrderArrayFanlights.push(c)
          break
      }
    })
    return newOrderArrayFanlights.reverse().concat(newOrderArrayOther.reverse())
  }

  get components(): ConstructionComponent[] {
    return this.viewService.currentSide === SideType.Inside
      ? this.inversedOrder()
      : this.configuratorConfigurationModel.configuratedDoor?.Components
  }

  get selected(): ConstructionComponent {
    return this.componentSelection.selectedComponent
  }

  set selected(component: ConstructionComponent) {
    this.componentSelection.selectedComponent = component.Index
  }
}
