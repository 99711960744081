<ng-template #downloadAndShareHoverMenu>
  <div class='pop-out-wrapper'>
    <i class='fa fa-caret-up'></i>
    <div class='pop-out'>
      <button (click)='downloadImage($event)' mat-stroked-button>
        <div class='inner-label'>
          <i class='fa fa-fw fa-3x fa-picture-o'></i>
          <span>{{ TRANSLATION_KEY.SHARE_MENU_GENERATE_IMAGE|translate }}</span>
        </div>
      </button>
      <button (click)='downloadPdf($event)' mat-stroked-button>
        <div class='inner-label'>
          <i class='fa fa-fw fa-3x fa-file-pdf-o'></i>
          <span>{{ TRANSLATION_KEY.SHARE_MENU_GENERATE_PDF|translate }}</span>
        </div>
      </button>
      <button (click)='openShareModal()' mat-stroked-button>
        <div class='inner-label'>
          <i class='fa fa-fw fa-3x fa-share-alt'></i>
          <span>{{ TRANSLATION_KEY.SHARE_MENU_GENERATE_SHARE|translate }}</span>
        </div>
      </button>
    </div>
  </div>
</ng-template>


<ng-template #memoListItemTpl let-item>
  <div #htmlItem class='memo-list-item'>
    <div (click)='this.togglePopOut(htmlItem)' class='image-wrapper'>
      <img
        *loadingAnimation="item?.thumbnailUrl | async; background: true; placement: 'container'"
        alt='{{TRANSLATION_KEY.MEMOLIST_IMAGE_PREVIEW|translate}} {{  item?.doorId  }}'
      />
      <div class='icon-overlay'>
                <span *ngIf='currentDoor.doorId === item.doorId' class='fa-stack'>
                    <i class='fa fa-fw fa-circle fa-stack-2x'></i>
                    <i class='fa fa-fw fa-stack-1x fa-inverse fa-wrench'></i>
                </span>
        <span *ngIf='memoListService.has(item.doorId)' class='fa-stack'>
                    <i class='fa fa-fw fa-circle fa-stack-2x'></i>
                    <i class='fa fa-fw fa-stack-1x fa-inverse fa-heart'></i>
                </span>
      </div>
    </div>
    <div #popout *ngIf='memoListService.has(item.doorId)' class='pop-out-wrapper'>
      <i class='fa fa-caret-up'></i>
      <div class='pop-out'>
        <button (click)='this.hideAllPopOuts(); this.loadRequest(item.doorId)' mat-stroked-button>
          <div class='inner-label'>
            <i class='fa fa-fw fa-3x fa-folder-open'></i>
            <span>{{ TRANSLATION_KEY.MEMOLIST_LOAD_CONFIG |translate }}</span>
          </div>
        </button>
        <button (click)='this.hideAllPopOuts(); memoListService.remove(item)' mat-stroked-button>
          <div class='inner-label'>
                <span class='fa-stack fa-2x'>
                    <i class='fa fa-heart fa-stack-2x'></i>
                    <span class='fa-stack fa-stack-1x'>
                        <i class='fa fa-minus fa-stack-1x fa-inverse'></i>
                        <i class='fa fa-minus-circle fa-stack-1x'></i>
                        <i class='fa fa-circle-thin fa-stack-1x fa-inverse'></i>
                    </span>
                </span>
            <span>{{ TRANSLATION_KEY.MEMOLIST_REMOVE_ENTRY|translate }}</span>
          </div>
        </button>
      </div>
    </div>
    <mat-checkbox (change)='onSelectionChange($event, item)' [checked]='selection.includes(item)'>
      {{ TRANSLATION_KEY.MEMOLIST_ENTRY_LABEL_MODEL | translate }}{{ item?.description }}<br>
      {{ TRANSLATION_KEY.MEMOLIST_ENTRY_LABEL_ID | translate }}{{ item?.doorId }}
    </mat-checkbox>
  </div>
</ng-template>

<ng-template #head>
  <div class='name'>
    <span>{{ TRANSLATION_KEY.TITLE | translate }}</span>
    <span (click)="closeMenu()" class='close'></span>
  </div>
</ng-template>

<configurator-base-menu
  [menuHead]='head'
  [menuNavigatorIdentifier]='NavigationMenuEntryKey.Finalize'
  menuClass='basis'
>
  <div #scrollContainer *ngIf='currentDoor' class='container'>
    <div class='container-inner'>
      <div class='memo-list'>
        <ng-container *ngIf='!memoListService.has(currentDoor.doorId)'>
          <ng-container
            *ngTemplateOutlet='memoListItemTpl; context: { $implicit: currentDoor }'
          >
          </ng-container>
        </ng-container>
        <ng-container *ngFor='let _ of memoListService.get(); template: memoListItemTpl'></ng-container>
      </div>

      <div class='buttonWrapper'>
        <button
          (click)='memoListService.addCurrentDoor()'
          [disabled]='!currentDoor || memoListService.has(currentDoor.doorId)'
          class='w-100 my-2'
          color='primary'
          mat-raised-button
        >
          {{ TRANSLATION_KEY.MEMOLIST_ADD_ENTRY | translate }} ({{ memoListService.length }})
        </button>
        <button
          #downloadButton (click)='this.togglePopOut(downloadButton)'
          [disabled]='selection.length === 0'
          class='w-100 my-2 download-button' color='primary'
          id='downloadButton'
          mat-raised-button
        >
          {{ TRANSLATION_KEY.MEMOLIST_SELECTED_ENTRY | translate }}
          (
          <mat-checkbox checked disabled></mat-checkbox>
          )
          ->
          {{ TRANSLATION_KEY.MEMOLIST_DOWNLOAD_ENTRY | translate }}
          +
          {{ TRANSLATION_KEY.MEMOLIST_SHARE_ENTRY | translate }}
          <ng-container *ngTemplateOutlet='downloadAndShareHoverMenu'></ng-container>
        </button>
      </div>


      <ng-container [ngSwitch]='settingsService.settings.IsAnfragenMoeglich'>
        <ng-container *ngSwitchCase='true'>
          <h2>
            {{ TRANSLATION_KEY.REQUEST_OFFER_TITLE | translate }}
          </h2>

          <form
            (ngSubmit)='onResultFormSubmit($event)'
            [formGroup]='this.requestForm'
          >

            <mat-form-field appearance='fill' class='w-50'>
              <mat-label>{{ TRANSLATION_KEY.REQUEST_OFFER_FIRST_NAME_LABEL | translate }}</mat-label>
              <input
                [formControl]='this.requestForm.controls.name'
                [placeholder]='TRANSLATION_KEY.REQUEST_OFFER_FIRST_NAME_PLACEHOLDER | translate'
                matInput
              />
              <mat-error *ngIf='this.requestForm.controls.name.invalid'>
                {{ this.getFormErrorForField('name') }}
              </mat-error>
            </mat-form-field>

            <mat-form-field appearance='fill' class='w-50'>
              <mat-label>{{ TRANSLATION_KEY.REQUEST_OFFER_LAST_NAME_LABEL | translate }}</mat-label>
              <input
                [formControl]='this.requestForm.controls.lastname'
                [placeholder]='TRANSLATION_KEY.REQUEST_OFFER_LAST_NAME_PLACEHOLDER | translate'
                matInput
              />
              <mat-error *ngIf='this.requestForm.controls.lastname.invalid'>
                {{ this.getFormErrorForField('lastname') }}
              </mat-error>
            </mat-form-field>

            <mat-form-field appearance='fill'>
              <mat-label>{{ TRANSLATION_KEY.REQUEST_OFFER_STREET_LABEL | translate }}</mat-label>
              <input
                [formControl]='this.requestForm.controls.street'
                [placeholder]='TRANSLATION_KEY.REQUEST_OFFER_STREET_PLACEHOLDER | translate'
                matInput
              />
              <mat-error *ngIf='this.requestForm.controls.street.invalid'>
                {{ this.getFormErrorForField('street') }}
              </mat-error>
            </mat-form-field>

            <mat-form-field appearance='fill' class='w-30'>
              <mat-label>{{ TRANSLATION_KEY.REQUEST_OFFER_ZIP_LABEL | translate }}</mat-label>
              <input
                [formControl]='this.requestForm.controls.zip'
                [placeholder]='TRANSLATION_KEY.REQUEST_OFFER_ZIP_PLACEHOLDER | translate'
                matInput
              />
              <mat-error *ngIf='this.requestForm.controls.zip.invalid'>
                {{ this.getFormErrorForField('zip') }}
              </mat-error>
            </mat-form-field>

            <mat-form-field appearance='fill' class='w-70'>
              <mat-label>{{ TRANSLATION_KEY.REQUEST_OFFER_CITY_LABEL | translate }}</mat-label>
              <input
                [formControl]='this.requestForm.controls.city'
                [placeholder]='TRANSLATION_KEY.REQUEST_OFFER_CITY_PLACEHOLDER | translate'
                matInput
              />
              <mat-error *ngIf='this.requestForm.controls.city.invalid'>
                {{ this.getFormErrorForField('city') }}
              </mat-error>
            </mat-form-field>

            <mat-form-field appearance='fill'>
              <mat-label>{{ TRANSLATION_KEY.REQUEST_OFFER_COUNTRY_LABEL | translate }}</mat-label>
              <input
                [formControl]='this.requestForm.controls.country'
                [placeholder]='TRANSLATION_KEY.REQUEST_OFFER_COUNTRY_PLACEHOLDER | translate'
                matInput
              />
              <mat-error *ngIf='this.requestForm.controls.country.invalid'>
                {{ this.getFormErrorForField('country') }}
              </mat-error>
            </mat-form-field>

            <mat-form-field appearance='fill'>
              <mat-label>{{ TRANSLATION_KEY.REQUEST_OFFER_PHONE_LABEL | translate }}</mat-label>
              <input
                [formControl]='this.requestForm.controls.phone'
                [placeholder]='TRANSLATION_KEY.REQUEST_OFFER_PHONE_PLACEHOLDER | translate'
                matInput
              />
              <mat-error *ngIf='this.requestForm.controls.phone.invalid'>
                {{ this.getFormErrorForField('phone') }}
              </mat-error>
            </mat-form-field>

            <mat-form-field appearance='fill'>
              <mat-label>{{ TRANSLATION_KEY.REQUEST_OFFER_EMAIL_LABEL| translate }}</mat-label>
              <input
                [formControl]='this.requestForm.controls.email' matInput
                placeholder='{{TRANSLATION_KEY.REQUEST_OFFER_EMAIL_PLACEHOLDER | translate}}'
              />
              <mat-error *ngIf='this.requestForm.controls.email.invalid'>
                {{ this.getFormErrorForField('email') }}
              </mat-error>
            </mat-form-field>


            <mat-form-field *ngIf='this.settings.AnfrageKatalog' appearance='fill'>
              <input [class.cdk-visually-hidden]='true' [formControl]='this.requestForm.controls.agree' matInput>
              <mat-checkbox (change)='updateCatalogValue($event.checked)'>
                {{ TRANSLATION_KEY.REQUEST_OFFER_SEND_CATALOG | translate }}
              </mat-checkbox>
            </mat-form-field>


            <mat-form-field *ngIf='this.settings.AnfrageHaendler' appearance='fill'>
              <input [class.cdk-visually-hidden]='true' [formControl]='this.requestForm.controls.agree' matInput>
              <mat-checkbox (change)='updateAgreeValue($event.checked)'>
                {{ TRANSLATION_KEY.REQUEST_OFFER_SHARE_WITH_RETAILER | translate }}
              </mat-checkbox>
              <mat-error
                *ngIf='settingsService.settings.AnfrageHaendler
                      && this.settings.AnfrageHaendlerPflicht
                      && this.requestForm.controls.agree.invalid'
                class='agreeCheckboxError'
              >
                {{ this.getFormErrorForField('agree') }}
              </mat-error>
            </mat-form-field>

            <label *ngIf='settingsService.settings.Fachpartner' [for]='fachpartnerInput?.nativeElement?.id'
                   class='fachpartner'>
              {{ TRANSLATION_KEY.REQUEST_OFFER_EXISTING_PARTNER_STRING | translate }}
            </label>

            <mat-form-field *ngIf='settingsService.settings.Fachpartner' appearance='fill'>
              <mat-label>{{ TRANSLATION_KEY.REQUEST_OFFER_EXISTING_PARTNER_LABEL | translate }}</mat-label>
              <input
                #fachpartnerInput
                [formControl]='this.requestForm.controls.fachpartner'
                matInput
                placeholder='{{TRANSLATION_KEY.REQUEST_OFFER_EXISTING_PARTNER_PLACEHOLDER | translate}}'
              />
              <mat-error *ngIf='this.requestForm.controls.fachpartner.invalid'>
                {{ this.getFormErrorForField('fachpartner') }}
              </mat-error>
            </mat-form-field>

            <div class='disclaimer'>
              {{ TRANSLATION_KEY.HINT_MEASURES_NOT_OBLIGING | translate }}
            </div>


            <button
              [disabled]="requestFormState === 'LOADING'" [ngSwitch]='requestFormState'
              class='w-100 my-4'
              color='primary'
              mat-raised-button
            >
              <i *ngSwitchCase="'INITIAL'" class='fa fa-mail-forward'></i>
              <i *ngSwitchCase="'LOADING'" class='fa fa-spinner fa-pulse fa-fw'></i>
              <i *ngSwitchCase="'SUCCESS'" class='fa fa-check'></i>
              <span *ngSwitchCase="'INITIAL'">{{ TRANSLATION_KEY.REQUEST_STATUS_INITIAL | translate }}</span>
              <span *ngSwitchCase="'LOADING'">{{ TRANSLATION_KEY.REQUEST_STATUS_LOADING | translate }}</span>
              <span *ngSwitchCase="'SUCCESS'">{{ TRANSLATION_KEY.REQUEST_STATUS_SUCCESS | translate }}</span>
            </button>


            <div class='text-right'>
              {{ TRANSLATION_KEY.DATA_PRIVACY_HINT_PREFIX | translate }}
              <a (click)='this.showPrivacyDialog(); false'
                 href='#'>{{ TRANSLATION_KEY.DATA_PRIVACY_HINT_SUFFIX | translate }}</a>
            </div>
          </form>
        </ng-container>


        <div *ngSwitchDefault class='container-child'>
          <div class='disclaimer'>
            {{ TRANSLATION_KEY.HINT_MEASURES_NOT_OBLIGING | translate }}
          </div>
        </div>
      </ng-container>

    </div>
  </div>
</configurator-base-menu>
