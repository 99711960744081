<configurator-base-menu
  [menuHead]="head"
  [menuNavigatorIdentifier]='NavigationMenuEntryKey.Addon'
  has-component-selector
  menuClass="mehrpreise"
>
  <div class="container elements">
    <div
      *ngIf="selectedComponent?.model
                && selectedComponent?.model?.getZubehoerCategories()?.length > 0"
      class="container-inner max">
      <div class="mehrpreisWrapper">


        <ng-container *ngFor="let mehrpreis of this.sortedMehrpreise">

          <ng-template *ngIf="isMehrpreisPossible(mehrpreis)"
                       [ngTemplateOutletContext]="{category: mehrpreis.Typ}"
                       [ngTemplateOutlet]="fbsMehrpreis"></ng-template>

        </ng-container>

        <div
          *ngIf="this.sortedMehrpreise?.length === 0"
          class="noResults">
          <span>{{ this.noAccessoriesText() }}</span>
        </div>
      </div>
    </div>
  </div>
</configurator-base-menu>

<ng-template #head>
  <div class="name">
    <span>{{ TRANSLATION_KEY.TITLE | translate }}</span>
    <span (click)="closeMenu()" class="close"></span>
  </div>
</ng-template>

<ng-template #fbsMehrpreis let-category="category">
  <ng-container
    *ngIf="

            category !== ZUBEHOER_CATEGORY_HAUSNUMMER
            && (categoryHasAddOns(selectedComponent.model, category) || categoryHasItems(category))
        "
  >
    <div class="basisrow">
      <div [class.active]="getZubehoerEntry(category)" class="title">
        {{ this.getMehrpreis(category).Bezeichnung | translate }}
      </div>
      <div class="content">
        <mat-form-field *ngIf="getZubehoerEntry(category)?.Item as item" appearance="fill">
          <mat-label>{{ TRANSLATION_KEY.MEHRPREIS_MENU_BOX_SELECTION_CHOICE_LABEL | translate }}</mat-label>
          <input (click)="showMehrpreis(category)" [value]="item.Beschreibung | translate" matInput readonly>
          <button (click)="toggleZubehoer(item, true)"
                  [attr.aria-label]="TRANSLATION_KEY.MEHRPREIS_MENU_BOX_SELECTION_CLEAR | translate"
                  mat-icon-button
                  matTextSuffix>
            <i class="fa fa-2x fa-times"></i>
          </button>
        </mat-form-field>

        <ng-container *ngIf="!getZubehoerEntry(category)?.Item && categoryHasItems(category)">
          <button (click)="showMehrpreis(category)" class="fullWidth" mat-flat-button>
            {{ this.categoryName(category) }}
          </button>
        </ng-container>
        <ng-container *ngFor="let dl of this.getDienstleistungen(category)">
          <ng-template *ngIf="this.dienstleistungPossible(dl)"
                       [ngTemplateOutletContext]="{
                       category: category,
                       addon: dl.Typ,
                       text: dl.Bezeichnung,
                       massblaetter: dl.Massblaetter ?? []
                     }"
                       [ngTemplateOutlet]="addonCheckbox"
          ></ng-template>
        </ng-container>

      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="category === ZUBEHOER_CATEGORY_HAUSNUMMER">
    <div class="basisrow">
      <div [class.active]="this.getZubehoerEntry(ZUBEHOER_CATEGORY_HAUSNUMMER)" class="title">
        {{ TRANSLATION_KEY.MEHRPREIS_MENU_BOX_HOUSENUMBER_TITLE | translate }}
      </div>
      <div class="content">
        <mat-form-field appearance="fill">
          <mat-label>{{ TRANSLATION_KEY.MEHRPREIS_MENU_BOX_HOUSENUMBER_LABEL | translate }}</mat-label>
          <input [(ngModel)]="hausnummer" id="hausnummerInput" matInput
                 placeholder="{{TRANSLATION_KEY.MEHRPREIS_MENU_BOX_HOUSENUMBER_LABEL | translate}}"
          >
          <button (click)="this.hausnummer = ''"
                  [attr.aria-label]="TRANSLATION_KEY.MEHRPREIS_MENU_BOX_SELECTION_CLEAR" mat-icon-button
                  matTextSuffix>
            <i class="fa fa-2x fa-times"></i>
          </button>
        </mat-form-field>
        <ng-container *ngFor="let dl of this.getDienstleistungen(ZUBEHOER_CATEGORY_HAUSNUMMER)">


          <ng-template *ngIf="this.dienstleistungPossible(dl)"
                       [ngTemplateOutletContext]="{
                       category: ZUBEHOER_CATEGORY_HAUSNUMMER,
                       addon: dl.Typ,
                       text: dl.Bezeichnung,
                       massblaetter: dl.Massblaetter ?? []
                     }"
                       [ngTemplateOutlet]="addonCheckbox"
          ></ng-template>
        </ng-container>
      </div>
    </div>
  </ng-container>
</ng-template>

<ng-template #addonCheckbox let-addon="addon" let-category="category" let-massblaetter="massblaetter" let-text="text">
  <div class="cbWrapper mdc-touch-target-wrapper">
    <mat-checkbox
      (change)="this.toggleZubehoerAddon(category, addon)"
      [checked]="!!this.getZubehoerEntry(category)?.getAddon(addon)"
      [disabled]="addon.includes('montage') && this.getZubehoerEntry(category)?.Item?.IsMontageMoeglich === false">
      {{ text | translate }}
    </mat-checkbox>
    <button
      (click)="this.massblattModal(massblaetter, this.getZubehoerEntry(category)?.getAddon(addon))"
      *ngIf="massblaetter && massblaetter.length > 0 && !!this.getZubehoerEntry(category)?.getAddon(addon)"
    >
      {{ TRANSLATION_KEY.MEHRPREIS_MENU_ADDON_DATASHEET_LABEL | translate }}
    </button>
  </div>
</ng-template>
