<modal-frame [footer]="footer" [hideCloseButton]="mode === 'input'"
             [title]="(mode === 'display' ? TRANSLATION_KEY.HEADING_DISPLAY : TRANSLATION_KEY.HEADING_INPUT) | translate"
             contentClass="content"
             hostClass="masse"
             hostId="mehrpreisDiv"
>
  <form (keyup.enter)="onAction()" class="spalte links">
    <div>
      <h3>
        {{
          (this.construction === SimpleConstruction.AufsatzBeidseitig
            ? TRANSLATION_KEY.FORM_HEADING_OUTSIDE : TRANSLATION_KEY.FORM_HEADING) | translate
        }}
      </h3>
      <mat-form-field appearance="fill">
        <mat-label>{{ TRANSLATION_KEY.FORM_LABEL_A | translate }}</mat-label>
        <input [(ngModel)]="constructionDimensions.A"
               [disabled]="mode === 'display' || this.construction === SimpleConstruction.Einsatz"
               matInput name="A" type="number"
        >
        <span matTextSuffix>{{ TRANSLATION_KEY.FORM_LABEL_UNIT | translate }}</span>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>{{ TRANSLATION_KEY.FORM_LABEL_B | translate }}</mat-label>
        <input [(ngModel)]="constructionDimensions.B" [disabled]="mode === 'display'" matInput name="B" type="number">
        <span matTextSuffix>{{ TRANSLATION_KEY.FORM_LABEL_UNIT | translate }}</span>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>{{ TRANSLATION_KEY.FORM_LABEL_C | translate }}</mat-label>
        <input [(ngModel)]="constructionDimensions.C"
               [disabled]="mode === 'display' || this.construction === SimpleConstruction.Einsatz"
               matInput name="C" type="number">
        <span matTextSuffix>{{ TRANSLATION_KEY.FORM_LABEL_UNIT | translate }}</span>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>{{ TRANSLATION_KEY.FORM_LABEL_D | translate }}</mat-label>
        <input [(ngModel)]="constructionDimensions.D"
               [disabled]="mode === 'display' || this.construction === SimpleConstruction.Einsatz"
               matInput name="D" type="number">
        <span matTextSuffix>{{ TRANSLATION_KEY.FORM_LABEL_UNIT | translate }}</span>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>{{ TRANSLATION_KEY.FORM_LABEL_E | translate }}</mat-label>
        <input [(ngModel)]="constructionDimensions.E"
               [disabled]="mode === 'display' || this.construction === SimpleConstruction.Einsatz"
               matInput name="E" type="number">
        <span matTextSuffix>{{ TRANSLATION_KEY.FORM_LABEL_UNIT | translate }}</span>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>{{ TRANSLATION_KEY.FORM_LABEL_F | translate }}</mat-label>
        <input [(ngModel)]="constructionDimensions.F" [disabled]="mode === 'display'" matInput name="F" type="number">
        <span matTextSuffix>{{ TRANSLATION_KEY.FORM_LABEL_UNIT | translate }}</span>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>{{ TRANSLATION_KEY.FORM_LABEL_G | translate }}</mat-label>
        <input [(ngModel)]="constructionDimensions.G"
               [disabled]="mode === 'display' || this.construction === SimpleConstruction.Einsatz"
               matInput name="G" type="number">
        <span matTextSuffix>{{ TRANSLATION_KEY.FORM_LABEL_UNIT | translate }}</span>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>{{ TRANSLATION_KEY.FORM_LABEL_H | translate }}</mat-label>
        <input [(ngModel)]="constructionDimensions.H"
               [disabled]="mode === 'display' || this.construction === SimpleConstruction.Einsatz"
               matInput name="H" type="number">
        <span matTextSuffix>{{ TRANSLATION_KEY.FORM_LABEL_UNIT | translate }}</span>
      </mat-form-field>
    </div>
    <div *ngIf="construction === SimpleConstruction.AufsatzBeidseitig">
      <h3>{{ TRANSLATION_KEY.FORM_HEADING_INSIDE }}</h3>
      <mat-form-field appearance="fill">
        <mat-label>{{ TRANSLATION_KEY.FORM_LABEL_I | translate }}</mat-label>
        <input [(ngModel)]="constructionDimensions.I" [disabled]="mode === 'display'" matInput name="I" type="number">
        <span matTextSuffix>{{ TRANSLATION_KEY.FORM_LABEL_UNIT | translate }}</span>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>{{ TRANSLATION_KEY.FORM_LABEL_J | translate }}</mat-label>
        <input [(ngModel)]="constructionDimensions.J" [disabled]="mode === 'display'" matInput name="J" type="number">
        <span matTextSuffix>{{ TRANSLATION_KEY.FORM_LABEL_UNIT | translate }}</span>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>{{ TRANSLATION_KEY.FORM_LABEL_K | translate }}</mat-label>
        <input [(ngModel)]="constructionDimensions.K" [disabled]="mode === 'display'" matInput name="K" type="number">
        <span matTextSuffix>{{ TRANSLATION_KEY.FORM_LABEL_UNIT | translate }}</span>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>{{ TRANSLATION_KEY.FORM_LABEL_L | translate }}</mat-label>
        <input [(ngModel)]="constructionDimensions.L" [disabled]="mode === 'display'" matInput name="L" type="number">
        <span matTextSuffix>{{ TRANSLATION_KEY.FORM_LABEL_UNIT | translate }}</span>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>{{ TRANSLATION_KEY.FORM_LABEL_M | translate }}</mat-label>
        <input [(ngModel)]="constructionDimensions.M" [disabled]="mode === 'display'" matInput name="M" type="number">
        <span matTextSuffix>{{ TRANSLATION_KEY.FORM_LABEL_UNIT | translate }}</span>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>{{ TRANSLATION_KEY.FORM_LABEL_N | translate }}</mat-label>
        <input [(ngModel)]="constructionDimensions.N" [disabled]="mode === 'display'" matInput name="N" type="number">
        <span matTextSuffix>{{ TRANSLATION_KEY.FORM_LABEL_UNIT | translate }}</span>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>{{ TRANSLATION_KEY.FORM_LABEL_O | translate }}</mat-label>
        <input [(ngModel)]="constructionDimensions.O" [disabled]="mode === 'display'" matInput name="O" type="number">
        <span matTextSuffix>{{ TRANSLATION_KEY.FORM_LABEL_UNIT | translate }}</span>
      </mat-form-field>

      <mat-form-field appearance="fill">
        <mat-label>{{ TRANSLATION_KEY.FORM_LABEL_P | translate }}</mat-label>
        <input [(ngModel)]="constructionDimensions.P" [disabled]="mode === 'display'" matInput name="P" type="number">
        <span matTextSuffix>{{ TRANSLATION_KEY.FORM_LABEL_UNIT | translate }}</span>
      </mat-form-field>
    </div>
  </form>
  <div class="spalte rechts">
    <img [src]="'https://images.rodenberg.ag/konstruktionen/'+ construction +'.png'" class='constructionImage'>
  </div>
</modal-frame>
<ng-template #footer>
  <p *ngIf="configuratorMode === ConfiguratorMode.FBS" class="infoText">
    {{ (mode === 'display' ? TRANSLATION_KEY.TEXT_DISPLAY : TRANSLATION_KEY.TEXT_INPUT) | translate }}
  </p>
  <button (click)="onAction()" color="primary" mat-flat-button>
    {{ (mode === 'display' ? TRANSLATION_KEY.BUTTON_LABEL_DISPLAY : TRANSLATION_KEY.BUTTON_LABEL_INPUT) | translate }}
  </button>
</ng-template>
