import {ResolveFn} from '@angular/router'
import {inject} from '@angular/core'
import {SettingsService} from '../../classes/service/settings/settings.service'
import {TranslateService} from '../../translate'

export const preloadConfiguratorRequiredServicesResolver: ResolveFn<true> = async (): Promise<true> => {
  const settingsService = inject(SettingsService)
  const translateService = inject(TranslateService)
  await settingsService.getSettings()
  await translateService.loadLanguage(settingsService.settings.Sprache)
  return true
}
