import {FbsGlas, Motiv} from '../../../../class'
import {Tuer} from '../../component/construction/tuer'
import {Seitenteil} from '../../component/construction/seitenteil'
import {Oberlicht} from '../../component/construction/oberlicht'
import {GlassModalMode} from '../../../../configurator/modal/glass-modal/glass-modal.component'
import {GlasaufbauPositionShort} from '../../../../types'
import {TranslateService} from '../../../../translate'

const TRANSLATION_KEY = {
  POSSIBLE_POSITION_INSIDE: 'GlassUpdateHelper.PossiblePosition.Inside',
  POSSIBLE_POSITION_MID: 'GlassUpdateHelper.PossiblePosition.Mid',
  POSSIBLE_POSITION_OUTSIDE: 'GlassUpdateHelper.PossiblePosition.Outside',
  GLASSTYPE_GLASS: 'GlassUpdateHelper.GlassType.Glass',
  GLASSTYPE_MOTIVE: 'GlassUpdateHelper.GlassType.Motive',
  NOT_POSSIBLE_FOR_OUTSIDE_PREFIX: 'GlassUpdateHelper.DisabilityReason.Position.Outside.Prefix',
  NOT_POSSIBLE_FOR_OUTSIDE_INFIX: 'GlassUpdateHelper.DisabilityReason.Position.Outside.Infix',
  NOT_POSSIBLE_FOR_INSIDE_PREFIX: 'GlassUpdateHelper.DisabilityReason.Position.Inside.Prefix',
  NOT_POSSIBLE_FOR_INSIDE_INFIX: 'GlassUpdateHelper.DisabilityReason.Position.Inside.Infix',
  NOT_POSSIBLE_FOR_MID_PREFIX: 'GlassUpdateHelper.DisabilityReason.Position.Mid.Prefix',
  NOT_POSSIBLE_FOR_MID_INFIX: 'GlassUpdateHelper.DisabilityReason.Position.Mid.Infix'
} as const

export class GlassUpdateHelper {
  constructor(
    private translateService: TranslateService,
    public element: Tuer | Seitenteil | Oberlicht,
    public glaeser: FbsGlas[] = [],
    public motive: Motiv[] = [],
    public position: GlasaufbauPositionShort = 'aussen',
    public mode: GlassModalMode = 'glass',
    public selectedGlas: FbsGlas | null = null,
    public selectedMotiv: Motiv | null = null,
    public filterTerm: string = ''
  ) {
  }

  getCurrentGlasselementId(): number {
    switch (this.position) {
      case 'aussen':
        return this.element.glasaufbau.GlasscheibeAussen?.Id
      case 'mitte1':
        return this.element.glasaufbau.GlasscheibeMitte1?.Id
      case 'mitte2':
        return this.element.glasaufbau.GlasscheibeMitte2?.Id
      case 'innen':
        return this.element.glasaufbau.GlasscheibeInnen?.Id
      default:
        console.error('Could not define which glass to get')
        return 0
    }
  }

  getCurrentMotivelementId(): number {
    switch (this.position) {
      case 'aussen':
        return this.element.glasaufbau.MotivAussen?.Id
      case 'mitte1':
        return this.element.glasaufbau.MotivMitte1?.Id
      case 'mitte2':
        return this.element.glasaufbau.MotivMitte2?.Id
      case 'innen':
        return this.element.glasaufbau.MotivInnen?.Id
      default:
        console.error('Could not define which motiv to get')
        return 0
    }
  }

  moveSelectedGlasToBeginning(): void {
    const selectedId = this.getCurrentGlasselementId()
    if (selectedId > 0) {
      const index = this.glaeser.findIndex((g): boolean => g.Id === selectedId)
      if (index !== -1) {
        this.glaeser.unshift(this.glaeser.splice(index, 1)[0])
      }
    }
  }

  moveSelectedMotivToBeginning(): void {
    const selectedId = this.getCurrentMotivelementId()
    if (selectedId > 0) {
      const index = this.motive.findIndex((m): boolean => m.Id === selectedId)
      if (index !== -1) {
        this.motive.unshift(this.motive.splice(index, 1)[0])
      }
    }
  }

  positionPossible(item: FbsGlas | Motiv, position: GlasaufbauPositionShort): boolean {
    if (item.IsAussenMoeglich && position === 'aussen') {
      return true
    }

    if (item.IsMitteMoeglich && position === ('mitte1' || 'mitte2')) {
      return true
    }

    return item.IsInnenMoeglich && position === 'innen'
  }

  togglePreselectGlas(glas: FbsGlas): void {
    if (this.selectedGlas === glas) {
      this.selectedGlas = null
    } else {
      this.selectedGlas = glas
    }
  }

  togglePreselectMotiv(motiv: Motiv): void {
    if (this.selectedMotiv === motiv) {
      this.selectedMotiv = null
    } else {
      this.selectedMotiv = motiv
    }
  }

  updateItemVisibility(glass: FbsGlas | Motiv): boolean {
    const glassType: string = this.mode.charAt(0).toUpperCase() + this.mode.slice(1, this.mode.length) // Glass | Motiv
    const reasons: string[] = []
    const possiblePositions: string[] = []


    if (glass.IsAussenMoeglich) {
      possiblePositions.push(this.translateService.translate(TRANSLATION_KEY.POSSIBLE_POSITION_OUTSIDE))
    }
    if (glass.IsMitteMoeglich) {
      possiblePositions.push(this.translateService.translate(TRANSLATION_KEY.POSSIBLE_POSITION_MID))
    }
    if (glass.IsInnenMoeglich) {
      possiblePositions.push(this.translateService.translate(TRANSLATION_KEY.POSSIBLE_POSITION_INSIDE))
    }

    if (this.position === 'aussen' && !glass.IsAussenMoeglich) {
      reasons.push(this.translateService.translate(TRANSLATION_KEY.NOT_POSSIBLE_FOR_OUTSIDE_PREFIX)
        + this.translateService.translate(glassType === 'Glass' ? TRANSLATION_KEY.GLASSTYPE_GLASS : TRANSLATION_KEY.GLASSTYPE_MOTIVE)
        + this.translateService.translate(TRANSLATION_KEY.NOT_POSSIBLE_FOR_OUTSIDE_INFIX)
        + possiblePositions.join(','))
    }
    if (this.position === 'innen' && !glass.IsInnenMoeglich) {
      reasons.push(this.translateService.translate(TRANSLATION_KEY.NOT_POSSIBLE_FOR_INSIDE_PREFIX)
        + this.translateService.translate(glassType === 'Glass' ? TRANSLATION_KEY.GLASSTYPE_GLASS : TRANSLATION_KEY.GLASSTYPE_MOTIVE)
        + this.translateService.translate(TRANSLATION_KEY.NOT_POSSIBLE_FOR_INSIDE_INFIX)
        + possiblePositions.join(','))
    }
    if ((this.position === 'mitte1' || this.position === 'mitte2') && !glass.IsMitteMoeglich) {
      reasons.push(this.translateService.translate(TRANSLATION_KEY.NOT_POSSIBLE_FOR_MID_PREFIX)
        + this.translateService.translate(glassType === 'Glass' ? TRANSLATION_KEY.GLASSTYPE_GLASS : TRANSLATION_KEY.GLASSTYPE_MOTIVE)
        + this.translateService.translate(TRANSLATION_KEY.NOT_POSSIBLE_FOR_MID_INFIX)
        + possiblePositions.join(','))
    }

    glass.disabilityReasons = reasons
    return true
  }

  updateSelection(): boolean {
    let hasChanged = false
    if (this.mode === 'glass') {
      if (this.selectedGlas) {
        hasChanged = this.element.glasaufbau.setGlas(this.position, this.selectedGlas)
      }
    } else if (this.mode === 'motiv') {
      if (this.selectedMotiv) {
        hasChanged = this.element.glasaufbau.setMotiv(this.position, this.selectedMotiv)
      }
    }
    return hasChanged
  }
}
