<configurator-base-menu
  [menuHead]='head'
  [menuNavigatorIdentifier]='NavigationMenuEntryKey.Option'
  has-component-selector
>

  <div class='container elements'>
    <div class='container-inner max'>

      <div *ngIf='this.selectedComponent.model && this.selectedComponent.model.Optionen.length === 0'
           class='modellOptionenWrapper'>
        <div class='noResults'>
          <span>{{ this.noOptionsText() }}</span>
        </div>
      </div>

      <div *ngIf='this.selectedComponent.model && this.selectedComponent.model.Optionen.length > 0'
           class='modellOptionenWrapper'>
        <ng-template
          [ngTemplateOutletContext]="{class: 'margin-top-0 margin-bottom-10'}"
          [ngTemplateOutlet]='menuViewSwitch'
        >
        </ng-template>

        <accordion *ngIf='this.optionsAvailable(this.view); else: noOptionsForSelectedSide'>
          <ng-container
            *ngFor='let category of this.selectedComponent.model.orderedOptions[this.view] | keyvalue,
                                    let group_index = index'
          >
            <accordion-group
              *ngIf='this.getFittingOptions(category.value).length > 0'
              [heading]='Option.optionCategoryMappings(category.key) | translate'
              [isOpen]='group_index === 0'
            >

              <div class='container-child'>
                <ng-container *ngFor='let item of this.getFittingOptions(category.value)'>

                  <div
                    (click)="item.disabilityReasons.length === 0 ? this.toggleOption(item, true) : ''"
                    *ngIf='
                          item.suitableForMaterial(this.selectedComponent.material)
                          && (item.updateVisibility(this.selectedComponent.material) || true)'
                    [class.active]='this.selectedComponent.hasOptionSelected(item, view)'
                    class='item'
                  >
                    <div
                      [class.disabled]='item.disabilityReasons.length > 0'
                      [mtxTooltipDisabled]='item.disabilityReasons.length === 0'
                      [mtxTooltip]='StringUtil.concatStringArray(item.disabilityReasons)'
                      class='image-wrapper'
                    >

                      <div
                        *ngIf='!item.loaded && item.PreviewImageUrl'
                        class='icon loading-image-spinner'
                      >
                        <i class='fa fa-spinner fa-pulse fa-3x fa-fw'></i>
                      </div>
                      <img (load)='item.loaded = true' [lazyLoad]='item.PreviewImageUrl'>
                    </div>
                    <p>
                            <span
                              class='option-text'
                            >{{ item.Bezeichnung | translate }}</span>
                    </p>
                  </div>
                </ng-container>
              </div>


            </accordion-group>
          </ng-container>
        </accordion>
      </div>
    </div>
  </div>
</configurator-base-menu>
<ng-template #head>
  <div class='name'>
    <span>{{ TRANSLATION_KEY.TITLE | translate }}</span>
    <span (click)="closeMenu()" class='close'></span>
  </div>
</ng-template>

<ng-template #menuViewSwitch let-params='class'>
  <div class='viewSwitchButtons {{ params }}'>
    <button
      (click)='this.view = SideType.Outside'
      [class.active]='view === SideType.Outside' class='viewSwitchButton'
    >
      {{ TRANSLATION_KEY.MENU_VIEWSWITCH_OUTSIDE | translate }}
    </button>
    <button
      (click)='this.view = SideType.Inside'
      [class.active]='view === SideType.Inside' class='viewSwitchButton'
    >
      {{ TRANSLATION_KEY.MENU_VIEWSWITCH_INSIDE | translate }}
    </button>
  </div>
</ng-template>

<ng-template #noOptionsForSelectedSide>
  <div class='noResults'>
    <span>{{ this.noOptionsForSideText() }}</span>
  </div>
</ng-template>
