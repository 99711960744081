import {ParamMap} from '@angular/router'

export class ExtraParameter {
  static readonly PARAMETER_NAME_PATTERN = /^\s*p(?<type>[bns])(?<number>[+\-]?[0-9]+)\s*$/i

  private constructor(
    readonly booleanParameters: Readonly<Record<number, boolean>> = {},
    readonly numberParameters: Readonly<Record<number, number>> = {},
    readonly stringParameters: Readonly<Record<number, string>> = {},
  ) {
  }

  static create(data?: ParamMap): [ExtraParameter, string[]] {
    const booleanParameters: Record<number, boolean> = {}
    const numberParameters: Record<number, number> = {}
    const stringParameters: Record<number, string> = {}
    const warnings: string[] = []
    data?.keys.map((parameterKey): [string, RegExpMatchArray] => [parameterKey, parameterKey.match(ExtraParameter.PARAMETER_NAME_PATTERN)])
      .filter(([, match]): boolean => !!(match?.groups?.type && match?.groups?.number))
      .map(([parameterKey, match]): [string, string, number] => {
        const parsedNumber = Number.parseInt(match.groups.number, 10)
        return Number.isNaN(parsedNumber)
        || !Number.isFinite(parsedNumber)
        || parsedNumber.toString(10) !== match.groups.number.replace(/^(?:\+?|(-))(?:0(?!$))+|^\+/, '$1')
          ? undefined
          : [parameterKey, match.groups.type.toLowerCase(), parsedNumber]
      }).filter((value): boolean => typeof value !== 'undefined')
      .forEach(([parameterKey, parameterTyp, parameterNumber]: [string, string, number]): void => {
        const setParameter = <T>(record: Record<number, T>, id: number, value: T): void => {
          if (id in record) {
            warnings.push(`Id '${id}' already exists on object! Skipping!`)
            return
          }
          record[id] = value
        }
        switch (parameterTyp) {
          case 's':
            setParameter(stringParameters, parameterNumber, data.get(parameterKey))
            break
          case 'b':
            setParameter(booleanParameters, parameterNumber, data.get(parameterKey).match(/^\s*f|0|n|false|nein|falsch\s*$/i) === null)
            break
          case 'n':
            const parsedNumber = Number.parseFloat(data.get(parameterKey))
            if (Number.isFinite(parsedNumber) && !Number.isNaN(parsedNumber)) {
              setParameter(numberParameters, parameterNumber, parsedNumber)
            }
            break
          default:
            warnings.push(`The impossible happened! Unknown parameter type '${parameterTyp}'!`)
        }
      })
    return [new ExtraParameter(booleanParameters, numberParameters, stringParameters), warnings]
  }
}
