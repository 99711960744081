import {Component, EventEmitter, Output, ViewChild} from '@angular/core'
import {ConfiguratedDoor} from '../../../classes/model/configuratedDoor'
import {ParameterService} from '../../../classes/service/parameter/parameter.service'
import {MatRadioChange} from '@angular/material/radio'
import {ObjectUtil} from '../../../classes/util/objectUtil'
import {Subscription} from 'rxjs'
import BetterMatRadioGroupDirective, {BetterMatRadioChange} from '../../../directives/better-mat-radio-group.directive'
import {PackageType, Paket} from '../../../classes/model/component/other/paket'
import {ConfiguratorMode} from '../../../types'
import {ModelConfiguration} from '../../../classes/model/modelConfiguration/modelConfiguration'
import {ModelConfigurationService} from '../../../classes/service/modelConfiguration.service'
import * as MaterialEnumModule from '../../../classes/model/material'
import {isMaterial, Material} from '../../../classes/model/material'
import {ConfiguratorModeService} from '../../../classes/service/configuratorMode.service'
import {ComponentSelectionService} from '../../../classes/service/componentSelectionService'
import {ConstructionComponent} from '../../../classes/model/component/construction/constructionComponent'
import * as ConstructionModule from '../../../classes/model/component/other/construction'
import {isSimpleConstruction, SimpleConstruction} from '../../../classes/model/component/other/construction'
import {ConfiguratorConfigurationModel} from '../../../classes/model/configuratorConfigurationModel'
import {ModalService} from '../../modal/modal.service'
import {NavigatorService} from '../../../classes/service/navigation/navigator.service'
import {NavigationMenuEntryKey} from '../../../classes/service/navigation/navigation-menu-entry'
import {EventBusSupport} from '../../../classes/service/event/event-bus-support'
import {EventBusService} from '../../../classes/service/event/event-bus.service'
import {renderRequest, zMassChanged} from '../../../classes/service/event/events'

const TRANSLATION_KEY = {
  TITLE: 'BasisMenuComponent.Title',
  BOX_TITLE_SYSTEM_MATERIAL: 'BasisMenuComponent.Box.Title.SystemMaterial',
  BOX_TITLE_FILLING_MATERIAL: 'BasisMenuComponent.Box.Title.FillingMaterial',
  BOX_TITLE_OPENINGTYPE: 'BasisMenuComponent.Box.Title.OpeningType',
  BOX_TITLE_KOMMISSION: 'BasisMenuComponent.Box.Title.Kommission',
  BOX_TITLE_DIN_SYSTEM: 'BasisMenuComponent.Box.Title.DinSystem',
  BOX_TITLE_DIN_FILLING: 'BasisMenuComponent.Box.Title.DinFilling',
  BOX_TITLE_WIDTH_FILLING: 'BasisMenuComponent.Box.Title.WidthFilling',
  BOX_TITLE_MEASURES: 'BasisMenuComponent.Box.Title.Measures',
  BOX_TITLE_ADHESIVE_SYSTEM: 'BasisMenuComponent.Box.Title.AdhesiveSystem',
  BOX_TITLE_CONSTRUCTION: 'BasisMenuComponent.Box.Title.Construction',
  BOX_TITLE_CONSTRUCTION_VARIANT: 'BasisMenuComponent.Box.Title.ConstructionVariant',
  BOX_TITLE_SHOPASSEMBLY: 'BasisMenuComponent.Box.Title.ShopAssembly',
  BOX_TITLE_MARITIMECLIMATE: 'BasisMenuComponent.Box.Title.MaritimeClimate',
  BOX_TITLE_PACKAGES: 'BasisMenuComponent.Box.Title.Packages',
  BOX_TITLE_HEIGHTDISTRIBUTION: 'BasisMenuComponent.Box.Title.HeightDistribution',
  BOX_TITLE_RAIL: 'BasisMenuComponent.Box.Title.Rail',
  BOX_CONTENT_INPUT_RAIL_LABEL: 'BasisMenuComponent.Box.Content.Rail.Label',
  BOX_CONTENT_INPUT_RAIL_LABEL_SUFFIX: 'BasisMenuComponent.Box.Content.Rail.Label.Suffix',
  BOX_CONTENT_INPUT_HEIGHTDISTRIBUTION_LABEL: 'BasisMenuComponent.Box.Content.HeightDistribution.Label',
  BOX_CONTENT_INPUT_HEIGHTDISTRIBUTION_LABEL_SUFFIX: 'BasisMenuComponent.Box.Content.HeightDistribution.Label.Suffix',
  BOX_CONTENT_DROPDOWN_PACKAGES_SECURITY_LABEL: 'BasisMenuComponent.Box.Content.Security.Label',
  BOX_CONTENT_DROPDOWN_PACKAGES_SECURITY_EMPTY_OPTION: 'BasisMenuComponent.Box.Content.Security.EmptyOption',
  BOX_CONTENT_DROPDOWN_PACKAGES_INSULATION_LABEL: 'BasisMenuComponent.Box.Content.Insulation.Label',
  BOX_CONTENT_DROPDOWN_PACKAGES_INSULATION_EMPTY_OPTION: 'BasisMenuComponent.Box.Content.Insulation.EmptyOption',
  ZVALUEMODAL_MESSAGE: 'BasisMenuComponent.ZValueModal.Message',
  ZVALUEMODAL_TITLE: 'BasisMenuComponent.ZValueModal.Title',
  BOX_CONTENT_CHECKBOX_MARITIMECLIMATE_LABEL: 'BasisMenuComponent.Box.Content.MaritimeClimate.Label',
  BOX_CONTENT_CHECKBOX_SHOPASSEMBLY_LABEL: 'BasisMenuComponent.Box.Content.ShopAssembly.Label',
  BOX_CONTENT_INPUT_CONSTRUCTION_VARIANT_ADHESIONSET: 'BasisMenuComponent.Box.Content.ConstructionVariant.AdhesionSet',
  BOX_CONTENT_INPUT_CONSTRUCTION_VARIANT_CHOOSE_LABEL: 'BasisMenuComponent.Box.Content.ConstructionVariant.Choose.Label',
  BOX_CONTENT_INPUT_CONSTRUCTION_VARIANT_CHOOSE_BUTTON: 'BasisMenuComponent.Box.Content.ConstructionVariant.Choose.Button',
  BOX_CONTENT_INPUT_CONSTRUCTION_VARIANT_CHOOSE_PLACEHOLDER: 'BasisMenuComponent.Box.Content.ConstructionVariant.Choose.Placeholder',
  BOX_CONTENT_CONSTRUCTION_INSERTED: 'BasisMenuComponent.Box.Content.Construction.Inserted',
  BOX_CONTENT_CONSTRUCTION_CONCEALING_SINGLE: 'BasisMenuComponent.Box.Content.Construction.ConsealingSingle',
  BOX_CONTENT_CONSTRUCTION_CONSEALING_BOTH: 'BasisMenuComponent.Box.Content.Construction.ConsealingBoth',
  BOX_CONTENT_DROPDOWN_ADHESIVE_SYSTEM_LABEL: 'BasisMenuComponent.Box.Content.AdhesiveSystem.Label',
  BOX_CONTENT_INPUT_WIDTH_LABEL: 'BasisMenuComponent.Box.Content.Width.Label',
  BOX_CONTENT_INPUT_WIDTH_SUFFIX: 'BasisMenuComponent.Box.Content.Width.Suffix',
  BOX_CONTENT_INPUT_ZVALUE_LABEL: 'BasisMenuComponent.Box.Content.ZValue.Label',
  BOX_CONTENT_INPUT_ZVALUE_SUFFIX: 'BasisMenuComponent.Box.Content.ZValue.Suffix',
  BOX_CONTENT_INPUT_ZVALUE_MIN_PREFIX: 'BasisMenuComponent.Box.Content.ZValue.Min.Prefix',
  BOX_CONTENT_INPUT_ZVALUE_MIN_SUFFIX: 'BasisMenuComponent.Box.Content.ZValue.Min.Suffix',
  BOX_CONTENT_INPUT_ZVALUE_MAX_PREFIX: 'BasisMenuComponent.Box.Content.ZValue.Max.Prefix',
  BOX_CONTENT_INPUT_ZVALUE_MAX_SUFFIX: 'BasisMenuComponent.Box.Content.ZValue.Max.Suffix',
  BOX_CONTENT_INPUT_KOMMISSION_LABEL: 'BasisMenuComponent.Box.Content.Input.Kommission.Label',
  BOX_CONTENT_INPUT_EXTERN_ID_LABEL: 'BasisMenuComponent.Box.Content.Input.ExternId.Label',
  BOX_CONTENT_BUTTON_MATERIAL_KU: 'BasisMenuComponent.Box.Content.Button.Material.Ku',
  BOX_CONTENT_BUTTON_MATERIAL_GLASS: 'BasisMenuComponent.Box.Content.Button.Material.Glas',
  BOX_CONTENT_BUTTON_MATERIAL_ALU: 'BasisMenuComponent.Box.Content.Button.Material.Alu',
  BOX_CONTENT_BUTTON_OPENINGTYPE_INSIDE: 'BasisMenuComponent.Box.Content.Button.OpeningType.Inside',
  BOX_CONTENT_BUTTON_OPENINGTYPE_OUTSIDE: 'BasisMenuComponent.Box.Content.Button.OpeningType.Outside',
  BOX_CONTENT_BUTTON_DIN_LEFT: 'BasisMenuComponent.Box.Content.Button.Din.Left',
  BOX_CONTENT_BUTTON_DIN_RIGHT: 'BasisMenuComponent.Box.Content.Button.Din.Right',
  BOX_CONTENT_BUTTON_SHOW_MEASURES: 'BasisMenuComponent.Box.Content.Button.ShowMeasures'
} as const

@Component({
  selector: 'configurator-basis-menu',
  templateUrl: './basis-menu.component.html',
  styleUrls: ['./basis-menu.component.scss']
})
export class BasisMenuComponent extends EventBusSupport {
  protected readonly Material = (MaterialEnumModule as { Material: Required<typeof Material> }).Material
  protected readonly NavigationMenuEntryKey = NavigationMenuEntryKey
  protected readonly ObjectUtil = ObjectUtil
  protected readonly SimpleConstruction = (ConstructionModule as {
    SimpleConstruction: Required<typeof SimpleConstruction>
  }).SimpleConstruction
  protected readonly TRANSLATION_KEY = TRANSLATION_KEY
  @Output() readonly elementMaterialChange: EventEmitter<BetterMatRadioChange>
  private elementMaterialChangeSubscription: Subscription | undefined

  constructor(
    private componentSelection: ComponentSelectionService,
    private configuratorConfigurationModel: ConfiguratorConfigurationModel,
    private _configuratorModeService: ConfiguratorModeService,
    private modalService: ModalService,
    private _modelConfigurationService: ModelConfigurationService,
    protected readonly navigatorService: NavigatorService,
    private _parameterService: ParameterService,
    eventBus: EventBusService
  ) {
    super(eventBus)
    this.elementMaterialChange = new EventEmitter<BetterMatRadioChange>()
  }

  protected closeMenu(): void {
    this.navigatorService.closeMenu()
  }

  constructionDisabled(konstruktion: number): boolean {
    return this.configuratorMode === ConfiguratorMode.FBS && [0, 1, 2].includes(konstruktion)
  }

  getSicherheitspakete(): Paket[] {
    return this.componentSelection.selectedComponent.model?.getPackages({
      material: this.componentSelection.selectedComponent.material,
      type: PackageType.Security
    })
  }

  getWaermeschutzpakete(): Paket[] {
    return this.componentSelection.selectedComponent.model?.getPackages({
      material: this.componentSelection.selectedComponent.material,
      type: PackageType.ThermalInsulation
    })
  }

  onConstructionChangeRequest(event: MatRadioChange): Promise<void> {
    if (!isSimpleConstruction(event.value)) {
      return
    }
    return this.configuratorConfigurationModel.setConstruction(
      this.selectedComponent,
      event.value
    )
  }

  onSystemMaterialChangeRequest(changeEvent: BetterMatRadioChange): void {
    this.modalService.showConfiguratorResetModal()
      .afterClosed()
      .subscribe((result): void => {
        if (result && isMaterial(changeEvent.value)) {
          this.configuratorConfigurationModel.material = changeEvent.value
        } else {
          changeEvent.cancel()
        }
      })
  }

  protected requestRender(): void {
    this.publish(renderRequest())
  }

  protected showKonstruktionsauswahlModal(): void {
    this.modalService.showKonstruktionsauswahlModal()
  }

  protected showMasseModal(): void {
    this.modalService.showMasseModal('display')
  }

  protected updateOeffnungsart(): void {
    const component = this.selectedComponent
    if (component.oeffnungsart === 'innen') {
      component.dinfuellung = component.din
    } else {
      component.dinfuellung = component.din === 0 ? 1 : 0
    }
    this.requestRender()
  }

  protected updateZMass(): void {
    void this.configuratorConfigurationModel.updateZmassValues()
  }

  zMassChange(zMassValue: string): void {
    const zMass = Number.parseFloat(zMassValue)
    const old = this.selectedComponent.Zmass
    if (!isNaN(zMass) && zMass !== old) {
      this.selectedComponent.Zmass = zMass
      this.publish(zMassChanged({
        component: this.selectedComponent,
        old,
        new: zMass
      }))
    }
  }

  get configuratedDoor(): ConfiguratedDoor {
    return this.configuratorConfigurationModel.configuratedDoor
  }

  get configuratorMode(): ConfiguratorMode {
    return this._configuratorModeService.mode
  }

  @ViewChild('elementMaterialRadio', {read: BetterMatRadioGroupDirective})
  set elementMaterialRadio(elementMaterialRadio: BetterMatRadioGroupDirective<unknown>) {
    this.elementMaterialChangeSubscription?.unsubscribe()
    this.elementMaterialChangeSubscription = elementMaterialRadio?.change.subscribe(this.elementMaterialChange)
  }

  get modelConfiguration(): ModelConfiguration {
    return this._modelConfigurationService.modelConfiguration
  }

  get parameterService(): ParameterService {
    return this._parameterService
  }

  get securityPackage(): Paket {
    return this.configuratorConfigurationModel.securityPackage
  }

  set securityPackage(securityPackage: Paket) {
    this.configuratorConfigurationModel.securityPackage = securityPackage
  }

  get selectedComponent(): ConstructionComponent {
    return this.componentSelection.selectedComponent
  }

  get thermalInsulationPackage(): Paket {
    return this.configuratorConfigurationModel.thermalInsulationPackage
  }

  set thermalInsulationPackage(thermalInsulationPackage: Paket) {
    this.configuratorConfigurationModel.thermalInsulationPackage = thermalInsulationPackage
  }
}
