import {environment as base} from "./environment.base"

export const environment = {
  ...base,
  production: false,
  //API_ENDPOINT: 'https://staging-api-fbs.rodenberg.ag/api/v1',
  API_ENDPOINT_V2: 'https://develop-api-fbs.rodenberg.ag/api/v2',
  //API_REPORTS_ENDPOINT: 'https://api-fbs.rodenberg.ag/reports/v1',
  API_REPORTS_ENDPOINT_V2: 'https://develop-api-fbs.rodenberg.ag/reports/v2',
  //API_RENDERER_ENDPOINT: 'https://api-fbs.rodenberg.ag/api/v1',
  API_RENDERER_ENDPOINT_V2: 'https://develop-api-fbs.rodenberg.ag/api/v2',
  //API_CALCULATE_ENDPOINT: 'https://api-fbs.rodenberg.ag/api/v1',
  API_CALCULATE_ENDPOINT_V2: 'https://develop-api-fbs.rodenberg.ag/api/v2',
  BASE_PROTOKOLL: 'https://',
  BASE_DOMAIN: '.rodenberg.ag/',
  LOGGER: true,
  token: "85e5b585-d313-4391-828a-a9b3b89cdf68",
  MODE: 'FBS',
  SUBDOMAIN: 'develop-fbs'
}

