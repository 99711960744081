const ZUBEHOER_CATEGORY_MAP = {
  ANIMAL_FLAP: 'tierklappen',
  BAR_HANDLE: 'stangengriff',
  DOOR_KNOCKER: 'tuerklopfer',
  FINGERPRINT: 'fingerprint',
  GENERAL: 'zubehoer_allgemein',
  HANDLE: 'griff',
  HANDLE_SHELL: 'griffschale',
  INDOOR_DESIGN: 'indoor_design',
  LEVER_HANDLE_SET: 'drueckergarnitur',
  MAIL_SLOT: 'briefdurchwurf',
  ROSETTE: 'rosette',
  SKIRTING_BOARD: 'sockelleiste',
  SPYHOLE: 'spion',
  STREET_NUMBER: 'hausnummer',
} as const

export const ZUBEHOER_CATEGORY: { [K in keyof typeof ZUBEHOER_CATEGORY_MAP]: ZubehoerCategoryIdentifier } = ZUBEHOER_CATEGORY_MAP

export type ZubehoerCategoryIdentifier = string
export type ZubehoerCategoryName = string
export type ZubehoerCategory = [ZubehoerCategoryIdentifier, ZubehoerCategoryName]
