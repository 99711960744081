import {Component} from '@angular/core'
import {SideType} from '../../../types'
import {StringUtil} from '../../../classes/util/stringUtil'
import {Option} from '../../../classes/model/component/other/option'
import {ComponentSelectionService} from '../../../classes/service/componentSelectionService'
import {ViewService} from '../../../classes/service/view.service'
import {ConstructionComponent} from '../../../classes/model/component/construction/constructionComponent'
import {TranslateService} from '../../../translate'
import {NavigationMenuEntryKey} from '../../../classes/service/navigation/navigation-menu-entry'
import {NavigatorService} from '../../../classes/service/navigation/navigator.service'
import {EventBusSupport} from '../../../classes/service/event/event-bus-support'
import {EventBusService} from '../../../classes/service/event/event-bus.service'
import {optionChanged} from '../../../classes/service/event/events'

const TRANSLATION_KEY = {
  TITLE: 'OptionsMenuComponent.Title',
  MENU_VIEWSWITCH_INSIDE: 'OptionsMenuComponent.MenuViewSwitch.Inside',
  MENU_VIEWSWITCH_OUTSIDE: 'OptionsMenuComponent.MenuViewSwitch.Outside',
  NO_OPTIONS_OUTSIDE: 'OptionsMenuComponent.NoOptionsOutside',
  NO_OPTIONS_INSIDE: 'OptionsMenuComponent.NoOptionsInside',
  NO_OPTIONS_DOOR_MODEL: 'OptionsMenuComponent.NoOptionsForDoorModel',
  NO_OPTIONS_FANLIGHT_MODEL: 'OptionsMenuComponent.NoOptionsForFanlightModel',
  NO_OPTIONS_SIDEPANEL_MODEL: 'OptionsMenuComponent.NoOptionsForSidepanelModel'
}

@Component({
  selector: 'configurator-options-menu',
  templateUrl: './options-menu.component.html',
  styleUrls: ['./options-menu.component.scss']
})
export class OptionsMenuComponent extends EventBusSupport {
  protected readonly NavigationMenuEntryKey = NavigationMenuEntryKey
  protected readonly Option = Option
  protected readonly SideType = SideType
  protected readonly StringUtil = StringUtil
  protected readonly TRANSLATION_KEY = TRANSLATION_KEY

  constructor(
    private readonly navigatorService: NavigatorService,
    private componentSelection: ComponentSelectionService,
    private viewService: ViewService,
    private _translate: TranslateService,
    eventBus: EventBusService
  ) {
    super(eventBus)
  }

  abhaengigeOptionen(option: Option): void {
    // if option is selected
    const select = this.selectedComponent.optionen[this.view].map((o: Option): number => o.Id).includes(option.Id)
    option.AbhaengigeOptionen.forEach((aoId: number): void => {
      if (select && !this.selectedComponent.optionen[this.view].map((o: Option): number => o.Id).includes(aoId)) {
        this.toggleOptionInternal(this.selectedComponent.model.Optionen.find((o: Option): boolean => o.Id === aoId), true)
      } else if (!select && this.selectedComponent.optionen[this.view].map((o: Option): number => o.Id).includes(aoId)) {
        this.toggleOptionInternal(this.selectedComponent.model.Optionen.find((o: Option): boolean => o.Id === aoId), true)
      }
    })
  }

  protected closeMenu(): void {
    this.navigatorService.closeMenu()
  }

  extendedChecks(option: Option): void {
    this.abhaengigeOptionen(option)
    this.kassetteIsSingleHandler(option)
    this.profilierungWithRahmen(option)
  }

  getFittingOptions(options: Option[]): Option[] {
    return options.filter((o): boolean => {
      o.updateVisibility(this.componentSelection.selectedComponent.material)
      return o.suitableForMaterial(this.componentSelection.selectedComponent.material)
    })
  }

  kassetteIsSingleHandler(option: Option): void {
    // kassette darf nicht alleine stehen
    const optionen = this.componentSelection.selectedComponent.model.Optionen.filter(
      (o): boolean => this.view === SideType.Inside ? o.IsInnen : o.IsAussen
    )
    if (option.Gruppe.includes('KASSETTE')) {
      const selectedKassetten = this.componentSelection.selectedComponent.optionen[this.view].filter(
        (op): boolean => op.Gruppe.includes('KASSETTE')
      )
      const selectedZierrahmen = this.componentSelection.selectedComponent.optionen[this.view].filter(
        (op): boolean => op.Bezeichnung.includes('Zierrahmen')
      )
      if (selectedKassetten.length > 0 && selectedZierrahmen.length === 0) {
        // kassette ist da, aber kein zierrahmen - füge ihn hinzu
        this.toggleOptionInternal(this.getFittingOptions(optionen).filter(
          (op): boolean => op.Bezeichnung.includes('Zierrahmen'))[0]
        )
      }
    } else if (option.Bezeichnung.includes('Zierrahmen')) {
      // kassette = this.getFittingOptions(optionen).filter(op => op.Gruppe.includes('KASSETTE'))
      const selectedKassetten = this.componentSelection.selectedComponent.optionen[this.view].filter(
        (op): boolean => op.Gruppe.includes('KASSETTE')
      )
      const selectedZierrahmen = this.componentSelection.selectedComponent.optionen[this.view].filter(
        (op): boolean => op.Bezeichnung.includes('Zierrahmen')
      )
      if (selectedKassetten.length > 0 && selectedZierrahmen.length === 0) {
        // kassette ist da, aber kein zierrahmen - entferne die kassette
        this.toggleOptionInternal(selectedKassetten[0])
      }
    }
  }

  noOptionsForSideText(): string {
    let noOptionsText: string = ''
    switch (this.view) {
      case SideType.Outside:
        noOptionsText = TRANSLATION_KEY.NO_OPTIONS_OUTSIDE
        break
      case SideType.Inside:
        noOptionsText = TRANSLATION_KEY.NO_OPTIONS_INSIDE
        break
    }
    return this._translate.translate(noOptionsText)
  }

  noOptionsText(): string {
    let noOptionsText: string = ''
    switch (this.componentSelection.selectedComponent.objectType as string) {
      case 'tuer':
        noOptionsText = TRANSLATION_KEY.NO_OPTIONS_DOOR_MODEL
        break
      case 'oberlicht':
        noOptionsText = TRANSLATION_KEY.NO_OPTIONS_FANLIGHT_MODEL
        break
      case 'seitenteil':
        noOptionsText = TRANSLATION_KEY.NO_OPTIONS_SIDEPANEL_MODEL
        break
    }
    return this._translate.translate(noOptionsText)
  }


  optionIsSelected(option: Option): boolean {
    return this.selectedComponent.optionen[this.view].map((o: Option): number => o.Id).includes(option.Id)
  }

  optionsAvailable(side: SideType): boolean {
    return this.componentSelection.selectedComponent.model.hasOptions(side)
  }

  profilierungWithRahmen(option: Option): void {
    // profilierung und flachrahmen dürfen nicht gemeinsam gewählt sein
    // check wird nur ausgeführt, wenn die option HINZUGEFÜGT wurde
    if (option.Gruppe.includes('PROFILIERUNG-IN') && this.optionIsSelected(option)) {
      const flachrahmen = this.componentSelection.selectedComponent.optionen[SideType.Inside].filter(
        (op): boolean => op.Gruppe.includes('FLACHRAHMEN')
      )
      if (flachrahmen.length > 0) {
        if (flachrahmen.length > 1) {
          console.warn('es gibt mehr als einen flachrahmen auf der innenseite')
        }
        this.toggleOptionInternal(flachrahmen[0], true)
      }
    } else if (option.Gruppe.includes('FLACHRAHMEN') && this.optionIsSelected(option) && option.IsInnen) {
      const profilierung = this.componentSelection.selectedComponent.optionen[SideType.Inside].filter(
        (op): boolean => op.Gruppe.includes('PROFILIERUNG')
      )
      if (profilierung.length > 0) {
        if (profilierung.length > 1) {
          console.warn('es gibt mehr als eine profilierung auf der innenseite')
        }
        this.toggleOptionInternal(profilierung[0], true)
      }
    }
  }

  toggleOption(option: Option, extendedChecks = false): void {
    this.toggleOptionInternal(option, extendedChecks)
    this.publish(optionChanged())
  }

  toggleOptionInternal(option: Option, extendedChecks = false): void {
    this.componentSelection.selectedComponent.toggleOption(option)
    if (extendedChecks) {
      this.extendedChecks(option)
    }
  }

  get selectedComponent(): ConstructionComponent {
    return this.componentSelection.selectedComponent
  }

  get view(): SideType {
    return this.viewService.currentSide
  }

  set view(side: SideType) {
    this.viewService.view = side
  }
}
