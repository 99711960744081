import {Injectable} from '@angular/core'
import {NavigationMenuEntryKey} from './navigation-menu-entry'
import {ComponentModel} from '../../model/component/model/component-model'
import {ComponentSelectionService} from '../componentSelectionService'
import {SettingsService} from '../settings/settings.service'
import {ConfiguratorModeService} from '../configuratorMode.service'
import {ConfiguratorMode} from '../../../types'
import {MenuClosed} from './menu-closed.type'
import {ActivableNavigationMenu} from './activable-navigation-menu.type'
import {ModalService} from '../../../configurator/modal/modal.service'
import {EventBusSupport} from '../event/event-bus-support'
import {EventBusService} from '../event/event-bus.service'
import {activeMenuChange} from '../event/events'
import {Settings} from '../settings/settings'
import {NGXLogger} from 'ngx-logger'


@Injectable()
export class NavigatorService extends EventBusSupport {
  #configuredMenuEntries: NavigationMenuEntryKey[]
  #currentActiveMenu: ActivableNavigationMenu
  #isNavigationMenuOpen: boolean = false

  constructor(
    private readonly configuratorModeService: ConfiguratorModeService,
    private readonly componentSelectionService: ComponentSelectionService,
    private readonly logger: NGXLogger,
    private readonly modalService: ModalService,
    readonly settingsService: SettingsService,
    eventBusService: EventBusService
  ) {
    super(eventBusService)
    settingsService.settingsChanged.subscribe({
      next: (settings: Settings): void => this.setSettings(settings)
    })
    this.setSettings(settingsService.settings)

    this.#currentActiveMenu = this.#configuredMenuEntries.length > 0
      ? this.#configuredMenuEntries[0]
      : MenuClosed
  }

  activateMenu(menu: ActivableNavigationMenu | BehindLastMenu, focusSearchInputRequest?: boolean): void {
    if (menu !== MenuClosed && !this.canActivateMenu(menu)) {
      return
    }
    if (menu === BehindLastMenu) {
      this.modalService.showKalkulationModal()
      return
    }
    this.#isNavigationMenuOpen = false
    if (this.#currentActiveMenu !== menu) {
      this.#currentActiveMenu = menu
      this.publish(activeMenuChange(menu), {focusSearchInputRequest})
    }
  }

  activateNextMenu(): void {
    const nextMenu = this.findNextActivableMenu()
    if (typeof nextMenu !== 'undefined') {
      this.activateMenu(nextMenu)
    }
  }

  activatePreviousMenu(): void {
    const previousMenu = this.findPreviousActivableMenu()
    if (typeof previousMenu !== 'undefined') {
      this.activateMenu(previousMenu)
    }
  }

  canActivateMenu(menuEntry: NavigationMenuEntryKey | BehindLastMenu): boolean {
    switch (menuEntry) {
      case NavigationMenuEntryKey.BaseShape:
      case NavigationMenuEntryKey.Catalogue:
      case NavigationMenuEntryKey.Characteristics:
      case NavigationMenuEntryKey.Dimension:
      case NavigationMenuEntryKey.Finalize:
      case NavigationMenuEntryKey.HouseFront:
      case NavigationMenuEntryKey.Load:
      case NavigationMenuEntryKey.Model:
        return true
      case NavigationMenuEntryKey.Addon:
      case NavigationMenuEntryKey.Basis:
        return !!this.componentSelectionService?.selectedComponent?.model
      case NavigationMenuEntryKey.Color:
        return areColorsAvailable(this.componentSelectionService?.selectedComponent?.model)
      case NavigationMenuEntryKey.Glass:
        return this.componentSelectionService?.selectedComponent?.model?.isGlassPossible()
      case NavigationMenuEntryKey.Option:
        return this.componentSelectionService?.selectedComponent?.model?.hasOptions()
      case BehindLastMenu:
        return this.configuratorModeService?.mode === ConfiguratorMode.FBS
          && !!this.componentSelectionService?.selectedComponent?.model
    }
  }

  canActivateNextMenu(): boolean {
    return typeof this.findNextActivableMenu() !== 'undefined'
  }

  canActivatePreviousMenu(): boolean {
    return typeof this.findPreviousActivableMenu() !== 'undefined'
  }

  closeMenu(): void {
    this.activateMenu(MenuClosed)
  }

  private findNextActivableMenu(): NavigationMenuEntryKey | BehindLastMenu | undefined {
    if (this.currentActiveMenu === MenuClosed) {
      return undefined
    }
    const currentIndex = this.#configuredMenuEntries.indexOf(this.currentActiveMenu)
    if (currentIndex < 0) {
      return undefined
    }
    for (let i = currentIndex + 1; i < this.#configuredMenuEntries.length; ++i) {
      if (this.canActivateMenu(this.#configuredMenuEntries[i])) {
        return this.#configuredMenuEntries[i]
      }
    }
    if (this.canActivateMenu(BehindLastMenu)) {
      return BehindLastMenu
    }
    return undefined
  }

  private findPreviousActivableMenu(): NavigationMenuEntryKey | undefined {
    if (this.currentActiveMenu === MenuClosed) {
      return undefined
    }
    const currentIndex = this.#configuredMenuEntries.indexOf(this.currentActiveMenu)
    for (let i = currentIndex - 1; i >= 0; --i) {
      if (this.canActivateMenu(this.#configuredMenuEntries[i])) {
        return this.#configuredMenuEntries[i]
      }
    }
    return undefined
  }

  private setSettings(settings: Settings): void {
    this.#configuredMenuEntries = settings.Menue.map((menu): NavigationMenuEntryKey => {
      switch (menu) {
        case 'BASIS':
          return NavigationMenuEntryKey.Basis
        case 'FARBEN':
          return NavigationMenuEntryKey.Color
        case 'FERTIG':
          return NavigationMenuEntryKey.Finalize
        case 'GLAESER':
          return NavigationMenuEntryKey.Glass
        case 'GRUNDFORM':
          return NavigationMenuEntryKey.BaseShape
        case 'HAUSFRONT':
          return NavigationMenuEntryKey.HouseFront
        case 'KATALOG':
          return NavigationMenuEntryKey.Catalogue
        case 'LADEN':
          return NavigationMenuEntryKey.Load
        case 'MASSE':
          return NavigationMenuEntryKey.Dimension
        case 'MEHRPREISE':
          return NavigationMenuEntryKey.Addon
        case 'MODELL':
          return NavigationMenuEntryKey.Model
        case 'OPTIONEN':
          return NavigationMenuEntryKey.Option
      }
      this.logger.warn(`Unknown navigation menu '${menu satisfies never as string}'! This menu will be ignored!'`)
      return undefined
    }).filter((key): boolean => typeof key !== 'undefined')
  }

  public toggleNavigationMenuOpen(): void {
    this.#isNavigationMenuOpen = !this.#isNavigationMenuOpen
  }

  get configuredMenuEntries(): (NavigationMenuEntryKey)[] {
    return this.#configuredMenuEntries
  }

  get currentActiveMenu(): ActivableNavigationMenu {
    return this.#currentActiveMenu
  }

  get isNavigationMenuOpen(): boolean {
    return this.#isNavigationMenuOpen
  }
}

const areColorsAvailable = (model: ComponentModel | undefined): boolean =>
  model?.Farben.length > 0 || model?.Folien.length > 0 || model?.Pulver.length > 0

const BehindLastMenu = Symbol()
type BehindLastMenu = typeof BehindLastMenu
