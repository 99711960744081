import {Lack} from '../model/color/lack'
import {Pulver} from '../model/color/pulver'
import {Folie} from '../model/color/folie'
import {ConstructionComponent} from '../model/component/construction/constructionComponent'
import {
  ColorConfig,
  ColorElementConfig,
  ColorStorage,
  ColorStorageEntry,
  ElementsColorConfig
} from './color-config.type'
import {Tuer} from '../model/component/construction/tuer'
import {SideType, SimpleIdentifier} from '../../types'
import {Seitenteil} from '../model/component/construction/seitenteil'
import {Oberlicht} from '../model/component/construction/oberlicht'
import {Beschichtung} from '../model/color/beschichtung'

export default class ColorRetainApplicator {
  private readonly config: ColorConfig

  constructor(components: ConstructionComponent[]) {
    this.config = {
      Door: components
        .filter(componentFilters.Door)
        .reduce(
          transformativeIndexByTypeReducer(
            (component): ColorElementConfig => ({
              Deckschichten: this.getDeckschichtBeschichtung(component),
              Fluegelrahmen: this.getFluegelrahmenBeschichtung(component)
            })
          ),
          {}
        ),
      Sidepanel: components
        .filter(componentFilters.Sidepanel)
        .reduce(
          transformativeIndexByTypeReducer(
            (component): ColorElementConfig => ({
              Deckschichten: this.merge(
                this.getDeckschichtBeschichtung(component),
                this.getDeckschichtBeschichtung(components.find(componentFilters.Door)) // TODO: first merge with other sidepanels
              ),
              Fluegelrahmen: this.merge(
                this.getFluegelrahmenBeschichtung(component),
                this.getFluegelrahmenBeschichtung(components.find(componentFilters.Door)) // TODO: first merge with other sidepanels
              )
            })
          ),
          {}
        ),
      Fanlight: components
        .filter(componentFilters.Fanlight)
        .reduce(
          transformativeIndexByTypeReducer(
            (component): ColorElementConfig => ({
              Deckschichten: this.merge(
                this.getDeckschichtBeschichtung(component),
                this.getDeckschichtBeschichtung(components.find(componentFilters.Door)) // TODO: first merge with other fanlights
              ),
              Fluegelrahmen: this.merge(
                this.getFluegelrahmenBeschichtung(component),
                this.getFluegelrahmenBeschichtung(components.find(componentFilters.Door)) // TODO: first merge with other fanlights
              )
            })
          ),
          {}
        )
    } as const
  }

  applyColors(component: ConstructionComponent): void {
    if (!component.model) {
      return
    }
    const colors: ColorStorage<Lack | Folie | Pulver, null> = this.getComponentColors(component)
    this.applyColorsToDeckschichten(component, colors)
    this.applyColorsToFluegelrahmen(component, colors)
    this.applyColorsToOptions(component, colors)
  }

  private applyColorsToDeckschichten(
    component: ConstructionComponent,
    colors: ColorStorage<Lack | Folie | Pulver, null>
  ): void {
    [SideType.Inside, SideType.Outside].forEach((side): void => {
      if (!colors.Deckschichten[side]) {
        return
      }
      if (colors.Deckschichten[side]) {
        component.Deckschichten[side]?.forEach(
          ds => void ds.colorize(colors.Deckschichten[side], side, component.material)
        )
      }
    })
  }

  private applyColorsToFluegelrahmen(
    component: ConstructionComponent,
    colors: ColorStorage<Lack | Folie | Pulver, null>
  ): void {
    [SideType.Inside, SideType.Outside].forEach((side): void => {
      if (!colors.Fluegelrahmen[side]) {
        return
      }
      if (colors.Fluegelrahmen[side]) {
        component.Fluegelrahmen[side]?.colorize(colors.Fluegelrahmen[side], side, component.material)
      }
    })
  }

  private applyColorsToOptions(
    component: ConstructionComponent,
    colors: ColorStorage<Lack | Folie | Pulver, null>
  ): void {
    [SideType.Inside, SideType.Outside].forEach((side): void => {
      if (!colors.Deckschichten[side]) {
        return
      }
      component?.optionen?.[side]?.forEach((option): void => {
        const optionConfiguration = component?.model?.Konfiguration?.Components?.[0]?.Optionen?.[side]
          ?.find((op): boolean => op.Id === option.Id)
        if (!option?.BeschichtungAnpassen && optionConfiguration?.HasBeschichtung) {
          // Option color should already be set to the one configured by the model which is correct and does not need
          // to be changed, so we can save the call to
          option.applyConfiguratedColor(component.model, optionConfiguration, side, component.material)
        } else {
          option.colorize(colors.Deckschichten[side], side, component.material)
        }
      })
    })
  }

  private getComponentColors(component: ConstructionComponent): ColorStorage<Lack | Folie | Pulver, null> {
    const componentKey: undefined | keyof typeof this.config =
      componentFilters.Door(component) ? 'Door'
        : componentFilters.Fanlight(component) ? 'Fanlight'
          : componentFilters.Sidepanel(component) ? 'Sidepanel'
            : undefined
    if (!componentKey) {
      return
    }
    const componentsConfig: ElementsColorConfig = this.config[componentKey]
    const componentConfig: ColorElementConfig = componentsConfig[component.IndexByType]
      ?? componentsConfig[parseInt(Object.keys(componentsConfig)[0] ?? '-1', 10)]
    if (!componentConfig) {
      return
    }
    return {
      Deckschichten: {
        Inside: component.model.findObjectFromData<Lack | Folie | Pulver>(componentConfig.Deckschichten.Inside),
        Outside: component.model.findObjectFromData<Lack | Folie | Pulver>(componentConfig.Deckschichten.Outside)
      },
      Fluegelrahmen: {
        Inside: component.model.findObjectFromData<Lack | Folie | Pulver>(componentConfig.Fluegelrahmen.Inside),
        Outside: component.model.findObjectFromData<Lack | Folie | Pulver>(componentConfig.Fluegelrahmen.Outside)
      }
    } as const
  }

  private getDeckschichtBeschichtung(
    component: ConstructionComponent | undefined | null
  ): ColorStorageEntry<SimpleIdentifier, undefined> {
    const extractDeckschichtBeschichtung = (side: SideType): Beschichtung =>
      component?.Deckschichten[side].find((d): boolean => !!d.Beschichtung?.Id)?.Beschichtung
    return [SideType.Inside, SideType.Outside].reduce(
      (acc, side): ColorStorageEntry<SimpleIdentifier, undefined> => {
        acc[side] = extractDeckschichtBeschichtung(side)
        return acc
      },
      {} as ColorStorageEntry<SimpleIdentifier, undefined>
    )
  }

  private getFluegelrahmenBeschichtung(
    component: ConstructionComponent | undefined | null
  ): ColorStorageEntry<SimpleIdentifier, undefined> {
    return [SideType.Inside, SideType.Outside].reduce(
      (acc, side): ColorStorageEntry<SimpleIdentifier, undefined> => {
        acc[side] = component?.Fluegelrahmen[side]?.Beschichtung
        return acc
      },
      {} as ColorStorageEntry<SimpleIdentifier, undefined>
    )
  }

  private merge<T, F extends null | undefined>(
    mainEntry: ColorStorageEntry<T, F>,
    fallbackEntry: ColorStorageEntry<T, F>
  ): F extends T ? never : ColorStorageEntry<T, F> {
    return [SideType.Inside, SideType.Outside].reduce(
      (acc, side): ColorStorageEntry<T, F> => {
        acc[side] = mainEntry[side] ?? fallbackEntry[side]
        return acc
      },
      {} as ColorStorageEntry<T, F>
    ) as F extends T ? never : ColorStorageEntry<T, F>
  }
}
const componentFilters: Record<keyof ColorConfig, (component: ConstructionComponent) => boolean> = {
  Door: (component: ConstructionComponent): component is Tuer => component instanceof Tuer,
  Fanlight: (component: ConstructionComponent): component is Oberlicht => component instanceof Oberlicht,
  Sidepanel: (component: ConstructionComponent): component is Seitenteil => component instanceof Seitenteil
}
const transformativeIndexByTypeReducer =
  <T>(transform: ((component: ConstructionComponent) => T)):
  (acc: Record<number, T>, curr: ConstructionComponent) => Record<number, T> => (
    acc: Record<number, T>,
    curr: ConstructionComponent
  ): Record<number, T> => {
    acc[curr.IndexByType] = transform(curr)
    return acc
  }

