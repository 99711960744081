import {ConfiguratorMode, errorEntry, InsideOutsideArray, InsideOutsideObject, SideType} from '../../types'
import {Tuer, TuerFactory} from './component/construction/tuer'
import {Seitenteil, SeitenteilFactory} from './component/construction/seitenteil'
import {Oberlicht, OberlichtFactory} from './component/construction/oberlicht'
import {ComponentModel} from './component/model/component-model'
import {ConfigurationComponent} from '../configurationComponent'
import {ConfiguratedBlendrahmen, Profile} from '../../class'
import {Blendrahmen} from './component/frame/blendrahmen'
import {Injectable} from '@angular/core'
import {ColorBase} from './color/colorBase'
import {ConstructionComponent} from './component/construction/constructionComponent'
import {Paket} from './component/other/paket'
import {Grundform} from './component/other/grundform'
import {Hausfront} from './component/hausfront/hausfront'
import {ConfiguratorModeService} from '../service/configuratorMode.service'
import {ProfileService} from '../service/profile.service'
import {ConstructionDimensionService} from '../service/construction-dimension.service'
import {Material} from './material'
import {ParameterService} from '../service/parameter/parameter.service'
import {ComponentSelectionService} from '../service/componentSelectionService'
import {ViewService} from '../service/view.service'
import {ConstructionComponentType} from './component/construction/construction-component-type.enum'
import {Price} from './price/price.interface'
import {ResponseValidationMessage} from '../api/validation/response-validation-message.interface'
import {ResponseInsulation} from '../api/insulations/response-insulation.interface'
import {ModelConfigurationService} from '../service/modelConfiguration.service'
import {GrundformService} from '../service/grundform.service'
import {CharacteristicsData} from './dataProvider/characteristics-data/characteristics-data'
import {DinUtil} from '../util/dinUtil'

export class ConfiguratedDoor {
  Aktion: string
  Blendrahmen: InsideOutsideObject<null | Blendrahmen>
  Clerk: string
  Components: ConstructionComponent[] = []
  DeckschichtStaerken: {
    StaerkeAussen: number
    StaerkeInnen: number
  }
  DoorWeight: number
  Errors: ResponseValidationMessage[]
  Grundform: Grundform
  Hausfronten: InsideOutsideObject<Hausfront>
  Images: InsideOutsideObject<string>
  Infos: ResponseValidationMessage[]
  Insulation: ResponseInsulation
  Kommission: string
  MasseOffen: boolean
  MaxZMass: number
  MinZMass: number
  Pos: string
  Prices: Price
  Seeklimatauglich: boolean
  TransKey: string
  Warnings: ResponseValidationMessage[]
  Werksmontage: boolean
  bauform: string
  errors: errorEntry[]
  mode: number
  notes: string
  profile: Profile
  schallschutzPaket: Paket
  sicherheitsPaket: Paket
  staerke: number
  staerken: number[]
  summary: CharacteristicsData
  uploadValuesChanged: boolean
  waermeschutzPaket: Paket

  constructor(
    data: Partial<ConfiguratedDoor>,
    private tuerFactory: TuerFactory,
    private oberlichtFactory: OberlichtFactory,
    private seitenteilFactory: SeitenteilFactory,
    private configuratorModeService: ConfiguratorModeService,
    private coordinateService: ConstructionDimensionService,
    /**
     * @deprecated
     */
    private componentSelection: ComponentSelectionService,
    private parameterService: ParameterService,
    private viewService: ViewService,
    private modelConfigurationService: ModelConfigurationService
  ) {
    // inject(ConfiguratorModeService)
    // inject(ProfileService)
    this.staerken = []
    this.uploadValuesChanged = false
    for (let i = 24; i <= 110; i++) {
      this.staerken.push(i)
    }
    this.bauform = data && data.bauform
    this.profile = data && data.profile && new Profile(data.profile)
    // this.breite = data && data.breite
    // this.hoehe = data && data.hoehe
    // this.customSize = (data && data.customSize) || false
    this.notes = data && data.notes
    this.summary = data?.summary instanceof CharacteristicsData ? data.summary : new CharacteristicsData()
    this.DoorWeight = data && data.DoorWeight
    this.Prices = (data && data.Prices) || {}
    this.waermeschutzPaket = data && data.waermeschutzPaket && new Paket(data.waermeschutzPaket)
    this.sicherheitsPaket = data && data.sicherheitsPaket && new Paket(data.sicherheitsPaket)
    this.schallschutzPaket = data && data.schallschutzPaket && new Paket(data.schallschutzPaket)
    this.Images = data && data.Images
    this.Errors = (data && data.Errors) || []
    this.Warnings = (data && data.Warnings) || []
    this.Infos = (data && data.Infos) || []
    this.Insulation = data && data.Insulation
    this.Clerk = data && data.Clerk
    this.Werksmontage = data && data.Werksmontage
    this.Seeklimatauglich = data && data.Seeklimatauglich
    this.Kommission = data && data.Kommission
    this.MasseOffen = data && data.MasseOffen
    // this.Konstruktion = data && data.Konstruktion
    this.TransKey = data && data.TransKey
    this.Pos = data && data.Pos
    this.Grundform = data && data.Grundform && new Grundform(data.Grundform)
    this.Hausfronten = {
      Inside: null,
      Outside: null
    }
    if (data?.Hausfronten?.Outside) {
      this.Hausfronten.Outside = new Hausfront(data.Hausfronten.Outside)
    }
    if (data?.Hausfronten?.Inside) {
      this.Hausfronten.Inside = new Hausfront(data.Hausfronten.Inside)
    }
    this.DeckschichtStaerken = (
      typeof data?.DeckschichtStaerken?.StaerkeAussen === 'number'
      && typeof data?.DeckschichtStaerken?.StaerkeInnen === 'number'
    ) ? data.DeckschichtStaerken
      : {StaerkeAussen: 0, StaerkeInnen: 0}
    this.Aktion = (data && data.Aktion) ?? ''
    if (data?.Blendrahmen instanceof Array) {
      this.Blendrahmen = {
        Inside: data.Blendrahmen
          .filter((f: Blendrahmen): boolean => f.IsInnen)
          .map((f: Blendrahmen): Blendrahmen => new Blendrahmen(f))
          .find((): boolean => true)
          ?? null,
        Outside: data.Blendrahmen
          .filter((f: Blendrahmen): boolean => f.IsAussen)
          .map((f: Blendrahmen): Blendrahmen => new Blendrahmen(f))
          .find((): boolean => true)
          ?? null
      }
    } else if (data?.Blendrahmen && SideType.Inside in data.Blendrahmen && SideType.Outside in data.Blendrahmen) {
      this.Blendrahmen = data?.Blendrahmen
    } else {
      this.Blendrahmen = {
        Inside: null,
        Outside: null
      }
    }
    data?.Components?.forEach((c): void => {
      switch (c.objectType) {
        case ConstructionComponentType.Door:
          this.Components.push(tuerFactory.create(c))
          break
        case ConstructionComponentType.SidePanel:
          this.Components.push(seitenteilFactory.create(c))
          break
        case ConstructionComponentType.Fanlight:
          this.Components.push(oberlichtFactory.create(c))
          break
        default:
          console.error('unknown Component ObjectType')
      }
    })
  }

  applyBlendrahmenColorFromConfig(blendrahmenConfig: InsideOutsideArray<ConfiguratedBlendrahmen>): void {
    [SideType.Inside, SideType.Outside].forEach((side): void => {
      const blendrahmen = this.Blendrahmen[side]
      const configuratedBlendrahmen = blendrahmenConfig[side].find((b): boolean => b.Id === blendrahmen.Id)
      if (configuratedBlendrahmen?.HasBeschichtung) {
        const color = this.firstElement()?.model?.findObjectFromData(configuratedBlendrahmen.Beschichtung)
        if (color && color instanceof ColorBase) {
          blendrahmen.colorize(color, side, this.profile.Material)
        }
      }
    })
  }

  createComponents(oeffnungsart: 'aussen' | 'innen', material?: Material): void {
    const defaultMaterial = material ?? this.profile.Material
    this.Components = []
    if (this.Grundform?.Key) {
      this.Grundform.Key.split('').forEach((letter, index): void => {
        if (letter === 'T') {
          const door = this.tuerFactory.create()
          door.oeffnungsart = oeffnungsart
          door.material ??= defaultMaterial
          door.breite = this.Hausfronten?.[this.view]?.TuerBreite ?? door.breite
          door.hoehe = this.Hausfronten?.[this.view]?.TuerHoehe ?? door.hoehe
          door.Index = this.Components.length
          door.IndexByType = this.tueren.length
          if (this.configuratorMode === ConfiguratorMode.FBS) {
            door.konstruktion = this.modelConfigurationService.modelConfiguration.konstruktion
            door.din = DinUtil.fromString(this.parameterService.model?.din)
            door.dinfuellung = DinUtil.getDinFuellung(door.oeffnungsart, door.din)
          } else {
            door.din = this.Grundform.dinForNthComponent(index)
            door.dinfuellung = this.Grundform.dinForNthComponent(index)
          }
          this.Components.push(door)
        }
        if (letter === 'S') {
          const sidepanel = this.seitenteilFactory.create()
          sidepanel.breite = this.Hausfronten?.[this.view]?.SeitenteilBreite ?? sidepanel.breite
          sidepanel.hoehe = this.Hausfronten?.[this.view]?.SeitenteilHoehe ?? sidepanel.hoehe
          sidepanel.Index = this.Components.length
          sidepanel.IndexByType = this.seitenteile.length
          sidepanel.material ??= defaultMaterial
          if (this.configuratorMode === ConfiguratorMode.FBS) {
            sidepanel.konstruktion = this.modelConfigurationService.modelConfiguration.konstruktion
          } else {
            sidepanel.din = DinUtil.fromString(this.Grundform.DinElement)
            sidepanel.dinfuellung = this.Grundform.dinForNthComponent(index)
          }
          this.Components.push(sidepanel)
        }
        if (letter === 'O') {
          const skylight = this.oberlichtFactory.create()
          skylight.breite = this.Hausfronten?.[this.view]?.OberlichtBreite ?? skylight.breite
          skylight.hoehe = this.Hausfronten?.[this.view]?.OberlichtHoehe ?? skylight.hoehe
          skylight.Index = this.Components.length
          skylight.IndexByType = this.oberlichter.length
          skylight.material ??= defaultMaterial
          if (this.configuratorMode === ConfiguratorMode.FBS) {
            skylight.konstruktion = this.modelConfigurationService.modelConfiguration.konstruktion
          } else {
            skylight.din = DinUtil.fromString(this.Grundform.DinElement)
            skylight.dinfuellung = this.Grundform.dinForNthComponent(index)
          }
          this.Components.push(skylight)
        }
      })
    } else {
      const door: Tuer = this.tuerFactory.create()
      door.oeffnungsart = oeffnungsart
      door.material ??= defaultMaterial
      this.Components.push(door)
    }
  }

  firstElement(): Tuer | Seitenteil | Oberlicht {
    return this.tueren?.[0] || this.seitenteile?.[0] || this.oberlichter?.[0]
  }

  getDoor(previousGrundform: Grundform): Tuer | undefined {
    let door: Tuer
    if (this.tueren.length > 1) {
      door = this.tueren.find((c: Tuer): boolean =>
        c.shortObjectType === previousGrundform.KeyIntern.split('')?.[c.Index])
    } else {
      door = this.tueren[0]
    }
    return door
  }

  getNumberOfComponentsByType(array: ConstructionComponent[], type: ConstructionComponentType): number {
    return array.filter((c: ConstructionComponent): boolean => c.objectType === type).length
  }

  measuresMatch(hausfront: Hausfront): boolean {
    let valuesMatch: boolean = true
    if (!hausfront) {
      return false
    }
    this.Components.forEach((c: ConstructionComponent): void => {
      if (valuesMatch) {
        let sameHeight: boolean
        let sameWidth: boolean
        switch (c.objectType) {
          case ConstructionComponentType.Door:
            sameHeight = c.hoehe === hausfront.TuerHoehe
            sameWidth = c.breite === hausfront.TuerBreite
            break
          case ConstructionComponentType.SidePanel:
            sameHeight = c.hoehe === hausfront.SeitenteilHoehe
            sameWidth = c.breite === hausfront.SeitenteilBreite
            break
          case ConstructionComponentType.Fanlight:
            sameHeight = c.hoehe === hausfront.OberlichtHoehe
            sameWidth = c.breite === hausfront.OberlichtBreite
            break
        }
        valuesMatch = sameHeight && sameWidth
      }
    })
    return valuesMatch
  }

  onlyOneDoorPresent(): boolean {
    return this.tueren.length === 1 &&
      this.seitenteile.length === 0 &&
      this.oberlichter.length === 0
  }

  setBlendrahmenFromConfig(model: ComponentModel): void {
    const blendrahmenInsideData = model.Konfiguration?.Blendrahmen
      ? model.Blendrahmen.find((f: Blendrahmen): boolean => f.IsInnen && f.Id === model.Konfiguration.Blendrahmen.Inside?.[0]?.Id) ?? null
      : model.Blendrahmen.find((f: Blendrahmen): boolean => f.IsInnen)
    const blendrahmenOutsideData = model.Konfiguration?.Blendrahmen
      ? model.Blendrahmen.find(
        (f): boolean => f.IsAussen && f.Id === model.Konfiguration.Blendrahmen.Outside?.[0]?.Id
      ) ?? null
      : model.Blendrahmen.find((f): boolean => f.IsAussen)
    const blendrahmenBeschichtung = {
      Inside: this.Blendrahmen.Inside?.Beschichtung,
      Outside: this.Blendrahmen.Outside?.Beschichtung
    }
    this.Blendrahmen = {
      Inside: new Blendrahmen(blendrahmenInsideData),
      Outside: new Blendrahmen(blendrahmenOutsideData)
    }
    if (model.Konfiguration.Blendrahmen && !blendrahmenBeschichtung.Inside && !blendrahmenBeschichtung.Outside) {
      this.applyBlendrahmenColorFromConfig(model.Konfiguration.Blendrahmen)
    }
    if (blendrahmenBeschichtung.Inside) {
      this.Blendrahmen.Inside.Beschichtung = blendrahmenBeschichtung.Inside
    }
    if (blendrahmenBeschichtung.Outside) {
      this.Blendrahmen.Outside.Beschichtung = blendrahmenBeschichtung.Outside
    }
  }

  setDefaultSizes(hausfront: Hausfront): void {
    this.Components.forEach((c: ConstructionComponent): void => {
      switch (c.objectType) {
        case ConstructionComponentType.Door:
          c.hoehe = hausfront.TuerHoehe
          c.breite = hausfront.TuerBreite
          break
        case ConstructionComponentType.SidePanel:
          c.hoehe = hausfront.SeitenteilHoehe
          c.breite = hausfront.SeitenteilBreite
          break
        case ConstructionComponentType.Fanlight:
          c.hoehe = hausfront.OberlichtHoehe
          c.breite = hausfront.OberlichtBreite
          break
      }
    })
  }

  setPaketeFromConfigurationComponent(config: ConfigurationComponent, paketStorage: Paket[]): void {
    this.schallschutzPaket = config.resolveSchallschutz(paketStorage)
    this.sicherheitsPaket = config.resolveSicherheit(paketStorage)
    this.waermeschutzPaket = config.resolveWaermeschutz(paketStorage)
  }

  /**
   * adds or removes Components dependent on the newly selected Grundform
   * **/
  updateComponents(previous: Grundform, current: Grundform): void {
    const DEFAULT_SIDEPANEL_WIDTH = 500
    const DEFAULT_FANLIGHT_HEIGHT = 500
    const DEFAULT_DOOR_WIDTH = 1000
    const DEFAULT_DOOR_HEIGHT = 2000
    const newComponents: ConstructionComponent[] = []
    const hausfrontFromCurrentGrundform = current?.Hausfronten
      ?.find((h: Hausfront): boolean => h.Bezeichnung === this.Hausfronten?.[this.view]?.Bezeichnung)
    const keys: [string[], string[], string[]] = [
      current.Key.split(''),
      current.KeyIntern.split(''),
      current.DinComponenten.split('')
    ]
    keys[0].map((_, idx): [string, string, string] => keys.map((k): string => k[idx]) as [string, string, string])
      .forEach(([letter, keyIntern, dinComponent]: [string, string, string]): void => {
        let component: ConstructionComponent
        switch (letter) {
          case 'T':
            component = this.tuerFactory.create(
              this.getDoor(previous)
              ?? newComponents.find((c): boolean => c.objectType === ConstructionComponentType.Door)
            )
            component.breite = hausfrontFromCurrentGrundform?.TuerBreite ?? DEFAULT_DOOR_WIDTH
            component.hoehe = hausfrontFromCurrentGrundform?.TuerHoehe ?? DEFAULT_DOOR_HEIGHT
            break
          case 'S':
            component = this.seitenteilFactory.create(
              this.seitenteile[0] ?? newComponents.find(
                (c): boolean => c.objectType === ConstructionComponentType.SidePanel
              )
            )
            component.breite = hausfrontFromCurrentGrundform?.SeitenteilBreite ?? DEFAULT_SIDEPANEL_WIDTH
            component.hoehe = hausfrontFromCurrentGrundform?.SeitenteilHoehe ?? DEFAULT_DOOR_HEIGHT
            break
          case 'O':
            component = this.oberlichtFactory.create(
              this.oberlichter[0] ?? newComponents.find(
                (c): boolean => c.objectType === ConstructionComponentType.Fanlight
              )
            )
            component.breite =
              hausfrontFromCurrentGrundform?.OberlichtBreite && hausfrontFromCurrentGrundform?.OberlichtBreite > 0
                ? hausfrontFromCurrentGrundform?.OberlichtBreite
                : DEFAULT_SIDEPANEL_WIDTH
            component.hoehe =
              hausfrontFromCurrentGrundform?.OberlichtHoehe && hausfrontFromCurrentGrundform?.OberlichtHoehe > 0
                ? hausfrontFromCurrentGrundform?.OberlichtHoehe
                : DEFAULT_FANLIGHT_HEIGHT
            break
          default:
            return
        }
        // TODO: Remove Fallback for incomplete Hausfronten
        if (!hausfrontFromCurrentGrundform?.OberlichtBreite) {
          const fanlightWidth: number = newComponents.filter(
            (c: ConstructionComponent): boolean =>
              c.objectType === ConstructionComponentType.Door || c.objectType === ConstructionComponentType.SidePanel
          ).reduce((widthSummed: number, object: ConstructionComponent): number => widthSummed + object.breite, 0)
          newComponents.filter((c: ConstructionComponent): boolean => c.objectType === ConstructionComponentType.Fanlight)
            .forEach((o: ConstructionComponent): void => {
              o.breite = fanlightWidth
            })
        }
        if (component.shortObjectType !== keyIntern) {
          component.clearMehrpreise()
        }
        component.Index = newComponents.length
        component.IndexByType = this.getNumberOfComponentsByType(newComponents, component.objectType)
        component.din = DinUtil.fromString(dinComponent)
        component.dinfuellung = DinUtil.fromString(dinComponent)
        newComponents.push(component)
      })
    this.Components = newComponents
  }

  updateHeights(): void {
    if (!this.customSize) {
      // this.messageToasty('Maßangaben wurden geändert', 'Der Hintergrund wurde entfernt, da die Maße geändert wurden.', "info");
      // this.customSize = true
    }
    // calculate new height
    let newHeight = 0
    this.oberlichter.map((o): void => {
      newHeight += o.hoehe
    })
    // as all doors now have the same height - only one height is needed
    newHeight += this.firstElement().hoehe
    // set new width
    if (this.hasCustomHausfront) {
      this.Hausfronten[this.view].ElementHoehe = newHeight
    }
  }

  updateKonstruktionsMasse(): void {
    this.Components.forEach((component: ConstructionComponent): void => {
      component.KonstruktionsMasse = this.coordinateService.calculateConstructionDimensions(component)
    })
  }

  updateSiblingHeigths(element: Tuer | Seitenteil): void {
    if (element.objectType === ConstructionComponentType.Door) {
      this.seitenteile.forEach((s): void => {
        s.hoehe = element.hoehe
      })
      this.tueren.forEach((t): void => {
        if (t !== element) {
          t.hoehe = element.hoehe
        }
      })
    } else if (element.objectType === ConstructionComponentType.SidePanel) {
      this.tueren.forEach((t): void => {
        t.hoehe = element.hoehe
      })
    }
  }

  updateWidths(): void {
    // calculate new width
    let newWidth = 0
    this.seitenteile.map((st): void => {
      newWidth += st.breite
    })
    this.tueren.map((t): void => {
      newWidth += t.breite
    })
    // set new width
    // this.configuratedDoor.breite = newWidth
    if (this.hasCustomHausfront) {
      this.Hausfronten[this.view].ElementBreite = newWidth
    }
    // set oberlichter widths
    this.oberlichter.map((o): void => {
      o.breite = newWidth
    })
  }

  get configuratorMode(): ConfiguratorMode {
    return this.configuratorModeService.mode
  }

  get customSize(): boolean {
    const measuresMatchInside = this.measuresMatch(this.Hausfronten?.Inside)
    const measuresMatchOutside = this.measuresMatch(this.Hausfronten?.Outside)
    return !(measuresMatchInside && measuresMatchOutside)
  }

  get hasCustomHausfront(): boolean {
    return this.Hausfronten && (this.Hausfronten[SideType.Outside]?.Custom || this.Hausfronten[SideType.Inside]?.Custom)
  }

  get height(): number {
    return (this.tueren[0]?.hoehe ?? this.seitenteile[0]?.hoehe ?? 0)
      + (this.oberlichter[0]?.hoehe ?? 0)
  }

  get heightString(): string {
    return this.height.toString()
  }

  get oberlichter(): Oberlicht[] {
    return this.Components.filter((t): boolean => t.objectType === ConstructionComponentType.Fanlight)
  }

  get seitenteile(): Seitenteil[] {
    return this.Components.filter((t): boolean => t.objectType === ConstructionComponentType.SidePanel)
  }

  /**
   * @deprecated
   */
  get selectedComponent(): ConstructionComponent {
    return this.componentSelection.selectedComponent
  }

  get tueren(): Tuer[] {
    return this.Components.filter((t): boolean => t.objectType === ConstructionComponentType.Door)
  }

  get view(): SideType {
    return this.viewService.currentSide
  }

  get width(): number {
    return [...this.tueren, ...this.seitenteile].reduce((acc, curr): number => acc + curr.breite, 0)
  }

  get widthString(): string {
    return this.width.toString()
  }
}

@Injectable()
export class ConfiguratedDoorFactory {
  constructor(
    private tuerFactory: TuerFactory,
    private oberlichtFactory: OberlichtFactory,
    private seitenteilFactory: SeitenteilFactory,
    private configuratorModeService: ConfiguratorModeService,
    private profileService: ProfileService,
    private coordinateService: ConstructionDimensionService,
    private componentSelection: ComponentSelectionService,
    private parameterService: ParameterService,
    private viewService: ViewService,
    private modelConfigurationService: ModelConfigurationService,
    private grundformService: GrundformService
  ) {
  }

  create(data?: ConstructorParameters<typeof ConfiguratedDoor>[0]): ConfiguratedDoor {
    data ??= {}
    data.bauform = 'T'
    data.Clerk ??= this.parameterService.parameter.clerk
    data.Kommission ??= this.parameterService.parameter.kommission
    data.Hausfronten ??= {
      Inside: null,
      Outside: null
    }
    data.Grundform = this.grundformService.grundform
    if (this.configuratorModeService.mode === ConfiguratorMode.TTK) {
      data.Hausfronten.Outside ??= data.Grundform?.Hausfronten?.find((h: Hausfront): boolean => h.IsAussen)
      data.Hausfronten.Inside ??= data.Grundform?.Hausfronten?.find((h: Hausfront): boolean => h.IsInnen)
    }
    data.profile ??= new Profile(this.profileService.getDefault())
    if (this.configuratorModeService.mode === ConfiguratorMode.FBS) {
      data.profile.Beschreibung = this.parameterService.parameter.profil ?? data.profile.Beschreibung
      data.profile.Material = this.modelConfigurationService.modelConfiguration?.material ?? data.profile.Material
    }
    return new ConfiguratedDoor(
      data,
      this.tuerFactory,
      this.oberlichtFactory,
      this.seitenteilFactory,
      this.configuratorModeService,
      this.coordinateService,
      this.componentSelection,
      this.parameterService,
      this.viewService,
      this.modelConfigurationService
    )
  }
}


