// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.
export const environment = {
  MODE: 'meh',
  DEFAULT_DOOR_MODEL_ID: 1433291, // 1433291 => 6508-50 Modern
  DEFAULT_SIDEPANEL_MODEL_ID: 866,
  DEFAULT_FANLIGHT_MODEL_ID: 1551,
  version: '2024.12.12.3'
}
