import {GlasaufbauPositionShort, SideType} from '../../../../types'
import {FbsGlas, Motiv} from '../../../../class'
import {Sprossen} from './sprossen'
import {Inject, Injectable, Injector} from '@angular/core'
import {ComponentModel} from '../model/component-model'
import {ConfiguratorConfigurationModel} from '../../configuratorConfigurationModel'
import {ConfiguratedDoor} from '../../configuratedDoor'
import {ToastrService} from 'ngx-toastr'
import {PackageType, Paket} from '../other/paket'
import {ComponentSelectionService} from '../../../service/componentSelectionService'
import {ConstructionComponent} from '../construction/constructionComponent'
import {GLASS_PROVIDER_TOKEN, GlassProvider} from '../../dataProvider/glass/glass.type'
import {Material} from '../../material'
import {
  ModelConfigurationGlasaufbau
} from '../../../api/model/configuration/glass/model-configuration-glasaufbau.interface'
import {ColorBase} from '../../color/colorBase'
import {Folie} from '../../color/folie'
import {Pulver} from '../../color/pulver'
import {Lack} from '../../color/lack'
import {
  ModelConfigurationSubassembly
} from '../../../api/model/configuration/addons/model-configuration-subassembly.interface'
import {TranslateService} from '../../../../translate'
import {NGXLogger} from 'ngx-logger'
import {
  SpacerThicknessError,
  SpacerThicknessExceededError,
  SpacerThicknessUndercutError
} from './spacer-thickness.error'

const safetyGlass: { [key: string]: string } = {
  SI1: '003200',
  SI2: '066204',
  SI3: '066205'
}

export class Glasaufbau {
  #configuratorConfigurationModel: ConfiguratorConfigurationModel
  GlasscheibeAussen: FbsGlas | null
  GlasscheibeInnen: FbsGlas | null
  GlasscheibeMitte1: FbsGlas | null
  GlasscheibeMitte2: FbsGlas | null
  Id: number
  IsStandard: boolean
  MaxStaerkeSZR: number
  MinAnzahlGlasscheiben: number
  MinStaerkeSZR: number
  MotivAussen: Motiv | null
  MotivInnen: Motiv | null
  MotivMitte1: Motiv | null
  MotivMitte2: Motiv | null
  Sprossen: Sprossen | null
  _errors: SpacerThicknessError[]

  constructor(
    data: ModelConfigurationGlasaufbau | Glasaufbau,
    private motivs: Motiv[],
    private sprossen: Sprossen[],
    private _toastrService: ToastrService,
    private _translateService: TranslateService,
    private injector: Injector,
    private glassProvider: GlassProvider,
    private componentSelection: ComponentSelectionService,
    private logger: NGXLogger,
  ) {
    this.GlasscheibeAussen = glassProvider.findGlass(data?.GlasscheibeAussen?.Id ?? 0) ?? null
    this.GlasscheibeInnen = glassProvider.findGlass(data?.GlasscheibeInnen?.Id ?? 0) ?? null
    this.GlasscheibeMitte1 = glassProvider.findGlass(data?.GlasscheibeMitte1?.Id ?? 0) ?? null
    this.GlasscheibeMitte2 = glassProvider.findGlass(data?.GlasscheibeMitte2?.Id ?? 0) ?? null
    this.Id = data && data.Id
    this.IsStandard = data && data.IsStandard
    if (data instanceof Glasaufbau) {
      this.MotivAussen = motivs.find(findById(data.MotivAussen?.Id ?? 0)) ?? null
      this.MotivInnen = motivs.find(findById(data.MotivInnen?.Id ?? 0)) ?? null
      this.MotivMitte1 = motivs.find(findById(data.MotivMitte1?.Id ?? 0)) ?? null
      this.MotivMitte2 = motivs.find(findById(data.MotivMitte2?.Id ?? 0)) ?? null
    } else {
      this.MotivAussen = motivs.find(findById(data?.GlasscheibeAussen?.Motiv?.Id ?? 0)) ?? null
      this.MotivInnen = motivs.find(findById(data?.GlasscheibeInnen?.Motiv?.Id ?? 0)) ?? null
      this.MotivMitte1 = motivs.find(findById(data?.GlasscheibeMitte1?.Motiv?.Id ?? 0)) ?? null
      this.MotivMitte2 = motivs.find(findById(data?.GlasscheibeMitte2?.Motiv?.Id ?? 0)) ?? null
    }
    this.MinAnzahlGlasscheiben = data && data.MinAnzahlGlasscheiben
    this.MaxStaerkeSZR = data && data.MaxStaerkeSZR
    this.MinStaerkeSZR = data && data.MinStaerkeSZR
    this._errors = []
    this.updateSprossen(data?.Sprossen)
    this.correctGlassaufbauAndUpdatePackets()
  }

  calcGlasStaerke(): number {
    if (!this.componentSelection.selectedComponent?.staerke) {
      return null
    }
    if (!this.Id) {
      return null
    }
    let maxThickness = this.componentSelection.selectedComponent.staerke
    // bei Kunststoff und Metallfüllungen pauschal 6mm abziehen
    if ([Material.Alu, Material.Kunststoff].includes(this.componentSelection.selectedComponent.material)) {
      maxThickness -= 6
    }
    // adding 1mm to the min and max ranges
    let calculatedThickness = 0
    let numOfSpacers = 0
    if (this.GlasscheibeAussen) {
      calculatedThickness += this.GlasscheibeAussen.Staerke
      numOfSpacers += 1
    }
    if (this.GlasscheibeMitte1) {
      calculatedThickness += this.GlasscheibeMitte1.Staerke
      numOfSpacers += 1
    }
    if (this.GlasscheibeMitte2) {
      calculatedThickness += this.GlasscheibeMitte2.Staerke
      numOfSpacers += 1
    }
    if (this.GlasscheibeInnen) {
      calculatedThickness += this.GlasscheibeInnen.Staerke
    }
    const spacerThicknessMax = (((maxThickness + 1) - calculatedThickness) / numOfSpacers)
    const spacerThicknessMin = (((maxThickness - 1) - calculatedThickness) / numOfSpacers)

    this._errors = this._errors.filter((e): boolean =>
      !(e instanceof SpacerThicknessUndercutError || e instanceof SpacerThicknessExceededError)
    )
    if (spacerThicknessMax < this.MinStaerkeSZR) {
      const neededMinThickness = (this.MinStaerkeSZR * numOfSpacers + calculatedThickness) - 1
        + ([Material.Alu, Material.Kunststoff].includes(this.componentSelection.selectedComponent.material) ? 6 : 0)
      this._errors.push(new SpacerThicknessUndercutError(neededMinThickness))
    }
    if (spacerThicknessMin > this.MaxStaerkeSZR) {
      const neededMaxThickness = (this.MaxStaerkeSZR * numOfSpacers + calculatedThickness) + 1
        + ([Material.Alu, Material.Kunststoff].includes(this.componentSelection.selectedComponent.material) ? 6 : 0)
      this._errors.push(new SpacerThicknessExceededError(neededMaxThickness))
    }
    return calculatedThickness
  }

  private correctGlassaufbauAndUpdatePackets(): void {
    for (let i = this.MinAnzahlGlasscheiben - this.numGlasses; i > 0; --i) {
      if (!this.GlasscheibeAussen) {
        this.GlasscheibeAussen = this.glassProvider.getDefaultGlass()
      } else if (!this.GlasscheibeInnen) {
        this.GlasscheibeInnen = this.glassProvider.getDefaultGlass()
      } else if (!this.GlasscheibeMitte1) {
        this.GlasscheibeMitte1 = this.glassProvider.getDefaultMiddleGlass() ?? this.glassProvider.getDefaultGlass()
      } else if (!this.GlasscheibeMitte2) {
        this.GlasscheibeMitte2 = this.glassProvider.getDefaultMiddleGlass() ?? this.glassProvider.getDefaultGlass()
      } else {
        this.logger.trace('Unable to fulfill required number of glasses')
        break
      }
    }
    if (
      !this.GlasscheibeAussen
      || this.MotivAussen && !this.grundglasChangePossible(this.MotivAussen, this.GlasscheibeAussen)
    ) {
      this.unsetMotiv('aussen')
    }
    if (
      !this.GlasscheibeInnen
      || this.MotivInnen && !this.grundglasChangePossible(this.MotivInnen, this.GlasscheibeInnen)
    ) {
      this.unsetMotiv('innen')
    }
    if (
      !this.GlasscheibeMitte1
      || this.MotivMitte1 && !this.grundglasChangePossible(this.MotivMitte1, this.GlasscheibeMitte1)
    ) {
      this.unsetMotiv('mitte1')
    }
    if (
      !this.GlasscheibeMitte2
      || this.MotivMitte2 && !this.grundglasChangePossible(this.MotivMitte2, this.GlasscheibeMitte2)
    ) {
      this.unsetMotiv('mitte2')
    }
    this.calcGlasStaerke()
    this.updateWSPaket()
    // Änderung PK => Sicherheitspaket soll nicht durch den Glasaufbau geupdatet werden
    // this.updateSafetyPacket()
  }

  getGlasByPosition(position: GlasaufbauPositionShort): FbsGlas | null {
    switch (position) {
      case 'aussen':
        return this.GlasscheibeAussen
      case 'mitte1':
        return this.GlasscheibeMitte1
      case 'mitte2':
        return this.GlasscheibeMitte2
      case 'innen':
        return this.GlasscheibeInnen
    }
    return null
  }

  getMotivByPosition(position: GlasaufbauPositionShort): Motiv | null {
    switch (position) {
      case 'aussen':
        return this.MotivAussen
      case 'mitte1':
        return this.MotivMitte1
      case 'mitte2':
        return this.MotivMitte2
      case 'innen':
        return this.MotivInnen
    }
    return null
  }

  grundglasChangePossible(motiv: Motiv, glass: FbsGlas): boolean {
    // remove Motiv if Grundglas not variabel AND GlassId is not GrundglassId
    if (!motiv.IsGrundglasVariabel && motiv.GrundglasId !== glass.Id) {
      this.toastrService.info(
        'Das zuvor gewählte Motiv unterstützt das gewählte Glas nicht und wurde daher entfernt.',
        'Grundglas geändert'
      )
      return false
    }
    return true
  }

  motivPossible(position: GlasaufbauPositionShort, motiv?: Motiv | null): boolean {
    const glas = this.getGlasByPosition(position)
    return motiv === null || (!glas?.IsWSG && (!motiv || motiv.IsGrundglasVariabel || motiv.GrundglasId === glas?.Id))
  }

  nonThermalInsulationVariant(glass: FbsGlas, position?: GlasaufbauPositionShort): FbsGlas {
    if (!glass.IsWSG) {
      return glass
    }
    const positionPredicate = this.positionToPositionPossiblePropertyName(position)
    const rodenbergCode = glass.RodenbergCode.replace('B', '')
    return this.glassProvider.getAllGlasses().find(
      (g): boolean => g.RodenbergCode === rodenbergCode && (!positionPredicate || g[positionPredicate])
    ) ?? this.glassProvider.getAllGlasses().find((g): boolean => g.RodenbergCode === '001000')
      ?? this.glassProvider.getAllGlasses().find((g): boolean => !g.IsWSG)
      ?? this.glassProvider.getDefaultGlass()
  }

  positionToPositionPossiblePropertyName(
    position?: GlasaufbauPositionShort
  ): 'IsMitteMoeglich' | 'IsAussenMoeglich' | 'IsInnenMoeglich' {
    switch (position) {
      case 'aussen':
        return 'IsAussenMoeglich'
      case 'mitte1':
      case 'mitte2':
        return 'IsMitteMoeglich'
      case 'innen':
        return 'IsInnenMoeglich'
    }
  }

  setGlas(glassPosition: GlasaufbauPositionShort, glass: Partial<FbsGlas>): boolean {
    if (glass.Id > 0) {
      const glassClone: FbsGlas = new FbsGlas(glass)
      switch (glassPosition) {
        case 'aussen':
          if (glassClone.IsAussenMoeglich && this.GlasscheibeAussen?.Id !== glassClone.Id) {
            this.GlasscheibeAussen = glassClone
          } else {
            return false
          }
          break
        case 'mitte1':
          if (glassClone.IsMitteMoeglich && this.GlasscheibeMitte1?.Id !== glassClone.Id) {
            this.GlasscheibeMitte1 = glassClone
          } else {
            return false
          }
          break
        case 'mitte2':
          if (glassClone.IsMitteMoeglich && this.GlasscheibeMitte2?.Id !== glassClone.Id) {
            this.GlasscheibeMitte2 = glassClone
          } else {
            return false
          }
          break
        case 'innen':
          if (glassClone.IsInnenMoeglich && this.GlasscheibeInnen?.Id !== glassClone.Id) {
            this.GlasscheibeInnen = glassClone
          } else {
            return false
          }
          break
        default:
          console.error('Could not define which glassname to set')
          return
      }
    } else {
      this.unsetGlas(glassPosition)
      this.unsetMotiv(glassPosition)
    }

    if (!this.motivPossible(glassPosition, this.getMotivByPosition(glassPosition))) {
      this.unsetMotiv(glassPosition)
      this.toastrService.info(
        'Das Motiv an der gesetzten Stelle wurde entfernt, da es mit dem Glas nicht kompatibel ist.',
        'Motiv entfernt'
      )
    }


    this.correctGlassaufbauAndUpdatePackets()
    return true
  }

  setMotiv(motivPosition: GlasaufbauPositionShort, motiv: Motiv): boolean {

    if (motiv.Id > 0) {
      const motivClone: Motiv = new Motiv(motiv)
      const grundglas = this.glassProvider.findGlass(motivClone.GrundglasId)
      switch (motivPosition) {
        case 'aussen':
          if (motivClone.IsAussenMoeglich && this.MotivAussen?.Id !== motivClone.Id) {
            this.MotivAussen = motivClone
          } else {
            return false
          }
          if (grundglas && (!motivClone.IsGrundglasVariabel || !this.GlasscheibeAussen)) {
            this.setGlas('aussen', grundglas)
          }
          break
        case 'innen':
          if (motivClone.IsInnenMoeglich && this.MotivInnen?.Id !== motivClone.Id) {
            this.MotivInnen = motivClone
          } else {
            return false
          }
          if (grundglas && (!motivClone.IsGrundglasVariabel || !this.GlasscheibeInnen)) {
            this.setGlas('innen', grundglas)
          }
          break
        case 'mitte1':
          if (motivClone.IsMitteMoeglich && this.MotivMitte1?.Id !== motivClone.Id) {
            this.MotivMitte1 = motivClone
          } else {
            return false
          }
          if (grundglas && (!motivClone.IsGrundglasVariabel || !this.GlasscheibeMitte1)) {
            this.setGlas('mitte1', grundglas)
          }
          break
        case 'mitte2':
          if (motivClone.IsMitteMoeglich && this.MotivMitte2?.Id !== motivClone.Id) {
            this.MotivMitte2 = motivClone
          } else {
            return false
          }
          if (grundglas && (!motivClone.IsGrundglasVariabel || !this.GlasscheibeMitte2)) {
            this.setGlas('mitte2', grundglas)
          }
          break
        default:
          console.error('Could not define which motivname to set')
      }
    } else {
      this.unsetMotiv(motivPosition)
    }
    return true
  }

  setSicherheitsGlaeser(): void {
    const paket = this.configuratedDoor.sicherheitsPaket
    if (this.componentSelection.selectedComponent.model.isGlassPossible()) {
      if (!paket) {
        const glasCold = this.glassProvider.getDefaultGlass()
        if (glasCold) {
          this.setGlas('aussen', glasCold)
        }
      } else {
        let rodenbergCode = safetyGlass[paket.Key]
        if (rodenbergCode && this.GlasscheibeAussen?.IsWSG) {
          rodenbergCode += 'B'
        }
        const glas = this.glassProvider.getAllGlasses()
          .find((g): boolean => g.RodenbergCode === rodenbergCode && g.IsAussenMoeglich)
        if (glas) {
          this.setGlas('aussen', glas)
        }
      }
    }
    this.correctGlassaufbauAndUpdatePackets()
  }

  setWaermeschutzGlaeser(): void {
    const paket = this.configuratedDoor.waermeschutzPaket
    if (this.componentSelection.selectedComponent.model.isGlassPossible()) {
      if (!paket) {
        const glassOutside = this.nonThermalInsulationVariant(this.GlasscheibeAussen, 'aussen')
        if (glassOutside) {
          this.setGlas('aussen', glassOutside)
        }
        const glassInside = this.thermalInsulationVariant(this.GlasscheibeInnen, 'innen')
        if (glassInside) {
          this.setGlas('innen', glassInside)
        }
        this.unsetGlas('mitte1')
        this.unsetGlas('mitte2')
      } else if (paket.Key === 'WS9') {
        // 3fach 1x beschichtet
        const glassOutside = this.nonThermalInsulationVariant(this.GlasscheibeAussen, 'aussen')
        if (glassOutside) {
          this.setGlas('aussen', glassOutside)
        }
        const glassInside = this.thermalInsulationVariant(this.GlasscheibeInnen, 'innen')
        if (glassInside) {
          this.setGlas('innen', glassInside)
        }
        const glassMiddle = this.nonThermalInsulationVariant(
          this.GlasscheibeMitte1
          ?? this.glassProvider.getDefaultMiddleGlass()
          ?? this.glassProvider.getDefaultGlass(),
          'mitte1'
        )
        if (glassMiddle) {
          this.setGlas('mitte1', glassMiddle)
        }
        this.unsetGlas('mitte2')
      } else if (paket.Key === 'WS7' || paket.Key === 'WS5') {
        const glassOutside = this.thermalInsulationVariant(this.GlasscheibeAussen, 'aussen')
        if (glassOutside) {
          this.setGlas('aussen', glassOutside)
        }
        const glassInside = this.thermalInsulationVariant(this.GlasscheibeInnen, 'innen')
        if (glassInside) {
          this.setGlas('innen', glassInside)
        }
        const glassMiddle1 = this.nonThermalInsulationVariant(
          this.GlasscheibeMitte1
          ?? this.glassProvider.getDefaultMiddleGlass()
          ?? this.glassProvider.getDefaultGlass(),
          'mitte1'
        )
        if (glassMiddle1) {
          this.setGlas('mitte1', glassMiddle1)
        }
        if (paket.Key === 'WS5') {
          const glassMiddle2 = this.nonThermalInsulationVariant(
            this.GlasscheibeMitte2
            ?? this.glassProvider.getDefaultMiddleGlass()
            ?? this.glassProvider.getDefaultGlass(),
            'mitte2'
          )
          if (glassMiddle2) {
            this.setGlas('mitte2', glassMiddle2)
          }
        } else {
          this.unsetGlas('mitte2')
        }
      }
    }
    this.correctGlassaufbauAndUpdatePackets()
  }

  thermalInsulationVariant(glass: FbsGlas, position?: GlasaufbauPositionShort): FbsGlas {
    if (glass.IsWSG) {
      return glass
    }
    const positionPredicate = this.positionToPositionPossiblePropertyName(position)
    const rodenbergCode = glass.RodenbergCode + 'B'
    return this.glassProvider.getAllGlasses().find(
      (g): boolean => g.RodenbergCode === rodenbergCode && (!positionPredicate || g[positionPredicate])
    ) ?? this.glassProvider.getAllGlasses().find((g): boolean => g.RodenbergCode === '001000B')
      ?? this.glassProvider.getAllGlasses().find((g): boolean => g.IsWSG)
      ?? this.glassProvider.getDefaultGlass()
  }

  unsetGlas(position: GlasaufbauPositionShort): void {
    switch (position) {
      case 'aussen':
        this.GlasscheibeAussen = null
        break
      case 'mitte1':
        this.GlasscheibeMitte1 = null
        break
      case 'mitte2':
        this.GlasscheibeMitte2 = null
        break
      case 'innen':
        this.GlasscheibeInnen = null
        break
      default:
        console.error('position unknown')
    }
    // also remove motiv if glass is being unset
    this.unsetMotiv(position)
    this.correctGlassaufbauAndUpdatePackets()
  }

  unsetMotiv(position: GlasaufbauPositionShort): void {
    switch (position) {
      case 'aussen':
        this.MotivAussen = null
        break
      case 'mitte1':
        this.MotivMitte1 = null
        break
      case 'mitte2':
        this.MotivMitte2 = null
        break
      case 'innen':
        this.MotivInnen = null
        break
      default:
        console.error('position unknown')
    }
  }

  updateGlasaufbau(conf?: ModelConfigurationGlasaufbau): void {
    if (conf) {
      this.Id = conf && conf.Id
      this.GlasscheibeAussen = this.glassProvider.findGlass(conf?.GlasscheibeAussen?.Id ?? 0) ?? null
      this.GlasscheibeInnen = this.glassProvider.findGlass(conf?.GlasscheibeInnen?.Id ?? 0) ?? null
      this.GlasscheibeMitte1 = this.glassProvider.findGlass(conf?.GlasscheibeMitte1?.Id ?? 0) ?? null
      this.GlasscheibeMitte2 = this.glassProvider.findGlass(conf?.GlasscheibeMitte2?.Id ?? 0) ?? null
      this.IsStandard = conf && conf.IsStandard
      this.MotivAussen = this.motivs.find(findById(conf?.GlasscheibeAussen?.Motiv?.Id ?? 0)) ?? null
      this.MotivInnen = this.motivs.find(findById(conf?.GlasscheibeInnen?.Motiv?.Id ?? 0)) ?? null
      this.MotivMitte1 = this.motivs.find(findById(conf?.GlasscheibeMitte1?.Motiv?.Id ?? 0)) ?? null
      this.MotivMitte2 = this.motivs.find(findById(conf?.GlasscheibeMitte2?.Motiv?.Id ?? 0)) ?? null
      this.MinAnzahlGlasscheiben = conf && conf.MinAnzahlGlasscheiben
      this.MaxStaerkeSZR = conf && conf.MaxStaerkeSZR
      this.MinStaerkeSZR = conf && conf.MinStaerkeSZR
      // Is the Sprossen key present in the configuration? Can be null or undefined to unset Sprossen
      if (typeof conf === 'object' && 'Sprossen' in conf) {
        this.updateSprossen(conf?.Sprossen)
      }
    }
    this.correctGlassaufbauAndUpdatePackets()
  }

  /*
  private updateSafetyPacket(): void {
    // TODO: Refactor to use ccm instead of confDoor?
    const safetyGlassMapping = Object.entries(safetyGlass)
      .find((mapping): boolean =>
        mapping[1] === this.GlasscheibeAussen?.RodenbergCode.slice(0, this.GlasscheibeAussen?.IsWSG ? -1 : undefined)
      )
    if (safetyGlassMapping) {
      this.configuratedDoor.sicherheitsPaket = this.safetyPackages
        .find((p: Paket): boolean => p.Key === safetyGlassMapping[0]) ?? null
    } else if (this.Id) {
      this.configuratedDoor.sicherheitsPaket = null
    }
  }
   */

  private updateSprossen(data: Sprossen | ModelConfigurationSubassembly): void {
    let sprossenBeschichtung: ColorBase
    this.Sprossen = new Sprossen()
    this.Sprossen.Id = data?.Id
    if (data?.Beschichtung) {
      sprossenBeschichtung = this.componentSelection.selectedComponent.model
        .findObjectFromData<Folie | Lack | Pulver>(data.Beschichtung)
    }
    if (this.Sprossen && this.Sprossen.Id > 0) {
      this.Sprossen = new Sprossen(this.sprossen.find((s): boolean => s.Id === this.Sprossen.Id))
      if (sprossenBeschichtung) {
        this.Sprossen.colorize(sprossenBeschichtung, SideType.Outside, this.componentSelection.selectedComponent.material)
      }
    } else {
      this.Sprossen = null
    }
  }

  private updateWSPaket(): void {
    // TODO: Refactor to use ccm instead of confDoor?
    const glaeser = this.numGlasses
    const wsgGlaeser = this.numTIGlasses
    if (glaeser === 2) {
      this.configuratedDoor.waermeschutzPaket = null
    } else if ((glaeser === 3 || glaeser === 4) && wsgGlaeser === 0) {
      this.configuratedDoor.waermeschutzPaket = null
    } else if (glaeser === 3 && wsgGlaeser === 1) {
      this.configuratedDoor.waermeschutzPaket = this.thermalInsulationPackages?.find((p: Paket): boolean => p.Key === 'WS9')
    } else if (glaeser === 3 && wsgGlaeser === 2) {
      this.configuratedDoor.waermeschutzPaket = this.thermalInsulationPackages?.find((p: Paket): boolean => p.Key === 'WS7')
    } else if (glaeser === 4 && wsgGlaeser === 2) {
      this.configuratedDoor.waermeschutzPaket = this.thermalInsulationPackages?.find((p: Paket): boolean => p.Key === 'WS5')
    } else if (glaeser === 4 && wsgGlaeser === 1) {
      this.configuratedDoor.waermeschutzPaket = null
    }
  }

  get configuratedDoor(): ConfiguratedDoor {
    return this.configuratorConfigurationModel.configuratedDoor
  }

  get configuratorConfigurationModel(): ConfiguratorConfigurationModel {
    if (!this.#configuratorConfigurationModel) {
      this.#configuratorConfigurationModel = this.injector.get(ConfiguratorConfigurationModel)
    }
    return this.#configuratorConfigurationModel
  }

  get errors(): SpacerThicknessError[] {
    return this._errors
  }

  get numGlasses(): 0 | 1 | 2 | 3 | 4 {
    return ((this.GlasscheibeAussen ? 1 : 0)
      + (this.GlasscheibeInnen ? 1 : 0)
      + (this.GlasscheibeMitte1 ? 1 : 0)
      + (this.GlasscheibeMitte2 ? 1 : 0)) as 0 | 1 | 2 | 3 | 4
  }

  get numTIGlasses(): 0 | 1 | 2 {
    return ((this.GlasscheibeAussen && this.GlasscheibeAussen.IsWSG ? 1 : 0)
      + (this.GlasscheibeInnen && this.GlasscheibeInnen.IsWSG ? 1 : 0)) as 0 | 1 | 2
  }

  get safetyPackages(): Paket[] {
    return this.componentSelection.selectedComponent.model.getPackages({
      material: this.componentSelection.selectedComponent.material,
      type: PackageType.Security
    })
  }

  get selectedComponent(): ConstructionComponent {
    return this.componentSelection.selectedComponent
  }

  get simpleGlassScheibe(): string {
    return this.selectedComponent.Zmass < 36
      ? // ANZAHL SCHEIBEN: 2
      'GlasscheibeAussen'
      : this.selectedComponent.Zmass < 61
        ? // ANZAHL SCHEIBEN: 3
        'GlasscheibeMitte1'
        : // ANZAHL SCHEIBEN: 4
        'GlasscheibeMitte1'
  }

  get simpleMotiv(): string {
    return this.selectedComponent.Zmass < 36
      ? // ANZAHL SCHEIBEN: 2
      'MotivAussen'
      : this.selectedComponent.Zmass < 61
        ? // ANZAHL SCHEIBEN: 3
        'MotivMitte1'
        : // ANZAHL SCHEIBEN: 4
        'MotivMitte1'
  }

  get simplePosition(): GlasaufbauPositionShort {
    return this.selectedComponent.Zmass < 36
      ? // ANZAHL SCHEIBEN: 2
      'aussen'
      :  // ANZAHL SCHEIBEN: 4
      'mitte1'
  }

  get thermalInsulationPackages(): Paket[] {
    return this.componentSelection.selectedComponent?.model?.getPackages({
      material: this.componentSelection.selectedComponent.material,
      type: PackageType.ThermalInsulation
    })
  }

  get toastrService(): ToastrService {
    return this._toastrService
  }

  get translateService(): TranslateService {
    return this._translateService
  }
}

const findById = <V>(id: V): (o: { Id: V }) => boolean => (o): boolean => o.Id === id

@Injectable()
export class GlasaufbauFactory {
  constructor(
    private toastrService: ToastrService,
    private translateService: TranslateService,
    private injector: Injector,
    @Inject(GLASS_PROVIDER_TOKEN) private glassProvider: GlassProvider,
    private componentSelection: ComponentSelectionService,
    private logger: NGXLogger,
  ) {
  }

  public create(model?: ComponentModel, data?: ConstructorParameters<typeof Glasaufbau>[0]): Glasaufbau {
    return new Glasaufbau(
      data,
      model?.Motive ?? [],
      model?.Sprossen ?? [],
      this.toastrService,
      this.translateService,
      this.injector,
      this.glassProvider,
      this.componentSelection,
      this.logger,
    )
  }
}
