import {Component} from '@angular/core'
import {ComponentSelectionService} from '../classes/service/componentSelectionService'
import {ConfiguratorConfigurationModel} from '../classes/model/configuratorConfigurationModel'
import {ConfiguratedDoorFactory} from '../classes/model/configuratedDoor'
import {GrundformService} from '../classes/service/grundform.service'
import {ConfiguratorModeService} from '../classes/service/configuratorMode.service'
import {GlasaufbauFactory} from '../classes/model/component/glassaufbau/glasaufbau'
import {ProfileService} from '../classes/service/profile.service'
import MemoListService from '../classes/service/memo-list/memo-list.service'
import {TuerFactory} from '../classes/model/component/construction/tuer'
import {OberlichtFactory} from '../classes/model/component/construction/oberlicht'
import {SeitenteilFactory} from '../classes/model/component/construction/seitenteil'
import {GLASS_PROVIDER_TOKEN} from '../classes/model/dataProvider/glass/glass.type'
import {ConfiguratorDataModel} from '../classes/model/configuratorDataModel'
import {GlassService} from '../classes/model/dataProvider/glass/glass.service'
import {ConstructionDimensionService} from '../classes/service/construction-dimension.service'
import {FbsModelService} from '../classes/model/dataProvider/fbsModel/fbsModel.service'
import {ViewService} from '../classes/service/view.service'
import {ModalService} from '../configurator/modal/modal.service'
import {NavigatorService} from '../classes/service/navigation/navigator.service'
import {
  CharacteristicsDataFactory
} from '../classes/model/dataProvider/characteristics-data/characteristics-data.factory'
import {ModelSearchService} from '../classes/service/model-search/model-search.service'
import {CatalogueService} from '../classes/service/catalogueService'
import {LoadModule} from '../configurator/load'
import {LoadDoorIdModalComponent} from '../configurator/modal/load-door-id-modal/load-door-id-modal.component'

@Component({
  templateUrl: './configurator-frame.component.html',
  styleUrls: ['./configurator-frame.component.scss'],
  providers: [
    CatalogueService,
    CharacteristicsDataFactory,
    ComponentSelectionService,
    ConfiguratedDoorFactory,
    ConfiguratorConfigurationModel,
    ConfiguratorDataModel,
    ConfiguratorModeService,
    ConstructionDimensionService,
    FbsModelService,
    GlasaufbauFactory,
    GlassService,
    GrundformService,
    MemoListService,
    ModalService,
    ModelSearchService,
    NavigatorService,
    OberlichtFactory,
    ProfileService,
    SeitenteilFactory,
    TuerFactory,
    ViewService,
    LoadDoorIdModalComponent,
    {provide: GLASS_PROVIDER_TOKEN, useExisting: ConfiguratorDataModel},
    ...LoadModule.provide()
  ]
})
export class ConfiguratorFrameComponent {

}
