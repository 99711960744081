<modal-frame
  [footer]='footer'
  [modalCloseButton]='false'
  [title]='(TRANSLATION_KEY.TITLE_PREFIX|translate)
    + (this.mehrpreisBezeichnung() | translate)
    + (TRANSLATION_KEY.TITLE_SUFFIX|translate)'
  hostId='mehrpreisDiv'
>

  <mat-form-field appearance='fill'>
    <mat-label>{{ TRANSLATION_KEY.MODAL_SEARCH_INPUT_LABEL | translate }}</mat-label>
    <input (keyup)="this.updateFilterlist()" [(ngModel)]='this.filterTerm' matInput>
  </mat-form-field>


  <div *ngIf="isDevMode()" class="taglist">
    <div class="favorites">
      <ng-container *ngFor="let tag of this.favoriteFilters; let index">
        <button (click)="this.toggleFilter(tag)"
                [class.active]="this.activeFilters.includes(tag)"
                mat-flat-button>{{ tag.Tag }}
        </button>
      </ng-container>
    </div>


    <mat-expansion-panel (closed)="this.showAllTags = false"
                         (opened)="this.showAllTags = true">
      <mat-expansion-panel-header>
        <mat-panel-title>{{ TRANSLATION_KEY.MORE_FILTERS | translate }}</mat-panel-title>
        <mat-panel-description>
          {{ (this.showAllTags ? TRANSLATION_KEY.HIDE_FILTERS : TRANSLATION_KEY.SHOW_FILTERS) | translate }}
        </mat-panel-description>
      </mat-expansion-panel-header>

      <div class="notFavorites">
        <ng-container *ngFor="let tag of this.notFavoriteFilters">
          <button (click)="this.toggleFilter(tag)"
                  [class.active]="this.activeFilters.includes(tag)"
                  mat-flat-button>{{ tag.Tag }}
          </button>
        </ng-container>
      </div>

    </mat-expansion-panel>


  </div>

  <div [class]='this.category' class='content'>

    <ng-container *ngFor='let item of this.updatedZubehoer()'>

      <div
        (click)="item.disabilityReasons.length === 0 ? this.preselectZubehoer(item) : ''"
        *ngIf='this.updateZubehoerVisibility(item)'
        [class.active]='this.zubehoerPreselected(item)'
        [class.current]='this.selectedZubehoerIds.includes(item.Id)'
        [class.disabled]='item.disabilityReasons.length > 0'
        class='item'
      >
        <div
          [mtxTooltipDisabled]='item.disabilityReasons.length === 0'
          [mtxTooltip]='StringUtil.concatStringArray(item.disabilityReasons)'
          class='image-wrapper'
        >
          <div *ngIf='!item.loaded && item.PreviewImageUrl' class='icon loading-image-spinner'>
            <i class='fa fa-spinner fa-pulse fa-3x fa-fw'></i>
          </div>
          <img (load)='item.loaded = true' [lazyLoad]='item.PreviewImageUrl || item.ImageUrl'>
        </div>
        <p>
          <span class='option-text'>{{ item.Beschreibung  | translate }}</span>
        </p>
      </div>
    </ng-container>

  </div>
</modal-frame>
<ng-template #footer>
  <button (click)='this.onConfirm()' color='primary'
          mat-flat-button>{{ TRANSLATION_KEY.MODAL_BUTTON_SAVE | translate }}
  </button>
</ng-template>
