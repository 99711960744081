import {Injectable} from '@angular/core'
import {NGXLogger} from 'ngx-logger'
import {HttpService} from '../../http.service'
import {Grundform} from '../model/component/other/grundform'
import {Hausfront} from '../model/component/hausfront/hausfront'
import {InsideOutsideObject, SideType} from '../../types'
import {ViewService} from './view.service'
import {HausfrontRequest} from '../model/component/hausfront/hausfront-request'

@Injectable()
export class GrundformService {
  private _currentGrundform: Grundform
  private _grundformen: Grundform[] = []

  constructor(
    private httpService: HttpService,
    private viewService: ViewService,
    private _logger: NGXLogger,
  ) {
  }

  addCustomHausfront(hausfront: Hausfront): void {
    this.grundformen.forEach((g: Grundform): void => {
      const sample: Hausfront = g.Hausfronten.find((h: Hausfront): boolean => !h.Custom)
      const custom: Hausfront = new Hausfront(hausfront)
      custom.Bezeichnung = custom.Id.toString()
      custom.OberlichtBreite = sample.OberlichtBreite
      custom.OberlichtHoehe = sample.OberlichtHoehe
      custom.SeitenteilBreite = sample.SeitenteilBreite
      custom.SeitenteilHoehe = sample.SeitenteilHoehe
      custom.TuerBreite = sample.TuerBreite
      custom.TuerHoehe = sample.TuerHoehe
      custom.Name = sample.Name

      g.Hausfronten.unshift(custom)

    })
  }

  createCustomHausfront(hausfront: Hausfront, image: File, elementWidth: number, elementHeight: number): Promise<Hausfront> {
    return new Promise<Hausfront>((resolve): void => {
      this.httpService.postUploadedHausfront(image, this.generateRequestObject(hausfront, elementWidth, elementHeight)).subscribe({
        next: (apiHausfront: Hausfront): void => {
          hausfront.Id = apiHausfront.Id
          hausfront.PreviewUrl = apiHausfront.PreviewUrl
          hausfront.Url = apiHausfront.Url
          hausfront.Custom = true

          this.addCustomHausfront(new Hausfront(hausfront))
          resolve(hausfront)
        },
        error: (error): void => {
          console.error(error)
        }
      })
    })
  }

  findHausfront(side: SideType, bezeichnung?: string): Hausfront | undefined {
    const hf = this._currentGrundform.Hausfronten.find(
      (hausfront: Hausfront): boolean =>
        (
          typeof bezeichnung !== 'string'
          || hausfront.Bezeichnung === bezeichnung
        ) && (
          hausfront.IsAussen && side === SideType.Outside
          || hausfront.IsInnen && side === SideType.Inside
        )
    )
    return hf
  }

  generateRequestObject(hausfront: Hausfront, elementWidth: number, elementHeight: number): HausfrontRequest {
    return new HausfrontRequest({
      GrundformId: hausfront.GrundformId,
      ElementBreite: Math.round(elementWidth),
      ElementHoehe: Math.round(elementHeight),
      TuerBreite: Math.round(hausfront.TuerBreite),
      TuerHoehe: Math.round(hausfront.TuerHoehe),
      SeitenteilBreite: Math.round(hausfront.SeitenteilBreite),
      SeitenteilHoehe: Math.round(hausfront.SeitenteilHoehe),
      OberlichtBreite: Math.round(hausfront.OberlichtBreite),
      OberlichtHoehe: Math.round(hausfront.OberlichtHoehe),
      IsAussen: this.viewService.currentSide === SideType.Outside,
      IsInnen: this.viewService.currentSide === SideType.Inside,
      X1: hausfront.X1,
      X2: hausfront.X2,
      X3: hausfront.X3,
      X4: hausfront.X4,
      Y1: hausfront.Y1,
      Y2: hausfront.Y2,
      Y3: hausfront.Y3,
      Y4: hausfront.Y4,
      Id: hausfront.Id
    })
  }

  getHausfront(grundformKey: string, side: SideType, hausfrontId?: number): Hausfront | null {
    const grundform = this._grundformen.find((g: Grundform): boolean => g.Key === grundformKey)
    const houseFrontById = typeof hausfrontId !== 'undefined'
      ? grundform?.Hausfronten.find((h: Hausfront): boolean => h.Id === hausfrontId)
      : undefined
    return houseFrontById
      ?? grundform?.Hausfronten.find((h: Hausfront): boolean => side === SideType.Inside ? h.IsInnen : h.IsAussen)
      ?? null
  }

  public load(): Promise<void> {
    this._logger.trace('loadAllGrundformen')
    return new Promise<void>((resolve, reject): void => {
      this.httpService.getAllGrundformen().subscribe({
        next: (data): void => {
          data?.forEach(grundform => void this._grundformen.push(new Grundform(grundform)))
          this._currentGrundform = this._grundformen[0]
        },
        error: (error): void => {
          this._logger.error(['Error', error])
          reject()
        },
        complete: (): void => {
          resolve()
        }
      })
    })
  }

  removeCustomHausfront(houseFront: Hausfront): void {
    if (!houseFront?.Custom) {
      return
    }
    this.grundformen.forEach((g: Grundform): void => {
      const index = g.Hausfronten.findIndex((front): boolean => front.Id === houseFront.Id)
      if (index === -1) {
        return
      }
      g.Hausfronten.splice(index, 1)
    })
  }

  setGrundform(grundform: Grundform, hausfront: InsideOutsideObject<Hausfront>, width: number, height: number): Promise<void> {
    this._currentGrundform = grundform
    if (!grundform) {
      return
    }
    return Promise.all([hausfront.Inside, hausfront.Outside].map((currentHausfront): Promise<Hausfront> => {
      if (!currentHausfront?.Custom) {
        return
      }
      // currentHausfront.GrundformId = grundformen.Current.Id
      const hausfrontFromGrundform: Hausfront = grundform.Hausfronten.find(
        (h: Hausfront): boolean => h.Id === currentHausfront.Id
      )
      hausfrontFromGrundform.GrundformId = grundform.Id
      return this.updateCustomHausfront(hausfrontFromGrundform, width, height)
    })).then((): void => {
    })
  }

  updateAllCustomHausfrontenElementSizes(side: SideType, width: number, height: number): void {
    this.grundformen.forEach((g: Grundform): void => {
      g.Hausfronten.forEach((h: Hausfront): void => {
        if (
          h.Custom
          && (side === SideType.Inside) === h.IsInnen
          && (side === SideType.Outside) === h.IsAussen
        ) {
          h.ElementBreite = width
          h.ElementHoehe = height
        }
      })
    })
  }

  updateCustomHausfront(hausfrontUpdate: Hausfront, elementWidth: number, elementHeight: number): Promise<Hausfront> {
    return new Promise<Hausfront>((resolve): void => {
      this.httpService.postHausfrontUpdate(this.generateRequestObject(hausfrontUpdate, elementWidth, elementHeight)).subscribe({
        next: (sourceHausfront: Hausfront): void => {
          let result: Hausfront
          this.grundformen.forEach((grundform: Grundform): void => {
            grundform.Hausfronten
              .filter((hausfrontTarget: Hausfront): boolean => hausfrontTarget.Id === sourceHausfront.Id)
              .forEach((hausfrontTarget: Hausfront): void => {
                hausfrontTarget.GrundformId = grundform.Id
                hausfrontTarget.Name = grundform.Key
                hausfrontTarget.X1 = sourceHausfront.X1
                hausfrontTarget.X2 = sourceHausfront.X2
                hausfrontTarget.X3 = sourceHausfront.X3
                hausfrontTarget.X4 = sourceHausfront.X4
                hausfrontTarget.Y1 = sourceHausfront.Y1
                hausfrontTarget.Y2 = sourceHausfront.Y2
                hausfrontTarget.Y3 = sourceHausfront.Y3
                hausfrontTarget.Y4 = sourceHausfront.Y4
                hausfrontTarget.ElementBreite = sourceHausfront.ElementBreite
                hausfrontTarget.ElementHoehe = sourceHausfront.ElementHoehe
                result = hausfrontTarget
              })
          })
          resolve(result ?? sourceHausfront)
        },
        error: (error): void => {
          console.error(error)
        }
      })
    })
  }

  get grundform(): Grundform {
    return this._currentGrundform
  }

  get grundformen(): Grundform[] {
    return this._grundformen
  }
}
