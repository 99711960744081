import {Injectable, OnDestroy} from '@angular/core'
import {Subscription} from 'rxjs'
import {EventBusService} from './event-bus.service'
import {OverloadedParameters} from '../../../types'
import {BusEvent} from '@d-m-p/ts-bus/dist/types'
import {EventCreatorFn, EventHandlerFn, SubscriptionDef} from '@d-m-p/ts-bus/src/types'
import {PredicateFn} from '@d-m-p/ts-bus/src/event-bus'

@Injectable({providedIn: null, deps: [EventBusSupport]})
export abstract class EventBusSupport implements OnDestroy {
  private readonly subscription: Subscription

  protected constructor(protected readonly eventBus: EventBusService) {
    this.subscription = new Subscription()
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe()
  }

  protected publish<T extends BusEvent>(event: T, meta?: T['meta']): void {
    this.eventBus.publish(event, meta)
  }

  protected publishAsync<T extends BusEvent>(event: T, meta?: T['meta']): Promise<unknown[]> {
    return this.eventBus.publishAsync(event, meta)
  }

  protected subscribe<T extends BusEvent>(
    subscription: EventCreatorFn<T>,
    handler: EventHandlerFn<ReturnType<typeof subscription>>,
  ): () => void

  protected subscribe<T extends BusEvent>(
    subscription: PredicateFn<T>,
    handler: EventHandlerFn<T>,
  ): () => void

  protected subscribe<T extends { type: string }>(
    subscription: T['type'],
    handler: EventHandlerFn<BusEvent & T>,
  ): () => void

  protected subscribe<T extends BusEvent>(
    subscription: SubscriptionDef<T>,
    handler: EventHandlerFn<T>,
  ): () => void {
    const teardown = this.eventBus.subscribe(
      subscription satisfies OverloadedParameters<typeof this.eventBus.subscribe<T>>[0] as Parameters<typeof this.eventBus.subscribe>[0],
      handler
    )
    const rxjsSubscription = new Subscription(teardown)
    this.subscription.add(rxjsSubscription)
    return (): void => rxjsSubscription.unsubscribe()
  }

}
