<div *ngIf="visible" [ngClass]="{'panel-open': isOpen}" class="panel panel-default">
  <div (click)="toggleOpen($event)" [attr.allow]="allowToggle" class="panel-heading">
    <h4 class="panel-title">
      <a href tabindex="0"><span><i [class.fa-minus]="isOpen" [class.fa-plus]="!isOpen"
                                    class="fa"></i>{{ heading }}</span></a>
    </h4>
  </div>
  <div [hidden]="!isOpen" class="panel-collapse">
    <div class="panel-body">
      <ng-content></ng-content>
    </div>
  </div>
</div>
