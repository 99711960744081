import {Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange} from '@angular/core'
import {D3, D3DragEvent, NgxD3Service, Selection} from '@d-m-p/ngx-d3'
import {DraggedElementBaseType, SubjectPosition} from 'd3-drag'

export type SizeChange = {
  height: SimpleChange
  width: SimpleChange
}
type Point = {
  name: string
  x: number
  y: number
}

@Component({
  selector: 'door-mask',
  template: '<svg id="controls"></svg>',
  styleUrls: ['door.mask.css']
})
export class DoorMaskComponent implements OnInit, OnChanges {
  @Output() changeBackground: EventEmitter<SizeChange> = new EventEmitter<SizeChange>()
  private controlsContainer: HTMLElement
  private d3: D3 // <-- Define the private member which will hold the d3 reference
  @Input() heightBackground = 0
  @Input() heightDoor = 0
  private parentNativeElement: Element
  @Output() pointChanged: EventEmitter<Point[]> = new EventEmitter<Point[]>()
  @Input() redColor = '#b1032b'
  private svgControls: Selection<SVGSVGElement, unknown, HTMLElement, unknown>
  @Input() urlBackground: string
  @Input() widthBackground = 0
  @Input() widthDoor = 0
  @Input() x0 = 0
  @Input() x1 = 0
  @Input() x2 = 0
  @Input() x3 = 0
  @Input() y0 = 0
  @Input() y1 = 0
  @Input() y2 = 0
  @Input() y3 = 0
  private zoomImage: HTMLImageElement

  constructor(element: ElementRef, d3Service: NgxD3Service) { // <-- pass the D3 Service into the constructor
    this.d3 = d3Service.getD3() // <-- obtain the d3 object from the D3 Service
    if (element.nativeElement instanceof Element) {
      this.parentNativeElement = element.nativeElement
    }
  }

  private backgroundChanged(changes: { [propKey: string]: SimpleChange }): void {
    // const backgroundSize: any = {width: this.widthBackground, height: this.heightBackground}
    const size = {height: changes.heightBackground, width: changes.widthBackground}
    setTimeout((): void => {
      this.changeBackground.emit(size)
    }, 1)
  }

  private changeLayout(): void {
    const d3 = this.d3 // <-- for convenience use a block scope variable
    let d3ParentElement: Selection<Element, unknown, HTMLElement, unknown>
    let controlsG: Selection<SVGGElement, unknown, HTMLElement, undefined>
    const lineFunction = d3.line()
      .x((d): number => d[0])
      .y((d): number => d[1])
      .curve(d3.curveLinearClosed)
    if (this.svgControls != null) {
      this.svgControls.selectAll('*').remove()
    }
    if (this.parentNativeElement !== null) {
      d3ParentElement = d3.select(this.parentNativeElement)
      this.svgControls = (
        d3ParentElement.select<SVGSVGElement>('#controls') satisfies
          Selection<SVGSVGElement, unknown, HTMLElement, unknown>
      )
      const targetPoints: { name: string; x: number; y: number }[] = [
        {name: 'h0', x: this.x0, y: this.y0},
        {name: 'h1', x: this.x1, y: this.y1},
        {name: 'h2', x: this.x2, y: this.y2},
        {name: 'h3', x: this.x3, y: this.y3}
      ]
      const updateLine = (): void => {
        controlsG.selectAll('.line').attr(
          'd',
          lineFunction(targetPoints.map((point): [number, number] => [point.x, point.y]))
        )
      }
      controlsG = this.svgControls.append<SVGGElement>('g')
      controlsG.attr('transform', 'translate(' + 0 + ',' + 0 + ')')
      controlsG.selectAll('.line').data([0]).enter()
        .append<SVGPathElement>('path')
        .attr('stroke', this.redColor)
        .attr('stroke-width', 1)
        .attr('fill', 'none')
        .attr('class', 'line')
        .attr('opacity', 1)
      controlsG.selectAll('.handle').data(targetPoints).enter()
        .append<SVGCircleElement>('circle')
        .attr('id', (d): string => d.name)
        .attr('fill', this.redColor)
        .attr('fill-opacity', 0.2)
        .attr('stroke', this.redColor)
        .attr('stroke-width', 2)
        .attr('cursor', 'move')
        .attr('transform', (d): string => 'translate(' + d.x + ',' + d.y + ')')
        .attr('r', 25)
        .call(
          d3.drag<SVGCircleElement, { name: string; x: number; y: number }>()
            .on('drag', (event: D3DragEvent<DraggedElementBaseType, unknown, unknown>, d: SubjectPosition): void => {
              const ZOOM_WINDOW_WIDTH_CENTER = 250 / 2
              const ZOOM_WINDOW_HEIGHT_CENTER = 150 / 2
              const SCALE = 3
              if (!this.zoomImage) {
                this.zoomImage = document.querySelector('#uploadedZoomImage')
              }
              if (!this.controlsContainer) {
                this.controlsContainer = document.querySelector('#controls')
              }
              this.zoomImage.style.setProperty('left', ((((event.x * SCALE) - ZOOM_WINDOW_WIDTH_CENTER) * -1)).toString() + 'px')
              this.zoomImage.style.setProperty('top', ((((event.y * SCALE) - ZOOM_WINDOW_HEIGHT_CENTER) * -1)).toString() + 'px')
              if (event.x < 250 && event.y < 150) {
                const controlsWidth = this.controlsContainer.getBoundingClientRect().width
                this.zoomImage.parentElement.style.setProperty('margin-left', (controlsWidth - 250).toString() + 'px')
              } else {
                this.zoomImage.parentElement.style.removeProperty('margin-left')
              }
              d.x = event.x
              d.y = event.y
              if (event.sourceEvent instanceof Event && event.sourceEvent.target instanceof Element) {
                d3.select(this.svgControls.select('#' + ((event.subject as {
                  name: string
                  x: number
                  y: number
                }).name)).node())
                  .attr('transform', `translate(${d.x},${d.y})`)
              }
              updateLine()
            })
            .on('start', (): void => {
              if (!this.zoomImage) {
                this.zoomImage = document.querySelector('#uploadedZoomImage')
              }
              this.zoomImage.parentElement.style.setProperty('display', 'block')
            })
            .on('end', (): void => {
              this.zoomImage.parentElement.style.removeProperty('display')
              this.pointChanged.emit(targetPoints)
            }),
          []
        )
      this.changedBackgroundDim()
      // renderLine();
      updateLine()
    }
  }

  private changedBackgroundDim(): void {
    this.svgControls.attr('width', this.widthBackground).attr('height', this.heightBackground)
  }

  ngOnChanges(changes: { [propKey: string]: SimpleChange }): void {
    if (
      (changes.x0 && !changes.x0.isFirstChange()) ||
      (changes.x1 && !changes.x1.isFirstChange()) ||
      (changes.x2 && !changes.x2.isFirstChange()) ||
      (changes.x3 && !changes.x3.isFirstChange()) ||
      (changes.y0 && !changes.y0.isFirstChange()) ||
      (changes.y1 && !changes.y1.isFirstChange()) ||
      (changes.y2 && !changes.y2.isFirstChange()) ||
      (changes.y3 && !changes.y3.isFirstChange()) ||
      (changes.heightBackground && !changes.heightBackground.isFirstChange()) ||
      (changes.widthBackground && !changes.widthBackground.isFirstChange())
    ) {
      this.changeLayout()
      if (
        (changes.heightBackground && !changes.heightBackground.isFirstChange()) ||
        (changes.widthBackground && !changes.widthBackground.isFirstChange())
      ) {
        this.backgroundChanged(changes)
      }
    }
  }

  ngOnInit(): void {
    this.changeLayout()
  }
}
