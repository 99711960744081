import {Injectable} from '@angular/core'
import {Tag} from '../model/component/other/tag'
import {HttpService} from '../../http.service'
import {NGXLogger} from 'ngx-logger'

@Injectable()
export class TagService {

  _tags: Tag[]

  constructor(
    private readonly httpService: HttpService,
    private logger: NGXLogger
  ) {

  }

  public loadTags(): Promise<void> {
    this.logger.trace('loadAllTags')
    return new Promise<void>((resolve, reject): void => {
      this.httpService.getTags().subscribe({
        next: (data): void => {
          this._tags = []
          if (data) {
            data.forEach((t: Tag): void => {
              const tag = new Tag(t)
              this._tags.push(tag)
            })
          }
        },
        error: (error): void => {
          this.logger.error(['Error', error])
          reject()
        },
        complete: (): void => resolve()
      })
    })
  }

}
