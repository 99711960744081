import {Injectable} from '@angular/core'
import {
  BROWSER_VERSIONS_RE,
  BROWSERS,
  BROWSERS_RE,
  DEVICES,
  DEVICES_RE,
  OS,
  OS_RE,
  OS_VERSIONS,
  OS_VERSIONS_RE
} from './device-constants'
import {RegexpTreeEvaluator} from './regexp-tree-evaluator'
import {DeviceInfo} from './device-info.interface'

@Injectable()


export class DeviceService implements DeviceInfo {
  browser: string
  browser_version: string
  device: string
  os: string
  os_version: string
  ua: string
  userAgent: string

  constructor() {
    const reTree = new RegexpTreeEvaluator()
    this.userAgent = window.navigator.userAgent

    const osTests = (Object.keys(OS) as (keyof typeof OS)[]).reduce(
      (obj: { [K: string]: boolean }, item: keyof typeof OS): { [K: string]: boolean } => {
        obj[OS[item]] = reTree.test(this.userAgent, OS_RE[item])
        return obj
      },
      {}
    )

    const browserTest = (Object.keys(BROWSERS) as (keyof typeof BROWSERS)[]).reduce(
      (obj: { [K: string]: boolean }, item: keyof typeof BROWSERS): { [K: string]: boolean } => {
        obj[BROWSERS[item]] = reTree.test(this.userAgent, BROWSERS_RE[item])
        return obj
      },
      {}
    )

    const deviceTest = (Object.keys(DEVICES) as (keyof typeof DEVICES)[]).reduce(
      (obj: { [K: string]: boolean }, item: keyof typeof DEVICES): { [K: string]: boolean } => {
        obj[DEVICES[item]] = reTree.test(this.userAgent, DEVICES_RE[item])
        return obj
      },
      {}
    )

    const osVersionTest = (Object.keys(OS_VERSIONS) as (keyof typeof OS_VERSIONS)[]).reduce(
      (obj: { [K: string]: boolean }, item: keyof typeof OS_VERSIONS): { [K: string]: boolean } => {
        obj[OS_VERSIONS[item]] = reTree.test(this.userAgent, OS_VERSIONS_RE[item])
        return obj
      },
      {}
    )

    this.os = [
      OS.WINDOWS,
      OS.IOS,
      OS.MAC,
      OS.ANDROID,
      OS.LINUX,
      OS.UNIX,
      OS.FIREFOX_OS,
      OS.CHROME_OS,
      OS.WINDOWS_PHONE
    ].reduce(
      (previousValue: (typeof OS)[keyof typeof OS],
       currentValue: (typeof OS)[keyof typeof OS]
      ): (typeof OS)[keyof typeof OS] =>
        (previousValue === OS.UNKNOWN && osTests[currentValue]) ? currentValue : previousValue
      , OS.UNKNOWN
    )

    this.browser = [
      BROWSERS.CHROME,
      BROWSERS.FIREFOX,
      BROWSERS.SAFARI,
      BROWSERS.OPERA,
      BROWSERS.IE,
      BROWSERS.MS_EDGE,
      BROWSERS.FB_MESSANGER
    ].reduce(
      (previousValue: (typeof BROWSERS)[keyof typeof BROWSERS],
       currentValue: (typeof BROWSERS)[keyof typeof BROWSERS]
      ): (typeof BROWSERS)[keyof typeof BROWSERS] =>
        (previousValue === BROWSERS.UNKNOWN && browserTest[currentValue]) ? currentValue : previousValue
      , BROWSERS.UNKNOWN
    )

    this.device = [
      DEVICES.ANDROID,
      DEVICES.I_PAD,
      DEVICES.IPHONE,
      DEVICES.I_POD,
      DEVICES.BLACKBERRY,
      DEVICES.FIREFOX_OS,
      DEVICES.CHROME_BOOK,
      DEVICES.WINDOWS_PHONE,
      DEVICES.PS4,
      DEVICES.CHROMECAST,
      DEVICES.APPLE_TV,
      DEVICES.GOOGLE_TV,
      DEVICES.VITA
    ].reduce(
      (previousValue: (typeof DEVICES)[keyof typeof DEVICES],
       currentValue: (typeof DEVICES)[keyof typeof DEVICES]
      ): (typeof DEVICES)[keyof typeof DEVICES] =>
        (previousValue === DEVICES.UNKNOWN && deviceTest[currentValue]) ? currentValue : previousValue
      , DEVICES.UNKNOWN
    )

    this.os_version = [
      OS_VERSIONS.WINDOWS_3_11,
      OS_VERSIONS.WINDOWS_95,
      OS_VERSIONS.WINDOWS_ME,
      OS_VERSIONS.WINDOWS_98,
      OS_VERSIONS.WINDOWS_CE,
      OS_VERSIONS.WINDOWS_2000,
      OS_VERSIONS.WINDOWS_XP,
      OS_VERSIONS.WINDOWS_SERVER_2003,
      OS_VERSIONS.WINDOWS_VISTA,
      OS_VERSIONS.WINDOWS_7,
      OS_VERSIONS.WINDOWS_8_1,
      OS_VERSIONS.WINDOWS_8,
      OS_VERSIONS.WINDOWS_10,
      OS_VERSIONS.WINDOWS_PHONE_7_5,
      OS_VERSIONS.WINDOWS_PHONE_8_1,
      OS_VERSIONS.WINDOWS_PHONE_10,
      OS_VERSIONS.WINDOWS_NT_4_0,
      OS_VERSIONS.MACOSX,
      OS_VERSIONS.MACOSX_3,
      OS_VERSIONS.MACOSX_4,
      OS_VERSIONS.MACOSX_5,
      OS_VERSIONS.MACOSX_6,
      OS_VERSIONS.MACOSX_7,
      OS_VERSIONS.MACOSX_8,
      OS_VERSIONS.MACOSX_9,
      OS_VERSIONS.MACOSX_10,
      OS_VERSIONS.MACOSX_11,
      OS_VERSIONS.MACOSX_12,
      OS_VERSIONS.MACOSX_13,
      OS_VERSIONS.MACOSX_14,
      OS_VERSIONS.MACOSX_15
    ].reduce(
      (previousValue: (typeof OS_VERSIONS)[keyof typeof OS_VERSIONS],
       currentValue: (typeof OS_VERSIONS)[keyof typeof OS_VERSIONS]
      ): (typeof OS_VERSIONS)[keyof typeof OS_VERSIONS] =>
        (previousValue === OS_VERSIONS.UNKNOWN && osVersionTest[currentValue]) ? currentValue : previousValue
      , OS_VERSIONS.UNKNOWN
    )

    this.browser_version = '0'
    if (this.browser !== BROWSERS.UNKNOWN) {
      const re = BROWSER_VERSIONS_RE[this.browser]
      const res = reTree.exec(this.userAgent, re)
      if (!!res) {
        this.browser_version = res[1]
      }
    }
  }

  public getDeviceInfo(): DeviceInfo {
    return {
      userAgent: this.userAgent,
      os: this.os,
      browser: this.browser,
      device: this.device,
      os_version: this.os_version,
      browser_version: this.browser_version,
    }
  }

  public isDesktop(): boolean {
    return [
      DEVICES.PS4,
      DEVICES.CHROME_BOOK,
      DEVICES.UNKNOWN
    ].some((item): boolean => this.device === item)
  }

  public isMobile(): boolean {
    return [
      DEVICES.ANDROID,
      DEVICES.I_PAD,
      DEVICES.IPHONE,
      DEVICES.I_POD,
      DEVICES.BLACKBERRY,
      DEVICES.FIREFOX_OS,
      DEVICES.WINDOWS_PHONE,
      DEVICES.VITA
    ].some((item): boolean => this.device === item)
  }

  public isTablet(): boolean {
    return [
      DEVICES.I_PAD,
      DEVICES.FIREFOX_OS
    ].some((item): boolean => this.device === item)
  }
}
