import {ColorBase} from './colorBase'
import {ColorType} from '../../../types'
import {Powder} from '../../api/model/color/powder.interface'

export class Pulver extends ColorBase {
  Glanzgrad: string
  Hersteller: string
  IsFeinstruktur: boolean
  IsGesperrt: boolean
  IsMehrpreis: boolean
  NcsCode: string
  Oberflaeche: string
  Pulvernummer: string
  SapCode: string
  Sperrgrund: string
  objectType = 'Pulver'

  constructor(data?: Partial<Powder>) {
    super(data)
    this.Glanzgrad = data && data.Glanzgrad
    this.Hersteller = data && data.Hersteller
    this.IsFeinstruktur = data && data.IsFeinstruktur
    this.IsGesperrt = data && data.IsGesperrt
    this.IsMehrpreis = data && data.IsMehrpreis
    this.NcsCode = data && data.NCSCode
    this.Oberflaeche = data && data.Oberflaeche
    this.Pulvernummer = data && data.Pulvernummer
    this.SapCode = data && data.SAPcode
    this.Sperrgrund = data && data.Sperrgrund
  }

  getType(): ColorType {
    return 'pulver'
  }
}
