import {NgModule} from '@angular/core'
import {TranslatePipe} from './translate.pipe'
import {TranslateService} from './translate.service'
import TranslationKeys from './translation-keys'

export {TranslatePipe, TranslateService, TranslationKeys}

@NgModule({
  providers: [TranslateService],
  declarations: [TranslatePipe],
  exports: [TranslatePipe]
})
export class TranslateModule {
}
