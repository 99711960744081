import {Injectable} from '@angular/core'
import {FbsGlas} from '../../../../class'
import {NGXLogger} from 'ngx-logger'
import {HttpService} from '../../../../http.service'
import {BehaviorSubject, Subscribable} from 'rxjs'
import {GlassProvider} from './glass.type'
import {ComponentModel} from '../../component/model/component-model'

@Injectable()
export class GlassService implements GlassProvider {
  private readonly glasses: BehaviorSubject<readonly FbsGlas[]>

  constructor(
    private httpService: HttpService,
    private _logger: NGXLogger
  ) {
    this.glasses = new BehaviorSubject<readonly FbsGlas[]>([])
  }

  public findGlass(id: number): FbsGlas | undefined {
    return this.getAllGlasses().find((g): boolean => g.Id === id)
  }

  public getAllGlasses(): readonly FbsGlas[] {
    return this.glasses.value
  }

  public getDefaultGlass(): FbsGlas {
    return this.getAllGlasses().find((g): boolean => g.RodenbergCode === '001000')
      ?? this.getAllGlasses()[0]
  }

  public getDefaultMiddleGlass(): FbsGlas {
    return this.getAllGlasses().find((g): boolean => g.RodenbergCode === '001000' && g.IsMitteMoeglich)
      ?? this.getAllGlasses().find((g): boolean => g.IsMitteMoeglich)
      ?? this.getDefaultGlass()
  }

  public getGlassesForModel(model: ComponentModel): FbsGlas[] {
    const modelGlasses: number[] = model?.Glasscheiben.map((g: FbsGlas): number => g.Id) ?? []
    return this.getAllGlasses().filter((glass): boolean => modelGlasses.includes(glass.Id))
  }

  public getObservableGlasses(): Subscribable<readonly FbsGlas[]> {
    return this.glasses
  }

  public loadGlasses(): Promise<void> {
    this._logger.trace('loadAllGlasses')
    return new Promise<void>((resolve, reject): void => {
      this.httpService.getGlasses().subscribe({
        next: (data): void => {
          const glasses = []
          if (data) {
            data.forEach((g): void => {
              const fbsGlas = new FbsGlas(g)
              if (fbsGlas.Aktiv) {
                glasses.push(fbsGlas)
              }
            })
            // glasses.sort((a, b) => (a.Beschreibung > b.Beschreibung) ? 1 : -1)
            this.glasses.next(glasses)
          }
        },
        error: (error): void => {
          this._logger.error(['Error', error])
          reject()
        },
        complete: (): void => resolve()
      })
    })
  }
}
