import type {PipeTransform} from '@angular/core'
import {Pipe} from '@angular/core'

@Pipe({
  name: 'instanceof',
  pure: true,
})
export class InstanceofPipe implements PipeTransform {
  public transform<V, R>(value: V, type: abstract new (...args: unknown[]) => R): R | undefined {
    return value instanceof type ? value : undefined
  }
}
