import {HttpService} from '../../../http.service'
import {BehaviorSubject, map} from 'rxjs'

export default class MemoListItem {
  #imageUrlSubject: BehaviorSubject<{ Aussen?: string; Innen?: string }>
  #reportUrlSubject: BehaviorSubject<string>
  readonly thumbnailUrl: BehaviorSubject<string>

  constructor(
    private httpService: HttpService,
    public description: string,
    public readonly doorId: string
  ) {
    this.thumbnailUrl = new BehaviorSubject<string>(null)
    if (this.doorId) {
      this.httpService.getDoorImageThumbnails(this.doorId)
        .pipe(map((imageObject): string => imageObject?.Images?.Aussen))
        .subscribe({
          next: (value) => void this.thumbnailUrl.next(value),
          error: (error) => void this.thumbnailUrl.error(error),
        })
    }
  }

  getImageUrl(side: 'Aussen' | 'Innen'): void {
    this.imageUrls.subscribe((urlObject: { Aussen?: string; Innen?: string }): string => {
      let imageUrl = ''
      if (urlObject.hasOwnProperty(side)) {
        imageUrl = urlObject[side]
      }
      return imageUrl
    })
  }

  get imageUrls(): BehaviorSubject<{ Aussen?: string; Innen?: string }> {
    if (!this.#imageUrlSubject) {
      this.#imageUrlSubject = new BehaviorSubject<{ Aussen?: string; Innen?: string }>(null)
      if (this.doorId) {
        this.httpService.getDoorImages(this.doorId)
          .pipe(map((imageObject): { Aussen?: string; Innen?: string } => imageObject?.Images))
          .subscribe({
            next: (value) => void this.#imageUrlSubject.next(value),
            error: (error) => void this.#imageUrlSubject.error(error),
          })
      }
    }
    return this.#imageUrlSubject
  }

  get reportUrl(): BehaviorSubject<string> {
    if (!this.#reportUrlSubject) {
      this.#reportUrlSubject = new BehaviorSubject<string>(null)
      if (this.doorId) {
        this.httpService.getDoorReportUrl(this.doorId)
          .pipe(map((urlObject): string => urlObject?.Url))
          .subscribe({
            next: (value) => void this.#reportUrlSubject.next(value),
            error: (error) => void this.#reportUrlSubject.error(error),
          })
      }
    }
    return this.#reportUrlSubject
  }
}
