<ng-template #modalContent>
  <div *ngIf="!hideHeadCloseButton" [mat-dialog-close]="closeValue" class="closeModal">
    <i class="fa fa-times"></i>
  </div>
  <h1 mat-dialog-title>
    <ng-container *ngIf="_title | instanceof: TemplateRef as titleTemplate; else defaultTitleTemplate">
      <ng-container [ngTemplateOutlet]="titleTemplate"></ng-container>
    </ng-container>
  </h1>
  <mat-dialog-content [class]="contentClass">
    <ng-content></ng-content>
  </mat-dialog-content>
  <mat-dialog-actions *ngIf="_footer">
    <ng-container [ngTemplateOutletContext]="footerContext" [ngTemplateOutlet]="_footer"></ng-container>
  </mat-dialog-actions>
</ng-template>
<ng-template #defaultTitleTemplate>
  {{ _title | translate }}
</ng-template>
