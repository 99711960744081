import {UnknownWorkflowFilterKey} from './internal.error'
import {ZubehoerAddonEntry} from '../classes/model/component/extras/zubehoer/zubehoerAddonEntry'
import {Dienstleistung} from '../classes/model/component/extras/mehrpreis/dienstleistung'
import {Massblatt} from '../classes/model/component/extras/massblatt'
import {MehrpreisEntry} from '../classes/model/component/extras/zubehoer/mehrpreisEntry'
import {NGXLogger} from 'ngx-logger'

const filterSplitter = /\[\s*(?<key>[a-zA-Z0-9_-]+)\s*(?:=\s*(?<value>[a-zA-Z0-9_-]+))?\s*]/

const filterFinder = new RegExp(filterSplitter, 'g')

const metaCharacters = {
  isSet: '*',
  not: '!',
} as const

const supportedDienstleistungKeys: readonly string[] = ['Typ', 'Id'] satisfies (keyof ZubehoerAddonEntry)[]
const supportedMassblattKeys: string[] = ['Id'] satisfies (keyof Massblatt)[]
const supportedMehrpreisKeys: string[] = ['Typ'] satisfies (keyof MehrpreisEntry)[]

/**
 * This function extracts the filter key-value pairs from the selector part.
 * If a key is not supported as a valid filter key, it will be added to the errors list.
 *
 * @param selectorPart A part of the selector that contains the key-value pairs of the filter
 * @param isFilterKeyValid A function that checks if a key is a valid filter key
 * @param workflowKey The key of the workflow
 */
const buildFilterMap = <T>(
  selectorPart: string,
  isFilterKeyValid: ((key: unknown) => key is keyof T),
  workflowKey?: string
): [Partial<T>, UnknownWorkflowFilterKey[]] => {
  const errors: UnknownWorkflowFilterKey[] = []
  const valueMap: Partial<T> = {}
  selectorPart.match(filterFinder)?.forEach((encodedFilter): void => {
    const filterParts = encodedFilter.match(filterSplitter)
    const key = filterParts.groups.key
    if (isFilterKeyValid(key)) {
      valueMap[key] = filterParts.groups.value?.toLowerCase() as T[typeof key]
    } else {
      errors.push(new UnknownWorkflowFilterKey(key, workflowKey))
    }
  })
  return [valueMap, errors]
}


/**
 * This function extracts the filter key-value pairs from the selector part.
 * If a key is not supported as a valid filter key, it will be logged as a warning.
 *
 * @param selectorPart A part of the selector that contains the key-value pairs of the filter
 * @param isFilterKeyValid A function that checks if a key is a valid filter key
 * @param workflowKey The key of the workflow
 * @param logger The logger to log the errors
 */
const buildValidFilterMap = <T>(
  selectorPart: string,
  isFilterKeyValid: ((key: unknown) => key is keyof T),
  workflowKey?: string,
  logger?: NGXLogger
): Partial<T> => {
  const [valueMap, errors] = buildFilterMap(selectorPart, isFilterKeyValid, workflowKey)
  errors.forEach((err: UnknownWorkflowFilterKey): void => {
    logger?.warn(err.message)
  })
  return valueMap
}

/**
 * This function extracts the predicates from the selector part.
 * If a key is not supported by the filter, it will be added to the errors list.
 * If the errors list is not empty, the function will return the errors list.
 * Otherwise, it will return the predicates list.
 *
 * @param selectorPart A part of the selector that contains the key-value pairs of the filter
 * @param isFilterKeyValid A function that checks if a key is a valid filter key
 * @param workflowKey The key of the workflow
 */
const buildPredicates = <T>(
  selectorPart: string,
  isFilterKeyValid: ((key: unknown) => key is keyof T),
  workflowKey?: string
): (UnknownWorkflowFilterKey[]) | (((t: T) => boolean)[]) => {
  const [valueMap, errors] = buildFilterMap(selectorPart, isFilterKeyValid, workflowKey)
  return errors.length !== 0
    ? errors
    : Object.entries(valueMap).map(([key, value]): (m: T) => boolean =>
      typeof value === 'undefined'
        ? (m: T): boolean => typeof m[key] !== 'undefined'
        : (m: T): boolean => String(m[key]).toLowerCase() === value
    )
}

/**
 * This function extracts the predicates from the selector part.
 * If any key is not supported by the filter, it will be logged and an empty array will be returned.
 * Otherwise, it will return the predicates list.
 * If a key is not supported by the filter, it will be added to the errors list.
 * If the errors list is not empty, the function will return the errors list.
 * Otherwise, it will return the predicates list.
 *
 * @param selectorPart A part of the selector that contains the key-value pairs of the filter
 * @param isFilterKeyValid A function that checks if a key is a valid filter key
 * @param workflowKey The key of the workflow
 * @param logger The logger to log the errors
 */
const buildValidPredicates = <T>(
  selectorPart: string,
  isFilterKeyValid: ((key: unknown) => key is keyof T),
  workflowKey?: string,
  logger?: NGXLogger
): ((t: T) => boolean)[] => {
  const predicates: (UnknownWorkflowFilterKey | ((t: T) => boolean))[] =
    buildPredicates<T>(selectorPart, isFilterKeyValid, workflowKey)
  predicates.filter((p): p is UnknownWorkflowFilterKey => p instanceof UnknownWorkflowFilterKey)
    .forEach((err: UnknownWorkflowFilterKey): void => {
      logger?.warn(err.message)
    })
  return predicates.filter((p): p is ((t: T) => boolean) => typeof p === 'function')
}

const castBool = (value: unknown): boolean =>
  typeof value === 'boolean'
    ? value
    : typeof value === 'string'
      ? value.toLowerCase() === 'true'
      : !!value

const isSupportedDienstleistungKey = (key: unknown): key is (keyof ZubehoerAddonEntry & keyof Dienstleistung) =>
  typeof key === 'string' && supportedDienstleistungKeys.includes(key)

const isSupportedMassblattKey = (key: unknown): key is (keyof Massblatt) =>
  typeof key === 'string' && supportedMassblattKeys.includes(key)

const isSupportedMehrpreisKey = (key: unknown): key is keyof MehrpreisEntry =>
  typeof key === 'string' && supportedMehrpreisKeys.includes(key)

export default Object.freeze({
  buildFilterMap,
  buildValidFilterMap,
  buildPredicates,
  buildValidPredicates,
  castBool,
  filterFinder,
  filterSplitter,
  isSupportedDienstleistungKey,
  isSupportedMassblattKey,
  isSupportedMehrpreisKey,
  metaCharacters,
} as const)
