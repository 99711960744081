import {AfterViewChecked, Component, HostListener, ViewChild} from '@angular/core'
import {ConfiguratorConfigurationModel} from '../../../classes/model/configuratorConfigurationModel'
import {Tuer} from '../../../classes/model/component/construction/tuer'
import {Seitenteil} from '../../../classes/model/component/construction/seitenteil'
import {Oberlicht} from '../../../classes/model/component/construction/oberlicht'
import {ColorizableComponent} from '../../../classes/model/color/colorizableComponent'
import {ColorBase} from '../../../classes/model/color/colorBase'
import {Blendrahmen} from '../../../classes/model/component/frame/blendrahmen'
import {ColorFilter, ColorType, ConfiguratorMode, SideType, SimpleIdentifier} from '../../../types'
import ArrayUtil from '../../../classes/util/arrayUtil'
import {CdkVirtualScrollViewport} from '@angular/cdk/scrolling'
import {Fluegelrahmen} from '../../../classes/model/component/frame/fluegelrahmen'
import {Option} from '../../../classes/model/component/other/option'
import {Sprossen} from '../../../classes/model/component/glassaufbau/sprossen'
import {Beschichtung} from '../../../classes/model/color/beschichtung'
import {Deckschicht} from '../../../classes/model/color/deckschicht'
import {Pulver} from '../../../classes/model/color/pulver'
import {Folie} from '../../../classes/model/color/folie'
import {Lack} from '../../../classes/model/color/lack'
import {TranslateService} from '../../../translate'
import {ComponentModel} from '../../../classes/model/component/model/component-model'
import {ComponentSelectionService} from '../../../classes/service/componentSelectionService'
import {ConstructionComponent} from '../../../classes/model/component/construction/constructionComponent'
import {ConfiguratorModeService} from '../../../classes/service/configuratorMode.service'
import {ViewService} from '../../../classes/service/view.service'
import {DesignOberflaeche} from '../../../classes/model/color/design'
import {Material} from '../../../classes/model/material'
import {NGXLogger} from 'ngx-logger'
import {SettingsService} from 'src/app/classes/service/settings/settings.service'
import {NavigatorService} from '../../../classes/service/navigation/navigator.service'
import {NavigationMenuEntryKey} from '../../../classes/service/navigation/navigation-menu-entry'
import {EventBusSupport} from '../../../classes/service/event/event-bus-support'
import {EventBusService} from '../../../classes/service/event/event-bus.service'
import {
  activeMenuChange,
  configuratedDoorInitialized,
  modelsLoadedAfterGrundformChange,
  selectedComponentChanged
} from '../../../classes/service/event/events'

type ColorItem =
  | (ColorizableComponent &
  Partial<{ Id: number; IsAussen: boolean; IsInnen: boolean }>)
  | 'komplett'
const TRANSLATION_KEY = {
  COLORS_MENU_TITLE: 'ColorsMenu.Title',
  BUTTON_TITLE_LACK_FBS: 'ColorsMenu.Button.Lack.Fbs',
  BUTTON_TITLE_LACK_TTK: 'ColorsMenu.Button.Lack.Ttk',
  BUTTON_TITLE_FOLIE_FBS: 'ColorsMenu.Button.Folie.Fbs',
  BUTTON_TITLE_FOLIE_TTK: 'ColorsMenu.Button.Folie.Ttk',
  BUTTON_TITLE_PULVER: 'ColorsMenu.Button.Pulver',
  BUTTON_TITLE_DESIGN: 'ColorsMenu.Button.Design',
  NO_LACKE_FOR_CONFIG_FBS: 'ColorsMenu.NoLackeForConfig.Fbs',
  NO_LACKE_FOR_CONFIG_TTK: 'ColorsMenu.NoLackeForConfig.Ttk',
  NO_FOLIEN_FOR_CONFIG_FBS: 'ColorsMenu.NoFolienForConfig.Fbs',
  NO_FOLIEN_FOR_CONFIG_TTK: 'ColorsMenu.NoFolienForConfig.Ttk',
  NO_DESIGNS_FOR_CONFIG: 'ColorsMenu.NoDesignsForConfig',
  NO_PULVER_FOR_CONFIG: 'ColorsMenu.NoPulverForConfig',
  COLORIZE_ALL_OUTSIDE: 'ColorsMenu.Dropdown.Colorize.All.Outside',
  COLORIZE_ALL_INSIDE: 'ColorsMenu.Dropdown.Colorize.All.Inside',
  COLORIZE_BLENDRAHMEN_OUTSIDE: 'ColorsMenu.Dropdown.Colorize.Blendrahmen.Outside',
  COLORIZE_BLENDRAHMEN_INSIDE: 'ColorsMenu.Dropdown.Colorize.Blendrahmen.Inside',
  COLORIZE_FLUEGELRAHMEN_OUTSIDE: 'ColorsMenu.Dropdown.Colorize.Fluegelrahmen.Outside',
  COLORIZE_FLUEGELRAHMEN_INSIDE: 'ColorsMenu.Dropdown.Colorize.Fluegelrahmen.Inside',
  COLORIZE_DROPDOWN_OPTION_BARS: 'ColorsMenu.Dropdown.Colorize.Option.Bars',
  VIEW_OUTSIDE: 'ColorsMenu.View.Outside',
  VIEW_INSIDE: 'ColorsMenu.View.Inside',
  COLORTYPE_NOT_SUPPORTED: 'ColorsMenu.ColortypeNotSupported',
  EXTENDED_COLOR_REASONING_ENABLED: 'ColorsMenu.ExtendedColorReasoning.Enabled',
  SEARCH_COLOR_DEKOR: 'ColorsMenu.SearchColorDekor',
  MENU_VIEWSWITCH_OUTSIDE: 'ColorsMenu.ViewSwitch.Outside',
  MENU_VIEWSWITCH_INSIDE: 'ColorsMenu.ViewSwitch.Inside',
  TOOLTIP_MANUFACTURER: 'ColorsMenu.Tooltip.Manufacturer',
  TOOLTIP_EMBOSSMENT: 'ColorsMenu.Tooltip.Embossment',
  TOOLTIP_SURFACE: 'ColorsMenu.Tooltip.Surface',
  TOOLTIP_GLOSSDEGREE: 'ColorsMenu.Tooltip.Glossdegree'
}

@Component({
  selector: 'configurator-colors-menu',
  styleUrls: ['./colors-menu.component.scss'],
  templateUrl: './colors-menu.component.html'
})
export class ColorsMenuComponent extends EventBusSupport implements AfterViewChecked {
  protected readonly Blendrahmen = Blendrahmen
  protected readonly ConfiguratorMode = ConfiguratorMode
  protected readonly DesignOberflaeche = DesignOberflaeche
  protected readonly Folie = Folie
  protected ITEMS_PER_ROW: number
  protected readonly Lack = Lack
  protected readonly NavigationMenuEntryKey = NavigationMenuEntryKey
  protected readonly Pulver = Pulver
  protected readonly SideType = SideType
  protected readonly TRANSLATION_KEY = TRANSLATION_KEY
  protected VIRTUAL_SCROLL_DESIGNOBERFLAECHEN_HEIGHT: number
  protected VIRTUAL_SCROLL_FOLIEN_HEIGHT: number
  protected VIRTUAL_SCROLL_ITEM_HEIGHT: number
  protected colorSearchTerm: string
  protected colorSelectedItem: ColorItem = 'komplett'
  @ViewChild('colorViewPort') protected colorViewPort: CdkVirtualScrollViewport
  protected extendedColorTooltips: boolean
  protected farbenFilter: ColorFilter = 'farben'
  protected filteredColors: ColorBase[][]
  private keyCombinationEventData: {
    counter: number
    firstPressedAt: Date
  }

  constructor(
    public readonly translator: TranslateService,
    private configuratorConfigurationModel: ConfiguratorConfigurationModel,
    private readonly navigatorService: NavigatorService,
    private componentSelection: ComponentSelectionService,
    private viewService: ViewService,
    private configuratorModeService: ConfiguratorModeService,
    protected settingsService: SettingsService,
    private logger: NGXLogger,
    eventBus: EventBusService
  ) {
    super(eventBus)
    this.updateItemHeight()
    this.colorSelectedItem = 'komplett'
    this.colorSearchTerm = ''
    this.farbenFilter = 'farben'
    this.filteredColors = []
    this.keyCombinationEventData = {
      counter: 0,
      firstPressedAt: new Date(0)
    }
    this.extendedColorTooltips = false
    this.subscribe(activeMenuChange, (event): void => {
      if (event.payload === NavigationMenuEntryKey.Color) {
        this.colorViewPort.checkViewportSize()
        this.scrollToSelectedColor()
        this.updateFarbenFilter()
        this.onSelectFarbenFilter(this.farbenFilter)
      }
    })

    this.subscribe(selectedComponentChanged, (): void => {
      this.colorSelectedItem = 'komplett'
      this.setFarbenFilter(this.farbenFilter)
    })
    this.subscribe(modelsLoadedAfterGrundformChange, (): void => {
      this.setFarbenFilter(this.farbenFilter)
    })

    this.subscribe(configuratedDoorInitialized, (): void => {
      this.updateFarbenFilter(true)
      this.setFarbenFilter(this.farbenFilter)
    })
  }

  closeMenu(): void {
    this.navigatorService.closeMenu()
  }

  collectAllColorableElements(): ColorizableComponent[] {
    const colorizableComponents: ColorizableComponent[] = []
    colorizableComponents.push(this.blendrahmen)
    colorizableComponents.push(this.fluegelrahmen)
    if (this.selectedComponent) {
      this.filterComponentsByColorType(
        this.selectedComponent.Deckschichten[this.view]
      ).forEach((ds: Deckschicht): number => colorizableComponents.push(ds))
      this.filterComponentsByColorType(
        this.selectedComponent.optionen[this.view]
      ).forEach((op: Option): number => colorizableComponents.push(op))
      if (
        this.selectedComponent.glasaufbau &&
        this.selectedComponent.glasaufbau.Sprossen
      ) {
        colorizableComponents.push(this.selectedComponent.glasaufbau.Sprossen)
      }
    }
    return colorizableComponents
  }

  colortypeSuitable(colorType: 'lack' | 'folie' | 'pulver'): boolean {
    if (this.colorSelectedItem instanceof ColorizableComponent) {
      let colors: ColorBase[]
      if (colorType === 'folie') {
        colors = this.fbsModel.Folien
      } else if (colorType === 'pulver') {
        colors = this.fbsModel.Pulver
      } else if (colorType === 'lack') {
        colors = this.fbsModel.Farben
      }
      if (colors) {
        return (
          this.colorSelectedItem.filterColors(
            colors,
            this.view,
            this.colorSelectedItem instanceof Blendrahmen
              ? this.configuratorConfigurationModel.configuratedDoor?.profile
                .Material
              : this.element.material
          ).length !== 0
        )
      }
    } else if (
      this.colorSelectedItem === 'komplett' &&
      colorType === 'pulver'
    ) {
      let pulverPossible: boolean = false
      const comps = this.collectAllColorableElements()
      comps.forEach((ccomp: ColorizableComponent): void => {
        if (!pulverPossible) {
          pulverPossible = ccomp.IsPulverMoeglich
        }
      })
      return pulverPossible
    }
    return true
  }

  dekorBezeichnung(folie: Folie, short?: boolean): string {
    return short ? this.translator.translate(folie.Bezeichnung)
      : this.translator.translate(folie.Beschreibung)
      + ' | ' + this.translator.translate(folie.Hersteller)
      + ' | ' + this.translator.translate(folie.Praegung)
      + ' - [' + this.translator.translate(folie.Bezeichnung) + ']'
  }

  farbenFilterMapping(colorization: ColorType): ColorFilter {
    switch (colorization) {
      case 'farbe':
        return 'farben'
      case 'pulver':
        return 'pulver'
      case 'folie':
        return 'folien'
      case 'designoberflaeche':
        return 'designoberflaechen'
    }
  }

  fbsColorActiveCheck(
    colorSelectedItem: ColorItem,
    color: ColorBase,
    type: ColorType
  ): boolean {
    const colorization = this.getColorization(colorSelectedItem)
    return (
      colorization && type === colorization.Typ && color.Id === colorization.Id
    )
  }

  filterColorElements<Color extends ColorBase>(colorArray: Color[]): Color[] {
    if (this.colorSelectedItem instanceof ColorizableComponent) {
      return this.filteredColor(
        this.colorSelectedItem.filterColors(
          colorArray,
          this.view,
          this.element.material
        )
      )
    } else if (this.colorSelectedItem === 'komplett') {
      return this.filteredColor(colorArray)
    } else {
      console.error('FiterColorElements returned all colors')
      return this.filteredColor(colorArray)
    }
  }

  filterComponentsByColorType<C extends ColorizableComponent>(
    components: C[]
  ): C[] {
    const property = (
      {
        farben: 'IsLackMoeglich',
        folien: 'IsFolieMoeglich',
        pulver: 'IsPulverMoeglich',
        designoberflaechen: 'IsDesignOberflaecheMoeglich'
      } as const
    )?.[this.farbenFilter]
    return components && property
      ? components.filter((d): boolean => d?.[property])
      : []
  }

  filteredColor<Color extends ColorBase>(colorArray: Color[]): Color[] {
    return colorArray.filter(
      (c): boolean =>
        this.translator
          .translate(c?.Bezeichnung)
          ?.toLowerCase()
          .includes(this.colorSearchTerm.toLowerCase()) ||
        this.translator
          .translate(c?.RodenbergCode)
          ?.toLowerCase()
          .includes(this.colorSearchTerm.toLowerCase()) ||
        this.translator
          .translate(c?.Beschreibung)
          ?.toLowerCase()
          .includes(this.colorSearchTerm.toLowerCase()) ||
        ((c instanceof Folie || c instanceof Pulver) &&
          this.translator
            .translate(c.Hersteller)
            ?.toLowerCase()
            .includes(this.colorSearchTerm.toLowerCase())) ||
        (c instanceof Folie &&
          this.translator
            .translate(c.Praegung)
            ?.toLowerCase()
            .includes(this.colorSearchTerm.toLowerCase()))
    )
  }

  getColorIdentifierFromBeschichtung(
    bs?: Beschichtung
  ): SimpleIdentifier | null {
    return bs && bs.Id && bs.Typ
      ? {
        Id: bs.Id,
        Typ: bs.Typ
      }
      : null
  }

  getColorIdentifierFromSubElement(): SimpleIdentifier | null {
    if (this.deckschicht) {
      return this.getColorIdentifierFromBeschichtung(
        this.deckschicht.Beschichtung
      )
    } else if (this.fluegelrahmen) {
      return this.getColorIdentifierFromBeschichtung(
        this.fluegelrahmen.Beschichtung
      )
    } else if (this.blendrahmen) {
      return this.getColorIdentifierFromBeschichtung(
        this.blendrahmen.Beschichtung
      )
    }
  }

  getColorWarningTooltip(color: ColorBase): string {
    const colorizableComponents: ColorizableComponent[] = []
    if (this.colorSelectedItem === 'komplett') {
      colorizableComponents.push(
        ...([] as typeof colorizableComponents).concat(
          [this.blendrahmen, this.fluegelrahmen],
          this.filterComponentsByColorType(
            this.element.Deckschichten[this.view]
          ),
          this.filterComponentsByColorType(
            this.componentSelection.selectedComponent.optionen[this.view]
          ),
          this.element &&
          this.element.glasaufbau &&
          this.element.glasaufbau.Sprossen &&
          (this.element.glasaufbau.Sprossen.IsFolieMoeglich ||
            this.element.glasaufbau.Sprossen.IsLackMoeglich ||
            this.element.glasaufbau.Sprossen.IsPulverMoeglich ||
            this.element.glasaufbau.Sprossen.IsDesignOberflaecheMoeglich)
            ? [this.element.glasaufbau.Sprossen]
            : []
        )
      )
    } else {
      colorizableComponents.push(this.colorSelectedItem)
    }
    const tooltipWarning = colorizableComponents
      .filter(
        (c): boolean =>
          !c.isColorSupported(color, this.view, this.element.material)
      )
      .reduce<string>(
      (acc, curr): string =>
        ((bezeichnung: string): string =>
          acc +
            '\n-' +
            bezeichnung +
            (this.extendedColorTooltips
              ? '\n' +
              curr.getColorSupportReasoning(
                color,
                this.view,
                this.element.material,
                bezeichnung
              ) +
              '\n\n'
              : ''))(
          this.translator.translate(
            'Bezeichnung' in curr && typeof curr.Bezeichnung === 'string'
              ? curr?.Bezeichnung
              : curr.constructor.name
          )
        ),
      ''
    )
    if (tooltipWarning !== '') {
      return (
        '\n⚠ Farbe kann auf folgende Komponenten nicht angewandt werden:' +
        tooltipWarning
      )
    }
    return ''
  }

  getColorization(colorSelectedItem: ColorItem): SimpleIdentifier | null {
    if (typeof colorSelectedItem === 'string') {
      return this.getColorIdentifierFromSubElement()
    } else if (
      colorSelectedItem instanceof Blendrahmen ||
      colorSelectedItem instanceof Deckschicht ||
      colorSelectedItem instanceof Fluegelrahmen ||
      colorSelectedItem instanceof Option ||
      colorSelectedItem instanceof Sprossen
    ) {
      return this.getColorIdentifierFromBeschichtung(
        colorSelectedItem?.Beschichtung
      )
    }
    return null
  }

  getColorsForFilter(colorFilter: ColorFilter): ColorBase[][] {
    let colors: ColorBase[][] = []
    if (colorFilter === 'folien') {
      colors = ArrayUtil.groupedArray(
        this.filterColorElements(this.fbsModel?.Folien),
        this.ITEMS_PER_ROW
      )
    }
    if (colorFilter === 'designoberflaechen') {
      colors = ArrayUtil.groupedArray(
        this.filterColorElements(this.fbsModel?.DesignOberflaechen),
        this.ITEMS_PER_ROW
      )
    }
    if (colorFilter === 'pulver') {
      colors = ArrayUtil.groupedArray(
        this.filterColorElements(this.fbsModel?.Pulver),
        this.ITEMS_PER_ROW
      )
    }
    if (colorFilter === 'farben') {
      colors = ArrayUtil.groupedArray(
        this.filterColorElements(
          this.fbsModel?.Farben.filter((f): string => f.RGB)
        ),
        this.ITEMS_PER_ROW
      )
    }
    return colors
  }

  getDesignOberflaecheTooltip(designoberflaeche: DesignOberflaeche): string {
    return (
      (designoberflaeche.Beschreibung
        ? this.translator.translate(designoberflaeche.Beschreibung) + '\n'
        : '') +
      (designoberflaeche.Bezeichnung
        ? this.translator.translate(designoberflaeche.Bezeichnung)
        : '') +
      this.getColorWarningTooltip(designoberflaeche)
    )
  }

  getFolieTooltip(folie: Folie): string {
    return (
      (folie.Beschreibung
        ? this.translator.translate(folie.Beschreibung) + '\n'
        : '') +
      (folie.Praegung
        ? this.translator.translate(TRANSLATION_KEY.TOOLTIP_EMBOSSMENT) +
        ': ' +
        this.translator.translate(folie.Praegung) +
        '\n'
        : '') +
      (folie.Hersteller
        ? this.translator.translate(TRANSLATION_KEY.TOOLTIP_MANUFACTURER) +
        ': ' +
        this.translator.translate(folie.Hersteller) +
        '\n'
        : '') +
      (folie.Bezeichnung ? this.translator.translate(folie.Bezeichnung) : '') +
      this.getColorWarningTooltip(folie)
    )
  }

  getLackTooltip(lack: Lack): string {
    return (
      (lack.Bezeichnung ? this.translator.translate(lack.Bezeichnung) : '') +
      this.getColorWarningTooltip(lack)
    )
  }

  getPulverTooltip(pulver: Pulver): string {
    return (
      (pulver.Beschreibung
        ? this.translator.translate(pulver.Beschreibung) + '\n'
        : '') +
      (pulver.Bezeichnung
        ? this.translator.translate(pulver.Bezeichnung) + '\n'
        : '') +
      (pulver.Oberflaeche
        ? this.translator.translate(TRANSLATION_KEY.TOOLTIP_SURFACE) +
        ': ' +
        this.translator.translate(pulver.Oberflaeche) +
        '\n'
        : '') +
      (pulver.Glanzgrad
        ? this.translator.translate(TRANSLATION_KEY.TOOLTIP_GLOSSDEGREE) +
        ': ' +
        this.translator.translate(pulver.Glanzgrad) +
        '\n'
        : '') +
      (pulver.RodenbergCode ? pulver.RodenbergCode : '') +
      this.getColorWarningTooltip(pulver)
    )
  }

  getSelectedColorId(colorSelectedItem: ColorItem): number | null {
    const colorization = this.getColorization(colorSelectedItem)
    if (
      colorization &&
      colorization.Id &&
      (colorization.Typ === this.farbenFilter ||
        colorization.Typ === this.farbenFilter.slice(0, -1))
    ) {
      return colorization.Id
    }
    return null
  }

  ngAfterViewChecked(): void {
    if (this.colorViewPort.getViewportSize() === 0) {
      this.colorViewPort.checkViewportSize()
    }
  }

  onDebugKeyCombinationPress(): void {
    if (
      Date.now() - this.keyCombinationEventData.firstPressedAt.getTime() >
      2_500
    ) {
      this.keyCombinationEventData.firstPressedAt = new Date()
      this.keyCombinationEventData.counter = 0
    }
    if (++this.keyCombinationEventData.counter >= 5) {
      this.extendedColorTooltips = !this.extendedColorTooltips
      this.keyCombinationEventData.counter = 0
    }
  }

  @HostListener('window:resize')
  onResize(): void {
    this.updateItemHeight()
  }

  onSelectColor(color: ColorBase): void {
    this.configuratorConfigurationModel.fbsSelectColor(
      this.colorSelectedItem,
      color,
      this.view,
      this.element.material
    )
  }

  onSelectFarbenFilter(selected: ColorFilter): void {
    let nocolors: boolean = true
    switch (selected) {
      case 'farben':
        nocolors = this.fbsModel?.Farben?.length === 0
        break
      case 'pulver':
        nocolors = this.fbsModel?.Pulver?.length === 0
        break
      case 'folien':
        nocolors = this.fbsModel?.Folien?.length === 0
        break
      case 'designoberflaechen':
        nocolors = this.fbsModel?.DesignOberflaechen?.length === 0
        break
    }
    if (nocolors) {
      return
    }
    this.colorSearchTerm = ''
    this.setFarbenFilter(selected)
    // this.selectedElementColorType()
  }

  pluralizeColor(type: ColorType): ColorFilter {
    if (type === 'farbe') {
      return 'farben'
    }
    if (type === 'folie') {
      return 'folien'
    }
    if (type === 'pulver') {
      return 'pulver'
    }
    if (type === 'designoberflaeche') {
      return 'designoberflaechen'
    }

    this.logger.trace(`Colortype ${type as string} is unknown - return default ColorType: "Farben"`)
    return 'farben'
  }

  resetColorTerm(): void {
    this.colorSearchTerm = ''
    this.setFarbenFilter(this.farbenFilter)
  }

  scrollToSelectedColor(): void {
    setTimeout((): void => {
      if (this.colorViewPort) {
        const selectedColorId = this.getSelectedColorId(this.colorSelectedItem)
        if (selectedColorId) {
          const groupIndex = this.filteredColors?.findIndex((group): boolean =>
            group.some((item): boolean => item.Id === selectedColorId)
          )
          if (groupIndex > -1) {
            const SCROLL_DISTANCE = 5
            if (groupIndex >= SCROLL_DISTANCE) {
              this.colorViewPort?.scrollToIndex(
                groupIndex - SCROLL_DISTANCE,
                'instant'
              )
            }
            this.colorViewPort.scrollToIndex(groupIndex, 'smooth')
          }
        }
      }
    })
  }

  selectedElementColorType(): void {
    const colortype = this.getColorization(this.colorSelectedItem)
    const newFarbenFilter = this.farbenFilterMapping(colortype?.Typ)
    if (this.getColorsForFilter(newFarbenFilter).length > 0) {
      this.farbenFilter = newFarbenFilter
      this.onSelectFarbenFilter(this.farbenFilter)
    }
  }

  setFarbenFilter(selected: ColorFilter): void {
    this.farbenFilter = selected
    if (!this.fbsModel) {
      return
    }
    this.filteredColors = this.getColorsForFilter(this.farbenFilter)
    this.scrollToSelectedColor()
  }

  sidePossible(color: ColorBase): boolean {
    // blend und fluegelrahmen immer moeglich
    if (this.colorSelectedItem instanceof Blendrahmen && this.colorSelectedItem instanceof Fluegelrahmen) {
      return true
    }

    // wenn ein anderes Element oder 'Komplett' gewählt ist, muss auch Innen oder Aussen möglich sein
    return this.view === SideType.Outside && color.IsAussenMoeglich || this.view === SideType.Inside && color.IsInnenMoeglich
  }

  updateFarbenFilter(initialLoad: boolean = false): void {
    let colorOrder: ColorFilter[]
    if (this.configuratorMode === ConfiguratorMode.TTK) {
      colorOrder = ['farben', 'folien', 'designoberflaechen']
    } else if (initialLoad) {
      colorOrder = ['farben', 'folien', 'pulver', 'designoberflaechen']
      if ((this.selectedComponent.material as string) === 'ALU') {
        colorOrder = ['pulver', 'farben', 'folien', 'designoberflaechen']
      } else if ((this.selectedComponent.material as string) === 'KU') {
        colorOrder = ['folien', 'farben', 'pulver', 'designoberflaechen']
      }
    }

    if (initialLoad) {
      this.colorSelectedItem = 'komplett'
    }

    // only update Farbenfilter, if it's the initial load OR if the current farbenFilter shows no colors
    if (
      initialLoad ||
      this.getColorsForFilter(this.farbenFilter).length === 0
    ) {
      for (let i = 0; i <= colorOrder.length; i++) {
        if (this.getColorsForFilter(colorOrder[i]).length > 0) {
          this.farbenFilter = colorOrder[i]
          break
        }
      }
    }

    if (!initialLoad) {

      if (this.colorSelectedItem === 'komplett' && this.selectedComponent.material !== Material.Glas) {
        const deckschichtColoring = this.selectedComponent?.Deckschichten?.[this.view]?.[0]?.Beschichtung?.Typ
        if (deckschichtColoring) {
          this.farbenFilter = this.pluralizeColor(deckschichtColoring)
        }
      } else if (this.colorSelectedItem === 'komplett' && this.selectedComponent.material === Material.Glas) {
        this.farbenFilter = this.pluralizeColor(this.selectedComponent?.Fluegelrahmen?.[this.view]?.Beschichtung?.Typ)
      } else if (this.colorSelectedItem !== 'komplett') {
        const beschichtung = this.colorSelectedItem?.Beschichtung

        if (this.view in beschichtung) {
          this.farbenFilter = this.pluralizeColor((beschichtung[this.view] as Beschichtung)?.Typ)
        } else if (beschichtung instanceof Beschichtung) {
          this.farbenFilter = this.pluralizeColor(beschichtung?.Typ)
        }

      }

    }
  }

  updateItemHeight(): void {
    if (window.innerWidth <= 767) {
      this.ITEMS_PER_ROW = 4
      this.VIRTUAL_SCROLL_ITEM_HEIGHT = parseInt(((window.innerWidth * 0.8) / this.ITEMS_PER_ROW).toString(), 10) + 30
      this.VIRTUAL_SCROLL_FOLIEN_HEIGHT = parseInt(((window.innerWidth * 0.8) / this.ITEMS_PER_ROW).toString(), 10) + 100
      this.VIRTUAL_SCROLL_DESIGNOBERFLAECHEN_HEIGHT = parseInt(((window.innerWidth * 0.8) / this.ITEMS_PER_ROW).toString(), 10) + 100
    } else {
      this.ITEMS_PER_ROW = 2
      this.VIRTUAL_SCROLL_ITEM_HEIGHT = 200
      this.VIRTUAL_SCROLL_FOLIEN_HEIGHT = 200
      this.VIRTUAL_SCROLL_DESIGNOBERFLAECHEN_HEIGHT = 200
    }
    if (this.fbsModel) {
      this.setFarbenFilter(this.farbenFilter)
    }
  }

  get blendrahmen(): Blendrahmen {
    return (
      this.configuratorConfigurationModel.configuratedDoor.Blendrahmen[
        this.view
      ] ?? new Blendrahmen()
    )
  }

  get configuratorMode(): ConfiguratorMode {
    return this.configuratorModeService.mode
  }

  get deckschicht(): Deckschicht | null {
    if (this.componentSelection.selectedComponent.hasDeckschichten(this.view)) {
      return this.componentSelection.selectedComponent.Deckschichten[
        this.view
      ][0]
    }
    return null
  }

  get element(): Tuer | Seitenteil | Oberlicht {
    return this.componentSelection.selectedComponent
  }

  get fbsModel(): ComponentModel {
    return this.componentSelection.selectedComponent.model
  }

  get fluegelrahmen(): Fluegelrahmen {
    return this.element.Fluegelrahmen[this.view] ?? new Fluegelrahmen()
  }

  get selectedComponent(): ConstructionComponent {
    return this.componentSelection.selectedComponent
  }

  get view(): SideType {
    return this.viewService.currentSide
  }

  set view(side: SideType) {
    if (side && this.viewService.currentSide !== side) {
      this.viewService.view = side
      this.colorSelectedItem = 'komplett'
      this.setFarbenFilter(this.farbenFilter)
      this.scrollToSelectedColor()
    }
  }
}
