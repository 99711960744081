import {NgModule, Provider} from '@angular/core'
import {LOAD_STRATEGY, LoadStrategy} from './load-strategy'
import {LoadService} from './load.service'
import {ConfiguratorInitializerService} from './configurator-initializer.service'
import {ConfigurationLoaderService} from './configuration-loader.service'

const providers = [
  ConfiguratorInitializerService,
  ConfigurationLoaderService,
  LoadService,
  LoadStrategy,
  {provide: LOAD_STRATEGY, useExisting: LoadStrategy},
]

@NgModule({providers})
export class LoadModule {
  static provide(): Provider[] {
    return providers
  }
}

export {ConfiguratorInitializerService, ConfigurationLoaderService, LoadService, LOAD_STRATEGY}
