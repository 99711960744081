import {createEventDefinition} from '@d-m-p/ts-bus'
import {ZubehoerChangeEventPayload} from '../../model/event/zubehoer-change.event'
import {AddonChangeEventPayload} from '../../model/event/addon-change.event'
import {ComponentValueChange, HouseFrontChange, ValueChange} from './type'
import {Grundform} from '../../model/component/other/grundform'
import {ActivableNavigationMenu} from '../navigation/activable-navigation-menu.type'
import {CompactComponentModel} from '../../api/model/compact-component-model.interface'
import {SideType} from '../../../types'
import {Material} from '../../model/material'
import {Paket} from '../../model/component/other/paket'
import {ConstructionComponent} from '../../model/component/construction/constructionComponent'

export const activeMenuChange =
  createEventDefinition<ActivableNavigationMenu, { focusSearchInputRequest: boolean }>()('event.active-menu.change')
export const closeCharacteristicsMenuRequest = createEventDefinition()('event.close-charasteristics-menu-request')
export const configuratedDoorInitialized = createEventDefinition()('event.configurated-door.initialized')
export const configuratedDoorOpened = createEventDefinition()('event.configurated-door.opened')
export const constructionVariationChanged = createEventDefinition()('event.constructionVariationChanged')
export const houseFrontChanged = createEventDefinition<HouseFrontChange>()('event.house-front.changed')
export const fillingMaterialChanged = createEventDefinition<ComponentValueChange<Material>>()('event.filling-material.changed')
export const amountGlassesChanged = createEventDefinition<ComponentValueChange<number>>()('event.glasses.amount.changed')
export const grundformChange = createEventDefinition<ValueChange<Grundform>>()('event.grundformChange')
export const loadRequestDoorId = createEventDefinition<string>()('event.load-request.door-id')
export const modelSelectRequest = createEventDefinition<CompactComponentModel>()('event.model-select-request')
export const modelsLoadedAfterGrundformChange = createEventDefinition()('event.modelsLoadedAfterGrundformChange')
export const optionChanged = createEventDefinition()('event.option.changed')
export const renderRequest = createEventDefinition()('event.renderRequest')
export const securityPackageChanged = createEventDefinition<ValueChange<Paket | null>>()('event.package.security.changed')
export const selectedCatalogueChange = createEventDefinition<ValueChange<number | null>>()('event.selectedCatalogueChange')
export const selectedComponentChanged = createEventDefinition<ConstructionComponent>()('event.selected-component.changed')
// export const simpleConstructionChanged = createEventDefinition()('event.simpleConstructionChanged')
export const systemMaterialChanged = createEventDefinition<ValueChange<Material>>()('event.system-material.changed')
export const thermalInsulationPackageChanged =
  createEventDefinition<ValueChange<Paket | null>>()('event.package.thermal-insulation.changed')
export const trackRequest = createEventDefinition<string>()('event.trackRequest')
export const zMassChanged = createEventDefinition<ComponentValueChange<number>>()('event.zMassChanged')
export const zubehoerAddonChanged = createEventDefinition<AddonChangeEventPayload>()('event.zubehoer.addon.changed')
export const zubehoerChanged = createEventDefinition<ZubehoerChangeEventPayload>()('event.zubehoer.changed')
export const viewChanged = createEventDefinition<ValueChange<SideType>>()('event.view.changed')
