import {ColorBase} from './colorBase'
import {ColorType} from '../../../types'
import {Color} from '../../api/model/color/color.interface'

export class Lack extends ColorBase {
  Beschreibung: string
  Bezeichnung: string
  Id: number
  ImageUrl: string
  IsAluMoeglich: boolean
  IsAussenMoeglich: boolean
  IsDeckschichtMoeglich: boolean
  IsDeckschichtfarbe: boolean
  IsGlasMoeglich: boolean
  IsInnenMoeglich: boolean
  IsKunststoffMoeglich: boolean
  IsLackstrasse: boolean
  IsMehrpreis: boolean
  PreviewImageUrl: string
  RALBezeichnung: string
  RALCode: string
  RGB: string
  RodenbergCode: string
  Rodenbergbezeichnung: string
  Typ: ColorType
  readonly objectType = 'Lack'

  constructor(data?: Partial<Color>) {
    super(data)
    this.Beschreibung = data?.Beschreibung
    this.Bezeichnung = data?.Bezeichnung
    this.Id = data?.Id
    this.ImageUrl = data?.ImageUrl
    this.IsAluMoeglich = data?.IsAluMoeglich
    this.IsAussenMoeglich = data?.IsAussenMoeglich
    this.IsDeckschichtfarbe = data?.IsDeckschichtfarbe
    this.IsDeckschichtMoeglich = data?.IsDeckschichtMoeglich
    this.IsGlasMoeglich = data?.IsGlasMoeglich
    this.IsInnenMoeglich = data?.IsInnenMoeglich
    this.IsKunststoffMoeglich = data?.IsKunststoffMoeglich
    this.IsLackstrasse = data?.IsLackstrasse
    this.IsMehrpreis = data?.IsMehrpreis
    this.PreviewImageUrl = data?.PreviewImageUrl
    this.RALBezeichnung = data?.RALBezeichnung
    this.RALCode = data?.RALCode
    this.RGB = data?.RGB
    this.Rodenbergbezeichnung = data?.Rodenbergbezeichnung
    this.RodenbergCode = data?.RodenbergCode
    this.Typ = data?.Typ
  }

  getType(): ColorType {
    return 'farbe'
  }
}
