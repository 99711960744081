<ng-template #loadButtonFooter>
  <button
    (click)='loadConfiguration()' aria-label='{{TRANSLATION_KEY.TITLE_DEFAULT | translate}}'
    color='primary'
    mat-flat-button
    title='{{TRANSLATION_KEY.TITLE_DEFAULT | translate}}'
  >
    <i class='fa fa-folder-open'></i>
    <span>{{ TRANSLATION_KEY.TITLE_DEFAULT | translate }}</span>
  </button>
</ng-template>

<modal-frame
  [footer]='modalFooter'
  [modalCloseButton]='false'
  [title]='modalTitle | translate'
>
  <ng-container [ngSwitch]='state'>
    <mat-form-field (keydown.enter)='loadConfiguration()' *ngSwitchCase="'INITIAL'">
      <mat-label>{{ TRANSLATION_KEY.LOAD_ID_INPUT_LABEL|translate }}</mat-label>
      <input [(ngModel)]='doorIdValue' class='text-left' matInput
             placeholder='{{TRANSLATION_KEY.LOAD_ID_INPUT_PLACEHOLDER|translate}}'>
      <mat-hint align='start'><strong>{{ TRANSLATION_KEY.LOAD_ID_INPUT_HINT|translate }}</strong></mat-hint>
      <button
        (click)='loadConfiguration()'
        [attr.aria-label]='TRANSLATION_KEY.LOAD_ID_BUTTON_LABEL|translate'
        [title]='TRANSLATION_KEY.LOAD_ID_BUTTON_LABEL|translate'
        mat-icon-button
        matSuffix
      >
        <i class='fa fa-folder-open'></i>
      </button>
    </mat-form-field>
    <ng-container *ngSwitchCase="'LOADING'">
      <i class='fa fa-spinner fa-pulse fa-fw'></i>
      {{ TRANSLATION_KEY.LOADING_CONFIGURATION|translate }}
    </ng-container>
    <ng-container *ngSwitchCase="'LOADED'">
      <img *loadingAnimation="doorImageUrl, background: true, sizing: 'css-variable'">
      <configurator-component-selector></configurator-component-selector>
    </ng-container>
  </ng-container>
</modal-frame>

