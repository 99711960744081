<div class="subNavigationContainer active">
  <div class="name">
    <span>{{ TRANSLATION_KEY.MENU_NAME | translate }}</span>
    <span (click)="closeMenu()" class="close"></span>
  </div>
  <div class="container">
    <div class="container-inner">
      <ng-container [ngSwitch]="configuratorMode">
        <configurator-component-selector *ngSwitchCase="ConfiguratorMode.TTK"></configurator-component-selector>
        <ng-container
          *ngSwitchCase="ConfiguratorMode.FBS"
          [ngSwitch]="!!(this.doorWeight && this.insulation)"
        >
          <table *ngSwitchCase="true" class="calc-values">
            <tr>
              <td>
                <p>{{ TRANSLATION_KEY.TABLE_CALC_LABEL_WEIGHT | translate }}</p>
              </td>
              <td>
                <p class="weight">{{
                    TRANSLATION_KEY.TABLE_CALC_VALUE_WEIGHT_PREFIX | translate
                  }}{{
                    this.doorWeight
                  }}{{
                    TRANSLATION_KEY.TABLE_CALC_VALUE_WEIGHT_SUFFIX | translate
                  }}</p>
              </td>
              <td>
                <p>{{ TRANSLATION_KEY.TABLE_CALC_LABEL_INSULATION_UP | translate }}</p>
              </td>
              <td>
                <p class="insulation">{{ this.insulation.Up }}</p>
              </td>
              <td>
                <p>{{ TRANSLATION_KEY.TABLE_CALC_LABEL_INSULATION_UG | translate }}</p>
              </td>
              <td>
                <p class="insulation">{{ this.insulation.Ug }}</p>
              </td>
            </tr>
          </table>
          <ng-container *ngSwitchDefault>
            {{ TRANSLATION_KEY.LOADING_IN_PROGRESS | translate }}
            <i class="fa fa-2x fa-spinner fa-spin"></i>
          </ng-container>
        </ng-container>
        <ng-container
          *ngSwitchCase="ConfiguratorMode.TTK"
        >
          <ng-container
            *ngTemplateOutlet="summeryTable; context: {
                            tableData: characteristics.getCommonCharacteristicsData(),
                            tableHeading: TRANSLATION_KEY.TABLE_HEADING_COMMON | translate
                        }"
          >
          </ng-container>
        </ng-container>
      </ng-container>
      <ng-container
        *ngTemplateOutlet="summeryTable; context: {
                        tableData: characteristics.getComponentCharacteristicsData(selectedComponentIndex),
                        tableHeading: selectedComponentLabel
                }"
      >
      </ng-container>
    </div>
  </div>
</div>

<ng-template #summeryTable let-tableData="tableData" let-tableHeading="tableHeading">
  <h2 *ngIf="configuratorMode === ConfiguratorMode.TTK">{{ tableHeading }}</h2>
  <table [dataSource]="tableData" class="summary" mat-table>
    <!-- Name Column -->
    <ng-container matColumnDef="Prefix">
      <th *matHeaderCellDef mat-header-cell>{{ TRANSLATION_KEY.TABLE_SUMMARY_LABEL_PREFIX | translate }}</th>
      <td *matCellDef="let element" mat-cell> {{ element.Prefix | translate }}</td>
    </ng-container>

    <!-- Weight Column -->
    <ng-container matColumnDef="Titel">
      <th *matHeaderCellDef mat-header-cell>{{ TRANSLATION_KEY.TABLE_SUMMARY_LABEL_TITLE | translate }}</th>
      <td *matCellDef="let element" mat-cell> {{ element.Titel | translate }}</td>
    </ng-container>

    <!-- Symbol Column -->
    <ng-container matColumnDef="Text">
      <th *matHeaderCellDef mat-header-cell>{{ TRANSLATION_KEY.TABLE_SUMMARY_LABEL_TEXT | translate }}</th>
      <td *matCellDef="let element" mat-cell> {{ element.Text | translate }}</td>
    </ng-container>

    <tr *matHeaderRowDef="['Prefix','Titel','Text']" mat-header-row></tr>
    <tr *matRowDef="let _; columns: ['Prefix','Titel','Text']" mat-row></tr>
    <tr *matNoDataRow class="mat-mdc-row mdc-data-table__row cdk-row">
      <td class="mat-mdc-cell mdc-data-table__cell cdk-cell" colspan="3" role="cell">
        {{ TRANSLATION_KEY.TABLE_SUMMARY_NO_DATA | translate }} - {{ TRANSLATION_KEY.LOADING_IN_PROGRESS | translate }}
        <i class="fa fa-2x fa-spin fa-spinner"></i>
      </td>
    </tr>
  </table>
</ng-template>
