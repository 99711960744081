import {InjectionToken, ModuleWithProviders, NgModule} from '@angular/core'

@NgModule()
export default class WorkflowModule {
  public static forRoot(massblattUpdateDebounceTimeMs: number = 250): ModuleWithProviders<WorkflowModule> {
    return {
      ngModule: WorkflowModule,
      providers: [
        {provide: MassblattUpdateDebounceTimeMs, useValue: massblattUpdateDebounceTimeMs}
      ]
    }
  }
}

export const MassblattUpdateDebounceTimeMs: InjectionToken<number> = new InjectionToken(
  'Workflow.MassblattUpdateDebounceTimeMs',
  {
    providedIn: WorkflowModule,
    factory: (): number => 250
  }
)
