import {Component, Inject, isDevMode} from '@angular/core'
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog'
import {StringUtil} from '../../../classes/util/stringUtil'
import {Zubehoer} from '../../../classes/model/component/extras/zubehoer/zubehoer'
import {MehrpreisCategory} from '../../../classes/model/component/extras/mehrpreis/fbsMehrpreis'
import {Material} from '../../../classes/model/material'
import {ComponentSelectionService} from '../../../classes/service/componentSelectionService'
import {TranslateService} from 'src/app/translate'
import {TagService} from '../../../classes/service/tag.service'
import {Tag} from '../../../classes/model/component/other/tag'

const TRANSLATION_KEY = {
  TITLE_PREFIX: 'MehrpreisModal.Title.Prefix',
  TITLE_SUFFIX: 'MehrpreisModal.Title.Suffix',
  MODAL_SEARCH_INPUT_LABEL: 'MehrpreisModal.Search.Input.Label',
  MODAL_BUTTON_SAVE: 'MehrpreisModal.Button.Save',
  REASON_THICKNESS_TOO_SMALL_PREFIX: 'MehrpreisModal.DisabilityReason.ThicknessTooSmall.Prefix',
  REASON_THICKNESS_TOO_SMALL_INFIX: 'MehrpreisModal.DisabilityReason.ThicknessTooSmall.Infix',
  REASON_THICKNESS_TOO_SMALL_SUFFIX: 'MehrpreisModal.DisabilityReason.ThicknessTooSmall.Suffix',
  REASON_THICKNESS_TOO_LARGE_PREFIX: 'MehrpreisModal.DisabilityReason.ThicknessTooLarge.Prefix',
  REASON_THICKNESS_TOO_LARGE_INFIX: 'MehrpreisModal.DisabilityReason.ThicknessTooLarge.Infix',
  REASON_THICKNESS_TOO_LARGE_SUFFIX: 'MehrpreisModal.DisabilityReason.ThicknessTooLarge.Suffix',
  REASON_ALU_IMPOSSIBLE: 'MehrpreisModal.DisabilityReason.Alu.Impossible',
  REASON_GLASS_IMPOSSIBLE: 'MehrpreisModal.DisabilityReason.Glass.Impossible',
  REASON_KU_IMPOSSIBLE: 'MehrpreisModal.DisabilityReason.Ku.Impossible',
  REASON_DINLEFT_IMPOSSIBLE: 'MehrpreisModal.DisabilityReason.DinLeft.Impossible',
  REASON_DINRIGHT_IMPOSSIBLE: 'MehrpreisModal.DisabilityReason.DinRight.Impossible',
  REASON_NOT_UNSELECTABLE: 'MehrpreisModal.DisabilityReason.NotUnselectable',
  REASON_NOT_SELECTABLE: 'MehrpreisModal.DisabilityReason.NotSelectable',
  MORE_FILTERS: 'MehrpreisModal.MoreFilters',
  HIDE_FILTERS: 'MehrpreisModal.HideFilters',
  SHOW_FILTERS: 'MehrpreisModal.ShowFilters'
}
export type MehrpreisModalData = {
  category: MehrpreisCategory
}

@Component({
  templateUrl: './mehrpreis-modal.component.html',
  styleUrls: ['./mehrpreis-modal.component.scss']
})
export class MehrpreisModalComponent {
  protected readonly StringUtil = StringUtil
  protected readonly TRANSLATION_KEY = TRANSLATION_KEY
  activeFilters: Tag[]
  category: string
  filterTerm: string
  protected readonly isDevMode = isDevMode
  preselectedZubehoer: Zubehoer
  selectedZubehoerIds: number[]
  showAllTags: boolean
  visibleFilters: Tag[]
  public windowRef: Window = window
  zubehoer: Zubehoer[]

  constructor(
    public dialogRef: MatDialogRef<MehrpreisModalComponent, Zubehoer | undefined>,
    private componentSelection: ComponentSelectionService,
    private tagService: TagService,
    private translator: TranslateService,
    @Inject(MAT_DIALOG_DATA) public data: MehrpreisModalData
  ) {
    this.showAllTags = false
    this.category = data.category
    this.filterTerm = ''
    this.activeFilters = []
    this.visibleFilters = []
    this.zubehoer = this.filterZubehoer(this.componentSelection.selectedComponent.model.Zubehoer)
    this.visibleFilters = this.tags
    this.preselectedZubehoer = undefined
    this.selectedZubehoerIds = []
    this.selectedZubehoerIds = this.getSelectedZubehoerIds()
  }

  addFilter(t: Tag): void {
    this.activeFilters.push(t)
  }

  private filterZubehoer(zubehoer: readonly Zubehoer[]): Zubehoer[] {
    if (zubehoer) {
      const filtered = zubehoer.filter((z): boolean => z.Typ === this.category)
      // order by Sort then By Bezeichnung and Beschreibung
      /* filtered = filtered.sort(
        (a, b): number => (a.Sort > b.Sort)
          ? 1
          : (a.Bezeichnung > b.Bezeichnung)
            ? 1
            : (a.Bezeichnung === b.Bezeichnung)
              ? (
                (a.Beschreibung > b.Beschreibung)
                  ? 1
                  : -1
              )
              : -1
      )*/
      const selected = this.getSelectedZubehoer(this.category)
      if (selected) {
        const index = filtered.map((e): number => e.Id).indexOf(selected.Id)
        filtered.splice(index, 1)
        filtered.unshift(selected)
      }
      return filtered
    } else {
      return []
    }
  }

  getSelectedZubehoer(category: string): Zubehoer {
    const foundZubehoer = this.componentSelection.selectedComponent.Mehrpreise.find(
      (z): boolean => z.Typ === category && !!z.Item
    )
    if (foundZubehoer) {
      return foundZubehoer.Item
    }
    return null
  }

  getSelectedZubehoerIds(): number[] {
    return [...this.componentSelection.selectedComponent.Mehrpreise.map((z): number => z?.Item?.Id)]
  }

  mehrpreisBezeichnung(): string {
    return this.componentSelection.selectedComponent.model.Mehrpreise.find((m): boolean => m.Typ === this.category).Bezeichnung
  }

  onConfirm(): void {
    this.dialogRef.close(
      this.preselectedZubehoer
    )
  }

  preselectZubehoer(item: Zubehoer): void {
    if (this.preselectedZubehoer === item) {
      this.preselectedZubehoer = undefined
    } else {
      this.preselectedZubehoer = item
    }
  }

  removeFilter(t: Tag): void {
    this.activeFilters.splice(this.activeFilters.indexOf(t), 1)
  }


  toggleFilter(t: Tag): void {

    if (this.activeFilters.includes(t)) {
      this.removeFilter(t)
    } else {
      this.addFilter(t)
    }
    this.updatedZubehoer()
    this.updateFilterlist()
  }

  updateFilterlist(): void {
    const uniqueTags: string[] = this.updatedZubehoer().reduce((acc: string[], z: Zubehoer): string[] => {
      z.Tags.forEach((tag: string): void => {
        if (!acc.includes(tag)) {
          acc.push(tag)
        }
      })
      return acc
    }, [] as string[])

    this.visibleFilters = this.tags.filter((t: Tag): boolean => uniqueTags.includes(t.Tag) || this.activeFilters.includes(t))
  }

  updateZubehoerVisibility(zubehoer: Zubehoer): true {
    const reasons: string[] = []
    if (zubehoer.StaerkeVon === 0 && zubehoer.StaerkeBis === 0) {
      return true
    }
    if (zubehoer.StaerkeVon > 0 && this.componentSelection.selectedComponent.staerke < zubehoer.StaerkeVon) {
      reasons.push(this.translator.translate(TRANSLATION_KEY.REASON_THICKNESS_TOO_SMALL_PREFIX)
        + zubehoer.StaerkeVon
        + this.translator.translate(TRANSLATION_KEY.REASON_THICKNESS_TOO_SMALL_INFIX)
        + this.componentSelection.selectedComponent.staerke
        + this.translator.translate(TRANSLATION_KEY.REASON_THICKNESS_TOO_SMALL_SUFFIX)
      )
    }
    if (zubehoer.StaerkeBis > 0 && this.componentSelection.selectedComponent.staerke > zubehoer.StaerkeBis) {
      reasons.push(this.translator.translate(TRANSLATION_KEY.REASON_THICKNESS_TOO_LARGE_PREFIX)
        + zubehoer.StaerkeBis
        + this.translator.translate(TRANSLATION_KEY.REASON_THICKNESS_TOO_LARGE_INFIX)
        + this.componentSelection.selectedComponent.staerke
        + this.translator.translate(TRANSLATION_KEY.REASON_THICKNESS_TOO_LARGE_SUFFIX))
    }
    if (this.componentSelection.selectedComponent.material === Material.Alu && !zubehoer.IsAluMoeglich) {
      reasons.push(this.translator.translate(TRANSLATION_KEY.REASON_ALU_IMPOSSIBLE))
    }
    if (this.componentSelection.selectedComponent.material === Material.Glas && !zubehoer.IsGlasMoeglich) {
      reasons.push(this.translator.translate(TRANSLATION_KEY.REASON_GLASS_IMPOSSIBLE))
    }
    if (this.componentSelection.selectedComponent.material === Material.Kunststoff && !zubehoer.IsKunststoffMoeglich) {
      reasons.push(this.translator.translate(TRANSLATION_KEY.REASON_KU_IMPOSSIBLE))
    }
    if (!zubehoer.IsDinRechtsMoeglich && !zubehoer.IsDinLinksMoeglich) {
      // alles ok wenn beides FALSE ist
    } else if (this.componentSelection.selectedComponent.din === 0 && !zubehoer.IsDinLinksMoeglich) {
      reasons.push(this.translator.translate(TRANSLATION_KEY.REASON_DINLEFT_IMPOSSIBLE))
    } else if (this.componentSelection.selectedComponent.din === 1 && !zubehoer.IsDinRechtsMoeglich) {
      reasons.push(this.translator.translate(TRANSLATION_KEY.REASON_DINRIGHT_IMPOSSIBLE))
    }
    if (zubehoer.IsStandard && !zubehoer.IsOptional) {
      reasons.push(this.translator.translate(TRANSLATION_KEY.REASON_NOT_UNSELECTABLE))
    } else if (!zubehoer.IsStandard && !zubehoer.IsOptional) {
      reasons.push(this.translator.translate(TRANSLATION_KEY.REASON_NOT_SELECTABLE))
    }
    zubehoer.disabilityReasons = reasons
    return true
  }

  updatedZubehoer(): Zubehoer[] {
    return this.zubehoerByTerm()
  }

  zubehoerByTag(zubehoer: Zubehoer[]): Zubehoer[] {
    if (this.activeFilters.length === 0) {
      return zubehoer
    }

    const list: Zubehoer[] = []

    zubehoer.forEach((z: Zubehoer): void => {
      let allActiveFiltersMet: boolean = true
      this.activeFilters.forEach((activeTag: Tag): void => {
        if (list.includes(z)) {
          return
        }
        if (!z.Tags.includes(activeTag.Tag)) {
          allActiveFiltersMet = false
        }
      })
      if (allActiveFiltersMet) {
        list.push(z)
      }
    })


    return list
  }

  zubehoerByTerm(): Zubehoer[] {
    if (this.filterTerm.length === 0) {
      return this.zubehoerByTag(this.zubehoer)
    }
    const filteredByTerm = this.zubehoer.filter((z): boolean => {
      const bezeichnung = z.Bezeichnung && this.translator.translate(z.Bezeichnung).toLowerCase().includes(this.filterTerm.toLowerCase())
      const beschreibung = z.Beschreibung && this.translator.translate(z.Beschreibung).toLowerCase().includes(this.filterTerm.toLowerCase())
      const id = z.Id.toString().includes(this.filterTerm)
      return bezeichnung || beschreibung || id
    })

    return this.zubehoerByTag(filteredByTerm)
  }

  zubehoerPreselected(item: Zubehoer): boolean {
    return this.preselectedZubehoer?.Id === item.Id
  }

  get favoriteFilters(): Tag[] {
    return this.visibleFilters.filter((t: Tag): boolean => t.IsFavorit)
  }

  get notFavoriteFilters(): Tag[] {
    return this.visibleFilters.filter((t: Tag): boolean => !t.IsFavorit)
  }

  get tags(): Tag[] {
    return this.tagService._tags.filter((t: Tag): boolean => t.Kategorie === 'Zubehoer')
  }
}
