import { Injectable, Injector} from '@angular/core'
import {ConstructionComponent} from '../model/component/construction/constructionComponent'
import {ConfiguratorConfigurationModel} from '../model/configuratorConfigurationModel'
import {EventBusService} from './event/event-bus.service'
import {selectedComponentChanged} from './event/events'

@Injectable()
export class ComponentSelectionService {
  private currentIndex: number = 0

  constructor(
    private injector: Injector,
    private readonly eventBus: EventBusService
  ) {
  }

  updateSelectedIndex(): void {
    if (this.injector.get(ConfiguratorConfigurationModel, null)?.configuratedDoor?.Components?.length <= this.currentIndex) {
      this.selectedComponent = 0
    }
  }

  get componentsWithGlassErrors(): ConstructionComponent[] {
    return this.injector.get(ConfiguratorConfigurationModel, null)
      ?.configuratedDoor?.Components.reduce((acc: ConstructionComponent[], comp): ConstructionComponent[] => {
        if (comp?.glasaufbau?.errors.length > 0) {
          acc.push(comp)
        }
        return acc
      }, [])
  }

  get selectedComponent(): ConstructionComponent {
    return this.injector.get(ConfiguratorConfigurationModel, null)?.configuratedDoor?.Components[this.currentIndex]
  }

  set selectedComponent(componentIndex: number) {
    this.currentIndex = componentIndex
    this.eventBus.publish(selectedComponentChanged(this.selectedComponent))
  }

  get selectedComponentIndex(): number {
    return this.currentIndex
  }
}


