<modal-frame
  [footer]='footer'
  [modalCloseButton]='false'
  [title]="this.glassUpdate.mode === 'glass'
             ? (TRANSLATION_KEY.MODAL_TITLE_GLASSES|translate )
             : (TRANSLATION_KEY.MODAL_TITLE_MOTIVES|translate)"
  hostId='glassmodalDiv'
>

  <mat-form-field appearance='fill'>
    <mat-label>{{ TRANSLATION_KEY.SEARCH_LABEL|translate }}</mat-label>
    <input [(ngModel)]='this.glassUpdate.filterTerm' matInput>
  </mat-form-field>
  <ng-container *ngIf="this.glassUpdate.mode === 'glass'">
    <div class='content glaeser'>
      <ng-container *ngFor='let item of this.glassUpdate.glaeser'>
        <div
          (click)="item.disabilityReasons.length === 0 ? this.glassUpdate.togglePreselectGlas(item) : ''"
          *ngIf="this.glassUpdate.updateItemVisibility(item)
                            && ( (
                                  this.glassUpdate.filterTerm.length > 0
                                  && this.translator.translate(item.Beschreibung).toLowerCase().
                                        indexOf(this.glassUpdate.filterTerm.toLowerCase()) > -1
                                )
                                || this.glassUpdate.filterTerm === '')"
          [class.active]='this.glassUpdate.selectedGlas && (this.glassUpdate.selectedGlas.Id === item.Id)'
          [class.disabled]='item.disabilityReasons.length > 0'
          class='item'
        >
          <div
            [mtxTooltipDisabled]='item.disabilityReasons.length === 0'
            [mtxTooltip]='StringUtil.concatStringArray(item.disabilityReasons)'
            class='image-wrapper'
          >
            <div (click)='this.showImageModal(item, $event)' class='zoomglassicon'>
              <i class='fa fa-search-plus fa-3x fa-fw'></i>
            </div>
            <div *ngIf='!item.loaded && item.PreviewImageUrl' class='icon loading-image-spinner'>
              <i class='fa fa-spinner fa-pulse fa-3x fa-fw'></i>
            </div>
            <img (load)='item.loaded = true' [lazyLoad]='item.PreviewImageUrl || item.ImageUrl'>
          </div>
          <p>
            <span class='option-text'>{{ item.Beschreibung | translate }}</span>
          </p>
        </div>
      </ng-container>
    </div>
  </ng-container>

  <ng-container *ngIf="this.glassUpdate.mode === 'motiv'">
    <div class='content motive'>
      <ng-container *ngFor='let item of this.glassUpdate.motive'>
        <div
          (click)="item.disabilityReasons.length === 0 ? this.glassUpdate.togglePreselectMotiv(item) : ''"
          *ngIf="this.glassUpdate.updateItemVisibility(item)
                            && ( (
                                  this.glassUpdate.filterTerm.length > 0
                                  && this.translator.translate(item.Beschreibung).toLowerCase()
                                        .indexOf(this.glassUpdate.filterTerm.toLowerCase()) > -1
                                )
                                || this.glassUpdate.filterTerm === '')"
          [class.active]='this.glassUpdate.selectedMotiv && (this.glassUpdate.selectedMotiv.Id === item.Id)'
          [class.disabled]='item.disabilityReasons.length > 0'
          class='item'
        >
          <div
            [mtxTooltipDisabled]='item.disabilityReasons.length === 0'
            [mtxTooltip]='StringUtil.concatStringArray(item.disabilityReasons)'
            class='image-wrapper'
          >
            <div (click)='this.showImageModal(item, $event)' class='zoomglassicon'>
              <i class='fa fa-search-plus fa-3x fa-fw'></i>
            </div>
            <div *ngIf='!item.loaded && item.PreviewImageUrl' class='icon loading-image-spinner'>
              <i class='fa fa-spinner fa-pulse fa-3x fa-fw'></i>
            </div>
            <img (load)='item.loaded = true' [lazyLoad]='item.PreviewImageUrl || item.ImageUrl'>
          </div>
          <p>
            <span class='option-text'>{{ item.Beschreibung | translate }}</span>
          </p>
        </div>
      </ng-container>
    </div>
  </ng-container>
</modal-frame>
<ng-template #footer>
  <button (click)='this.onConfirm()' color='primary' mat-flat-button>{{ TRANSLATION_KEY.SAVE_BUTTON | translate }}
  </button>
</ng-template>

