import {Component, ElementRef, Inject, ViewChild} from '@angular/core'
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog'
import {Hausfront} from '../../../classes/model/component/hausfront/hausfront'
import {HausfrontDoorPosition} from '../../../class'
import {UploaderOptions, UploadFile, UploadOutput} from '@angular-ex/uploader'
import {ConfiguratorConfigurationModel} from '../../../classes/model/configuratorConfigurationModel'
import {ViewService} from '../../../classes/service/view.service'
import {GrundformService} from '../../../classes/service/grundform.service'
import {SizeChange} from '../../../d3/door.mask'

const TRANSLATION_KEY = {
  TITLE: 'HausfrontModal.Title',
  INFO_DRAG_FILE_HERE: 'HausfrontModal.DragHere',
  INFO_OR: 'HausfrontModal.Or',
  INFO_DRAG_CORNERS: 'HausfrontModal.DragCorners',
  FILESIZE_PREFIX: 'HausfrontModal.Filesize.Prefix',
  FILESIZE_SUFFIX: 'HausfrontModal.Filesize.Suffix',
  ERROR_USE_LANDSCAPE: 'HausfrontModal.Error.UseLandscape',
  BUTTON_SAVE_LABEL: 'HausfrontModal.Button.Save.Label',
  BUTTON_CHOOSE_IMAGE_LABEL: 'HausfrontModal.Button.ChooseImage.Label'
}

@Component({
  templateUrl: './hausfront-modal.component.html',
  styleUrls: ['./hausfront-modal.component.scss']
})
export class HausfrontModalComponent {
  protected readonly TRANSLATION_KEY = TRANSLATION_KEY
  customHausfront: Hausfront
  dragOver: boolean
  edit: boolean
  files: UploadFile[]
  options: UploaderOptions
  uploadImagePath: string
  uploadPosition: HausfrontDoorPosition
  uploadStatus: string
  uploadedHTMLImage: HTMLElement
  uploadedImageData: File
  @ViewChild('uploadedImageRef') private uploadedImageRef: ElementRef<HTMLImageElement>
  uploaderError: string

  // uploadImageConstruction: Grundform
  constructor(
    private dialogRef: MatDialogRef<HausfrontModalComponent, Hausfront | false>,
    private configuratorConfigurationModel: ConfiguratorConfigurationModel,
    private viewService: ViewService,
    private grundformService: GrundformService,
    @Inject(MAT_DIALOG_DATA) data: Hausfront
  ) {
    this.files = []
    this.uploadImagePath = null
    this.uploadStatus = null
    // this.customHausfront.ElementHoehe = this.doorHeight
    // this.customHausfront.ElementBreite = this.doorWidth
    this.uploadPosition = new HausfrontDoorPosition()
    this.options = {
      concurrency: 1,
      maxUploads: 3,
      maxFileSize: 10000000,
      allowedContentTypes: [
        'image/png',
        'image/jpeg'
      ]
    }
    if (data) {
      this.edit = true
      this.customHausfront = new Hausfront(data)
      this.uploadImagePath = this.customHausfront.Url
      this.uploadStatus = 'Done'
      setTimeout((): void => {
        this.onLoadImageEdit()
      }, 500)
    } else {
      this.edit = false
      this.customHausfront = new Hausfront(this.configuratorConfigurationModel.configuratedDoor.Hausfronten[this.viewService.currentSide])
      this.customHausfront.GrundformId = this.configuratorConfigurationModel.configuratedDoor.Grundform.Id
      this.customHausfront.Custom = true
    }
  }

  maskPositionFirst(): void {
    // Standard position auf dem Bild setzen
    this.customHausfront.ElementHoehe = this.configuratorConfigurationModel.configuratedDoor.height
    this.customHausfront.ElementBreite = this.configuratorConfigurationModel.configuratedDoor.width
    const doorImageHeight = Math.min(this.customHausfront.BildPxHoehe, 800)
    const doorImageWidth =
      (doorImageHeight / this.customHausfront.ElementHoehe) *
      this.customHausfront.ElementBreite
    // x und y werte neu für die HT brechnen
    // Mitte des Bildes Upload
    const middleOfWidth = this.customHausfront.BildPxBreite / 2
    const middleOfHeight = this.customHausfront.BildPxHoehe / 2
    this.customHausfront.Custom = true
    // this.uploadedImage.Key = this.uploadImageConstruction.Key
    // Top Left
    this.customHausfront.X1 = middleOfWidth - doorImageWidth / 2
    this.customHausfront.Y1 = middleOfHeight - doorImageHeight / 2
    // Top Right
    this.customHausfront.X2 = middleOfWidth + doorImageWidth / 2
    this.customHausfront.Y2 = middleOfHeight - doorImageHeight / 2
    // Bottom Right
    this.customHausfront.X3 = middleOfWidth + doorImageWidth / 2
    this.customHausfront.Y3 = middleOfHeight + doorImageHeight / 2
    // Bottom Left
    this.customHausfront.X4 = middleOfWidth - doorImageWidth / 2
    this.customHausfront.Y4 = middleOfHeight + doorImageHeight / 2
    // this.customHausfront.ElementBreite = doorImageWidth
    // this.customHausfront.ElementHoehe = doorImageHeight
    this.uploadPosition.x0 =
      (this.customHausfront.X1 / this.customHausfront.BildPxBreite) *
      this.uploadPosition.backgroundWidth
    this.uploadPosition.y0 =
      (this.customHausfront.Y1 / this.customHausfront.BildPxHoehe) *
      this.uploadPosition.backgroundHeight
    this.uploadPosition.x1 =
      (this.customHausfront.X2 / this.customHausfront.BildPxBreite) *
      this.uploadPosition.backgroundWidth
    this.uploadPosition.y1 =
      (this.customHausfront.Y2 / this.customHausfront.BildPxHoehe) *
      this.uploadPosition.backgroundHeight
    this.uploadPosition.x2 =
      (this.customHausfront.X3 / this.customHausfront.BildPxBreite) *
      this.uploadPosition.backgroundWidth
    this.uploadPosition.y2 =
      (this.customHausfront.Y3 / this.customHausfront.BildPxHoehe) *
      this.uploadPosition.backgroundHeight
    this.uploadPosition.x3 =
      (this.customHausfront.X4 / this.customHausfront.BildPxBreite) *
      this.uploadPosition.backgroundWidth
    this.uploadPosition.y3 =
      (this.customHausfront.Y4 / this.customHausfront.BildPxHoehe) *
      this.uploadPosition.backgroundHeight
  }

  maskPositionOnResize(size: SizeChange): void {
    // TL
    this.uploadPosition.x0 =
      (this.customHausfront.X1 / this.customHausfront.BildPxBreite) *
      size.width.currentValue
    this.uploadPosition.y0 =
      (this.customHausfront.Y1 / this.customHausfront.BildPxHoehe) *
      size.height.currentValue
    // TR
    this.uploadPosition.x1 =
      (this.customHausfront.X2 / this.customHausfront.BildPxBreite) *
      size.width.currentValue
    this.uploadPosition.y1 =
      (this.customHausfront.Y2 / this.customHausfront.BildPxHoehe) *
      size.height.currentValue
    // BR
    this.uploadPosition.x2 =
      (this.customHausfront.X3 / this.customHausfront.BildPxBreite) *
      size.width.currentValue
    this.uploadPosition.y2 =
      (this.customHausfront.Y3 / this.customHausfront.BildPxHoehe) *
      size.height.currentValue
    // BL
    this.uploadPosition.x3 =
      (this.customHausfront.X4 / this.customHausfront.BildPxBreite) *
      size.width.currentValue
    this.uploadPosition.y3 =
      (this.customHausfront.Y4 / this.customHausfront.BildPxHoehe) *
      size.height.currentValue
  }

  onChangePoints(
    points: [{ x: number; y: number }, { x: number; y: number }, { x: number; y: number }, { x: number; y: number }]
  ): void {
    this.customHausfront.X1 =
      Math.round((points[0].x / this.uploadPosition.backgroundWidth) *
        this.customHausfront.BildPxBreite)
    this.customHausfront.Y1 =
      Math.round((points[0].y / this.uploadPosition.backgroundHeight) *
        this.customHausfront.BildPxHoehe)
    this.customHausfront.X2 =
      Math.round((points[1].x / this.uploadPosition.backgroundWidth) *
        this.customHausfront.BildPxBreite)
    this.customHausfront.Y2 =
      Math.round((points[1].y / this.uploadPosition.backgroundHeight) *
        this.customHausfront.BildPxHoehe)
    this.customHausfront.X3 =
      Math.round((points[2].x / this.uploadPosition.backgroundWidth) *
        this.customHausfront.BildPxBreite)
    this.customHausfront.Y3 =
      Math.round((points[2].y / this.uploadPosition.backgroundHeight) *
        this.customHausfront.BildPxHoehe)
    this.customHausfront.X4 =
      Math.round((points[3].x / this.uploadPosition.backgroundWidth) *
        this.customHausfront.BildPxBreite)
    this.customHausfront.Y4 =
      Math.round((points[3].y / this.uploadPosition.backgroundHeight) *
        this.customHausfront.BildPxHoehe)
    this.uploadPosition.x0 = points[0].x
    this.uploadPosition.y0 = points[0].y
    this.uploadPosition.x1 = points[1].x
    this.uploadPosition.y1 = points[1].y
    this.uploadPosition.x2 = points[2].x
    this.uploadPosition.y2 = points[2].y
    this.uploadPosition.x3 = points[3].x
    this.uploadPosition.y3 = points[3].y
    this.uploadPosition.doorHeight = this.customHausfront.ElementHoehe
    this.uploadPosition.doorWidth = this.customHausfront.ElementBreite
  }

  onChangeUploadedImage(e: Event): void {
    this.uploadStatus = 'done'
    if (e.target instanceof HTMLImageElement) {
      this.customHausfront.BildPxHoehe = e.target.height
      this.customHausfront.BildPxBreite = e.target.width
    }
  }

  onChangeUploadedImagePosition(): void {
    if (this.uploadedHTMLImage) {
      this.uploadPosition.backgroundWidth = this.uploadedHTMLImage.getBoundingClientRect().width
      this.uploadPosition.backgroundHeight = this.uploadedHTMLImage.getBoundingClientRect().height
    }
  }

  onConfirm(hausfront: Hausfront): void {
    this.dialogRef.close(hausfront)
  }

  onLoadImageEdit(): void {
    this.uploadedHTMLImage = document.getElementById('uploadedImage')
    this.uploadPosition.backgroundWidth = this.uploadedHTMLImage.getBoundingClientRect().width
    this.uploadPosition.backgroundHeight = this.uploadedHTMLImage.getBoundingClientRect().height
    this.uploadPosition.x0 =
      (this.customHausfront.X1 / this.customHausfront.BildPxBreite) * this.uploadPosition.backgroundWidth
    this.uploadPosition.x1 =
      (this.customHausfront.X2 / this.customHausfront.BildPxBreite) * this.uploadPosition.backgroundWidth
    this.uploadPosition.x2 =
      (this.customHausfront.X3 / this.customHausfront.BildPxBreite) * this.uploadPosition.backgroundWidth
    this.uploadPosition.x3 =
      (this.customHausfront.X4 / this.customHausfront.BildPxBreite) * this.uploadPosition.backgroundWidth
    this.uploadPosition.y0 =
      (this.customHausfront.Y1 / this.customHausfront.BildPxHoehe) * this.uploadPosition.backgroundHeight
    this.uploadPosition.y1 =
      (this.customHausfront.Y2 / this.customHausfront.BildPxHoehe) * this.uploadPosition.backgroundHeight
    this.uploadPosition.y2 =
      (this.customHausfront.Y3 / this.customHausfront.BildPxHoehe) * this.uploadPosition.backgroundHeight
    this.uploadPosition.y3 =
      (this.customHausfront.Y4 / this.customHausfront.BildPxHoehe) * this.uploadPosition.backgroundHeight
  }

  onUploadOutput(output: UploadOutput): void {
    switch (output.type) {
      case 'allAddedToQueue':
        // uncomment this if you want to auto upload files when added
        // const event: UploadInput = {
        //   type: 'uploadAll',
        //   url: '/upload',
        //   method: 'POST',
        //   data: { foo: 'bar' }
        // };
        // this.uploadInput.emit(event);
        break
      case 'addedToQueue':
        this.uploadStatus = 'loading'
        if (typeof output.file !== 'undefined') {
          this.uploadImagePath = null
          this.uploadedHTMLImage = null
          this.readFile(output.file.nativeFile)
          this.files.push(output.file)
        }
        break
      case 'uploading':
        this.uploadStatus = 'loading'
        if (typeof output.file !== 'undefined') {
          // update current data in files array for uploading file
          const index = this.files.findIndex(
            (file): boolean =>
              typeof output.file !== 'undefined' && file.id === output.file.id
          )
          this.files[index] = output.file
        }
        break
      case 'removed':
        // remove file from array when removed
        this.files = this.files.filter(
          (file: UploadFile): boolean => file !== output.file
        )
        break
      case 'dragOver':
        this.dragOver = true
        break
      case 'dragOut':
      case 'drop':
        this.dragOver = false
        break
      case 'rejected':
        if (!this.options.allowedContentTypes.some((t): boolean => t === output.file.type)) {
          /* this.messageToasty(
              this._translate.translate("Ungültiges Dateiformat"),
              this._translate.translate(
                `Dieses Dateiformat wird nicht unterstützt. Bitte wählen sie eine jpeg oder png Datei.`
              ),
              "error"
            )*/
        } else if (output.file.size > this.options.maxFileSize) {
          // your code
          /* this.messageToasty(
              this._translate.translate("Datei zu groß"),
              this._translate.translate(
                `Die Datei darf maximal ${(this.options.maxFileSize / 1024 / 1024).toFixed(1)} MB groß sein.`
              ),
              "error"
            )*/
        }
        break
      case 'done':
        this.uploadStatus = 'done'
        // The file is downloaded
        break
    }
  }

  postNew(hausfront: Hausfront, image: File, elementWidth: number, elementHeight: number): void {
    this.grundformService.createCustomHausfront(hausfront, image, elementWidth, elementHeight).then((hf): void => {
      this.uploadStatus = 'done'
      this.onConfirm(hf)
    }).catch((error): void => {
      console.error(error)
    })
  }

  postUpdate(hausfront: Hausfront, elementWidth: number, elementHeight: number): void {
    this.grundformService.updateCustomHausfront(hausfront, elementWidth, elementHeight).then((hf: Hausfront): void => {
      this.uploadStatus = 'done'
      this.onConfirm(hf)
    }).catch((error): void => {
      console.error(error)
    })
  }

  postUploadedImage(hausfront: Hausfront, image: File, elementWidth: number, elementHeight: number): void {
    if (this.edit) {
      this.postUpdate(hausfront, elementWidth, elementHeight)
    } else {
      this.postNew(hausfront, image, elementWidth, elementHeight)
    }
  }

  readFile(inputFile: File): void {
    const file: File = inputFile
    const reader = new FileReader()
    this.uploadedImageData = inputFile
    reader.onloadend = (): void => {
      const image = new Image()
      // const imageUrl = reader.result.toString()
      const imageUrl = typeof reader.result === 'string' ? reader.result : (new TextDecoder()).decode(reader.result)
      image.src = imageUrl
      image.onload = (): void => {
        this.uploaderError = null
        this.uploadImagePath = imageUrl
        this.customHausfront.Url = imageUrl
        this.customHausfront.PreviewUrl = imageUrl
      }
      image.addEventListener('load', (e): void => {
        this.uploadedHTMLImage = document.getElementById('uploadedImage')
        this.onChangeUploadedImage(e)
        this.onChangeUploadedImagePosition()
        this.maskPositionFirst()
      })
    }
    reader.readAsDataURL(file)
  }

  saveHausfrontChanges(hausfront: Hausfront, elementWidth: number, elementHeight: number): void {
    this.uploadStatus = 'loading'
    this.postUploadedImage(hausfront, this.uploadedImageData, elementWidth, elementHeight)
  }

  zoomIn(): void {
    let currentScale: number = parseFloat(this.uploadedImageRef.nativeElement.style.scale)
    if (isNaN(currentScale)) {
      currentScale = 1
    }
    this.uploadedImageRef.nativeElement.style.scale = (currentScale + 0.1).toString()
  }

  zoomOut(): void {
    let currentScale: number = parseFloat(this.uploadedImageRef.nativeElement.style.scale)
    if (isNaN(currentScale)) {
      currentScale = 1
    }
    this.uploadedImageRef.nativeElement.style.scale = (currentScale - 0.1).toString()
  }

  get doorHeight(): number {
    return this.configuratorConfigurationModel.configuratedDoor.height
  }

  get doorWidth(): number {
    return this.configuratorConfigurationModel.configuratedDoor.width
  }
}
