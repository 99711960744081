import {Component, ElementRef, HostListener, Input, QueryList, ViewChildren} from '@angular/core'
import {Hausfront} from '../../../classes/model/component/hausfront/hausfront'
import {ConfiguratorConfigurationModel} from '../../../classes/model/configuratorConfigurationModel'
import {InsideOutsideObject, SideType} from '../../../types'
import {ModalService} from '../../modal/modal.service'
import {ViewService} from '../../../classes/service/view.service'
import {GrundformService} from '../../../classes/service/grundform.service'
import {ConfiguratedDoor} from '../../../classes/model/configuratedDoor'
import {NavigatorService} from '../../../classes/service/navigation/navigator.service'
import {NavigationMenuEntryKey} from '../../../classes/service/navigation/navigation-menu-entry'
import {EventBusSupport} from '../../../classes/service/event/event-bus-support'
import {EventBusService} from '../../../classes/service/event/event-bus.service'
import {activeMenuChange, grundformChange, renderRequest} from '../../../classes/service/event/events'
import {NGXLogger} from 'ngx-logger'

const TRANSLATION_KEY = {
  TITLE: 'HausfrontMenuComponent.Title',
  USE_CUSTOM_MILIEU: 'HausfrontMenuComponent.UseCustomMilieu'
}

@Component({
  selector: 'configurator-hausfront-menu',
  templateUrl: './hausfront-menu.component.html',
  styleUrls: ['./hausfront-menu.component.scss']
})
export class HausfrontMenuComponent extends EventBusSupport {
  protected readonly NavigationMenuEntryKey = NavigationMenuEntryKey
  protected readonly SideType = SideType
  protected readonly TRANSLATION_KEY = TRANSLATION_KEY
  @ViewChildren('doorPreviewImage', {read: ElementRef}) private doorPreviewImage: QueryList<ElementRef>
  @ViewChildren('hausfrontPreviewImage', {read: ElementRef}) private hausfrontPreviewImage: QueryList<ElementRef>
  @Input() lazyImageLoaded: (object: unknown) => void
  protected loadingBackground: boolean

  constructor(
    private readonly navigatorService: NavigatorService,
    private readonly logger: NGXLogger,
    private _modalService: ModalService,
    private _viewService: ViewService,
    private _configuratorConfigurationModel: ConfiguratorConfigurationModel,
    private grundformService: GrundformService,
    eventBus: EventBusService
  ) {
    super(eventBus)

    this.loadingBackground = false

    this.subscribe(grundformChange, (): void => {
      this.onGrundformChange()
    })

    this.subscribe(activeMenuChange, (event): void => {
      if (event.payload === NavigationMenuEntryKey.HouseFront) {
        setTimeout((): void => {
          this.setMiniDoorPosition()
        }, 300)
      }
    })
  }

  protected closeMenu(): void {
    this.navigatorService.closeMenu()
  }

  createCustomHouseFront(): void {
    this._modalService.showHausfrontModal()
      .afterClosed()
      .subscribe((houseFront): void => {
        if (houseFront) {
          const serviceHouseFront = this.grundformService.getHausfront(
            this.configuratedDoor.Grundform.Key,
            this.view,
            houseFront.Id
          )
          if (
            houseFront.IsInnen !== (this.view === SideType.Inside)
            || houseFront.IsAussen !== (this.view === SideType.Outside)
          ) {
            this.logger.error(
              'Custom house front has wrong side flag(s) set!',
              {
                houseFront,
                serviceHouseFront,
                side: this.view,
              })
          }
          if (!serviceHouseFront) {
            this.logger.error(
              'Could not find new house front in service!',
              {
                grundformService: this.grundformService,
                houseFront,
                serviceHouseFront,
              })
            return
          }
          this._configuratorConfigurationModel.setHouseFront(serviceHouseFront, this.view)
        }
      })
  }

  deleteCustomHausfront(customHouseFront: Hausfront): void {
    const currentHouseFront = this.configuratedDoor.Hausfronten?.[this.view]
    this.grundformService.removeCustomHausfront(customHouseFront)
    if (currentHouseFront && currentHouseFront.Id !== customHouseFront.Id) {
      return
    }
    const replacementHouseFront = this.grundformService.getHausfront(this.configuratedDoor.Grundform.Key, this.view)
    this._configuratorConfigurationModel.setHouseFront(replacementHouseFront, this.view)
    this.publish(renderRequest())
  }

  editCustomHausfront(hausfront: Hausfront): void {
    this._modalService.showHausfrontModal(hausfront)
      .afterClosed()
      .subscribe((hf): void => {
        if (hf) {
          console.log('editCustomhausfront renderREQUEST')
          this.publish(renderRequest())
        }
      })
  }

  onGrundformChange(): void {
    const CALL_RENDER_AFTER_GRUNDFORMCHANGE: boolean = false
    if (this.currentHausfronten) {
      [SideType.Inside, SideType.Outside].filter((side): boolean => (this.currentHausfronten[side] instanceof Hausfront))
        .forEach((side): void => {
          this.selectHouseFront(
            this.grundformService.findHausfront(side, this.currentHausfronten[side]?.Bezeichnung)
              ?? this.grundformService.findHausfront(side)
              ?? new Hausfront(),
            side,
            true,
            CALL_RENDER_AFTER_GRUNDFORMCHANGE
          )
        }
        )
    }
  }

  @HostListener('window:resize')
  onResize(): void {
    if (this.configuratedDoor?.Hausfronten) {
      this.setMiniDoorPosition()
    }
  }

  selectHouseFront(houseFront: Hausfront, side: SideType = this.view, force: boolean = false, callRender: boolean = true): void {
    side ??= this.view
    if (houseFront.Id === this.configuratedDoor.Hausfronten[side]?.Id) {
      return
    }
    const setHouseFront = (): void => {
      this._configuratorConfigurationModel.setHouseFront(houseFront, side, callRender)
      if (callRender) {
        this.publish(renderRequest())
      }
    }
    // ohne Abfrage, wenn bestehende maße identisch sind mit der gewählten hausfront, oder man von einer standard
    // hausfront zu einer standard hausfront wechselt
    if (houseFront.Custom) {
      void this.grundformService.updateCustomHausfront(
        houseFront,
        this.configuratedDoor.width,
        this.configuratedDoor.height
      ).then((): void => setHouseFront())
      return
    } else if (
      force
      || this.configuratedDoor.measuresMatch(houseFront)
      || (
        this._configuratorConfigurationModel.configuratedDoor.Hausfronten?.[side]
        && houseFront
        && !this._configuratorConfigurationModel.configuratedDoor.Hausfronten[side].Custom
        && !houseFront.Custom
      )) {
      setHouseFront()
      return
    }
    this._modalService.showChooseDefaultHausfrontModal()
      .afterClosed()
      .subscribe((result): void => {
        if (!result) {
          setHouseFront()
        }
      })
  }

  setMiniDoorPosition(): void {
    this.doorPreviewImage.forEach((doorPreviewRef): void => {
      const doorPreview: unknown = doorPreviewRef.nativeElement
      if (!(doorPreview instanceof HTMLImageElement)) {
        return
      }
      const houseFrontPreview: unknown = this.hausfrontPreviewImage
        .find((image): boolean => doorPreview.previousElementSibling === image.nativeElement)?.nativeElement
      const houseFront = this.hausfronten.find((front): boolean => String(front.Id) === doorPreview.dataset.hausfront)
      if (!(houseFrontPreview instanceof HTMLImageElement) || !houseFront) {
        return
      }
      const scale = houseFront.BildPxBreite / houseFrontPreview.getBoundingClientRect().width
      doorPreview.style.setProperty(
        '--minidoor-left',
        `${houseFront.X1 / scale + houseFrontPreview.offsetLeft}px`
      )
      doorPreview.style.setProperty('--minidoor-top',
        `${houseFront.Y1 / scale + houseFrontPreview.offsetTop}px`
      )
      doorPreview.style.setProperty('--minidoor-display', 'block')
      doorPreview.style.setProperty('--minidoor-width', `${(houseFront.X2 - houseFront.X1) / scale}px`)
      doorPreview.style.setProperty('--minidoor-height', `${(houseFront.Y3 - houseFront.Y1) / scale}px`)
    })
  }

  get configuratedDoor(): ConfiguratedDoor {
    return this._configuratorConfigurationModel.configuratedDoor
  }

  get currentHausfronten(): InsideOutsideObject<Hausfront> {
    return this._configuratorConfigurationModel?.configuratedDoor?.Hausfronten
  }

  get hausfronten(): Hausfront[] {
    return this.grundformService.grundform.Hausfronten
  }

  get view(): SideType {
    return this._viewService.currentSide
  }
}
